import React, {
    useEffect, useState, useCallback, useRef, useContext, useMemo,
    cloneElement,
} from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import ComponentList from '../shared/componentList';
import {
    UserCreatedComponentConfig,
    getCustomComponentLatestVersion,
} from '../../store/processes/studioFile';
import { UserCreatedComponent, WebpackModFedContainer } from './type';
import { SDKContext } from '../../sdkContext';
import {
    initContainer,
    initSharing,
    getModFedContainer,
} from './utils';
import { selectScriptStatus } from '../../store/processes/customComponents';
import { LOADABLE_STATUS_READY } from '../../store/processes/loadableState';
import { ComponentProps } from '../shared/types';
import {
    RuntimePromise,
    makeUseMultiMacros,
    useMacroScope,
    mapPairs,
} from '../shared/macroHelpers';
import {
    UserCreatedComponentMacroOptionStatus,
    UserCreatedComponentStaticConfig,
} from '../../store/processes/studioFile/componentConfigs/userCreatedComponent';

const useUserCreatedComponent = (
    uuid: string,
    latestVersion: string,
): [UserCreatedComponent, UserCreatedComponentStaticConfig] | [null, null] => {
    const [refReady, setRefReady] = useState(false);
    const ref = useRef<any>(null);
    const sdk = useContext(SDKContext);
    const scriptStatus = useSelector(selectScriptStatus(uuid));

    useEffect(() => {
        (async () => {
            if (scriptStatus === LOADABLE_STATUS_READY) {
                const modFedContainer = getModFedContainer(uuid) as WebpackModFedContainer;
                await initSharing();
                const [, componentModuleFactory, configModuleFactory] = await Promise.all([
                    initContainer(modFedContainer),
                    modFedContainer.get('./component'),
                    modFedContainer.get('./config'),
                ]);
                const builtComponentModule = componentModuleFactory();
                const builtConfigModule = configModuleFactory();
                ref.current = [
                    typeof(builtComponentModule.default) == 'object'
                        ? cloneElement(
                            builtComponentModule.default as unknown as React.ReactElement<any>,
                            { 'bb-reset': true }
                        )
                        : builtComponentModule.default,
                    builtConfigModule
                ];
                setRefReady(true);
            }
        })();
    }, [scriptStatus, uuid, latestVersion]);

    return refReady && sdk ? ref.current : [null, null];
};

const runtimePromiseToMacroStatus = (
    prop: string,
    promise: RuntimePromise.RuntimePromise<string, string>,
): UserCreatedComponentMacroOptionStatus => {
    if (RuntimePromise.isSuccess(promise)) {
        return {
            status: 'Resolved',
            message: 'Successfully evaluated macro',
        };
    }
    if (RuntimePromise.isErr(promise)) {
        return {
            status: 'Error',
            message: RuntimePromise.unwrapErr(promise),
        };
    }
    return {
        status: 'Unresolved',
        message: 'Will evaluate macro',
    };
};

type UserCreatedComponentProps = ComponentProps<unknown> & UserCreatedComponentConfig;

const UserCreatedComponentContainer = ({
    options: { componentUuid, userDefinedOptions },
    componentLists,
    macroContext,
}: UserCreatedComponentProps) => {
    const componentLatestVersion = useSelector(getCustomComponentLatestVersion(componentUuid));
    const [Component, config] = useUserCreatedComponent(componentUuid, componentLatestVersion);

    const childListId = componentLists?.content ?? null;

    const renderChildren = useCallback(() => (
        childListId
            ? <ComponentList id={childListId} />
            : <></>
    ), [childListId]);

    const staticProps = userDefinedOptions as Record<string, string>;
    const staticMacroEnabledOptionNames = useMemo(() => (
        (config?.options ?? [])
            .filter(({ enableMacros }) => enableMacros === true)
            .map(({ name }) => name)
        ),
        [config?.options]
    );
    const staticEditors = useMemo(() => (
        (config?.options ?? [])
            // @ts-expect-error type provided by component config
            .filter(({ type }) => type === 'htmlEditor')
            .map(({ name }) => name)
        ),
        [config?.options]
    );
    const componentDisplayName = config?.displayName ?? 'UserCreatedComponent';
    const macroScope = useMacroScope(macroContext);
    const macros: Record<string, string> = useMemo(() => (
        R.pick(staticMacroEnabledOptionNames, staticProps)
    ), [staticMacroEnabledOptionNames, staticProps]);
    const macroOptions = useMemo(() => R.mapObjIndexed((_, key) => ({
        htmlLinebreaks: staticEditors.includes(key),
    }), macros), [staticEditors, macros]);
    const useMacros = useMemo(() => makeUseMultiMacros(componentDisplayName, macroOptions),
        [macroOptions, componentDisplayName]);
    const macroResults = useMacros(macroScope, macros);
    const macrosStatus = useMemo(() => RuntimePromise.allObject(macroResults), [macroResults]);

    const delayRender: boolean = useMemo(() => !Component || !(
        RuntimePromise.isSuccess(macrosStatus)
        || (Component.renderWhenMacroUnresolved === true
            && RuntimePromise.isUnresolved(macrosStatus))
        || (Component.renderWhenMacroError === true
            && RuntimePromise.isErr(macrosStatus))
    ), [Component, macrosStatus]);

    const options = useMemo(() => (!Component ? {}
        : mapPairs(([k, v]: [string, string | undefined]) => [
            k,
            staticMacroEnabledOptionNames.includes(k)
                ? RuntimePromise.unwrapOr(undefined, macroResults[k])
                : v,
        ], staticProps)),
        [Component, staticProps, staticMacroEnabledOptionNames, macroResults]);

    const optionStatus = useMemo(() => (Component?.renderWhenMacroError === true
        || Component?.renderWhenMacroUnresolved === true
        ? mapPairs(([k, v]
            : [string, RuntimePromise.RuntimePromise<string, string>]) => [
                k, runtimePromiseToMacroStatus(k, v),
            ], macroResults)
        : undefined),
        [Component, macroResults]);

    if (!Component || delayRender) {
        return null;
    }

    return (
        <Component
            key={componentUuid}
            options={options}
            optionStatus={optionStatus}
            renderChildren={renderChildren}
        />
    );
};

export default UserCreatedComponentContainer;
