export const LOADABLE_STATUS_UNINITIALIZED = (
    'LOADABLE_STATUS_UNINITIALIZED' as const
);
export const LOADABLE_STATUS_LOADING = 'LOADABLE_STATUS_LOADING' as const;
export const LOADABLE_STATUS_READY = 'LOADABLE_STATUS_READY' as const;
export const LOADABLE_STATUS_ERROR = 'LOADABLE_STATUS_ERROR' as const;

type Uninitialized = { readonly status: typeof LOADABLE_STATUS_UNINITIALIZED };
type Loading = { readonly status: typeof LOADABLE_STATUS_LOADING };
type Ready<A> = {
    readonly status: typeof LOADABLE_STATUS_READY;
    readonly value: A;
};
type Err<E> = {
    readonly status: typeof LOADABLE_STATUS_ERROR;
    readonly error: E;
};

export type Type<A, E> =
    | Uninitialized
    | Loading
    | Ready<A>
    | Err<E>;

export const Uninitialized: Uninitialized = {
    status: LOADABLE_STATUS_UNINITIALIZED,
};
export const Loading: Loading = { status: LOADABLE_STATUS_LOADING };
export const Ready = <A>(value: A): Type<A, never> => ({
    status: LOADABLE_STATUS_READY,
    value,
});
export const Err = <E>(error: E): Type<never, E> => ({
    status: LOADABLE_STATUS_ERROR,
    error,
});
export const unwrap = <A>(value: Ready<A>): A => value.value;
export const unwrapErr = <E>(value: Err<E>): E => value.error;

export const isUninitialized = (
    state: Type<any, any>,
): state is Uninitialized => (
    state.status === LOADABLE_STATUS_UNINITIALIZED
);
export const isLoading = (state: Type<any, any>): state is Loading => (
    state.status === LOADABLE_STATUS_LOADING
);
export const isReady = <A>(state: Type<A, any>): state is Ready<A> => (
    state.status === LOADABLE_STATUS_READY
);
export const isError = <E>(state: Type<any, E>): state is Err<E> => (
    state.status === LOADABLE_STATUS_ERROR
);
export const unwrapOr = <A, B>(fallback: A, state: Type<B, any>): A | B => (
    isReady(state)
        ? state.value
        : fallback
);
