export const VIDEO_SOURCE_TYPE_VIDEO = 'video' as const;
export const VIDEO_SOURCE_TYPE_PLAYLIST = 'playlist' as const;
export const VIDEO_SOURCE_TYPE_USER = 'user' as const;

export type VideoSourceType =
    | typeof VIDEO_SOURCE_TYPE_VIDEO
    | typeof VIDEO_SOURCE_TYPE_PLAYLIST
    | typeof VIDEO_SOURCE_TYPE_USER;

export type YoutubeOptions = {
    type: VideoSourceType;
    term: string;
};

type YoutubeConfig = {
    options: YoutubeOptions;
};

export default YoutubeConfig;
