import * as R from 'ramda';
import { State } from '../index';
import * as RuntimePromise from '../../../macros/promise';
import * as SimpleDict from '../../../utils/simpleDict';
import {
    VALIDATION_REQUIRED,
    Validation,
    Validations,
    validationsArray,
} from '../studioFile/componentConfigs/shared/form';

export const FORM_STATUS_SETUP = 'SETUP' as const;
export const FORM_STATUS_INITIAL = 'INITIAL' as const;
export const FORM_STATUS_SUBMITTING = 'SUBMITTING' as const;
export const FORM_STATUS_DONE = 'DONE' as const;
export const FORM_STATUS_FAILED = 'FAILED' as const;
export const FORM_STATUS_REDIRECT = 'REDIRECT' as const;

export type FormStatus =
    | typeof FORM_STATUS_SETUP
    | typeof FORM_STATUS_INITIAL
    | typeof FORM_STATUS_SUBMITTING
    | typeof FORM_STATUS_DONE
    | typeof FORM_STATUS_FAILED
    | typeof FORM_STATUS_REDIRECT;


export const FORM_SET_ATTRIBUTE = 'FORMS/SET_ATTRIBUTE' as const;
const FORM_TRANSITION_STATUS = 'FORMS/TRANSITION_STATUS' as const;

type FormSetAttributeAction = {
    type: typeof FORM_SET_ATTRIBUTE;
    formId: string;
    name: string;
    value: RuntimePromise.Type<string | boolean, string>;
    validation: Validations;
};

export const formSetAttribute = (
    formId: string,
    name: string,
    value: RuntimePromise.Type<string | boolean, string>,
    validation: Validations,
): FormSetAttributeAction => ({
    type: FORM_SET_ATTRIBUTE,
    formId,
    name,
    value,
    validation,
});

export type FormResponse = null | {
    status: number;
    body: unknown;
};

type FormTransitionStatusAction = {
    type: typeof FORM_TRANSITION_STATUS;
    formId: string;
    status: FormStatus;
    payload: FormResponse;
};
export const formTransitionStatus = (
    formId: string,
    status: FormStatus,
    payload: FormResponse = null,
): FormTransitionStatusAction => ({
    type: FORM_TRANSITION_STATUS,
    formId,
    status,
    payload,
});

type FormActions =
    | FormSetAttributeAction
    | FormTransitionStatusAction;

type FormData = SimpleDict.SimpleDict<RuntimePromise.Type<string | boolean, string>>;
export type Form = {
    id: string;
    data: FormData;
    status: FormStatus;
    response: FormResponse;
    validations: Record<string, Array<string>>;
};
type Forms = SimpleDict.SimpleDict<Form>;

const required = (val: unknown): Array<string> => (
    (
        (typeof val === 'string' && val.replace(/\s/g, '').length > 0)
        || (typeof val === 'boolean' && val)
    )
        ? []
        : ['required']
);

const validationFailures = (val: unknown) => (validation: Validation): Array<string> => (
    !validation.on
        ? []
        : (
            validation.type === VALIDATION_REQUIRED
                ? required(val)
                : ['unknown']
        )
);

const runValidations = (validations: Validations) => (val: unknown): Array<string> => (
    R.chain(validationFailures(val), validationsArray(validations))
);

export type LocalState = Forms;

const reducer = (state: LocalState = {}, action: FormActions): LocalState => {
    switch (action.type) {
        case FORM_SET_ATTRIBUTE:
            return SimpleDict.over(
                action.formId,
                R.evolve({
                    data: (
                        typeof action.value === 'undefined'
                            ? SimpleDict.remove(action.name)
                            : SimpleDict.set(action.name, action.value)
                    ),
                    validations: (
                        typeof action.value === 'undefined'
                            ? SimpleDict.remove(action.name)
                            : R.pipe(
                                RuntimePromise.map(runValidations(action.validation)),
                                // no validation errors when not available yet
                                RuntimePromise.unwrapOr([]),
                                (messages: Array<string>) => SimpleDict.set(action.name, messages),
                            )(action.value)
                    ),
                }),
                state,
            );
        case FORM_TRANSITION_STATUS: {
            // SimpleDict.over does not get called if the value does not exist
            if (action.status === FORM_STATUS_SETUP) {
                return {
                    ...state,
                    [action.formId]: {
                        id: action.formId,
                        status: FORM_STATUS_SETUP,
                        data: {},
                        response: null,
                        validations: {},
                    },
                };
            }

            return SimpleDict.over(
                action.formId,
                (form: Form): Form => {
                    const base = R.assoc('status', action.status, form);
                    switch (action.status) {
                        case FORM_STATUS_DONE:
                        case FORM_STATUS_FAILED:
                            return {
                                ...base,
                                response: action.payload,
                            };
                        default:
                            return base;
                    }
                },
                state,
            );
        }
        default:
            return state;
    }
};

export const getForms = (state: State): Forms => state.forms;
export const getAllFormIds = (state: State): Array<string> => Object.keys(state.forms);
export const getAllForms = (state: State): Array<Form> => Object.values(state.forms);
export const getForm = (state: State, formId: string): Form | null => getForms(state)[formId] ?? null;
export const anyFormOfStatus = (status: FormStatus) => (
    (state: State): boolean => R.any(
        (id: string): boolean => {
            const form = getForm(state, id);
            return !!(form && form.status === status);
        },
        getAllFormIds(state),
    )
);

export const getAnyFormDone = anyFormOfStatus(FORM_STATUS_DONE);
export const getAnyFormFailed = anyFormOfStatus(FORM_STATUS_FAILED);
export const getResolvedFormData = (formId: string) => (
    state: State,
): RuntimePromise.Type<Record<string, string | boolean>, string> => {
    const formData = getForm(state, formId)?.data;
    if (!formData) {
        return RuntimePromise.Err(`form ${formId} was not initialized`);
    }
    return RuntimePromise.allValues(formData);
};
export const getFormPassedValidation = (formId: string) => (state: State): boolean => {
    const form = getForm(state, formId);
    if (!form) { return false; }
    return !R.any(
        (messages: Array<string>) => messages.length > 0,
        R.values(form.validations),
    );
};

export const getAllSubmittedForms = (state: State): Array<Form> => (
    R.filter(
        R.propEq('status', FORM_STATUS_DONE),
        getAllForms(state),
    )
);

export const submitDisabled = (form: Form | null): boolean => !form || ([
    FORM_STATUS_SUBMITTING,
    FORM_STATUS_DONE,
    FORM_STATUS_REDIRECT,
] as Array<FormStatus>).includes(form.status);

export default reducer;
