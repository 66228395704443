import * as R from 'ramda';
import * as React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import queryString from 'query-string';

type Embed = {
    html: string;
    width: number | null;
    height: number | null;
    ttl?: number;
};

const getEmbed = async (
    name: string,
    options: object,
): Promise<Embed | null> => {
    const response = await fetch([
        __EXPERIENCE_STUDIO_API_BASE_URL__,
        '/embed/',
        name,
        '?',
        queryString.stringify(options),
    ].join(''));
    if (!response.ok) { return null; }
    return response.json();
};

export const useEmbed = (name: string, options: object | null): Embed | null => {
    const [debouncedEmbedOptions, setDebouncedEmbedOptions] = React.useState<object | null>(
        options,
    );
    const [updateOptionsCallback] = useDebouncedCallback(
        () => {
            if (!R.equals(options, debouncedEmbedOptions)) {
                setDebouncedEmbedOptions(options);
            }
        },
        200,
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    React.useEffect(updateOptionsCallback, [debouncedEmbedOptions, options]);
    const [embed, setEmbed] = React.useState<Embed | null>(null);
    React.useEffect(
        () => {
            (async (): Promise<void> => {
                setEmbed(
                    name && debouncedEmbedOptions
                        ? await getEmbed(name, debouncedEmbedOptions)
                        : null,
                );
            })();
        },
        [debouncedEmbedOptions, name],
    );
    return embed;
};
