import queryString from 'query-string';
import * as Sentry from '@sentry/browser';
import { SagaIterator } from 'redux-saga';
import {
    put,
    call,
    takeEvery,
    CallEffect,
} from 'redux-saga/effects';
import { ActionWithMetadata } from '../actions';
import {
    select,
    waitForPromiseWithTimeout,
    withDelayedDispatch,
    SelectorReturnType,
} from '../../../utils/redux';
import { cleanLink } from '../../../functions/clean';
import {
    getForm,
    formTransitionStatus,
    FORM_STATUS_INITIAL,
    FORM_STATUS_SUBMITTING,
    FORM_STATUS_REDIRECT,
    FORM_STATUS_DONE,
    FORM_STATUS_FAILED,
    getFormPassedValidation,
    getResolvedFormData,
    FORM_SET_ATTRIBUTE,
} from './reducer';
import {
    getComponentById,
    FormComponentConfig,
} from '../studioFile';
import FormConfig, {
    FORM_SUBMIT_HTML,
    FORM_SUBMIT_FETCH,
    FORM_SUBMIT_NONE,
    FORM_METHOD_GET,
} from '../studioFile/componentConfigs/form';
import analytics from '../analytics';
import { runMacroString, makeMacroScope } from '../../../macros';
import * as RuntimePromise from '../../../macros/promise';

const FORM_SUBMIT = 'FORMS/SUBMIT' as const;

type FormSubmitAction = {
    type: typeof FORM_SUBMIT;
    action: ActionWithMetadata;
};

export const formSubmit = (action: ActionWithMetadata): FormSubmitAction => ({
    type: FORM_SUBMIT,
    action,
});

export const formSubmitAnalytics = (
    action: string,
    eventLabel: string,
    actionWithMetadata: ActionWithMetadata,
): CallEffect => call(
    analytics,
    action,
    actionWithMetadata.actionAnalytics,
    {
        eventCategory: actionWithMetadata.meta.component,
        eventLabel,
    },
    actionWithMetadata.meta,
);

export const formSubmitSuccessAnalytics = (
    eventLabel: string,
    actionWithMetadata: ActionWithMetadata,
): CallEffect => call(
    analytics,
    'Form Submit',
    actionWithMetadata.actionAnalytics,
    {
        eventCategory: actionWithMetadata.meta.component,
        eventLabel,
    },
    actionWithMetadata.meta,
);
export const formSubmitFailedAnalytics = (
    eventLabel: string,
    actionWithMetadata: ActionWithMetadata,
): CallEffect => call(
    analytics,
    'Form Error',
    { type: 'default' },
    {
        eventCategory: actionWithMetadata.meta.component,
        eventLabel,
    },
    actionWithMetadata.meta,
);

export const formResponseExtractBody = async (
    response: Response,
): Promise<unknown> => (
    response.headers.get('Content-Type')?.includes('application/json')
        /* eslint-disable-next-line promise/prefer-await-to-then */
        ? response.json().catch(() => null)
        : null
);

export function* renderFormBody(
    bodyTemplate: string,
    formId: string,
): SagaIterator<RuntimePromise.Type<unknown, string>> {
    const bodyTextPromise: RuntimePromise.Type<string, string> = yield call(
        waitForPromiseWithTimeout,
        {
            timeout: 5000,
            throttle: 200,
            pollAction: '*',
        },
        function* render(): SagaIterator<RuntimePromise.Type<string, string>> {
            return yield call(
                withDelayedDispatch,
                function* inner(dispatch): SagaIterator<RuntimePromise.Type<string, string>> {
                    const macroScope: SelectorReturnType<typeof makeMacroScope> = (
                        yield select(makeMacroScope, {
                            callsiteMetadata: {
                                componentId: formId,
                                formId,
                            },
                            dispatch,
                        })
                    );

                    return runMacroString(bodyTemplate || '', macroScope);
                },
            );
        },
    );

    return RuntimePromise.chain(
        (text: string): RuntimePromise.Type<unknown, string> => {
            try {
                return RuntimePromise.Success(JSON.parse(text));
            } catch (e) {
                return RuntimePromise.Err('Invalid JSON in Form Request body');
            }
        },
        bodyTextPromise,
    );
}

export function* renderAction(
    form: FormConfig<typeof FORM_SUBMIT_HTML | typeof FORM_SUBMIT_FETCH>,
    qs: unknown,
): SagaIterator<RuntimePromise.Type<string, string>> {
    return yield call(
        waitForPromiseWithTimeout,
        {
            timeout: 5000,
            throttle: 200,
            pollAction: '*',
        },
        function* render() {
            const macroPromise = yield call(
                withDelayedDispatch,
                function* inner(dispatch): SagaIterator<RuntimePromise.Type<string, string>> {
                    const macroScope: SelectorReturnType<typeof makeMacroScope> = (
                        yield select(makeMacroScope, {
                            dispatch,
                            callsiteMetadata: {
                                componentId: form.id,
                                formId: form.id,
                            },
                        })
                    );
                    return runMacroString(form.options.action ?? '', macroScope);
                },
            );

            return RuntimePromise.chain(
                (link: string): RuntimePromise.Type<string, string> => {
                    const cleanedLink = cleanLink(link);

                    if (form.options.method !== FORM_METHOD_GET) {
                        return RuntimePromise.Success(cleanedLink);
                    }

                    try {
                        const qsString = queryString.stringify(qs as Record<string, string>);
                        if (!qsString.length) { return RuntimePromise.Success(cleanedLink); }
                        return RuntimePromise.Success(
                            !cleanedLink.includes('?')
                                ? `${cleanedLink}?${qsString}`
                                : `${cleanedLink}&${qsString}`,
                        );
                    } catch (_e) {
                        return RuntimePromise.Err('invalid query string');
                    }
                },
                macroPromise,
            );
        },
    );
}

export function* submitInplaceForm(
    formId: string,
    method: 'GET' | 'POST' | 'PUT',
    formAction: string,
    body: unknown,
    action: ActionWithMetadata,
): SagaIterator {
    let response: Response;
    try {
        response = yield call(
            fetch,
            formAction,
            {
                method,
                headers: {
                    Accept: 'application/json, */*',
                    ...(
                        method === FORM_METHOD_GET
                            ? {}
                            : { 'Content-Type': 'application/json' }
                    ),
                },
                ...(
                    method === FORM_METHOD_GET
                        ? {}
                        : { body: JSON.stringify(body) }
                ),
            },
        );
    } catch (e) {
        console.error(e);
        yield formSubmitFailedAnalytics(formAction, action);
        yield put(formTransitionStatus(formId, FORM_STATUS_FAILED));
        return;
    }

    const responseBody: Resolve<ReturnType<typeof formResponseExtractBody>> = yield call(
        formResponseExtractBody,
        response,
    );

    if (response.ok) {
        yield formSubmitSuccessAnalytics(formAction, action);
        yield put(formTransitionStatus(formId, FORM_STATUS_DONE, {
            status: response.status,
            body: responseBody,
        }));
    } else {
        yield formSubmitFailedAnalytics(formAction, action);
        yield put(formTransitionStatus(formId, FORM_STATUS_FAILED, {
            status: response.status,
            body: responseBody,
        }));
    }
}

export const blurActiveElement = (): void => {
    // Hide keyboard.
    // activeElement may not exist in legacy browsers.
    // activeElement could be an SVG which is not an HTMLElement
    if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
    }
};

// Fields like hidden input can have values defined via macro
// these macros may require time to resolve. This saga waits for
// these values to resolve.
export function* waitForDataToResolve(formId: string): SagaIterator<FormData> {
    return yield call(
        waitForPromiseWithTimeout,
        {
            timeout: 5000,
            throttle: 200,
            pollAction: FORM_SET_ATTRIBUTE,
        },
        function* waitForFormData(): SagaIterator {
            return (yield select(getResolvedFormData(formId)));
        },
    );
}

type RenderActionBodyResult = {
    action: string;
    body: unknown;
} | null;
function* renderActionAndBody(
    form: FormConfig<typeof FORM_SUBMIT_HTML | typeof FORM_SUBMIT_FETCH>,
): SagaIterator<RenderActionBodyResult> {
    const bodyPromise = (
        form.options.submissionType === FORM_SUBMIT_FETCH
            ? (yield call(
                renderFormBody,
                form.options.bodyTemplate,
                form.id,
            )) as RuntimePromise.Type<unknown, string>
            : RuntimePromise.Success((yield select((state) => getForm(state, form.id)))?.data)
    );

    if (RuntimePromise.logUnresolved(bodyPromise, 'form submit \'body\'')) { return null; }
    const body = RuntimePromise.unwrap(bodyPromise);

    const actionPromise: RuntimePromise.Type<string, string> = yield call(renderAction, form, body);

    if (RuntimePromise.logUnresolved(actionPromise, 'form submit \'action\'')) { return null; }
    const action = RuntimePromise.unwrap(actionPromise);
    if (!action || action.startsWith('?')) {
        console.error('Form Submit Error: provided "action" is blank '
            + `for component id="${form.id}"`);
        yield put(formTransitionStatus(form.id, FORM_STATUS_FAILED));
        return null;
    }

    return {
        action,
        body,
    };
}

export function* formSubmitSagaBody(
    { id: formId, options: formOptions, ...rest }: FormConfig,
    action: ActionWithMetadata,
): SagaIterator {
    const formData = yield call(waitForDataToResolve, formId);

    if (!RuntimePromise.isSuccess(formData)) {
        yield put(formTransitionStatus(formId, FORM_STATUS_FAILED));
        return;
    }

    const submit: SelectorReturnType<typeof getFormPassedValidation> = yield select(
        getFormPassedValidation,
        formId,
    );
    // We don't have form validation styling or messaging yet
    // when we do we will fire an action here to update a flag
    // on the form component
    if (!submit) {
        yield put(formTransitionStatus(formId, FORM_STATUS_INITIAL));
        return;
    }

    if (formOptions.submissionType === FORM_SUBMIT_NONE) {
        yield call(blurActiveElement);
        yield formSubmitSuccessAnalytics('none', action);
        yield put(formTransitionStatus(formId, FORM_STATUS_DONE));
        return;
    }

    const actionAndBody = yield call(renderActionAndBody, {
        id: formId,
        options: formOptions,
        ...rest,
    });
    if (!actionAndBody) { return; }

    yield call(blurActiveElement);
    switch (formOptions.submissionType) {
        case 'redirect': {
            yield formSubmitSuccessAnalytics(actionAndBody.action, action);
            yield put(formTransitionStatus(formId, FORM_STATUS_REDIRECT));
            break;
        }
        case 'inplace': {
            yield call(
                submitInplaceForm,
                formId,
                formOptions.method,
                actionAndBody.action,
                actionAndBody.body,
                action,
            );
            break;
        }
    }
}

export function* formSubmitSaga({ action }: FormSubmitAction): SagaIterator {
    const { formId } = action.meta;
    const form: (FormConfig & FormComponentConfig) | null = (
        formId
        && (yield select(getComponentById, formId))
    );
    if (!(formId && form)) {
        Sentry.captureException(new Error('Submit Triggered Outside Form'), {
            tags: {
                'error.source': 'renderer',
                'component.type': 'form',
            },
    });
        return;
    }
    try {
        yield put(formTransitionStatus(formId, FORM_STATUS_SUBMITTING));
        yield call(formSubmitSagaBody, form, action);
    } catch (e) {
        console.error(e);
        if (!__IS_LOCAL__) {
            Sentry.captureException(e, {
                tags: {
                    'error.source': 'renderer',
                    'component.type': 'form',
                },
        });
        }
        yield put(formTransitionStatus(formId, FORM_STATUS_FAILED));
    }
}

function* saga(): SagaIterator {
    yield takeEvery(FORM_SUBMIT, formSubmitSaga);
}

export default saga;
