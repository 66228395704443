export const GENERIC_MACRO_ERROR = 'Macro Error' as const;
export const UNRESOLVED_MACRO_ERROR = 'Macro Did Not Resolve' as const;

export type ErrorType = (
    | typeof GENERIC_MACRO_ERROR
    | typeof UNRESOLVED_MACRO_ERROR
);

export const WHILE_RENDERING_COMPONENT = 'Rendering Component';

type ErrorWhile =
    | typeof WHILE_RENDERING_COMPONENT
;

export type ErrorTypeOptions<A extends ErrorType> = (
    A extends typeof GENERIC_MACRO_ERROR ? { macro: string; error: string } :
    A extends typeof UNRESOLVED_MACRO_ERROR ? { macro: string } :
    never
)

export type WhileOptions<A extends ErrorWhile> = (
    A extends typeof WHILE_RENDERING_COMPONENT ? { component: string; key: string } :
    never
);

export type ErrorOptions<A extends ErrorType, B extends ErrorWhile> = (
    ErrorTypeOptions<A> & WhileOptions<B>
);

export const whileText = <A extends ErrorWhile>(
    errorType: A,
    options: WhileOptions<A>,
): string => (
    errorType === WHILE_RENDERING_COMPONENT
    ? [
        'Rendering the ',
        options.key,
        ' option of the ',
        options.component,
        ' Component',
    ].join('')
    : ''
);

export const logUserError = <
    A extends ErrorType,
    B extends ErrorWhile,
>(
    errorType: A,
    whileType: B,
    options: ErrorOptions<A, B>,
): void => {
    if (!__IS_TEST__) {
        console.warn([
            'Studio ',
            errorType,
            '\n While ',
            whileText(whileType, options),
            ...(
                errorType === GENERIC_MACRO_ERROR
                    ? [
                        '\n',
                        /* TypeScript is not using our check narrow the type since */
                        /* they are related only by type generic type mappings */
                        ((options as any) as ErrorTypeOptions<typeof GENERIC_MACRO_ERROR>).error,
                    ]
                    : []
            ),
        ].join(''));
    }
};
