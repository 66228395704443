export const ANALYTICS_NONE = 'none' as const;
export const ANALYTICS_CUSTOM = 'custom' as const;
export const ANALYTICS_DEFAULT = 'default' as const;

export type AnalyticsType =
    | typeof ANALYTICS_NONE
    | typeof ANALYTICS_CUSTOM
    | typeof ANALYTICS_DEFAULT;

export type ActionAnalyticsCustom = {
    type: typeof ANALYTICS_CUSTOM;
    customOptions: {
        eventCategory?: string;
        eventAction?: string;
        eventLabel?: string;
        eventName?: string;
        eventParameters?: Array<{ key: string, value: string }>;
    }
};

export type ActionAnalyticsNone = {
    type: typeof ANALYTICS_NONE;
};

export type ActionAnalyticsDefault = {
    type: typeof ANALYTICS_DEFAULT;
};

export type ActionAnalyticsOptions<T extends AnalyticsType = AnalyticsType> = (
    T extends typeof ANALYTICS_NONE ? ActionAnalyticsNone
    : T extends typeof ANALYTICS_DEFAULT ? ActionAnalyticsDefault
    : T extends typeof ANALYTICS_CUSTOM ? ActionAnalyticsCustom
    : never
);

export type ActionAnalytics<T extends AnalyticsType = AnalyticsType> = {
    actionAnalytics?: ActionAnalyticsOptions<T>;
};
