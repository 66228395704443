/* eslint-disable max-len */
import React from 'react';

const Sun = (): React.ReactElement => (
    <svg aria-label="sun icon" width="128" height="128" viewBox="0 0 128 128">
        <defs>
            <clipPath id="clip-path-sun">
                <rect id="Rectangle_1" data-name="Rectangle 1" width="80" height="80.139" fill="none" />
            </clipPath>
            <clipPath id="clip-Sun">
                <rect width="128" height="128" />
            </clipPath>
        </defs>
        <g id="Sun" clipPath="url(#clip-Sun)">
            <rect width="128" height="128" fill="#fff" />
            <g id="Group_2" data-name="Group 2" transform="translate(24 24)">
                <g id="Group_1" data-name="Group 1" transform="translate(0 0)" clipPath="url(#clip-path-sun)">
                    <path id="Path_1" data-name="Path 1" d="M57.308,37.43A19.768,19.768,0,1,1,37.491,17.711,19.768,19.768,0,0,1,57.308,37.43" transform="translate(2.343 2.335)" fill="#f3d33f" />
                    <path id="Path_2" data-name="Path 2" d="M35.544,11.809a2.265,2.265,0,0,1-2.269-2.258l-.018-7.281a2.264,2.264,0,1,1,4.527-.011L37.8,9.539a2.264,2.264,0,0,1-2.258,2.269" transform="translate(4.385 -0.001)" fill="#f3d33f" />
                    <path id="Path_3" data-name="Path 3" d="M69.8,38.032l-7.282.018a2.264,2.264,0,1,1-.01-4.527l7.281-.018a2.264,2.264,0,0,1,.011,4.527" transform="translate(7.944 4.417)" fill="#f3d33f" />
                    <path id="Path_4" data-name="Path 4" d="M9.551,38.181,2.269,38.2a2.264,2.264,0,1,1-.011-4.527l7.281-.018a2.264,2.264,0,0,1,.011,4.527" transform="translate(0 4.437)" fill="#f3d33f" />
                    <path id="Path_5" data-name="Path 5" d="M54.752,19.665A2.264,2.264,0,0,1,53.142,15.8l5.136-5.161a2.264,2.264,0,1,1,3.209,3.194L56.352,19a2.259,2.259,0,0,1-1.6.667" transform="translate(6.92 1.315)" fill="#f3d33f" />
                    <path id="Path_6" data-name="Path 6" d="M12.052,62.08a2.264,2.264,0,0,1-1.611-3.861l5.136-5.161a2.264,2.264,0,1,1,3.209,3.194l-5.135,5.161a2.259,2.259,0,0,1-1.6.667" transform="translate(1.29 6.908)" fill="#f3d33f" />
                    <path id="Path_7" data-name="Path 7" d="M17.1,19.385a2.257,2.257,0,0,1-1.6-.659L10.333,13.59a2.264,2.264,0,1,1,3.194-3.209l5.161,5.135A2.264,2.264,0,0,1,17.1,19.385" transform="translate(1.275 1.281)" fill="#f3d33f" />
                    <path id="Path_8" data-name="Path 8" d="M60.039,62.05a2.263,2.263,0,0,1-1.585-.641l-5.22-5.078a2.264,2.264,0,1,1,3.158-3.245l5.22,5.076a2.264,2.264,0,0,1-1.573,3.887" transform="translate(6.929 6.915)" fill="#f3d33f" />
                    <path id="Path_9" data-name="Path 9" d="M35.693,72.179a2.265,2.265,0,0,1-2.269-2.258l-.018-7.281a2.264,2.264,0,1,1,4.527-.011l.018,7.281a2.264,2.264,0,0,1-2.258,2.269" transform="translate(4.405 7.96)" fill="#f3d33f" />
                </g>
            </g>
        </g>
    </svg>
);

export default Sun;
