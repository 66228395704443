import * as R from 'ramda';
import {
    IDENTIFIER,
    ACCESS_OPERATOR,
    FLOAT_LITERAL,
    INT_LITERAL,
    OBJECT_LITERAL,
    STRING_CONTENT,
    FALLBACK_OPERATOR,
    GT_OPERATOR,
    LT_OPERATOR,
    GTE_OPERATOR,
    LTE_OPERATOR,
    EQ_OPERATOR,
    NEQ_OPERATOR,
    MULTIPLY_OPERATOR,
    DIVIDE_OPERATOR,
    ADD_OPERATOR,
    SUBTRACT_OPERATOR,
    AND_OPERATOR,
    OR_OPERATOR,
    GenericNode,
    STRING_LITERAL,
    ARRAY_LITERAL,
    BASE_STRING,
    STRING_INTERPOLATION,
    FUNCTION_APPLICATION,
    INDEX,
    PARENTH,
    INDEXING,
    EXPRESSION,
    isASTNodeType,
    unwrapASTNode,
} from '@bluebitellc/studio-utils';
import { escapeHtml, unescapeHtml } from '../utils/escape';
import {
    callGenericRuntimeValueWithGenericArgs,
    callFunctionWithGenericArgs,
    coerceToString,
    access,
    stringConcat,
    gt,
    lt,
    gte,
    lte,
    equality,
    not,
    mul,
    div,
    add,
    sub,
    and,
    or,
    index,
} from './functions';
import * as RuntimePromise from './promise';
import { MacroEvalContext } from './context';
import {
    OBJECT,
    STRING,
    NUMBER,
    GLOBAL,
    NULL,
    ARRAY,
    LAZY,
    runtimeValue,
    runtimeOrNull,
    GenericRuntimeValue,
    isRuntimeType,
    unwrap,
    RuntimeFunction,
} from './runtime';

type EvaluateResult<T> = RuntimePromise.Type<T, string>;

// We need to use mutually recursive functions

function rawEvalAst(
    ast: GenericNode,
    scope: GenericRuntimeValue,
    context: MacroEvalContext,
): EvaluateResult<GenericRuntimeValue> {
    const fnWithArgs = (
        fn: RuntimeFunction<any, any>,
        argNodes: ReadonlyArray<GenericNode>,
    ): RuntimePromise.Type<GenericRuntimeValue, string> => (
        RuntimePromise.chain(
            callFunctionWithGenericArgs(fn),
            evalAsts(argNodes, scope, context),
        )
    );
    switch (ast.type) {
        case FLOAT_LITERAL:
            return RuntimePromise.Success(runtimeValue(
                NUMBER,
                Number.parseFloat(ast.value),
            ));
        case INT_LITERAL:
            return RuntimePromise.Success(runtimeValue(
                NUMBER,
                Number.parseInt(ast.value, 10),
            ));
        case OBJECT_LITERAL:
            return RuntimePromise.Success(runtimeValue(OBJECT, {}));
        case IDENTIFIER: {
            if (!isRuntimeType(GLOBAL, scope)) {
                return RuntimePromise.Err('Unexpected Error');
            }

            const global = unwrap(scope);

            return (
                Object.prototype.hasOwnProperty.call(global, ast.value)
                    ? RuntimePromise.Success(runtimeOrNull(global[ast.value]))
                    : RuntimePromise.Err(`Not Defined: "${ast.value}"`)
            );
        }
        case EXPRESSION: {
            const { op, args } = ast.value;

            switch (op.type) {
                case ACCESS_OPERATOR: {
                    // We must try to parse the second argument as
                    // an identifier before trying to evaluating it
                    const [arg0, arg1] = args;

                    const secondIdentifier = (
                        isASTNodeType(IDENTIFIER, arg1)
                        ? RuntimePromise.Success(
                            runtimeValue(STRING, unwrapASTNode(arg1)),
                        )
                        : RuntimePromise.Err(null)
                    );

                    const evaluatedArgs = RuntimePromise.all([
                        evalAst(arg0, scope, context),
                        RuntimePromise.orElse(
                            () => evalAst(arg1, scope, context),
                            secondIdentifier,
                        ),
                    ]);

                    return RuntimePromise.chain(
                        callFunctionWithGenericArgs(access),
                        evaluatedArgs,
                    );
                }
                case FALLBACK_OPERATOR: {
                    // We should not try to evaluate the
                    // AST for argument 2 until we know it's needed.
                    const resultA = evalAst(args[0], scope, context);

                    if (
                        RuntimePromise.isErr(resultA)
                        || (
                            RuntimePromise.isSuccess(resultA)
                            && isRuntimeType(
                                NULL,
                                RuntimePromise.unwrap(resultA),
                            )
                        )
                    ) { return evalAst(args[1], scope, context); }

                    return resultA;
                }
                case GT_OPERATOR:
                    return fnWithArgs(gt, args);
                case LT_OPERATOR:
                    return fnWithArgs(lt, args);
                case GTE_OPERATOR:
                    return fnWithArgs(gte, args);
                case LTE_OPERATOR:
                    return fnWithArgs(lte, args);
                case EQ_OPERATOR:
                    return fnWithArgs(equality, args);
                case NEQ_OPERATOR: {
                    return RuntimePromise.chain(
                        (a) => callFunctionWithGenericArgs(not, [a]),
                        fnWithArgs(equality, args),
                    );
                }
                case MULTIPLY_OPERATOR:
                    return fnWithArgs(mul, args);
                case DIVIDE_OPERATOR:
                    return fnWithArgs(div, args);
                case ADD_OPERATOR:
                    return fnWithArgs(add, args);
                case SUBTRACT_OPERATOR:
                    return fnWithArgs(sub, args);
                case AND_OPERATOR:
                    return fnWithArgs(and, args);
                case OR_OPERATOR:
                    return fnWithArgs(or, args);
                default:
                    throw new Error('Unhandled Macro Operator');
            }
        }
        case FUNCTION_APPLICATION: {
            const { callee, call } = ast.value;
            return RuntimePromise.chain(
                ([f, args]) => callGenericRuntimeValueWithGenericArgs(f, args),
                RuntimePromise.all([
                    evalAst(callee, scope, context),
                    evalAsts(call.value, scope, context),
                ]),
            );
        }
        case ARRAY_LITERAL: {
            return RuntimePromise.Success({
                type: ARRAY,
                value: ast.value.map((value) => ({
                    type: LAZY,
                    value,
                })),
            });
        }
        case INDEX:
            return evalAst(ast.value, scope, context);
        case INDEXING:
            return fnWithArgs(index, [ast.value.index, ast.value.indexable]);
        case PARENTH:
            return evalAst(ast.value, scope, context);
        case BASE_STRING:
        case STRING_LITERAL:
            return R.reduce(
                (
                    acc: RuntimePromise.Type<GenericRuntimeValue, string>,
                    c: GenericNode,
                ) => {
                    switch (c.type) {
                        case STRING_CONTENT: {
                            let str = c.value;
                            if (context.evalOptions.unescapeStringLiterals) {
                                str = unescapeHtml(str);
                            }
                            if (context.evalOptions.htmlLinebreaks) {
                                str = str.replace(/\n/g, '<br>');
                            }
                            return RuntimePromise.chain(
                                (racc: GenericRuntimeValue) => (
                                    callFunctionWithGenericArgs(stringConcat, [
                                        racc,
                                        runtimeValue(STRING, str),
                                    ])
                                ),
                                acc,
                            );
                        }
                        case STRING_INTERPOLATION: {
                            const escapedValue = RuntimePromise.map(
                                (value) => {
                                    if (
                                        value.type === STRING
                                        && context.evalOptions.escapeHtml
                                        && ast.type === BASE_STRING
                                    ) {
                                        let escapedStr = escapeHtml(unwrap(value));
                                        if (context.evalOptions.htmlLinebreaks) {
                                            escapedStr = escapedStr.replace(/\n/g, '<br>');
                                        }
                                        return { ...value, value: escapedStr };
                                    }
                                    if (
                                        value.type === STRING
                                        && context.evalOptions.htmlLinebreaks
                                        && ast.type === BASE_STRING
                                    ) {
                                        return {
                                            ...value,
                                            value: unwrap(value).replace(/\n/g, '<br>')
                                        };
                                    }
                                    return value;
                                },
                                fnWithArgs(coerceToString, [c.value]),
                            );

                            return RuntimePromise.chain(
                                callFunctionWithGenericArgs(stringConcat),
                                RuntimePromise.all([acc, escapedValue]),
                            );
                        }
                        default:
                            throw new Error('UNREACHABLE');
                    }
                },
                RuntimePromise.Success(runtimeValue(STRING, '')),
                ast.value,
            );
        default:
            return RuntimePromise.Err('Unknown AST Type');
    }
}

// Resolve lazy values
function eager(
    runtimeValue: GenericRuntimeValue,
    scope: GenericRuntimeValue,
    context: MacroEvalContext,
): EvaluateResult<GenericRuntimeValue> {
    return (
        runtimeValue.type === LAZY
            ? RuntimePromise.chain(
                (runtimeValue: GenericRuntimeValue) => eager(runtimeValue, scope, context),
                rawEvalAst(runtimeValue.value, scope, context),
            )
            : RuntimePromise.Success(runtimeValue)
    );
}

function evalAst(
    node: GenericNode,
    scope: GenericRuntimeValue,
    context: MacroEvalContext,
): EvaluateResult<GenericRuntimeValue> {
    const rawResult = rawEvalAst(node, scope, context);
    return (
        context.evalOptions.eager
            ? RuntimePromise.chain(
                (runtimeValue: GenericRuntimeValue) => eager(runtimeValue, scope, context),
                rawResult,
            )
            : rawResult
    );
}

function evalAsts(
    nodes: ReadonlyArray<GenericNode>,
    scope: GenericRuntimeValue,
    context: MacroEvalContext,
): EvaluateResult<ReadonlyArray<GenericRuntimeValue>> {
    return RuntimePromise.all(nodes.map((node) => evalAst(node, scope, context)));
}

export default evalAst;
