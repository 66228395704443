import { useState, useEffect } from 'react';

type WindowSize = {
    innerWidth: number | undefined;
    innerHeight: number | undefined;
};

const isClient = typeof window === 'object';

const getSize = (): WindowSize => ({
    innerWidth: isClient ? window.innerWidth : undefined,
    innerHeight: isClient ? window.innerHeight : undefined,
});

export const useWindowSize = (): WindowSize => {
    const [windowSize, setWindowSize] = useState(getSize());

    useEffect(() => {
        if (!isClient) {
            return;
        }

        const handleResize = (): void => {
            setWindowSize(getSize());
        };

        window.addEventListener('resize', handleResize);

        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
};
