import {
    FontWeight,
    TextAlign,
} from './shared/style';

export const FORM_SUBMIT_HTML = 'redirect' as const;
export const FORM_SUBMIT_FETCH = 'inplace' as const;
export const FORM_SUBMIT_NONE = 'none' as const;

export type FormSubmissionType =
    | typeof FORM_SUBMIT_HTML
    | typeof FORM_SUBMIT_FETCH
    | typeof FORM_SUBMIT_NONE;

export const FORM_METHOD_GET = 'GET' as const;
export const FORM_METHOD_POST = 'POST' as const;

export type FormRedirectMethod =
    | typeof FORM_METHOD_GET
    | typeof FORM_METHOD_POST;

export const FORM_METHOD_PUT = 'PUT' as const;

export type FormInplaceMethod =
    | FormRedirectMethod
    | typeof FORM_METHOD_PUT;

export const FORM_BODY_TYPE_JSON = 'json' as const;

export type FormBodyType = typeof FORM_BODY_TYPE_JSON;

export type FormStylingOptions = {
    inputWidth?: string;
    inputPadding?: string;
    inputMargins?: string;
    inputBorderWidth?: string;
    inputBorderRadius?: string;
    inputFontSize?: string;
    inputFontWeight?: FontWeight;
    inputTextAlign?: TextAlign;
    labelMargins?: string;
    labelFontSize?: string;
    labelFontWeight?: FontWeight;
    labelTextAlign?: TextAlign;
    inputTextColor?: string;
    labelTextColor?: string;
    inputBackgroundColor?: string;
    inputBorderColor?: string;
};

type SharedFormOptions =
    & FormStylingOptions
    & {
        title: string;
    };

// Due to a longstanding bug and lack of validation actions may be undefined

export type FormOptionsHTML =
    & SharedFormOptions
    & {
        action?: string;
        submissionType: typeof FORM_SUBMIT_HTML;
        method: FormRedirectMethod;
    };

export type FormOptionsFetch =
    & SharedFormOptions
    & {
        action?: string;
        submissionType: typeof FORM_SUBMIT_FETCH;
        method: FormInplaceMethod;
        bodyType: FormBodyType;
        bodyTemplate: string;
    };

export type FormOptionsNone =
    & SharedFormOptions
    & {
        submissionType: typeof FORM_SUBMIT_NONE;
    };

export type FormOptions<T extends FormSubmissionType = FormSubmissionType> =
    T extends typeof FORM_SUBMIT_HTML ? FormOptionsHTML
    : T extends typeof FORM_SUBMIT_FETCH ? FormOptionsFetch
    : T extends typeof FORM_SUBMIT_NONE ? FormOptionsNone
    : never;

export type FormComponentLists = {
    body: string;
};

type FormConfig<T extends FormSubmissionType = FormSubmissionType> = {
    id: string;
    options: FormOptions<T>;
    componentLists: FormComponentLists;
};

export default FormConfig;
