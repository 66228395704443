import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    ExclusiveOptions,
    ExclusiveComponentLists,
} from '../store/processes/studioFile/componentConfigs/exclusive';
import * as LoadableState from '../store/processes/loadableState';
import {
    getPagePayload,
} from '../store/processes/pagePayload';
import { ComponentProps } from './shared/types';
import ComponentList from './shared/componentList';

type ExclusiveProps = ComponentProps<ExclusiveOptions, ExclusiveComponentLists>;

const Exclusive = ({
    options: { verified },
    componentLists: { passed },
}: ExclusiveProps): React.ReactElement | null => {
    const loadablePagePayload = useSelector(getPagePayload);

    const pagePayload = LoadableState.unwrapOr(null, loadablePagePayload);
    const {
        supported = false,
        result = false,
    } = pagePayload?.sdkData?.interaction?.touchpoint?.verification ?? {};

    const didPass = (
        (supported && result)
        === (verified === 'true' || verified === true)
    );

    return (
        didPass
            ? <ComponentList id={passed} />
            : null
    );
};

export default Exclusive;
