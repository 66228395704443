import { getCacheBreakInfo } from './previewMode';

const fetchExperienceFile = async (studioId: string): Promise<any> => {
    if (__IS_LOCAL__ && __IS_V3__) {
        /* eslint-disable @typescript-eslint/no-var-requires */
        const studioFile = require('../../dev/v3_experience_file.json');
        /* eslint-enable @typescript-eslint/no-var-requires */
        if (studioFile === null) {
            throw new Error('Studio file not found');
        }
        return studioFile;
    }

    const { cache, cacheBreakingQueryString } = getCacheBreakInfo();

    const response = await fetch(
        [
            __EXPERIENCE_STUDIO_API_BASE_URL__,
            '/experience/',
            studioId,
            cacheBreakingQueryString,
        ].join(''),
        {
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            cache,
        },
    );

    if (!response.ok) { throw new Error('Failed To Fetch Experience'); }

    return response.json();
};
/* eslint-enable global-require */

export default fetchExperienceFile;
