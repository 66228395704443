import * as R from 'ramda';
import { GenericNode } from '@bluebitellc/studio-utils';
import * as RuntimePromise from './promise';
import { FormResponse } from '../store/processes/forms';

export const NULL = 'NULL' as const;
export const STRING = 'STRING' as const;
export const FUNCTION = 'FUNCTION' as const;
export const OBJECT = 'OBJECT' as const;
export const BOOLEAN = 'BOOLEAN' as const;
export const NUMBER = 'NUMBER' as const;
export const GLOBAL = 'GLOBAL' as const;
export const ARRAY = 'ARRAY' as const;
export const REGEXP = 'REGEXP' as const;
export const LAZY = 'LAZY' as const;

export type RuntimeType =
    | typeof NULL
    | typeof STRING
    | typeof FUNCTION
    | typeof OBJECT
    | typeof BOOLEAN
    | typeof NUMBER
    | typeof GLOBAL
    | typeof ARRAY
    | typeof REGEXP
    | typeof LAZY;

// Stringable objects can coerce into strings
// all strings are also stringable
export const STRINGABLE = 'STRINGABLE' as const;
export const ANY = 'ANY' as const;
export const NUMBERABLE = 'NUMBERABLE' as const;
export const STRING_OR_REGEXP = 'STRING_OR_REGEXP' as const;

export type AbstractType =
    | typeof STRINGABLE
    | typeof NUMBERABLE
    | typeof STRING_OR_REGEXP
    | typeof ANY;

export type AbstractValue<V> =
    V extends typeof STRINGABLE
        ? RuntimeValue<
            | typeof STRING
            | typeof NUMBER
            | typeof OBJECT
            | typeof BOOLEAN
        > :
    V extends typeof NUMBERABLE
        ? RuntimeValue<typeof STRING | typeof NUMBER> :
    V extends typeof STRING_OR_REGEXP
        ? RuntimeValue<typeof STRING | typeof REGEXP> :
    V extends typeof ANY ? GenericRuntimeValue :
    never;

export type ArgumentType = RuntimeType | AbstractType;

export type Signature = ReadonlyArray<ArgumentType>;
export type ArgumentForType<A> =
    A extends RuntimeType ? RuntimeValue<A> :
    A extends AbstractType ? AbstractValue<A> :
    never;
export type GenericArgument = ArgumentForType<ArgumentType>;
export type ArgumentsForSignature<S extends Signature> =
    { [K in keyof S]: ArgumentForType<S[K]> };
export type SignatureForRuntimeFunction<F> =
    F extends RuntimeFunction<infer R, any> ? R : never;
export type ResultForRuntimeFunction<F> =
    F extends RuntimeFunction<any, infer R> ? R : never;
export type ArgumentsForRuntimeFunction<F> =
    ArgumentsForSignature<SignatureForRuntimeFunction<F>>;
export type RuntimeFunctionImplementation<
    A extends Signature,
    R extends ArgumentType,
> =
    (...args: ArgumentsForSignature<A>) =>
        RuntimePromise.Type<ArgumentForType<R>, string>;
export type RuntimeFunction<
    A extends Signature,
    R extends ArgumentType,
> = {
    type: typeof FUNCTION;
    value: {
        name: string;
        arity: number;
        args: ReadonlyArray<RuntimePromise.Type<GenericArgument, string>>;
        result: R;
        signature: A;
        func: RuntimeFunctionImplementation<A, R>;
    };
};

export type RuntimeLazy = {
    type: typeof LAZY;
    value: GenericNode;
};
export type RuntimeArray<V extends GenericRuntimeValue> = {
    type: typeof ARRAY;
    value: ReadonlyArray<V>;
};
export type RuntimeNull = {
    type: typeof NULL;
    value: null;
};
export type RuntimeNumber = {
    type: typeof NUMBER;
    value: number;
};
export type RuntimeString = {
    type: typeof STRING;
    value: string;
};
export type RuntimeObject = {
    type: typeof OBJECT;
    value: { [key: string]: GenericRuntimeValue };
};
export type RuntimeBoolean = {
    type: typeof BOOLEAN;
    value: boolean;
};

export type RuntimeRegExp = {
    type: typeof REGEXP;
    value: RegExp;
};

export type RuntimeValue<T> = (
    T extends typeof NULL ? RuntimeNull :
    T extends typeof STRING ? RuntimeString :
    T extends typeof OBJECT ? RuntimeObject :
    T extends typeof BOOLEAN ? RuntimeBoolean :
    T extends typeof NUMBER ? RuntimeNumber :
    T extends typeof GLOBAL ? RuntimeObject :
    T extends typeof ARRAY ? RuntimeArray<any> :
    T extends typeof FUNCTION ? RuntimeFunction<any, any> :
    T extends typeof REGEXP ? RuntimeRegExp :
    T extends typeof LAZY ? RuntimeLazy :
    never
);

export type GenericRuntimeValue =
    | RuntimeNull
    | RuntimeString
    | RuntimeObject
    | RuntimeBoolean
    | RuntimeNumber
    | RuntimeArray<any>
    | RuntimeFunction<any, any>
    | RuntimeRegExp
    | RuntimeLazy;

type RuntimeContent<A> = RuntimeValue<A>['value'];

export const isRuntimeType = <
    A extends RuntimeType,
    B extends RuntimeValue<RuntimeType>,
>(
    type: A,
    obj: B,
): obj is (B & RuntimeValue<A>) => obj.type === type;

export const runtimeValue: {
    <A extends RuntimeType>(
        type: A,
        value: RuntimeContent<A>,
    ): RuntimeValue<A>;
    <A extends RuntimeType>(
        type: A,
    ): (value: RuntimeContent<A>) => RuntimeValue<A>;
} = R.curry(<A extends RuntimeType>(
    type: A,
    value: RuntimeContent<A>,
): RuntimeValue<A> => ({
    type,
    value,
}) as any) as any;

export const unwrap = <A extends RuntimeValue<any>>(
    rt: A,
): A['value'] => rt.value;

export const isRuntimeValue = (
    value: unknown,
): value is GenericRuntimeValue => (
    !!value
    && Object.prototype.hasOwnProperty.call(value, 'type')
    && Object.prototype.hasOwnProperty.call(value, 'value')
);

export const runtimeNull: RuntimeNull = runtimeValue(NULL, null);
export const runtimeTrue = runtimeValue(BOOLEAN, true);
export const runtimeFalse = runtimeValue(BOOLEAN, false);

const runtimeFloatOrNull = (f: unknown): RuntimeNumber | RuntimeNull => {
    switch (typeof f) {
        case 'string': {
            const float = Number.parseFloat(f);
            return Number.isNaN(float)
                ? runtimeNull
                : runtimeValue(NUMBER, float);
        }
        case 'number':
            return runtimeValue(NUMBER, f);
        default:
            return runtimeNull;
    }
};

const runtimeIntOrNull = (i: unknown): RuntimeNumber | RuntimeNull => {
    switch (typeof i) {
        case 'string': {
            const int = Number.parseInt(i, 10);
            return Number.isNaN(int) ? runtimeNull : runtimeValue(NUMBER, int);
        }
        case 'number':
            return runtimeValue(NUMBER, Math.floor(i));
        default:
            return runtimeNull;
    }
};

export const runtimeString = (str: string): RuntimeString => runtimeValue(STRING, str);
export const runtimeStringOrNull = (
    i: unknown,
): RuntimeString | RuntimeNull => {
    switch (typeof i) {
        case 'string':
            return runtimeValue(STRING, i);
        default:
            return runtimeNull;
    }
};

export const runtimeBoolean = (b: boolean): RuntimeBoolean => (
    b === true
        ? runtimeTrue
        : runtimeFalse
);
export const runtimeBooleanOrNull = (
    v: unknown,
): RuntimeBoolean | RuntimeNull => (
    typeof v === 'boolean'
        ? runtimeBoolean(v)
        : runtimeNull
);
export const booleanToRuntimeOrNull = runtimeBooleanOrNull;

export const jsonToRuntime = (v: unknown): GenericRuntimeValue => {
    if (R.is(Array, v)) {
        return R.pipe(
            R.map(jsonToRuntime),
            runtimeValue(ARRAY),
        )(v as Array<unknown>);
    }
    if (R.is(Object, v)) {
        return R.pipe(
            R.toPairs as (obj: Record<string, any>) => Array<[string, unknown]>,
            R.map(([key, value]) => [key, jsonToRuntime(value)]) as
                (pairs: Array<[string, unknown]>) =>
                    Array<[string, GenericRuntimeValue]>,
            R.fromPairs as
                (pairs: Array<[string, GenericRuntimeValue]>) =>
                    {[key: string]: GenericRuntimeValue},
            runtimeValue(OBJECT),
        )(v as { [key: string]: unknown });
    }
    if (typeof v === 'number') {
        return runtimeValue(NUMBER, v);
    }
    if (typeof v === 'string') {
        return runtimeValue(STRING, v);
    }
    if (typeof v === 'boolean') {
        return runtimeValue(BOOLEAN, v);
    }
    return runtimeNull;
};

export const runtimeLocation = (
    location: {
        latitude: unknown;
        longitude: unknown;
        timestamp?: unknown;
        source?: unknown;
    },
): RuntimeObject | RuntimeNull => {
    const latitude = runtimeFloatOrNull(location.latitude);
    const longitude = runtimeFloatOrNull(location.longitude);
    const timestamp = runtimeIntOrNull(location.timestamp);
    const source = runtimeStringOrNull(location.source);

    if (latitude.type === NULL || longitude.type === NULL) {
        return runtimeNull;
    }

    return runtimeValue(OBJECT, {
        _type: runtimeValue(STRING, 'Location'),
        coords: runtimeValue(OBJECT, {
            _type: runtimeValue(STRING, 'Coordinates'),
            latitude,
            longitude,
        }),
        timestamp,
        source,
    });
};

const cleanMustacheValue = (v: unknown): unknown => (v !== '' && v != null ? v : null);
const cleanMustacheObject: (obj: { [key: string]: unknown }) =>
    { [key: string]: unknown } = R.map(cleanMustacheValue) as any;

export const runtimeCountry = R.pipe(
    R.pick(['name', 'code']),
    cleanMustacheObject,
    (props) => jsonToRuntime({
        _type: 'Country',
        value: props.name,
        ...props,
    }),
);

export const runtimeState = R.pipe(
    R.pick(['name', 'code']),
    cleanMustacheObject,
    (props) => jsonToRuntime({
        _type: 'State',
        ...props,
    }),
);

export const runtimeAddress = (
    raw: {
        state?: unknown;
        city?: unknown;
        postal_code?: unknown;
        country?: unknown;
        street?: unknown;
    },
): RuntimeObject | RuntimeNull => R.pipe(
    R.pick(['street', 'city', 'postal_code']),
    cleanMustacheObject,
    ({ city, postal_code, street }) => runtimeValue(OBJECT, {
        _type: runtimeString('Address'),
        city: runtimeStringOrNull(city),
        postal_code: runtimeStringOrNull(postal_code),
        street: runtimeStringOrNull(street),
        state: runtimeState(raw.state || {}),
        country: runtimeCountry(raw.country || {}),
    }),
)(raw);

export const runtimeDma = R.pipe(
    cleanMustacheObject,
    ({ code, name }) => (!code ? runtimeNull : jsonToRuntime({
        _type: 'DMA',
        code,
        name,
        value: name,
    })),
);

export const runtimeVenue = R.pipe(
    cleanMustacheObject,
    ({ name, type }) => (
        (!name || !type)
        ? runtimeNull
        : jsonToRuntime({
            _type: 'Venue',
            name,
            type,
            value: name,
        })),
);

export const runtimeAsset = R.pipe(
    cleanMustacheObject,
    ({ id, type }) => (
        (!id || !type)
        ? runtimeNull
        : jsonToRuntime({
            _type: 'Asset',
            id,
            type,
            value: id,
        })),
);

export const formToRuntime = (
    form: {
        status: string;
        data: Record<string, RuntimePromise.Type<string | boolean, string>>;
        response?: FormResponse;
    } | null | undefined,
): RuntimeObject | RuntimeNull => (
    form
        ? runtimeValue(OBJECT, {
            entry: runtimeValue(OBJECT, R.map(
                R.pipe(
                    (v: RuntimePromise.Type<string | boolean, string>): string | boolean | null => (
                        RuntimePromise.isSuccess(v)
                            ? RuntimePromise.unwrap(v)
                            : null
                    ),
                    (v: string | boolean | null): GenericRuntimeValue => {
                        if (typeof v === 'string') {
                            return runtimeValue(STRING, v);
                        }
                        if (typeof v === 'boolean') {
                            return runtimeValue(BOOLEAN, v);
                        }
                        return runtimeNull;
                    },
                ),
                form.data || {},
            )),
            status: runtimeStringOrNull(form.status),
            response: jsonToRuntime(form.response ? {
                ...form.response,
                body_string: form?.response?.body ? JSON.stringify(form.response.body) : null,
            } : null),
        })
        : runtimeNull
);

export const runtimeOrNull = (val: unknown): GenericRuntimeValue => (
    isRuntimeValue(val)
        ? val
        : runtimeNull
);
export const makeFunction = <
    F extends RuntimeFunctionImplementation<S, R>,
    S extends Signature,
    R extends ArgumentType,
>(
    name: string,
    signature: S,
    result: R,
    func: F,
): RuntimeFunction<S, R> => ({
    type: FUNCTION,
    value: {
        name,
        signature,
        result,
        arity: signature.length,
        args: [],
        func,
    },
});
