import { SagaIterator } from 'redux-saga';
import {
    cancel,
    spawn,
    take,
    fork,
    put,
    takeEvery,
    call,
} from 'redux-saga/effects';
import {
    updateGpsLocation,
    Location,
    LocationType,
    GEO_SLOW,
} from './reducer';
import {
    getLocation as getLocationRaw,
    watchLocation as watchLocationRaw,
    BrowserLocation,
} from '../../../functions/location';
import * as LoadableState from '../loadableState';

const GET_LOCATION = 'LOCATION/GET' as const;
const WATCH_LOCATION = 'LOCATION/WATCH' as const;

type GetLocationAction = {
    type: typeof GET_LOCATION;
    source: LocationType;
    options: object;
};

export const getLocation = (
    type: LocationType,
    options: object,
): GetLocationAction => ({
    type: GET_LOCATION,
    source: type,
    options,
});

type WatchLocationAction = {
    type: typeof WATCH_LOCATION;
    source: LocationType;
    maximumAge: number;
    options: object;
};

export const watchLocation = (
    source: LocationType,
    maximumAge: number,
    options: object,
): WatchLocationAction => ({
    type: WATCH_LOCATION,
    source,
    maximumAge,
    options,
});

const extractLocation = (
    l: BrowserLocation,
    source: LocationType,
): Location => ({
    latitude: l.coords.latitude,
    longitude: l.coords.longitude,
    timestamp: l.timestamp,
    source,
});

function* getLocationSaga({
    source,
    ...options
}: ReturnType<typeof getLocation>): SagaIterator<void> {
    try {
        const location = yield call(getLocationRaw, {
            ...options,
            enableHighAccuracy: source === GEO_SLOW,
        });
        yield put(updateGpsLocation(
            LoadableState.Ready(extractLocation(location, source)),
        ));
    } catch (e) {
        yield put(updateGpsLocation(
            LoadableState.Err('Failed to get geo location'),
        ));
    }
}

function* watchingSaga({
    source,
    ...options
}: { source: LocationType; maximumAge: boolean }): SagaIterator<void> {
    const channel = yield call(watchLocationRaw, {
        ...options,
        enableHighAccuracy: source === GEO_SLOW,
    });
    while (true) {
        const location = yield take(channel);
        yield put(updateGpsLocation(
            LoadableState.Ready(extractLocation(location, source)),
        ));
    }
}

function* watchLocationSaga(): SagaIterator<void> {
    while (true) {
        let task = null;
        let currentTimeout = null;

        while (true) {
            const { source, maximumAge } = yield take(WATCH_LOCATION);

            try {
                if (!currentTimeout || currentTimeout > maximumAge) {
                    if (task) {
                        yield cancel(task);
                    }

                    task = yield fork(watchingSaga, {
                        source,
                        maximumAge,
                    });
                    currentTimeout = maximumAge;
                }
            } catch (e) {
                yield put(updateGpsLocation(
                    LoadableState.Err('Error watching location'),
                ));
                break;
            }
        }
    }
}

export function* saga(): SagaIterator<void> {
    yield takeEvery(GET_LOCATION, getLocationSaga);
    yield spawn(watchLocationSaga);
}
