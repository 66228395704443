import { ActionAnalytics } from './analytics';

export const ACTION_TYPE_NONE = 'none' as const;
export const ACTION_TYPE_LINK = 'link' as const;
export const ACTION_TYPE_EXPERIENCE_LINK = 'experienceLink' as const;
export const ACTION_TYPE_TAG_VARIABLE = 'tagUserData' as const;
export const ACTION_TYPE_LOCAL_VARIABLE = 'changeVariable' as const;
export const ACTION_TYPE_FORM_SUBMIT = 'formSubmit' as const;

export type ActionType =
    | typeof ACTION_TYPE_NONE
    | typeof ACTION_TYPE_LINK
    | typeof ACTION_TYPE_EXPERIENCE_LINK
    | typeof ACTION_TYPE_TAG_VARIABLE
    | typeof ACTION_TYPE_LOCAL_VARIABLE
    | typeof ACTION_TYPE_FORM_SUBMIT;

type ActionBase<T extends ActionType> = (
    ActionAnalytics
    & {
        action: T;
    }
);

const VARIABLE_SET = 'set' as const;
const VARIABLE_INCREMENT = 'increment' as const;
const VARIABLE_DECREMENT = 'decrement' as const;

type VariableOperation =
    | typeof VARIABLE_SET
    | typeof VARIABLE_INCREMENT
    | typeof VARIABLE_DECREMENT
;

type ActionVariableChangeOptions = {
    varName: string;
    varOperation: VariableOperation;
    opValue: string;
};
export type ActionLinkOptions = {
    link: string;
    targetNewTab?: boolean;
};
export type ActionExperienceLinkOptions = {
    studioFile: string;
};

export type ActionOptionsForType<T extends ActionType = ActionType> = (
    ActionBase<T> & (
        T extends typeof ACTION_TYPE_NONE
            ? object
        : T extends typeof ACTION_TYPE_LINK
            ? ActionLinkOptions
        : T extends typeof ACTION_TYPE_EXPERIENCE_LINK
            ? ActionExperienceLinkOptions
        : T extends typeof ACTION_TYPE_TAG_VARIABLE
            ? ActionVariableChangeOptions
        : T extends typeof ACTION_TYPE_LOCAL_VARIABLE
            ? ActionVariableChangeOptions
        : T extends typeof ACTION_TYPE_FORM_SUBMIT
            ? object
        : never
    )
);

export type ActionOptions = (
    | ActionBase<typeof ACTION_TYPE_NONE>
    | (ActionBase<typeof ACTION_TYPE_LINK> & ActionLinkOptions)
    | (ActionBase<typeof ACTION_TYPE_EXPERIENCE_LINK>
        & ActionExperienceLinkOptions)
    | (ActionBase<typeof ACTION_TYPE_TAG_VARIABLE>
        & ActionVariableChangeOptions)
    | (ActionBase<typeof ACTION_TYPE_LOCAL_VARIABLE>
        & ActionVariableChangeOptions)
    | ActionBase<typeof ACTION_TYPE_FORM_SUBMIT>
);
