import { State } from '../index';
import * as LoadableState from '../loadableState';

const SET_LOCATION = 'LOCATION/SET' as const;

export const GEO_SLOW = 'geo_slow' as const;
export const GEO_FAST = 'geo_fast' as const;
export const IP = 'ip' as const;

export type LocationType = typeof GEO_SLOW | typeof GEO_FAST | typeof IP;

export const LOCATION_TYPES = [
    GEO_SLOW,
    GEO_FAST,
    IP,
] as const;

export const isLocationType = (s: string): s is LocationType => (
    LOCATION_TYPES.includes(s as LocationType)
);

export type LocationError = string;

export type LocationState = LoadableState.Type<Location, LocationError>;

export type Location = {
    latitude: number;
    longitude: number;
    timestamp: number;
    source: LocationType;
};

type UpdateGpsLocationAction = {
    type: typeof SET_LOCATION;
    location: LocationState;
};

export const updateGpsLocation = (
    loc: LocationState,
): UpdateGpsLocationAction => ({
    type: SET_LOCATION,
    location: loc,
});

type Actions = UpdateGpsLocationAction | { type: 'FALLBACK' };

const initialState = {
    currentGpsLocation: LoadableState.Uninitialized,
};

export type LocalState = {
    currentGpsLocation: LocationState;
};

export const reducer = (
    state: LocalState = initialState,
    action: Actions,
): LocalState => {
    switch (action.type) {
        case SET_LOCATION:
            return {
                currentGpsLocation: action.location,
            };
        default:
            return state;
    }
};

const location = (state: State): LocalState => state.location;
export const currentGpsLocation = (state: State): LocationState => (
    location(state).currentGpsLocation
);
