import * as React from 'react';
import { ComponentProps } from './shared/types';
import { useMacroScope, makeUseMacros, MacroContext } from './shared/macroHelpers';
import * as RuntimePromise from '../macros/promise';


type TextEditorProps = ComponentProps<{
    textContent?: string;
}>;

const TextEditor = ({
    options: { textContent },
    macroContext,
}: TextEditorProps): React.ReactElement => (
    <TextEditorSnippet
        code={textContent}
        macroContext={macroContext}
    />
);

export default TextEditor;

type TextEditorSnippetProps = {
    macroContext: MacroContext;
    code?: string;
};

const useMacros = makeUseMacros('TextEditorSnippet', {
    code: { escapeHtml: true, unescapeStringLiterals: true },
});

const TextEditorSnippet = ({
    code,
    macroContext,
}: TextEditorSnippetProps): React.ReactElement | null => {
    const scope = useMacroScope(macroContext);

    const macroOptionsWithFallbacks = React.useMemo(() => ({
        code: code ?? '',
    }), [code]);

    // All values dynamically inputed via macro must be HTML escaped
    const renderedCodePromise = useMacros(scope, macroOptionsWithFallbacks);

    if (!RuntimePromise.isSuccess(renderedCodePromise)) { return null; }

    const {
        code: renderedCode,
    } = RuntimePromise.unwrap(renderedCodePromise);

    return (
        <div
            className="textEditor"
            // eslint-disable-next-line @typescript-eslint/naming-convention, react/no-danger
            dangerouslySetInnerHTML={{ __html: renderedCode }}
        />
    );
};
