import * as React from 'react';
import styled from 'styled-components';
import { displayTemperature } from '../shared/utils';
import WeatherIcon from './WeatherIcon';
import { unwrapExists } from '../../functions/utils';

const Hours = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
`;

const HourTime = styled.div`
    text-align: center;
`;

const HourImage = styled.div`
    width: 48px;
    height: 48px;

    svg {
        width: 100%;
        height: 100%;
    }
`;

const HourTemperature = styled.div`
    height: 28.5px;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    color: #3b3f47;
`;

const formatHours = (time: number): string => {
    const date = new Date(time * 1000);
    const hours = date.getHours();
    const suffix = hours >= 12 ? 'pm' : 'am';
    return (((hours + 11) % 12) + 1) + suffix;
};

type WeatherHoursProps = {
    hourly: Array<{
        dt: number;
        temp: number;
        weather: Array<{
            icon: string;
        }>;
    }>;
}

const WeatherHours = ({
    hourly,
}: WeatherHoursProps): React.ReactElement => (
    <Hours>
        {
            hourly.map((hour) => (
                <div key={hour.dt}>
                    <HourTime>
                        {formatHours(hour.dt)}
                    </HourTime>
                    <HourImage>
                        <WeatherIcon name={unwrapExists(hour.weather[0]).icon} />
                    </HourImage>
                    <HourTemperature>
                        {displayTemperature(hour.temp)}
                    </HourTemperature>
                </div>
            ))
        }
    </Hours>
);

export default WeatherHours;
