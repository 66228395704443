import { escapeRegExp } from './escape';

export const errorToString = (e: unknown): string => {
    if (e && (e as any)?.constructor?.name?.match?.(/error/i)) {
        return (e as any).toString();
    }
    throw new Error('Tried to stringify non-error');
};

export const makeAllowedErrorUrlRegEx = (location: { host: string }) => {
    const allowRendererChunks = `(^${escapeRegExp(`https://${location.host}/shared-assets/`)}.*$)`;
    const allowLocalFile = `(^${escapeRegExp('file://')}.*)$`;
    const compositeRegex = [allowRendererChunks, allowLocalFile]
        .reduce((p, c, i) => (i === 0 ? c : `${p}|${c}`), '');
    return new RegExp(compositeRegex, 'g');
};
