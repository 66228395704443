import { State } from '../index';

const SET_ROOT_STUDIO_FILE = 'ROOT_STUDIO_FILE/SET' as const;

type SetRootStudioFileAction = {
    type: typeof SET_ROOT_STUDIO_FILE;
    id: string;
}

export const setRootStudioFile = (id: string): SetRootStudioFileAction => ({
    type: SET_ROOT_STUDIO_FILE,
    id,
});

type Action = ReturnType<typeof setRootStudioFile> | { type: 'FALLBACK' };

export type LocalState = string | null;

export const reducer = (
    state: LocalState = null,
    action: Action,
): LocalState => {
    switch (action.type) {
        case SET_ROOT_STUDIO_FILE:
            if (state === null) { return action.id; }
            return state;
        default:
            return state;
    }
};

export const getRootStudioFileId = (state: State): string | null => (
    state.rootStudioFile
);
