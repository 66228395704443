import * as R from 'ramda';
import { AnalyticsIds } from '../store/processes/studioFile/reducer';
import { AnalyticsTrafficTypeParameter, buildAnalyticsTrafficTypeParameter } from './previewMode';

const GTAG_TIMEOUT = 3000;

type GTagOptions = {
    eventLabel?: string;
    eventCategory?: string;
    pagePath?: string;
    sendTo?: AnalyticsIds;
    eventName?: string;
    eventParameters?: Array<{ key: string, value: string }>;
    trafficTypeParameter?: AnalyticsTrafficTypeParameter;
    cookie_flags?: string,
};

type GTagCommand = 'config' | 'event';

const gtagRaw = (
    a: GTagCommand,
    b: string,
    {
        eventLabel,
        eventCategory,
        pagePath,
        sendTo,
        eventParameters,
        trafficTypeParameter,
        ...dimensions
    }: GTagOptions,
): Promise<void> => (
    new Promise((resolve): void => {
        if (!window.gtag) {
            window.dataLayer = window.dataLayer || [];
            window.gtag = window.gtag ?? function gtag() {
                // eslint-disable-next-line prefer-rest-params
                window.dataLayer.push(arguments);
            };
            window.gtag('js', new Date());
            window.gtag('set', 'cookie_flags', 'Secure');
        }
        window.gtag(a, b, R.filter(R.pipe(R.isNil, R.not), {
            event_label: eventLabel,
            event_category: eventCategory,
            page_path: pagePath,
            ...dimensions,
            ...eventParameters,
            ...trafficTypeParameter,
            event_callback: resolve,
            send_to: sendTo,
        }));
    })
);

const gtag = (a: GTagCommand, b: string, c: GTagOptions): Promise<void> => Promise.race([
    (async (): Promise<void> => {
        try {
            await gtagRaw(a, b, c);
        } catch (e) {
            console.error(e);
        }
    })(),
    new Promise<void>((resolve) => { setTimeout(resolve, GTAG_TIMEOUT); }),
]);

const config = async (trackingId: AnalyticsIds, options: GTagOptions)
    : Promise<void> => {
    const measureIds: string[] = typeof trackingId === 'string' ? [trackingId] : trackingId;
    for (const measureId of measureIds) {
        const trafficTypeParameter = buildAnalyticsTrafficTypeParameter(measureId);
        gtag('config', measureId, {
                ...options,
                trafficTypeParameter,
                cookie_flags: 'Secure',
        });
    }
};

const event = (eventName: string, options: GTagOptions) => {
    const { sendTo: originalSendTo } = options;
    if (originalSendTo) {
        const originalSendTos: string[] = typeof originalSendTo === 'string'
            ? [originalSendTo] : originalSendTo;
        const ua3SendTos = originalSendTos.filter((x) => x?.toUpperCase().startsWith('UA-'));
        const ga4SendTos = originalSendTos.filter((x) => x?.toUpperCase().startsWith('G-'));
        if (ua3SendTos.length) {
            const sendTo = ua3SendTos.length === 1 ? ua3SendTos[0] : ua3SendTos;
            gtag('event', eventName, {
                ...options,
                sendTo,
            });
        }
        if (ga4SendTos.length) {
            const sendTo = ga4SendTos.length === 1 ? ga4SendTos[0] : ga4SendTos;
            const trafficTypeParameter = buildAnalyticsTrafficTypeParameter(ga4SendTos[0]);
            gtag('event', eventName, {
                ...options,
                trafficTypeParameter,
                sendTo,
            });
        }
    } else {
        gtag('event', eventName, options);
    }
};

export default {
    config,
    event,
};
