import * as React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import 'swiper/css/swiper.css';
import {
    getAdPayload,
    getPagePayload,
    isV3,
    swiperLetters,
} from '../store/processes/pagePayload';
import * as LoadableState from '../store/processes/loadableState';
import { CampaignRoot } from './campaign';
import { ComponentProps } from './shared/types';

const ContentWrapper = styled.div`
    margin-bottom: ${({ theme }): string | null => (theme.showWidget ? '12px' : null)};

    img {
        width: 100%;
    }
`;

type SwiperProps = ComponentProps;

const Swiper = (
    { id }: SwiperProps,
): React.ReactElement | null => {
    React.useEffect(() => {
        (async (): Promise<void> => {
            const { default: SwiperPackage } = await
                import(/* webpackChunkName: "swiper" */ 'swiper');

            /* eslint-disable-next-line no-new */
            new SwiperPackage(`.swiper-container.component-${id}`, {
                direction: 'horizontal',
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                },
            });
        })();
    }, [id]);

    const loadablePagePayload = useSelector(getPagePayload);

    if (!LoadableState.isReady(loadablePagePayload)) {
        return null;
    }

    const pagePayload = LoadableState.unwrap(loadablePagePayload);

    if (isV3(pagePayload)) {
        return null;
    }

    const payloads = swiperLetters.map((letter) => {
        const adPayload = getAdPayload(pagePayload, letter);
        return {
            ...adPayload,
            letter,
        };
    });

    const filteredPayloads = payloads.filter((payload) => payload.data);

    // img elements should have an alt prop with meaningful text
    // but we currently don't support this
    // https://bluebite.atlassian.net/browse/ES-455
    return (
        filteredPayloads.length === 0
            ? null
            : filteredPayloads.length === 1
                ? (
                    <CampaignRoot
                        options={{ letter: filteredPayloads[0].letter }}
                    />
                )
                : (
                    <ContentWrapper>
                        <div className={`swiper-container component-${id}`}>
                            <div className="swiper-wrapper">
                                {filteredPayloads.map((payload) => (
                                    <div
                                        key={payload.letter}
                                        className="swiper-slide"
                                    >
                                        {payload.url
                                            ? (
                                                <a
                                                    href={payload.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img
                                                        src={payload.data}
                                                    />
                                                </a>
                                            )
                                            : (
                                                <img
                                                    src={payload.data}
                                                />
                                            )}
                                    </div>
                                ))}
                            </div>
                            <div className="swiper-pagination swiper-pagination-black" />
                        </div>
                    </ContentWrapper>
                )
    );
};

export default Swiper;
