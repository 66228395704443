import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
    getAdPayload,
    getPagePayload,
    isV3,
} from '../store/processes/pagePayload';
import * as LoadableState from '../store/processes/loadableState';


const MODAL_WAIT_TIME = 5000;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const Modal = styled.div`
    display: flex;
    width: calc(100% - 36px);
    max-width: 400px;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
`;

const ModalContent = styled.div`
    width: 100%;

    @media all and (orientation: landscape) {
        width: 50vh;
    }

    margin: 16px;
    overflow: hidden;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 12px 6px rgba(0, 0, 0, 0.25);
    position: relative;
`;

const ModalImage = styled.img`
    width: 100%;
    display: block;
`;

const ModalClose = styled.div`
    align-self: center;
    min-height: 30px;
    fill: #fff;
`;

const Interstitial = (): React.ReactElement | null => {
    const [isShown, setIsShown] = useState(true);
    const hide = (): void => setIsShown(false);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setIsShown(false);
        }, MODAL_WAIT_TIME);
        return (): void => clearTimeout(timeout);
    }, []);

    const loadablePagePayload = useSelector(getPagePayload);

    if (!LoadableState.isReady(loadablePagePayload)) {
        return null;
    }

    const pagePayload = LoadableState.unwrap(loadablePagePayload);

    if (isV3(pagePayload)) {
        return null;
    }

    const adPayload = getAdPayload(pagePayload, 'interstitial');

    return (
        !adPayload.data || !isShown
            ? null
            : (
                /* eslint-disable max-len */
                <Overlay>
                    <Modal>
                        <ModalContent>
                            {adPayload.url
                                ? (
                                    <a
                                        href={adPayload.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <ModalImage src={adPayload.data} />
                                    </a>
                                )
                                : <ModalImage src={adPayload.data} />}
                        </ModalContent>
                        <ModalClose onClick={hide}>
                            <svg width="24" height="24" viewBox="0 0 24 24">
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                <path d="M0 0h24v24H0z" fill="none" />
                            </svg>
                        </ModalClose>
                    </Modal>
                </Overlay>
                /* eslint-enable max-len */
            )
    );
};

export default Interstitial;
