export class Task {
    #rawCancel: (task: Task) => void;

    constructor(rawCancel: (task: Task) => void) {
        this.#rawCancel = rawCancel;
    }

    cancel() {
        this.#rawCancel(this);
    }
}
