/* eslint-disable max-len */
import React from 'react';

const PartlyCloudyNight = (): React.ReactElement => (
    <svg aria-label="partly cloudy night icon" width="128" height="128" viewBox="0 0 128 128">
        <defs>
            <clipPath id="clip-path-partly-night">
                <rect id="Rectangle_4" data-name="Rectangle 4" width="72.246" height="50.268" fill="none" />
            </clipPath>
            <clipPath id="clip-PartlyCloudyNight">
                <rect width="128" height="128" />
            </clipPath>
        </defs>
        <g id="PartlyCloudyNight" clipPath="url(#clip-PartlyCloudyNight)">
            <rect width="128" height="128" fill="#fff" />
            <g id="Group_26" data-name="Group 26" transform="translate(28 39)">
                <g id="Group_7" data-name="Group 7" transform="translate(0 0)" clipPath="url(#clip-path-partly-night)">
                    <path id="Path_21" data-name="Path 21" d="M65.3,2.586A13.136,13.136,0,0,1,65.553,0a14.214,14.214,0,1,0,15.9,15.352,13.112,13.112,0,0,1-2.952.367A13.166,13.166,0,0,1,65.3,2.586" transform="translate(-9.203 0)" fill="#d9d2ca" />
                    <path id="Path_22" data-name="Path 22" d="M53.284,22.3a17.486,17.486,0,0,0-33.2-5.663,17.488,17.488,0,1,0-2.538,34.783L52.9,51.328a14.519,14.519,0,0,0,.38-29.033" transform="translate(0 -1.147)" fill="#e6e7ea" />
                </g>
            </g>
        </g>
    </svg>
);

export default PartlyCloudyNight;
