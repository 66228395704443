import { SagaIterator } from 'redux-saga';
import {
    call,
    put,
} from 'redux-saga/effects';
import { setRootStudioFile } from './reducer';
import { initializeSDK } from '../variables';

export function* setRootStudioFileSaga(id: string | null): SagaIterator {
    if (id) {
        yield put(setRootStudioFile(id));
        yield call(initializeSDK, id);
    }
}
