import * as R from 'ramda';
import { Dispatch } from 'redux';
import { createSelector, createStructuredSelector } from 'reselect';
import {
    ANY,
    STRING,
    FUNCTION,
    NUMBER,
    OBJECT,
    BOOLEAN,
    ARRAY,
    NULL,
    REGEXP,
    STRINGABLE,
    NUMBERABLE,
    STRING_OR_REGEXP,
    ArgumentType,
    ArgumentForType,
    formToRuntime,
    runtimeValue,
    runtimeOrNull,
    jsonToRuntime,
    runtimeNull,
    runtimeAddress,
    runtimeLocation,
    runtimeDma,
    GenericRuntimeValue,
    makeFunction,
    isRuntimeType,
    RuntimeString,
    unwrap,
    RuntimeFunction,
    ArgumentsForSignature,
    Signature,
    RuntimeObject,
} from './runtime';
import { State } from '../store/processes';
import { getForm } from '../store/processes/forms/reducer';
import { coordinateDist } from '../functions/coordinates';
import {
    PagePayload,
    getPagePayload,
    isV2,
} from '../store/processes/pagePayload';
import {
    getLocation as rawGetLocation,
    watchLocation as rawWatchLocation,
    currentGpsLocation,
    IP,
    isLocationType,
} from '../store/processes/location';
import * as LoadableState from '../store/processes/loadableState';
import * as RuntimePromise from './promise';

const MISSMATCH = 'MISSMATCH' as const;
const EXECUTION = 'EXECUTION' as const;

const URL_OBJECT_TYPE = 'URL' as const;

type TypeCheckMissmatchError = {
    type: typeof MISSMATCH;
    message: string;
}
type TypeCheckExecutionError = {
    type: typeof EXECUTION;
    message: string;
}
type TypeCheckError =
    | TypeCheckMissmatchError
    | TypeCheckExecutionError
    ;

const typeCheckMissmatchError = (
    message: string,
): RuntimePromise.Err<TypeCheckMissmatchError> => (
    RuntimePromise.Err({ type: MISSMATCH, message })
);
const typeCheckExecutionError = (
    message: string,
): RuntimePromise.Err<TypeCheckExecutionError> => (
    RuntimePromise.Err({ type: EXECUTION, message })
);

const errorToString = (e: unknown) => (
    (e as any)?.toString?.() ?? ''
);

const typeCheck = <A extends ArgumentType>(
    type: A,
    value: RuntimePromise.Type<GenericRuntimeValue, string>,
): RuntimePromise.Type<ArgumentForType<A>, TypeCheckError> => {
    if (RuntimePromise.isUnresolved(value)) {
        return RuntimePromise.Unresolved;
    }
    if (RuntimePromise.isErr(value)) {
        return typeCheckExecutionError(RuntimePromise.unwrapErr(value));
    }

    const resolved = RuntimePromise.unwrap(value);

    if (
        type === STRINGABLE
        && (
            resolved.type === STRING
            || resolved.type === BOOLEAN
            || resolved.type === NUMBER
            || resolved.type === OBJECT
        )
    ) {
        return RuntimePromise.Success(resolved as ArgumentForType<A>);
    }
    if (
        type === NUMBERABLE
        && (
            resolved.type === STRING
            || resolved.type === BOOLEAN
            || resolved.type === NUMBER
            || resolved.type === OBJECT
        )
    ) {
        return RuntimePromise.Success(resolved as ArgumentForType<A>);
    }
    if (
        type === STRING_OR_REGEXP
        && (
            resolved.type === STRING
            || resolved.type === REGEXP
        )
    ) {
        return RuntimePromise.Success(resolved as ArgumentForType<A>);
    }
    if (type === ANY) {
        return RuntimePromise.Success(resolved as ArgumentForType<A>);
    }
    if (type === resolved.type) {
        return RuntimePromise.Success(
            resolved.value as any as ArgumentForType<A>,
        );
    }
    return typeCheckMissmatchError(
        `type ${resolved.type} cannot be assigned as type ${type}`,
    );
};

const callFunction = (
    f: GenericRuntimeValue,
    v: RuntimePromise.Type<GenericRuntimeValue, string>,
): RuntimePromise.Type<GenericRuntimeValue, string> => {
    if (!isRuntimeType(FUNCTION, f)) {
        return RuntimePromise.Err(`Tried to call type ${f.type} as a function`);
    }

    const {
        arity,
        args,
        name,
        signature,
        ...rest
    } = unwrap(f);

    if (signature.length === 0) {
        return rest.func(...([] as any));
    }

    const checked = typeCheck(signature[signature.length - arity], v);

    if (RuntimePromise.isErr(checked)) {
        const err = RuntimePromise.unwrapErr(checked);

        switch (err.type) {
            case MISSMATCH:
                return RuntimePromise.Err([
                    `Function ${name} called with args `,
                    JSON.stringify([...args, v].map(R.pipe(
                        RuntimePromise.map((v: GenericRuntimeValue) => v.type),
                        RuntimePromise.unwrapOr('UNRESOLVED'),
                    ))),
                    ' but ',
                    err.message,
                ].join(''));
            case EXECUTION:
                return RuntimePromise.Err(err.message);
        }
    }

    const newFunc = {
        name: `${name}.`,
        arity: arity - 1,
        args: [...args, v],
        signature,
        ...rest,
    };

    if (signature.length - newFunc.args.length > 0) {
        return RuntimePromise.Success(runtimeValue(FUNCTION, newFunc));
    }

    return RuntimePromise.chain(
        (args: Array<GenericRuntimeValue>) => (
            newFunc.func(...args.slice(0, newFunc.signature.length) as any)
        ),
        RuntimePromise.all(newFunc.args) as RuntimePromise.Type<
            Array<GenericRuntimeValue>,
            string
        >,
    );
};

export const callGenericRuntimeValueWithGenericArgs: {
    (func: GenericRuntimeValue, args: ReadonlyArray<GenericRuntimeValue>):
        RuntimePromise.Type<GenericRuntimeValue, string>;
    (func: GenericRuntimeValue):
        (args: ReadonlyArray<GenericRuntimeValue>) =>
            RuntimePromise.Type<GenericRuntimeValue, string>;
} = R.curry(<R extends ArgumentType>(
    func: RuntimeFunction<any, R>,
    args: ReadonlyArray<GenericRuntimeValue>,
): RuntimePromise.Type<GenericRuntimeValue, string> => (
    args.length
        ? R.reduce(
            (fn: RuntimePromise.Type<GenericRuntimeValue, string>, a) => (
                RuntimePromise.chain((f) => callFunction(f, a), fn)
            ),
            RuntimePromise.Success(func),
            args.map(RuntimePromise.Success),
        )
        : callFunction(func, RuntimePromise.Success(runtimeNull))
)) as any;

export const callFunctionWithGenericArgs: {
    <R extends ArgumentType>(
        func: RuntimeFunction<any, R>,
        args: ReadonlyArray<GenericRuntimeValue>,
    ): RuntimePromise.Type<ArgumentForType<R>, string>;
    <R extends ArgumentType>(func: RuntimeFunction<any, R>):
        (args: ReadonlyArray<GenericRuntimeValue>) =>
            RuntimePromise.Type<ArgumentForType<R>, string>;
} = callGenericRuntimeValueWithGenericArgs as any;

// Adds typing so arguments are checked when called from within functions
// written in typescript
const callFunctionWithArgs: {
    <S extends Signature, R extends ArgumentType>(
        func: RuntimeFunction<S, R>,
        args: ArgumentsForSignature<S>,
    ): RuntimePromise.Type<ArgumentForType<R>, string>;
    <S extends Signature, R extends ArgumentType>(func: RuntimeFunction<S, R>):
        (args: ArgumentsForSignature<S>) =>
            RuntimePromise.Type<ArgumentForType<R>, string>;
} = callGenericRuntimeValueWithGenericArgs as any;

export const coerceToString = makeFunction(
    '^coerceToString', [STRINGABLE] as const, STRING,
    (value): RuntimePromise.Type<RuntimeString, string> => {
        switch (value.type) {
            case STRING:
            case BOOLEAN:
            case NUMBER:
                return RuntimePromise.Success(
                    runtimeValue(STRING, `${unwrap(value)}`),
                );
            case OBJECT: {
                const obj = unwrap(value);
                // if obj has a value property we use this as a toString
                if (obj.value?.type === STRING) {
                    return RuntimePromise.Success(obj.value);
                }
                return RuntimePromise.Err(
                    'OBJECT could not be used as a string',
                );
            }
        }
    },
);

// Going to rely on type inference to use the return type from makeFunction
// This is because the function type is complicated and set by the parameters

export const stringConcat = makeFunction(
    '^stringConcat', [STRING, STRING] as const, STRING,
    (str1, str2) => RuntimePromise.Success(
        runtimeValue(STRING, `${unwrap(str1)}${unwrap(str2)}`),
    ),
);

export const index = makeFunction(
    '^index', [NUMBER, ARRAY] as const, ANY,
    (idx, arr) => (
        RuntimePromise.Success(unwrap(arr)[unwrap(idx)] || runtimeNull)
    ),
);

export const access = makeFunction(
    '^access', [OBJECT, STRINGABLE] as const, ANY,
    (obj, keyRaw) => RuntimePromise.map(
        (key: RuntimeString) => runtimeOrNull(unwrap(obj)[unwrap(key)]),
        callFunctionWithArgs(coerceToString, [keyRaw]),
    ),
);

export const not = makeFunction(
    '^!', [BOOLEAN] as const, BOOLEAN,
    (b) => RuntimePromise.Success(runtimeValue(BOOLEAN, !unwrap(b))),
);

export const equality = makeFunction(
    '^==', [ANY, ANY] as const, BOOLEAN,
    (v1, v2) => RuntimePromise.Success(
        runtimeValue(
            BOOLEAN,
            v1.type === v2.type
            && [STRING, NULL, NUMBER, BOOLEAN].includes(v1.type as any)
            && v1.value === v2.value,
        ),
    ),
);

const toNumber = (v: string | number): number => (
    typeof v === 'string'
        ? parseFloat(v)
        : v
);

export const mul = makeFunction(
    '^*', [NUMBERABLE, NUMBERABLE] as const, NUMBER,
    (v1, v2) => RuntimePromise.Success(
        runtimeValue(NUMBER, toNumber(unwrap(v1)) * toNumber(unwrap(v2))),
    ),
);

export const div = makeFunction(
    '^/', [NUMBERABLE, NUMBERABLE] as const, NUMBER,
    (v1, v2) => RuntimePromise.Success(
        runtimeValue(NUMBER, toNumber(unwrap(v1)) / toNumber(unwrap(v2))),
    ),
);

export const add = makeFunction(
    '^+', [NUMBERABLE, NUMBERABLE] as const, NUMBER,
    (v1, v2) => RuntimePromise.Success(
        runtimeValue(NUMBER, toNumber(unwrap(v1)) + toNumber(unwrap(v2))),
    ),
);

export const sub = makeFunction(
    '^-', [NUMBERABLE, NUMBERABLE] as const, NUMBER,
    (v1, v2) => RuntimePromise.Success(
        runtimeValue(NUMBER, toNumber(unwrap(v1)) - toNumber(unwrap(v2))),
    ),
);

export const uppercase = makeFunction(
    'String:to_upper_case', [STRING] as const, STRING,
    (s) => RuntimePromise.Success(
        runtimeValue(STRING, unwrap(s).toUpperCase()),
    ),
);

export const lowercase = makeFunction(
    'String:to_lower_case', [STRING] as const, STRING,
    (s) => RuntimePromise.Success(
        runtimeValue(STRING, unwrap(s).toLowerCase()),
    ),
);

export const replace = makeFunction(
    'String:replace', [STRING_OR_REGEXP, STRING, STRING] as const, STRING,
    (substr, newSubstr, str) => RuntimePromise.Success(
        runtimeValue(STRING, unwrap(str).replace(unwrap(substr), unwrap(newSubstr))),
    ),
);

export const replaceAll = makeFunction(
    'String:replace_all', [STRING_OR_REGEXP, STRING, STRING] as const, STRING,
    (match, replacement, str) => RuntimePromise.Success(
        runtimeValue(STRING, unwrap(str).split(unwrap(match)).join(unwrap(replacement))),
    ),
);

export const slice = makeFunction(
    'String:slice', [NUMBER, NUMBER, STRING] as const, STRING,
    (start, end, str) => RuntimePromise.Success(
        runtimeValue(STRING, unwrap(str).slice(unwrap(start), unwrap(end))),
    ),
);

export const split = makeFunction(
    'String:split', [STRING_OR_REGEXP, STRING] as const, ARRAY,
    (separator, str) => RuntimePromise.Success(
        runtimeValue(ARRAY, unwrap(str).split(unwrap(separator))
            .map((elem) => runtimeValue(STRING, elem))),
    ),
);

export const indexOf = makeFunction(
    'String:index_of', [STRING, NUMBER, STRING] as const, NUMBER,
    (searchValue, fromIndex, str) => RuntimePromise.Success(
        runtimeValue(NUMBER, unwrap(str).indexOf(unwrap(searchValue), unwrap(fromIndex))),
    ),
);

// Does not yet support an array of promises
export const arrayFind = makeFunction(
    'Array:find', [STRING, ANY, ARRAY] as const, ANY,
    (key, value, a) => {
        const result = unwrap(a).find(R.pipe(
            (item: GenericRuntimeValue) => callFunctionWithGenericArgs(access, [item, key]),
            RuntimePromise.chain(
                (innerItem) => callFunctionWithGenericArgs(equality, [innerItem, value]),
            ),
            RuntimePromise.map(
                (innerItem) => unwrap(innerItem),
            ),
            RuntimePromise.unwrapOr(false),
        ));

        return R.pipe(
            runtimeOrNull,
            RuntimePromise.Success,
        )(result);
    },
);

export const getFormForId = (id: string, state: State) => formToRuntime(getForm(state, id));

export const loadFormData = (state: State) => makeFunction(
    'Form:get', [STRINGABLE], ANY, // OBJECT | NULL when we support or types
    (s) => {
        const idPromise = callFunctionWithArgs(coerceToString, [s]);
        if (!RuntimePromise.isSuccess(idPromise)) { return idPromise; }
        const id = unwrap(RuntimePromise.unwrap(idPromise));

        return RuntimePromise.Success(getFormForId(id, state));
    },
);

export const getIpLocation = (state: State) => RuntimePromise.map(
    (pagePayload) => runtimeLocation({
        latitude: pagePayload.sdkData.location?.latitude,
        longitude: pagePayload.sdkData.location?.longitude,
        timestamp: window.macroPageLoadEpoch || 0,
        source: IP,
    }),
    RuntimePromise.fromLoadableState(getPagePayload(state)),
);

export const getLocation = (state: State, dispatch?: Dispatch) => makeFunction(
    'Location:get', [STRING, OBJECT] as const, ANY,
    (rawType) => {
        const type = unwrap(rawType);
        if (!isLocationType(type)) {
            return RuntimePromise.Err(`Unknown location type: "${type}"`);
        }
        if (type === IP) {
            return getIpLocation(state);
        }

        const location = currentGpsLocation(state);

        if (LoadableState.isUninitialized(location)) {
            dispatch?.(rawGetLocation(type, {}));
        }

        return RuntimePromise.map(
            runtimeLocation,
            RuntimePromise.fromLoadableState(location),
        );
    },
);

export const watchLocation = (state: State, dispatch?: Dispatch) => makeFunction(
    'Location:watch', [STRING, NUMBER, OBJECT] as const, ANY,
    (typeRaw, timeRaw) => {
        const type = unwrap(typeRaw);
        const time = unwrap(timeRaw);
        if (!isLocationType(type)) {
            return RuntimePromise.Err(`Unknown location type: "${type}"`);
        }
        if (type === IP) {
            return getIpLocation(state);
        }

        const location = currentGpsLocation(state);

        dispatch?.(rawWatchLocation(type, time, {}));

        return RuntimePromise.map(
            runtimeLocation,
            RuntimePromise.fromLoadableState(location),
        );
    },
);

export const newLocation = makeFunction(
    'Location:new', [NUMBER, NUMBER, OBJECT] as const, ANY,
    (lat, lon) => RuntimePromise.Success(jsonToRuntime({
        _type: 'Location',
        coords: {
            _type: 'Coordinates',
            latitude: unwrap(lat),
            longitude: unwrap(lon),
        },
        timestamp: Date.now(),
        source: 'user_defined',
    })),
);

export const mapDeviceLocationToAddress = (state: State) => RuntimePromise.map(
    (pagePayload) => {
        const macros = isV2(pagePayload) ? pagePayload.legacy.macros : null;
        const { location } = pagePayload.sdkData;
        return {
            city: location?.city?.name,
            state: {
                code: location?.subdivision_1?.iso_code,
            },
            postal_code: location?.postal_code,
            // Street was only provided in V2 via tag configuration
            // and is not included on pagePayload
            street: macros?.['device:address_street'] ?? null,
            country: {
                name: location?.country?.name,
                code: location?.country?.iso_code,
            },
        };
    },
    RuntimePromise.fromLoadableState(getPagePayload(state)),
);

export const mapDeviceLocationToDma = (state: State) => RuntimePromise.map(
    (pagePayload) => {
        if (!(
            isV2(pagePayload)
            && pagePayload.legacy
            && pagePayload.legacy.macros
        )) { return {}; }
        const { macros } = pagePayload.legacy;
        return {
            code: macros['device:address_dma_code'],
            name: macros['device:address_dma_name'],
        };
    },
    RuntimePromise.fromLoadableState(getPagePayload(state)),
);

export const addressFromLocation = (state: State) => makeFunction(
    'Address:from_location', [OBJECT], ANY,
    (l) => {
        const location = unwrap(l);

        // Currently only ip based addresses can be looked up
        if (
            location._type?.value === 'Location'
            && location.source?.value === IP
        ) {
            return RuntimePromise.map(
                runtimeAddress,
                mapDeviceLocationToAddress(state),
            );
        }

        return RuntimePromise.Success(runtimeNull);
    },
);

export const dmaFromLocation = (state: State) => makeFunction(
    'DMA:from_location', [OBJECT], ANY,
    (l) => {
        const location = unwrap(l);

        // Currently only ip based addresses can be looked up
        if (
            location._type?.value === 'Location'
            && location.source?.value === IP
        ) {
            return RuntimePromise.map(
                runtimeDma,
                mapDeviceLocationToDma(state),
            );
        }

        return RuntimePromise.Success(runtimeNull);
    },
);

export const locationDistance = makeFunction(
    'Location:distance', [OBJECT, OBJECT] as const, NUMBER,
    (l1Raw, l2Raw) => {
        const l1 = unwrap(l1Raw);
        const l2 = unwrap(l2Raw);

        if (l1._type.value !== 'Location' || l2._type.value !== 'Location') {
            return RuntimePromise.Err('Value is not a Location');
        }
        return R.pipe(
            (coords: [number, number, number, number]) => coordinateDist(...coords),
            (dist) => dist.toFixed(2),
            Number.parseFloat,
            runtimeValue(NUMBER),
            RuntimePromise.Success,
        )([
            (l1 as any).coords.value.latitude.value,
            (l1 as any).coords.value.longitude.value,
            (l2 as any).coords.value.latitude.value,
            (l2 as any).coords.value.longitude.value,
        ]);
    },
);

export const gt = makeFunction(
    '>', [NUMBER, NUMBER] as const, BOOLEAN,
    (n1, n2) => RuntimePromise.Success(
        runtimeValue(BOOLEAN, unwrap(n1) > unwrap(n2)),
    ),
);

export const lt = makeFunction(
    '<', [NUMBER, NUMBER] as const, BOOLEAN,
    (n1, n2) => RuntimePromise.Success(
        runtimeValue(BOOLEAN, unwrap(n1) < unwrap(n2)),
    ),
);

export const gte = makeFunction(
    '>=', [NUMBER, NUMBER] as const, BOOLEAN,
    (n1, n2) => RuntimePromise.Success(
        runtimeValue(BOOLEAN, unwrap(n1) >= unwrap(n2)),
    ),
);

export const lte = makeFunction(
    '<=', [NUMBER, NUMBER] as const, BOOLEAN,
    (n1, n2) => RuntimePromise.Success(
        runtimeValue(BOOLEAN, unwrap(n1) <= unwrap(n2)),
    ),
);

export const and = makeFunction(
    '^and', [BOOLEAN, BOOLEAN] as const, BOOLEAN,
    (b1, b2) => RuntimePromise.Success(
        runtimeValue(BOOLEAN, unwrap(b1) && unwrap(b2)),
    ),
);

export const or = makeFunction(
    '^or', [BOOLEAN, BOOLEAN] as const, BOOLEAN,
    (b1, b2) => RuntimePromise.Success(
        runtimeValue(BOOLEAN, unwrap(b1) || unwrap(b2)),
    ),
);

const selectDeviceLanguage = createSelector(
    () => !!__IS_PREVIEW__,
    getPagePayload,
    (isPreview: boolean, pagePayload: LoadableState.Type<PagePayload, string>): string => (isPreview
        ? LoadableState.unwrapOr(null, pagePayload)
            ?.sdkData?.device?.language ?? navigator.language
        : navigator.language),
);

const dateToLocaleString = createSelector(
    selectDeviceLanguage,
    (locale: string) => makeFunction(
        'Date:to_locale_string', [OBJECT, NUMBER] as const, STRING,
        (_o, t) => {
            // we currently don't implement options but will support this in the future
            // const options = _o;
            const time = unwrap(t); // in milliseconds from unix epoch time
            return RuntimePromise.Success(
                runtimeValue(STRING, new Date(time).toLocaleString(locale)),
            );
        },
    ),
);

const toLocaleTimeString = createSelector(
    selectDeviceLanguage,
    (locale: string) => makeFunction(
        'Date:to_locale_time_string', [OBJECT, NUMBER] as const, STRING,
        (_o, t) => {
            // we currently don't implement options but will support this in the future
            // const options = _o;

            const time = unwrap(t); // in milliseconds from unix epoch time
            return RuntimePromise.Success(
                runtimeValue(STRING, new Date(time).toLocaleTimeString(locale)),
            );
        },
    ),
);

export const toLocaleDateString = createSelector(
    selectDeviceLanguage,
    (locale: string) => makeFunction(
        'Date:to_locale_date_string', [OBJECT, NUMBER] as const, STRING,
        (_o, t) => {
            // we currently don't implement options but will support this in the future
            // const options = _o;

            const time = unwrap(t); // in milliseconds from unix epoch time
            return RuntimePromise.Success(
                runtimeValue(STRING, new Date(time).toLocaleDateString(locale)),
            );
        },
    ),
);

export const getRuntimeLocalFromStore = createStructuredSelector<State, {
    to_locale_string: ReturnType<typeof dateToLocaleString>,
    to_locale_time_string: ReturnType<typeof toLocaleTimeString>,
    to_locale_date_string: ReturnType<typeof toLocaleDateString>,
}>({
    to_locale_string: dateToLocaleString,
    to_locale_time_string: toLocaleTimeString,
    to_locale_date_string: toLocaleDateString,
});

export const toFixed = makeFunction(
    'Number:to_fixed', [NUMBER, NUMBER] as const, STRING,
    (num, digits) => RuntimePromise.Success(
        runtimeValue(STRING, unwrap(num).toFixed(unwrap(digits))),
    ),
);

export const newURLInner = (url: string): RuntimeObject => {
    const newURL = new URL(url);

    const returnURL = Object.fromEntries([
        'hash' as const,
        'host' as const,
        'hostname' as const,
        'href' as const,
        'origin' as const,
        'password' as const,
        'pathname' as const,
        'port' as const,
        'protocol' as const,
        'search' as const,
        'searchParams' as const,
        'username' as const,
    ].map((property: keyof URL) => {
        if (property === 'searchParams') {
            return [property, Object.fromEntries(newURL[property])];
        }
        return [property, newURL[property]];
    }));

    returnURL._type = URL_OBJECT_TYPE;
    returnURL.value = url;

    return jsonToRuntime(returnURL) as RuntimeObject;
};

export const newURL = makeFunction(
    'URL:new', [STRING, OBJECT] as const, OBJECT,
    (url) => {
        try {
            return RuntimePromise.Success(newURLInner(unwrap(url)));
        } catch (e) {
            return RuntimePromise.Err(`Error in URL:new '${errorToString(e)}'`);
        }
    },
);

export const newRegExp = makeFunction(
    'RegExp:new', [STRING, OBJECT] as const, REGEXP,
    (str) => {
        try {
            return RuntimePromise.Success(
                runtimeValue(REGEXP, new RegExp(unwrap(str))),
            );
        } catch (e) {
            return RuntimePromise.Err(`Error in RegExp:new '${errorToString(e)}'`);
        }
    },
);
