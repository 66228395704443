import {
    useContext,
    useState,
    useEffect,
    useCallback,
    useMemo,
} from 'react';
import { SDKContext } from '../../../sdkContext';
import { BlueBiteSDK } from './sdk';
import {
    BBStudioUseLocalVariableHook,
    BBStudioUseLocalVariablesHook,
    Interaction,
    InteractionLoading,
} from './types';

const assertSDK = (sdk: BlueBiteSDK | undefined): BlueBiteSDK => {
    if (!sdk) {
        throw new Error('Blue Bite SDK is not defined');
    }
    return sdk;
};

export const useLocalVariables: BBStudioUseLocalVariablesHook = () => {
    const sdk = assertSDK(useContext(SDKContext));
    const [localVariables, setLocalVariables] = useState(sdk.getLocalVariables());

    useEffect(() => {
        const task = sdk.registerOnLocalVariableChange(() => {
            setLocalVariables(sdk.getLocalVariables());
        });

        return () => {
            task.cancel();
        };
    }, [sdk]);

    return Object.fromEntries(
        Object.entries(localVariables).map(([key, value]) => [key, value]),
    );
};

export const useLocalVariable: BBStudioUseLocalVariableHook = (
    key: string,
    defaultValue?: string,
) => {
    const sdk = assertSDK(useContext(SDKContext));
    const localVariables = useLocalVariables();
    const currentValue = localVariables[key] ?? defaultValue;
    const setter = (value?: string) => {
        sdk.setLocalVariable(key, value);
    };
    return [currentValue, setter];
};

export const useInteraction = (): InteractionLoading<{
    interaction: Interaction & {
        object: {
            setObjectVariables: (updates: { [key: string]: string }) => Promise<void>,
        },
    },
}> => {
    const sdk = assertSDK(useContext(SDKContext));
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [interaction, setInteraction] = useState<Interaction | null | undefined>();
    const [objectVariables, setObjectVariablesState] = useState<Record<string, string>>({});
    const objectUuid = interaction?.object.uuid;

    useEffect(() => {
        (async () => {
            try {
                const sdkInteraction = await sdk.getInteraction();
                setInteraction(sdkInteraction);
                setObjectVariablesState(sdkInteraction?.object.variables ?? {});
                setIsLoading(false);
                if (sdkInteraction === null) {
                    setIsError(true);
                }
            } catch (err) {
                setIsLoading(false);
                setIsError(true);
                setInteraction(null);
            }
        })();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        const task = sdk.registerOnObjectVariablesChange(() => {
            setObjectVariablesState(sdk.getObjectVariables(objectUuid ?? ''));
        });

        return () => {
            task.cancel();
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [objectUuid]);

    const setObjectVariables = useCallback(async (updates: { [key: string]: string }) => {
        if (!objectUuid) {
            setIsError(true);
            return;
        }
        try {
            await sdk.setObjectVariables(objectUuid, updates);
        } catch (err) {
            setIsError(true);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [objectUuid]);

    return useMemo(() => {
        if (isLoading) {
            return { isLoading: true };
        }

        if (isError || !interaction) {
            return { isLoading: false, isError: true };
        }

        return {
            interaction: {
                ...interaction,
                object: {
                    ...interaction.object,
                    setObjectVariables,
                    variables: {
                        ...objectVariables,
                    },
                },
            },
            isLoading: false,
            isError: false,
        };
    }, [isLoading, isError, interaction, objectVariables, setObjectVariables]);
};

window.bbstudio = window.bbstudio ?? {};
window.bbstudio.hooks = {
    ...(window.bbstudio.hooks ?? {}),
    useLocalVariables,
    useLocalVariable,
    useInteraction,
};
