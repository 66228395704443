import { Dispatch } from 'redux';
import { List } from 'ts-toolbelt';
import {
    channel,
    SagaIterator,
} from 'redux-saga';
import {
    put,
    call,
    throttle,
    delay,
    flush,
    cancel,
    take,
    race,
    select as rawSelect,
    CallEffect,
    SagaReturnType,
} from 'redux-saga/effects';
import { State } from '../store/store';
import * as RuntimePromise from '../macros/promise';

type SimpleSelector<V = any> = (state: State) => V;

export type SelectorReturnType<S> = (
    S extends SimpleSelector<infer V>
        ? V
    : S extends (...args: any) => SimpleSelector<infer V>
        ? V
    : never
);

function* innerSelect<A extends Array<any>>(
    selector: (
        (state: State) => any
        | ((...args: A) => (state: State) => any)
    ),
    ...args: A
): SagaIterator {
    if (args.length === 0) {
        return yield rawSelect(selector);
    }
    return yield rawSelect((selector as any)(...args));
}

export function* withDelayedDispatch<
    S extends(dispatch: Dispatch, ...params: Array<any>) => SagaIterator
>(
    saga: S,
    ...params: List.Tail<Parameters<S>>): SagaIterator<SagaReturnType<S>> {
    // All actions fired from macro are placed in this channel
    const c = yield call(channel);
    const result = yield call(saga as any, c.put as Dispatch, ...params);
    // Now that we are finished using the scope we
    // will fire any actions stored in the channel
    const actions = yield flush(c);
    for (const a of actions) {
        yield put(a);
    }
    return result;
}

export function* waitForPromiseWithTimeout<A, E>(
    { timeout, throttle: throttleTime, pollAction }: {
        // Amount of time to wait for the promise before giving up leaving it unresolved
        timeout: number;
        // Amount of time throttled between polling attempts to rerender
        throttle: number;
        // Polling action that trigers a rerender
        pollAction: string;
    },
    saga: () => SagaIterator<RuntimePromise.Type<A, E>>,
): SagaIterator<RuntimePromise.Type<A, E>> {
    const resultChannel = yield call(channel);

    const eagerResult = yield call(saga);
    if (!RuntimePromise.isUnresolved(eagerResult)) { return eagerResult; }

    const task = yield throttle(throttleTime, pollAction, function* render() {
        const result: RuntimePromise.Type<A, E> = yield call(saga);
        if (!RuntimePromise.isUnresolved(result)) {
            resultChannel.put(result);
        }
    });
    const [result, timedOut] = yield race([
        take(resultChannel),
        delay(timeout, true),
    ]);

    yield cancel(task);

    if (timedOut) {
        return RuntimePromise.Unresolved;
    }
    return result;
}


// Redux Sagas typicaly have selector types like `(state, ...args) => V`
// we have been using `(...args) => (state) => V` because it better suited
// for FP style and composing selectors with less repeated typing of the state.
export const select: {
    (selector: SimpleSelector): CallEffect;
    <A>(selector: (a: A) => SimpleSelector, a: A): CallEffect;
    <A, B>(selector: (a: A, b: B) => SimpleSelector, a: A, b: B): CallEffect;
    <A, B, C>(selector: (a: A, b: B, c: C) => SimpleSelector, a: A, b: B, c: C): CallEffect;
    /* eslint-disable max-len */
    <A, B, C, D>(selector: (a: A, b: B, c: C, d: D) => SimpleSelector, a: A, b: B, c: C, d: D): CallEffect;
    <A, B, C, D, E>(selector: (a: A, b: B, c: C, d: D, e: E) => SimpleSelector, a: A, b: B, c: C, d: D, e: E): CallEffect;
    <A, B, C, D, E, F>(selector: (a: A, b: B, c: C, d: D, e: E, f: F) => SimpleSelector, a: A, b: B, c: C, d: D, e: E, f: F): CallEffect;
    /* eslint-enable max-len */
} = (<A extends Array<any>>(
    selector: (
        (state: State) => any
        | ((...args: A) => (state: State) => any)
    ),
    ...args: A
): CallEffect => call(
    innerSelect,
    selector,
    ...args,
)) as any;
