import * as R from 'ramda';
import { Dispatch } from 'redux';
import queryString from 'query-string';
import memoize from 'fast-memoize';
import { createSelector } from 'reselect';
import * as BCP47 from 'bcp-47';
import { parseMacroLine } from '@bluebitellc/studio-utils';
import { currentGpsLocation } from '../store/processes/location';
import evalAst from './evaluate';
import { MacroEvalContext, MacroScopeContext, defaultMacroOptions } from './context';
import {
    DEVICE_VAR,
    OBJECT_VAR,
    getVariablesForType,
} from '../store/processes/variables/reducer';
import {
    getAllSubmittedForms,
    getAnyFormFailed,
    getAnyFormDone,
} from '../store/processes/forms/reducer';
import {
    getParentEvent,
    EVENT_TYPE_FORM_SUBMIT,
    EVENT_WHEN_AFTER,
    Event,
} from '../store/processes/studioFile';
import {
    getCookieConsentAccepted,
    getCookieConsentEnabled,
} from '../store/processes/cookieConsent';
import { getPage } from '../store/processes/page';
import {
    coerceToString,
    callFunctionWithGenericArgs,
    loadFormData,
    arrayFind,
    getLocation,
    watchLocation,
    newLocation,
    locationDistance,
    getIpLocation,
    addressFromLocation,
    dmaFromLocation,
    getFormForId,
    uppercase,
    lowercase,
    replace,
    replaceAll,
    slice,
    split,
    indexOf,
    getRuntimeLocalFromStore,
    toFixed,
    newURLInner,
    newURL,
    newRegExp,
} from './functions';
import {
    runtimeValue,
    runtimeVenue,
    booleanToRuntimeOrNull,
    runtimeNull,
    runtimeLocation,
    runtimeAddress,
    runtimeDma,
    runtimeTrue,
    runtimeFalse,
    BOOLEAN,
    GLOBAL,
    OBJECT,
    STRING,
    NUMBER,
    RuntimeString,
    RuntimeObject,
    RuntimeNull,
    GenericRuntimeValue,
    runtimeBoolean,
    jsonToRuntime,
} from './runtime';
import * as Result from './result';
import { State, Selector } from '../store/processes';
import {
    getPagePayload,
    isV2,
    V3SDKData,
    V3SDKLocationData,
} from '../store/processes/pagePayload';
import * as RuntimePromise from './promise';

export type {
    MacroEvalContext,
    MacroScopeContext,
    MacroEvalOptions,
    MacroCallsiteMetadata,
} from './context';

export type MacroScope = GenericRuntimeValue;

const V2_TAG_TYPES: { [key: string]: string } = {
    b: 'iBeacon',
    e: 'Eddystone',
    g: 'Geofence',
    n: 'NFC',
    p: 'Preview',
    q: 'QR',
    s: 'SMS',
    u: 'URL',
    w: 'WiFi',
    unknown: 'Unknown',
};

const V3_TAG_TYPES: { [key: string]: string } = {
    gs1: 'GS1',
    nfc: 'NFC',
    qr: 'QR',
    unknown: 'Unknown',
};

const V3_TO_V2_MAP: { [key: string]: string } = {
    nfc: 'n',
    qr: 'q',
};

const wrapString = (v: unknown): RuntimeString | RuntimeNull => (
    typeof v === 'string'
        ? runtimeValue(STRING, v)
        : runtimeNull
);

const wrapStringOrNull = (v: unknown): RuntimeString | RuntimeNull => (
    typeof v === 'string' && v.replace(/\s/g, '')
        ? runtimeValue(STRING, v)
        : runtimeNull
);

type RuntimeMapping = { [key: string]: GenericRuntimeValue };

export const pagePayloadScope = (state: State): RuntimeMapping => {
    const pagePayloadPromise = RuntimePromise.fromLoadableState(
        getPagePayload(state),
    );
    if (!RuntimePromise.isSuccess(pagePayloadPromise)) { return {}; }
    const pagePayload = RuntimePromise.unwrap(pagePayloadPromise);

    const cookieConsentBlock = (
        getCookieConsentEnabled(state)
        && !getCookieConsentAccepted(state)
    );

    const legacy = (
        (isV2(pagePayload) && pagePayload.legacy)
            || {
                rollingToken: undefined,
                macros: {} as { [key: string]: undefined },
            }
    );

    const {
        device = ({} as { [key: string]: undefined }),
        location = ({} as V3SDKLocationData),
        interaction = {
            touchpoint: {
                slug: null,
                technology: null,
                identifiers: {},
                url: null,
                tamper: {
                    supported: false,
                    result: null,
                },
                verification: {
                    supported: false,
                    result: null,
                },
            },
        },
        object = {
            uuid: null,
            data: {},
        },
    } = pagePayload.sdkData;
    const project = (pagePayload.sdkData as V3SDKData).project ?? {};

    const customObjectData = runtimeValue(OBJECT, R.map(wrapString, object.data));
    const projectData = runtimeValue(OBJECT, R.map(wrapString, project.data ?? {}));

    // We check if the first part of the urls path contains a '-' or '_'
    // in these cases we are using new protocols that have not yet been added
    // these are currently listed as tech 'unknown' as well as any unlisted
    // single letter mtag prefix
    let v2Tech = 'unknown';
    let v3Tech = 'unknown';
    if (isV2(pagePayload)) {
        const t = window.location.pathname.split('/')[1];
        v2Tech = (
            (
                R.contains(t[0], R.keys(V2_TAG_TYPES))
                && !R.contains('-', t)
                && !R.contains('_', t)
            )
                ? t[0]
                : 'unknown'
        );
    } else {
        v3Tech = pagePayload.sdkData.interaction?.touchpoint?.technology ?? 'unknown';
    }

    const language = __IS_PREVIEW__
        ? device?.language ?? 'en-US' // defaults preview to US English
        : navigator.language;

    const languageData = BCP47.parse(language);
    const { tamper, verification } = interaction.touchpoint;

    // macro syntax object: replaces tag:, with backwards compatibility
    const objectScope = {
        data: customObjectData,
        variable: objectUserDataSelector(state),
        ...(
            !isV2(pagePayload)
                ? {
                    uuid: wrapStringOrNull(object.uuid),
                    uid: wrapString(pagePayload.sdkData.interaction?.touchpoint.identifiers.uid),
                }
                : {
                    ...R.map(wrapStringOrNull, {
                        mtag_id: legacy.macros['tag:mtag_id'],
                        status: legacy.macros['tag:status'],
                        // deprecated values which are now namespaced
                        asset_id: legacy.macros['tag:asset_id'],
                        asset_type_name: legacy.macros['tag:asset_type_name'],
                        venue_name: legacy.macros['tag:venue_name'],
                        venue_type_name: legacy.macros['tag:venue_type_name'],
                        tag_verifier: legacy.macros['tag:tag_verifier'],
                        uid: legacy.macros['tag:uid'],
                    }) as { [key: string]: GenericRuntimeValue },
                    uses_uid: booleanToRuntimeOrNull(
                        legacy.macros['tag:uses_uid'],
                    ),
                    mtag_id36: (
                        legacy.macros['tag:mtag_id']
                            ? wrapString(
                                parseInt(
                                    legacy.macros['tag:mtag_id'],
                                    10,
                                ).toString(36),
                            )
                            : runtimeNull
                    ),
                    venue: runtimeVenue({
                        name: legacy.macros['tag:venue_name'],
                        type: legacy.macros['tag:venue_type_name'],
                    }),
                    asset: runtimeVenue({
                        id: legacy.macros['tag:asset_id'],
                        type: legacy.macros['tag:asset_type_name'],
                    }),
                    location: runtimeLocation({
                        latitude: location.latitude,
                        longitude: location.longitude,
                        source: 'tag',
                    }),
                    // tag is V2 metadata so address here uses legacy macros
                    address: runtimeAddress({
                        city: legacy.macros['tag:address_city'],
                        state: {
                            code: legacy.macros['tag:address_state'],
                        },
                        postal_code: legacy.macros['tag:address_zip'],
                        street: legacy.macros['tag:address_street'],
                        country: {
                            name: legacy.macros['tag:address_country_name'],
                            code: legacy.macros['tag:address_country'],
                        },
                    }),
                    dma: runtimeDma({
                        code: legacy.macros['tag:address_dma_code'],
                        name: legacy.macros['tag:address_dma_name'],
                    }),
                }
        ),
    };

    return {
        interaction: runtimeValue(OBJECT, {
            _type: wrapString('Interaction'),
            rk: wrapStringOrNull(
                isV2(pagePayload)
                    ? legacy?.rollingToken
                    : pagePayload.eventToken,
            ),
            token: wrapStringOrNull(
                isV2(pagePayload)
                    ? null
                    : pagePayload.eventToken,
            ),
            tech: isV2(pagePayload)
                ? runtimeValue(OBJECT, {
                    value: wrapString(v2Tech),
                    name: wrapStringOrNull(V2_TAG_TYPES[v2Tech]),
                })
                : runtimeValue(OBJECT, {
                    value: wrapString(V3_TO_V2_MAP[v3Tech] ?? 'unknown'),
                    name: wrapStringOrNull(V2_TAG_TYPES[V3_TO_V2_MAP[v3Tech] ?? 'unknown']),
                }),
            touchpoint: isV2(pagePayload)
                ? runtimeNull
                : ((): RuntimeObject | RuntimeNull => {
                    const touchpoint = pagePayload.sdkData.interaction?.touchpoint;
                    if (touchpoint == null) {
                        return runtimeNull;
                    }

                    const returnObj = jsonToRuntime(touchpoint) as RuntimeObject;
                    returnObj.value.url = touchpoint.url
                        ? newURLInner(touchpoint.url.href)
                        : runtimeNull;
                    returnObj.value.technology = runtimeValue(OBJECT, {
                        value: wrapString(v3Tech),
                        name: wrapString(V3_TAG_TYPES[v3Tech]),
                    });
                    return returnObj;
                })(),
            tampered: booleanToRuntimeOrNull(
                tamper.supported
                    ? tamper.result
                    : null,
            ),
            verified: booleanToRuntimeOrNull(
                verification.supported
                    ? verification.result
                    : null,
            ),
            verification: runtimeValue(OBJECT, {
                supported: booleanToRuntimeOrNull(
                    verification.supported,
                ),
                result: booleanToRuntimeOrNull(
                    verification.result,
                ),
            }),
            tamper: runtimeValue(OBJECT, {
                supported: booleanToRuntimeOrNull(tamper.supported),
                result: booleanToRuntimeOrNull(tamper.result),
            }),
        }),
        device: runtimeValue(OBJECT, {
            _type: wrapString('Device'),
            id: cookieConsentBlock ? runtimeNull : wrapStringOrNull(device.id),
            ip: wrapStringOrNull(device.ip_address),
            os: runtimeValue(OBJECT, {
                _type: wrapString('OS'),
                value: wrapStringOrNull(device.os_name),
                name: wrapStringOrNull(device.os_name),
            }),
            user_agent: wrapStringOrNull(device.user_agent),
            device_type: wrapStringOrNull(device.type),
            browser: wrapStringOrNull(device.browser),
            manufacturer: wrapStringOrNull(device.manufacturer),
            carrier: wrapStringOrNull(device.carrier),
            language: runtimeValue(OBJECT, {
                _type: wrapString('Language'),
                value: wrapString(languageData.language),
                code: wrapString(language),
                region: wrapString(languageData.region),
                script: wrapString(languageData.script),
            }),
        }),
        object: runtimeValue(OBJECT, {
            _type: wrapString('Object'),
            ...objectScope,
        }),
        tag: runtimeValue(OBJECT, {
            _type: wrapString('Tag'),
            ...objectScope,
        }),
        project: runtimeValue(OBJECT, {
            _type: wrapString('Project'),
            id: wrapStringOrNull(project.id),
            name: wrapStringOrNull(project.name),
            data: projectData,
        }),
    };
};

const constantsScope = {
    infinity: runtimeValue(NUMBER, Infinity),
    null: runtimeNull,
    true: runtimeTrue,
    false: runtimeFalse,
};

const cookieConsentScope = createSelector(
    getCookieConsentAccepted,
    getCookieConsentEnabled,
    (accepted: true | false | null, enabled: true | false) => ({
        CookieConsent: runtimeValue(OBJECT, {
            enabled: runtimeBoolean(enabled),
            acknowledged: runtimeBoolean(typeof accepted === 'boolean'),
            gave_consent: runtimeBoolean(accepted === true && enabled),
        }),
    }),
);

export const variableScope = createSelector(
    getVariablesForType(DEVICE_VAR),
    (deviceVars: { [key: string]: string } | null | undefined) => ({
        local: runtimeValue(
            OBJECT,
            R.map(wrapString, deviceVars),
        ),
    }),
);

const pageScope = createSelector(
    getPage,
    (page) => ({
        page: runtimeValue(OBJECT, {
            url: runtimeValue(OBJECT, {
                value: runtimeValue(STRING, page.location.href),
                protocol: runtimeValue(STRING, page.location.protocol),
                hostname: runtimeValue(STRING, page.location.hostname),
                pathname: runtimeValue(STRING, page.location.pathname),
                search: runtimeValue(STRING, page.location.search),
                hash: runtimeValue(STRING, page.location.hash),
                origin: runtimeValue(STRING, page.location.origin),
                param: runtimeValue(OBJECT, R.map(
                    wrapString,
                    queryString.parse(page.location.search),
                )),
            }),
            load_unixtimestamp: runtimeValue(NUMBER, page.loadUnixtimestamp),
        }),
    }),
);

const objectUserDataSelector = createSelector(
    getVariablesForType(OBJECT_VAR),
    (objectVariables: { [key: string]: string } | null | undefined) => (
        runtimeValue(OBJECT, R.map(wrapString, objectVariables ?? {}))
    ),
);

// Not currying because of circular dependency
// remove when form state is typed
const getSubmittedForm = (state: State) => (
    R.sortBy(R.prop('id'), getAllSubmittedForms(state))[0]
);

const hasParentFormSubmitEvent = (componentId: string) => (state: State): boolean => {
    let event: null | {
        id: string;
        options?: {
            events?: ReadonlyArray<Event>;
        };
    } = getParentEvent(componentId, state);
    while (event) {
        if (!event) { return false; }
        if (R.any(
            (e) => (
                e.type === EVENT_TYPE_FORM_SUBMIT
                && e.when === EVENT_WHEN_AFTER
            ),
            event?.options?.events ?? [],
        )) {
            return true;
        }
        event = getParentEvent(event.id, state);
    }
    return false;
};

const formScope = (
    componentId: string | null,
    formId: string | null,
) => (state: State): RuntimeMapping => ({
    form: ((): RuntimeObject | RuntimeNull => {
        if (formId) {
            return getFormForId(formId, state);
        }

        if (componentId && hasParentFormSubmitEvent(componentId)(state)) {
            const form = getSubmittedForm(state);
            if (form) {
                return getFormForId(form.id, state);
            }
        }

        return runtimeNull;
    })(),
});

export const formNamespace = (state: State): RuntimeMapping => ({
    Form: runtimeValue(OBJECT, {
        get: loadFormData(state),
        any_form_submitted: runtimeValue(BOOLEAN, getAnyFormDone(state)),
        any_form_has_errors: runtimeValue(BOOLEAN, getAnyFormFailed(state)),
    }),
});

const functionScope = (state: State): RuntimeMapping => ({
    Array: runtimeValue(OBJECT, {
        find: arrayFind,
    }),
    Address: runtimeValue(OBJECT, {
        from_location: addressFromLocation(state),
    }),
    DMA: runtimeValue(OBJECT, {
        from_location: dmaFromLocation(state),
    }),
    String: runtimeValue(OBJECT, {
        to_lower_case: lowercase,
        to_upper_case: uppercase,
        replace,
        replace_all: replaceAll,
        slice,
        split,
        index_of: indexOf,
    }),
    Date: runtimeValue(OBJECT, getRuntimeLocalFromStore(state)),
    Number: runtimeValue(OBJECT, {
        to_fixed: toFixed,
    }),
    URL: runtimeValue(OBJECT, {
        new: newURL,
    }),
    RegExp: runtimeValue(OBJECT, {
        new: newRegExp,
    }),
});

export const locationScope = (dispatch?: Dispatch) => (state: State): RuntimeMapping => ({
    Location: runtimeValue(OBJECT, {
        current_location: RuntimePromise.unwrapOr(
            runtimeNull,
            RuntimePromise.immediateOr(
                getIpLocation(state),
                RuntimePromise.map(
                    runtimeLocation,
                    RuntimePromise.fromLoadableState(
                        currentGpsLocation(state),
                    ),
                ),
            ),
        ),
        get: getLocation(state, dispatch),
        watch: watchLocation(state, dispatch),
        new: newLocation,
        distance: locationDistance,
    }),
});

export const makeMacroScope = ({
    dispatch,
    callsiteMetadata: {
        componentId,
        formId,
    } = { componentId: null, formId: null },
}: MacroScopeContext = {}): Selector<GenericRuntimeValue> => createSelector(
    [
        formScope(componentId, formId),
        formNamespace,
        variableScope,
        locationScope(dispatch),
        functionScope,
        pagePayloadScope,
        cookieConsentScope,
        pageScope,
        (): RuntimeMapping => constantsScope,
    ],
    (...mapings: Array<RuntimeMapping>) => (
        runtimeValue(GLOBAL, Object.assign({}, ...mapings))
    ),
);

const runParse = memoize(parseMacroLine);

const defaultScope = runtimeValue(GLOBAL, {});

export const runMacroString = (
    str: string,
    scope?: MacroScope | undefined,
    context?: MacroEvalContext,
): RuntimePromise.Type<string, string> => {
    if (!R.is(String, str) || !str.includes('{{')) {
        return RuntimePromise.Success(str);
    }
    const lines = str.split('\n');
    const values = RuntimePromise.all(R.map(
        (line: string) => {
            const astResult = runParse(line);
            if (!Result.isOk(astResult)) {
                return RuntimePromise.Err(`Syntax error in '${line}'`);
            }
            const ast = Result.unwrap(astResult);

            return RuntimePromise.map(
                (v: RuntimeString) => v.value,
                RuntimePromise.chain(
                    (r) => callFunctionWithGenericArgs(coerceToString, [r]),
                    evalAst(ast, scope ?? defaultScope, {
                        evalOptions: context?.evalOptions ?? defaultMacroOptions,
                    }),
                ),
            );
        },
        lines,
    ));
    return RuntimePromise.map(R.join('\n'), values);
};
