/* eslint-disable max-len */
import React from 'react';

const Snow = (): React.ReactElement => (
    <svg aria-label="snow icon" width="128" height="128" viewBox="0 0 128 128">
        <defs>
            <clipPath id="clip-path-snow">
                <rect id="Rectangle_11" data-name="Rectangle 11" width="70" height="65.451" fill="none" />
            </clipPath>
            <clipPath id="clip-Snow">
                <rect width="128" height="128" />
            </clipPath>
        </defs>
        <g id="Snow" clipPath="url(#clip-Snow)">
            <rect width="128" height="128" fill="#fff" />
            <g id="Group_28" data-name="Group 28" transform="translate(29 31.274)">
                <g id="Group_21" data-name="Group 21" transform="translate(0 0)" clipPath="url(#clip-path-snow)">
                    <path id="Path_58" data-name="Path 58" d="M55.343,16.288a18.162,18.162,0,0,0-34.487-5.882,18.164,18.164,0,1,0-2.637,36.127l36.729-.09a15.081,15.081,0,0,0,.395-30.155" transform="translate(0 0)" fill="#e6e7ea" />
                    <path id="Path_59" data-name="Path 59" d="M35.086,73.668l-.008-3.087L32.4,69.044l-2.67,1.551.008,3.086,2.677,1.538Z" transform="translate(-4.206 -9.767)" fill="#b3ccfa" />
                    <path id="Path_60" data-name="Path 60" d="M51.107,73.629,51.1,70.541,48.422,69l-2.67,1.551.008,3.086,2.677,1.538Z" transform="translate(-6.472 -9.761)" fill="#b3ccfa" />
                    <path id="Path_61" data-name="Path 61" d="M27.049,62.925l-.008-3.087L24.365,58.3l-2.67,1.551.008,3.086,2.677,1.538Z" transform="translate(-3.069 -8.247)" fill="#b3ccfa" />
                    <path id="Path_62" data-name="Path 62" d="M43.07,62.886,43.062,59.8l-2.677-1.537-2.67,1.551.008,3.086L40.4,64.436Z" transform="translate(-5.335 -8.242)" fill="#b3ccfa" />
                    <path id="Path_63" data-name="Path 63" d="M59.091,62.846l-.008-3.087-2.677-1.537-2.67,1.551.008,3.086L56.421,64.4Z" transform="translate(-7.602 -8.236)" fill="#b3ccfa" />
                </g>
            </g>
        </g>
    </svg>
);

export default Snow;
