import {
    createStore as rawCreateStore,
    compose,
    applyMiddleware,
    Store,
} from 'redux';
import createSagaMiddleware, { Saga, Task } from 'redux-saga';
import {
    reducer,
    saga,
    State,
    createContext,
} from './processes';
import { BlueBiteSDK } from './processes/rendererSDK';
import { ExperienceManager } from './processes/xpManager';

export type { State, BlueBiteSDK };

const devtoolCompose = (
  !__PRODUCTION__
  && typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) || compose;

export type EnhancedStore = Store & {
    BlueBiteSDK: () => Promise<BlueBiteSDK>,
    ExperienceManager: () => Promise<ExperienceManager>,
    run: <S extends Saga>(
        saga: S,
        ...args: Parameters<S>
    ) => Task;
};

const createStore = (): EnhancedStore => {
    const context = createContext();
    const sagaMiddleware = createSagaMiddleware({ context });

    const store = rawCreateStore(
        reducer,
        {},
        devtoolCompose(applyMiddleware(sagaMiddleware)),
    );

    sagaMiddleware.run(saga);

    const BlueBiteSDK = async () => {
        const manger = await context.rendererSDKManagerInitializer.waitForInitialization();
        return manger.connect();
    };

    const ExperienceManager = async () => {
        const manger = await context.previewManagerInitializer.waitForInitialization();
        return manger.connect();
    };

    return {
        ...store,
        run: sagaMiddleware.run,
        BlueBiteSDK,
        ExperienceManager,
    };
};

export { reducer };

export default createStore;
