import * as React from 'react';
import * as BCP47 from 'bcp-47';
import { useSelector } from 'react-redux';
import { TranslationFile } from './type';
import { getPagePayload } from '../store/processes/pagePayload';
import * as LoadableState from '../store/processes/loadableState';

const makeGetLanguage = (name: string) => async (): Promise<TranslationFile> => (
    (await import(`./trans_${name}`)).default
);

type LanguageMapping = {
    script?: string;
    region?: string;
    getFile: () => Promise<TranslationFile>;
}

const availableLanguages: Record<string, Array<LanguageMapping>> = {
    de: [{
        getFile: makeGetLanguage('de'),
    }],
    en: [{
        getFile: makeGetLanguage('en'),
    }],
    es: [{
        getFile: makeGetLanguage('es'),
    }],
    fr: [{
        getFile: makeGetLanguage('fr'),
    }],
    it: [{
        getFile: makeGetLanguage('it'),
    }],
    ja: [{
        getFile: makeGetLanguage('ja'),
    }],
    ko: [{
        getFile: makeGetLanguage('ko'),
    }],
    zh: [
        {
            script: 'hant',
            getFile: makeGetLanguage('zh_hant'),
        },
        {
            getFile: makeGetLanguage('zh'),
        },
    ],
};

const getTranslations = (previewLanguage: string | null): Promise<TranslationFile> => {
    const languages = (previewLanguage ? [previewLanguage]
        : [...(navigator.languages ?? []), navigator.language])
        .map((l) => BCP47.parse(l));

    for (const l of languages) {
        const { language, script, region } = l;
        if (language) {
            const languageVariants = availableLanguages[language] ?? [];
            const chosenVariant = languageVariants.find((v) => (
                (!v.script || v.script.toLowerCase() === script?.toLowerCase())
                && (!v.region || v.region.toLowerCase() === region?.toLowerCase())
            ));
            if (chosenVariant) { return chosenVariant.getFile(); }
        }
    }

    return makeGetLanguage('en')();
};

const TranslationContext = React.createContext({} as Record<string, string>);

export const TranslationProvider = ({
    children,
}: React.PropsWithChildren<object>): React.ReactElement => {
    const [translations, setTranslations] = React.useState({});
    const loadablePagePayload = useSelector(getPagePayload);
    const previewLanguage = __IS_PREVIEW__ ? null
        : LoadableState.unwrapOr(null, loadablePagePayload)?.sdkData?.device?.language ?? null;

    React.useEffect(() => {
        (async (): Promise<void> => {
            setTranslations(await getTranslations(previewLanguage));
        })();
    }, [previewLanguage]);
    return (
        <TranslationContext.Provider value={translations}>
            {children}
        </TranslationContext.Provider>
    );
};

export const useTranslations = (): ((key: keyof TranslationFile) => string | null) => {
    const translations = React.useContext(TranslationContext);
    return React.useCallback(
        (key: string): string | null => translations?.[key] ?? null,
        [translations],
    );
};
