import { getCacheBreakInfo } from './previewMode';

type RootFontDefinition = {
    keyword: string;
    fallback: string;
}

const fetchRootFont = async (fontId: number): Promise<RootFontDefinition | null> => {
    if (!fontId) { return null; }

    const { cache, cacheBreakingQueryString } = getCacheBreakInfo();

    const response = await fetch(
        [
            __EXPERIENCE_STUDIO_API_BASE_URL__,
            '/font-family/',
            fontId,
            cacheBreakingQueryString,
        ].join(''),
        {
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            cache,
        },
    );

    if (!response.ok) {
        // if font not found, render xp with Roboto
        return null;
     }

    return response.json();
};
/* eslint-enable global-require */

export default fetchRootFont;
