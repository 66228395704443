import { Selector } from '../index';

type LocationInfo = {
    href: string;
    protocol: string;
    hostname: string;
    pathname: string;
    search: string;
    hash: string;
    origin: string;
}

export type LocalState = {
    location: LocationInfo;
    loadUnixtimestamp: number;
}

const makeLocation = (): LocationInfo => ({ ...window.location });

const createInitialState: () => LocalState = () => ({
    location: makeLocation(),
    loadUnixtimestamp: window.macroPageLoadEpoch ?? Date.now(),
});

const UPDATE_LOCATION = 'PAGE/UPDATE_LOCATION' as const;
const UPDATE_LOAD_TIME = 'PAGE/UPDATE_LOAD_TIME' as const;

type UpdateLocationAction = {
    type: typeof UPDATE_LOCATION;
};
type UpdateLoadTimeAction = {
    type: typeof UPDATE_LOAD_TIME;
    timestamp: number;
};

type Actions = UpdateLocationAction | UpdateLoadTimeAction | { type: 'OTHER' };

export const updateLocation = (): UpdateLocationAction => ({ type: UPDATE_LOCATION });
export const updateLoadTime = (timestamp: number): UpdateLoadTimeAction => ({
    type: UPDATE_LOAD_TIME,
    timestamp,
});

export const reducer = (
    stateRaw: LocalState | undefined,
    action: Actions,
): LocalState => {
    const state = stateRaw || createInitialState();
    switch (action.type) {
        case UPDATE_LOCATION:
            return { ...state, location: makeLocation() };
        case UPDATE_LOAD_TIME:
            return {
                ...state,
                loadUnixtimestamp: action.timestamp,
            };
        default:
            return state;
    }
};

export const getPage: Selector<LocalState> = (state) => state.page;
