import * as R from 'ramda';

// This type an immutable version of the JS Map class
// implemented as a simple Object in JS. This is mostly used for
// good typing and currying.

export type SimpleDict<V> = Record<string, V>;

export const get: {
    <V>(key: string, map: SimpleDict<V>): V | undefined;
    (key: string): <V>(map: SimpleDict<V>) => V | undefined;
} = R.curry(<V>(key: string, map: SimpleDict<V>): V | undefined => map[key]) as any;

export const set: {
    <V>(key: string, val: V, dict: SimpleDict<V>): SimpleDict<V>;
    <V>(key: string, val: V): (dict: SimpleDict<V>) => SimpleDict<V>;
    (key: string): <V>(val: V, dict: SimpleDict<V>) => SimpleDict<V>;
    (key: string): <V>(val: V) => (dict: SimpleDict<V>) => SimpleDict<V>;
} = R.curry(<V>(key: string, val: V, dict: SimpleDict<V>): SimpleDict<V> => ({
    ...dict,
    [key]: val,
})) as any;

export const remove: {
    <V>(key: string, dict: SimpleDict<V>): SimpleDict<V>;
    (key: string): <V>(dict: SimpleDict<V>) => SimpleDict<V>;
} = R.curry(<V>(key: string, dict: SimpleDict<V>): SimpleDict<V> => (
    R.omit([key], dict)
)) as any;

// Run a function on the value stored at the given key of an object
// and puts the returned value into a new object with all other
// properties copied over. If the key does not exist the initial
// object is returned as is.
export const over: {
    <V>(
        key: string,
        func: (v: V) => V,
        dict: SimpleDict<V>,
    ): SimpleDict<V>;
    <V>(
        key: string,
        func: (v: V) => V,
    ): (dict: SimpleDict<V>) => SimpleDict<V>;
    (
        key: string
    ): <V>(func: (v: V) => V, dict: SimpleDict<V>) => SimpleDict<V>;
    (
        key: string
    ): <V>(func: (v: V) => V) => (dict: SimpleDict<V>) => SimpleDict<V>;
} = R.curry(<V>(
    key: string,
    func: (v: V | undefined) => V,
    dict: SimpleDict<V>,
): SimpleDict<V> => (
    Object.prototype.hasOwnProperty.call(dict, key)
        ? {
            ...dict,
            [key]: func(dict[key]),
        }
        : dict
)) as any;
