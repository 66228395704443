import * as React from 'react';
import styled from 'styled-components';
import Snippet from './snippet';
import { SNIPPET_COMPONENT_TYPE_ID } from '../store/processes/studioFile';
import { ComponentProps } from './shared/types';

/* eslint-disable @typescript-eslint/naming-convention */

const TextWrapper = styled.div`
    img {
        max-width: 100%;
        height: auto;
    }
`;

type TextProps = ComponentProps<{
    converted_markdown?: string;
}>;

const SNIPPET_LISTS = {};

const Text = ({
    id,
    options: { converted_markdown },
    macroContext,
}: TextProps): React.ReactElement => {
    const options = React.useMemo(
        () => ({
            code: converted_markdown,
            rawHtml: false,
        }),
        [converted_markdown],
    );
    return (
        <TextWrapper>
            <Snippet
                id={id}
                typeId={SNIPPET_COMPONENT_TYPE_ID}
                options={options}
                macroContext={macroContext}
                componentLists={SNIPPET_LISTS}
            />
        </TextWrapper>
    );
};

export default Text;
