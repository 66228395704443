export const previewToken = 'pvwsrc';
const STUDIO_TRAFFIC = 'es' as const;
const MANAGER_TRAFFIC = 'om' as const;
export const previewSources = [STUDIO_TRAFFIC, MANAGER_TRAFFIC] as const;
type PreviewSource = typeof previewSources[number];


const trafficTypes = {
    [STUDIO_TRAFFIC]: 'studio_preview',
    [MANAGER_TRAFFIC]: 'dashboard_preview',
} as const;

export type AnalyticsTrafficTypeParameter = {
    traffic_type?: typeof trafficTypes.es | typeof trafficTypes.om | '';
};

const getPvwSrc = (): string | null => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(previewToken);
};

const getPreviewSource = (): PreviewSource | null => {
    const pvwSrc = getPvwSrc();
    return previewSources.includes(pvwSrc as PreviewSource)
        ? pvwSrc as PreviewSource : null;
};

export const getCacheBreakInfo = () => {
    const previewSource = getPreviewSource();
    const shouldBreakCache = !!previewSource;
    const cacheBreakingQueryString = shouldBreakCache
        ? `?${previewToken}=${previewSource}` : '';
    const cache: RequestCache = shouldBreakCache ? 'no-store' : 'default';
    return {
        cache,
        cacheBreakingQueryString,
    };
};

export const buildAnalyticsTrafficTypeParameter = (trackingId: string)
    : AnalyticsTrafficTypeParameter => {
    if (!trackingId?.toUpperCase().startsWith('G-')) {
        return {}; // report traffic_type for GA4 only, not UA3
    }

    const previewSource = getPreviewSource();
    return {
        traffic_type: previewSource ? trafficTypes[previewSource] : '',
    };
};

// true only if preview source token signifies studio editor
export const isStudioPreviewSource = () => (
    getPreviewSource() === STUDIO_TRAFFIC
);

const navActionToken = 'navaction';

// true by default and when param is not set to false
export const getNavActionEnabled = (): boolean => {
    const searchParams = new URLSearchParams(window.location.search);
    const value = searchParams.get(navActionToken);
    return !value || (value !== 'false');
};
