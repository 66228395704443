import * as React from 'react';
import styled from 'styled-components';
import { formatDistanceStrict, fromUnixTime } from 'date-fns';
import { useMacroScope, useMacro } from './shared/macroHelpers';
import { ComponentProps } from './shared/types';
import * as RuntimePromise from '../macros/promise';
import Widget from './shared/widget';
import { getTime } from './shared/getTime';

const SIZE = 100;
const STROKE_WIDTH = 8;
const RADIUS = SIZE / 2 - STROKE_WIDTH / 2;
const CIRCUMFERENCE = Math.PI * 2 * RADIUS;

const CountdownWidget = styled.div<{ text: string }>`
    display: flex;
    justify-content:
        ${(props): string => (
        props.text
            ? 'flex-start'
            : 'center'
    )};
    padding: ${(props): string => (props.theme.showWidget ? '12px' : '0')};
`;

const CountdownDiv = styled.div`
    width: ${SIZE}px;
    height: ${SIZE}px;
    display: flex;
    align-self: center;
    position: relative;
`;

const CountdownGraph = styled.svg`
    width: ${SIZE}px;
    height: ${SIZE}px;
    transform: rotate(-90deg);
    display: block;
`;

const TotalCircle = styled.circle`
    stroke-width: ${STROKE_WIDTH};
    stroke-dasharray: ${(SIZE - STROKE_WIDTH) * Math.PI};
    stroke-dashoffset: 0;
    fill: transparent;
    transition: stroke-dashoffset 1s linear;
    stroke: rgba(0, 0, 0, 0.16);
`;

const RemainingCircle = styled.circle<{ color: string }>`
    stroke-width: ${STROKE_WIDTH * 0.5};
    fill: transparent;
    stroke-dasharray: ${CIRCUMFERENCE};
    transition: stroke-dashoffset 1s linear;
    stroke: ${(props): string => props.color};
`;

const CountdownLabel = styled.div<{ color: string }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: ${(props): string => props.color};
`;

const CountdownLabelTime = styled.div`
    font-size: 36px;
    font-weight: 600;
    line-height: 1;
`;

const CountdownLabelFormat = styled.div`
    font-size: 14px;
    line-height: 1;
`;

const Text = styled.div`
    align-self: center;
    margin-left: 12px;
`;

const componentName = 'Countdown';

type CountdownProps = ComponentProps<{
    startDate: {
        offset: number;
        epoch: number | null;
    };
    endDate: {
        offset: number;
        epoch: number | null;
    };
    color?: string;
    text?: string;
}>;

const Countdown = ({
    options,
    macroContext,
}: CountdownProps): React.ReactElement | null => {
    const [currentTime, setCurrentTime] = React.useState(getTime());

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(getTime());
        }, 1000);
        return (): void => clearInterval(interval);
    }, []);

    const startDateEpoch = options.startDate.epoch ?? currentTime;
    const endDateEpoch = options.endDate.epoch ?? currentTime;
    const color = options.color ?? '#008cff';

    const macroScope = useMacroScope(macroContext);
    const text = RuntimePromise.unwrapOr(
        '',
        useMacro(
            options.text ?? '',
            macroScope,
            {
                componentName,
                optionName: 'text',
            },
        ),
    );

    const remainingTime: number = endDateEpoch - currentTime;
    const totalTime: number = endDateEpoch - startDateEpoch;
    const remainingTimeRatio = Math.max(remainingTime / totalTime, 0);
    const barRatio: number = 1 - Math.min(remainingTimeRatio, 1);
    const strokeDashoffset = `${barRatio * CIRCUMFERENCE}px`;

    const formattedCurrentTime = fromUnixTime(currentTime);
    const formattedEndDate = fromUnixTime(endDateEpoch);
    const formattedTime: string = formatDistanceStrict(
        formattedEndDate,
        formattedCurrentTime,
    );
    const [time, format]: string[] = formattedTime.split(' ');

    return (
        remainingTime <= 0 ? null
            : (
                <Widget>
                    <CountdownWidget text={text}>
                        <CountdownDiv>
                            <CountdownGraph>
                                <TotalCircle
                                    r={SIZE / 2 - STROKE_WIDTH / 2}
                                    cx={SIZE / 2}
                                    cy={SIZE / 2}
                                />
                                <RemainingCircle
                                    r={SIZE / 2 - STROKE_WIDTH / 2}
                                    cx={SIZE / 2}
                                    cy={SIZE / 2}
                                    color={color}
                                    strokeDashoffset={strokeDashoffset}
                                />
                            </CountdownGraph>
                            <CountdownLabel color={color}>
                                <CountdownLabelTime>
                                    {time}
                                </CountdownLabelTime>
                                <CountdownLabelFormat>
                                    {format}
                                </CountdownLabelFormat>
                            </CountdownLabel>
                        </CountdownDiv>
                        <Text>{text}</Text>
                    </CountdownWidget>
                </Widget>
            )
    );
};

export default Countdown;
