import * as Sentry from '@sentry/browser';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { updatePagePayload } from './reducer';
import * as LoadableState from '../loadableState';
import { createPagePayload } from './createPagePayload';
import { loadObjectVariablesSaga } from '../variables';

// / pass in page pagload and call from init
export function* loadPagePayloadSaga(): SagaIterator<void> {
    try {
        yield put(updatePagePayload(LoadableState.Loading));
        const pagePayload = yield call(createPagePayload);
        yield call(loadObjectVariablesSaga, pagePayload);
        yield put(updatePagePayload(LoadableState.Ready(pagePayload)));
    } catch (e) {
        console.error(e);
        Sentry.captureException(e, {
            tags: {
                'error.source': 'renderer',
                'component.type': 'none',
            },
        });
        yield put(updatePagePayload(
            LoadableState.Err('Failed to load page payload'),
        ));
    }
}
