import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import {
    put,
    takeEvery,
    call,
    select,
    getContext,
} from 'redux-saga/effects';
import { makeMacroScope, runMacroString, MacroScope } from '../../../macros';
import { withDelayedDispatch } from '../../../utils/redux';
import analytics, { ActionAnalyticsOptions } from '../analytics';
import { cleanLink } from '../../../functions/clean';
import { navigate } from '../../../functions/navigate';
import { loadStudioFile } from '../studioFile';
import * as RuntimePromise from '../../../macros/promise';
import { ExperienceManager, ExperienceManagerInitializer } from '../xpManager';

import { MetadataOptions } from '../../../components/shared/meta';

const NONE = 'ACTION/NONE' as const;
const URL_LINK = 'LINKS/URL_LINK' as const;
export const EXPERIENCE_LINK = 'LINKS/EXPERIENCE_LINK' as const;

type UrlLinkParams = {
    link: string;
    meta: MetadataOptions;
    actionAnalytics: ActionAnalyticsOptions;
};
type UrlLinkAction = (
    Action<typeof URL_LINK>
    & UrlLinkParams
);
export const urlLink = ({
    link,
    meta,
    actionAnalytics,
}: UrlLinkParams): UrlLinkAction => ({
    type: URL_LINK,
    link,
    meta,
    actionAnalytics,
});

type ExperienceLinkParams = {
    studioFile: string;
    meta: MetadataOptions;
    actionAnalytics: ActionAnalyticsOptions;
};
type ExperienceLinkAction = (
    Action<typeof EXPERIENCE_LINK>
    & ExperienceLinkParams
);
export const experienceLink = ({
    studioFile,
    meta,
    actionAnalytics,
}: ExperienceLinkParams): ExperienceLinkAction => ({
    type: EXPERIENCE_LINK,
    studioFile,
    meta,
    actionAnalytics,
});

type NoneLinkParams = {
    meta: MetadataOptions;
    actionAnalytics: ActionAnalyticsOptions;
};
type NoneLinkAction = (
    Action<typeof NONE>
    & NoneLinkParams
);
// this is for actions set to none
export const none = ({
    meta,
    actionAnalytics,
}: NoneLinkParams): NoneLinkAction => ({
    type: NONE,
    meta,
    actionAnalytics,
});

function* urlLinkSaga({
    link: rawLink,
    meta,
    actionAnalytics,
}: UrlLinkAction): SagaIterator {
    yield call(
        withDelayedDispatch,
        function* run(dispatch) {
            const scope: MacroScope = yield select(makeMacroScope({
                callsiteMetadata: {
                    componentId: meta.firingComponentId,
                    formId: meta.formId,
                },
                dispatch,
            }));
            const link = RuntimePromise.unwrapOr('', runMacroString(rawLink, scope));
            yield call(
                analytics,
                'URL Link',
                actionAnalytics,
                {
                    eventCategory: meta.component,
                    eventLabel: link,
                },
                meta,
            );
            if (__IS_PREVIEW__ && meta.event?.target !== '_blank') {
                const managerInitializer: ExperienceManagerInitializer = (
                    yield getContext('previewManagerInitializer')
                );
                const manager: ExperienceManager = yield call(() => (
                    managerInitializer.waitForInitialization()
                ));
                yield call(() => manager.leavingPage(link));
                return;
            }
            yield call(navigate, cleanLink(link), meta);
        },
    );
}

function* experienceLinkSaga({
    studioFile,
    meta,
    actionAnalytics,
}: ExperienceLinkAction): SagaIterator {
    yield call(
        analytics,
        'Experience Link',
        actionAnalytics,
        {
            eventCategory: meta.component,
            eventLabel: studioFile,
        },
        meta,
    );
    yield put(loadStudioFile(studioFile));
    if (__IS_PREVIEW__) {
        const managerInitializer: ExperienceManagerInitializer = (
            yield getContext('previewManagerInitializer')
        );
        const manager: ExperienceManager = yield call(() => (
            managerInitializer.waitForInitialization()
        ));
        yield call(() => manager.changedExperiences(studioFile));
    }
}

function* noneSaga({
    meta,
    actionAnalytics,
}: NoneLinkAction): SagaIterator {
    yield call(
        analytics,
        'None',
        actionAnalytics,
        {
            eventCategory: meta.component,
            eventLabel: '',
        },
        meta,
    );
}

function* saga(): SagaIterator {
    yield takeEvery(URL_LINK, urlLinkSaga);
    yield takeEvery(EXPERIENCE_LINK, experienceLinkSaga);
    yield takeEvery(NONE, noneSaga);
}

export default saga;
