import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ActionComponentOptions,
} from '../store/processes/studioFile/componentConfigs/action';
import {
    ACTION_TYPE_FORM_SUBMIT,
} from '../store/processes/studioFile/componentConfigs/shared/action';
import { fireAction } from '../store/processes/actions';
import { getForm, FORM_STATUS_INITIAL } from '../store/processes/forms';
import { ComponentProps } from './shared/types';
import { State } from '../store/processes';

const ACTION_COMPONENT_NAME = 'Action';

type ActionProps = ComponentProps<ActionComponentOptions>;

const Action = ({
    id,
    typeId,
    options,
    macroContext,
}: ActionProps): null => {
    const dispatch = useDispatch();
    const form = useSelector((state: State) => getForm(state, macroContext.formId ?? '-1'));

    const [triggered, setTriggered] = React.useState(false);
    React.useEffect(
        () => {
            if (
                !triggered
                && (
                    options.action !== ACTION_TYPE_FORM_SUBMIT
                    || form?.status === FORM_STATUS_INITIAL
                )
            ) {
                dispatch(fireAction({
                    ...options,
                    meta: {
                        component: ACTION_COMPONENT_NAME,
                        firingComponentId: id,
                        firingComponentType: {
                            name: ACTION_COMPONENT_NAME,
                            id: typeId,
                        },
                        formId: macroContext.formId,
                    },
                }));
                setTriggered(true);
            }
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [triggered, form?.status],
    );

    return null;
};

export default Action;
