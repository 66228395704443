/* eslint-disable max-len */
import React from 'react';

const BreakingCloud = (): React.ReactElement => (
    <svg aria-label="breaking cloud icon" width="128" height="128" viewBox="0 0 128 128">
        <defs>
            <clipPath id="breaking-cloud-icon">
                <rect width="128" height="128" />
            </clipPath>
        </defs>
        <g id="Break" clipPath="url(#breaking-cloud-icon)">
            <rect width="128" height="128" fill="#fff" />
            <g id="Group_30" data-name="Group 30" transform="translate(24.999 38)">
                <g id="Group_31" data-name="Group 31" transform="translate(0 0)">
                    <path id="Path_56" data-name="Path 56" d="M76.541,16.4a18.283,18.283,0,0,0-34.717-5.921A18.285,18.285,0,1,0,39.17,46.844l36.975-.091a15.181,15.181,0,0,0,.4-30.356" transform="translate(-12.827 -3)" fill="#3d3d3d" />
                    <path id="Path_57" data-name="Path 57" d="M55.712,25.48a18.283,18.283,0,0,0-34.717-5.921,18.285,18.285,0,1,0-2.654,36.368l36.974-.091a15.181,15.181,0,0,0,.4-30.356" transform="translate(0 -1.233)" fill="#e6e7ea" />
                </g>
            </g>
        </g>
    </svg>
);

export default BreakingCloud;
