/* eslint-disable max-len */
import React from 'react';

const ShowersNight = (): React.ReactElement => (
    <svg aria-label="showers at night icon" width="128" height="128" viewBox="0 0 128 128">
        <defs>
            <clipPath id="clip-path-night-showers">
                <rect id="Rectangle_6" data-name="Rectangle 6" width="73.88" height="69.558" fill="none" />
            </clipPath>
            <clipPath id="clip-ShowersNight">
                <rect width="128" height="128" />
            </clipPath>
        </defs>
        <g id="ShowersNight" clipPath="url(#clip-ShowersNight)">
            <rect width="128" height="128" fill="#fff" />
            <g id="Group_27" data-name="Group 27" transform="translate(27 13.645)">
                <g id="Group_12" data-name="Group 12" transform="translate(0 12.577)">
                    <g id="Group_11" data-name="Group 11" transform="translate(0 0)" clipPath="url(#clip-path-night-showers)">
                        <path id="Path_37" data-name="Path 37" d="M65.575,2.645A13.442,13.442,0,0,1,65.835,0,14.536,14.536,0,1,0,82.091,15.7a13.408,13.408,0,0,1-3.019.375,13.464,13.464,0,0,1-13.5-13.431" transform="translate(-8.211 0)" fill="#d9d2ca" />
                        <path id="Path_38" data-name="Path 38" d="M54.49,22.65a17.882,17.882,0,0,0-33.955-5.791,17.884,17.884,0,1,0-2.6,35.57L54.1,52.34a14.848,14.848,0,0,0,.389-29.69" transform="translate(0 -1.023)" fill="#e6e7ea" />
                        <path id="Path_39" data-name="Path 39" d="M24.4,79.672a1.691,1.691,0,0,1-1.695-1.686l-.027-10.91a1.691,1.691,0,1,1,3.381-.008l.027,10.91A1.691,1.691,0,0,1,24.4,79.672" transform="translate(-3.508 -10.115)" fill="#1a65ef" />
                        <path id="Path_40" data-name="Path 40" d="M45.864,79.619a1.691,1.691,0,0,1-1.695-1.686l-.028-10.91a1.691,1.691,0,0,1,3.381-.008l.028,10.91a1.692,1.692,0,0,1-1.686,1.695" transform="translate(-6.829 -10.107)" fill="#1a65ef" />
                        <path id="Path_41" data-name="Path 41" d="M35.118,74.208a1.691,1.691,0,0,1-1.695-1.686l-.014-5.472a1.691,1.691,0,1,1,3.381-.008l.014,5.472a1.691,1.691,0,0,1-1.686,1.695" transform="translate(-5.169 -10.111)" fill="#1a65ef" />
                        <path id="Path_42" data-name="Path 42" d="M56.582,74.155a1.692,1.692,0,0,1-1.7-1.686L54.874,67a1.69,1.69,0,1,1,3.38-.008l.014,5.472a1.691,1.691,0,0,1-1.686,1.695" transform="translate(-8.489 -10.102)" fill="#1a65ef" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default ShowersNight;
