import * as React from 'react';
import { useSelector } from 'react-redux';
import { FormContext } from './form/formContext';
import {
    StudioComponentTypeId,
    StudioComponentConfig,
    getComponentById,
    CAMPAIGN_A_COMPONENT_TYPE_ID,
    CAMPAIGN_B_COMPONENT_TYPE_ID,
    CAMPAIGN_C_COMPONENT_TYPE_ID,
    CAMPAIGN_D_COMPONENT_TYPE_ID,
    WEATHER_COMPONENT_TYPE_ID,
    YOUTUBE_COMPONENT_TYPE_ID,
    SWIPER_COMPONENT_TYPE_ID,
    INTERSTITIAL_COMPONENT_TYPE_ID,
    SNIPPET_COMPONENT_TYPE_ID,
    EXCLUSIVE_COMPONENT_TYPE_ID,
    COUNTDOWN_COMPONENT_TYPE_ID,
    IMAGE_COMPONENT_TYPE_ID,
    VERIFIED_COMPONENT_TYPE_ID,
    INPUT_COMPONENT_TYPE_ID,
    CHECKBOX_COMPONENT_TYPE_ID,
    FORM_COMPONENT_TYPE_ID,
    TEXT_COMPONENT_TYPE_ID,
    TEXT_EDITOR_COMPONENT_TYPE_ID,
    SCHEDULE_COMPONENT_TYPE_ID,
    GALLERY_COMPONENT_TYPE_ID,
    EVENT_COMPONENT_TYPE_ID,
    ACTION_COMPONENT_TYPE_ID,
    SECTION_COMPONENT_TYPE_ID,
    HIDDEN_INPUT_COMPONENT_TYPE_ID,
    SELECT_COMPONENT_TYPE_ID,
    DELAY_COMPONENT_TYPE_ID,
    BUTTON_COMPONENT_TYPE_ID,
    INSTAGRAM_COMPONENT_TYPE_ID,
    TWITTER_COMPONENT_TYPE_ID,
    COOKIE_BANNER_COMPONENT_TYPE_ID,
    USER_CREATED_COMPONENT_TYPE_ID,
} from '../store/processes/studioFile';
import Campaign from './campaign';
import Weather from './weather';
import Youtube from './youtube';
import Swiper from './swiper';
import Interstitial from './interstitial';
import Snippet from './snippet';
import Exclusive from './exclusive';
import Countdown from './countdown';
import Image from './image';
import Verified from './verified';
import Input from './form/input';
import Checkbox from './form/checkbox';
import Form from './form';
import Text from './text';
import TextEditor from './textEditor';
import Schedule from './schedule';
import Gallery from './gallery';
import Event from './event';
import Action from './action';
import Section from './section';
import Delay from './delay';
import HiddenInput from './form/hiddenInput';
import SelectInput from './form/select';
import Button from './button';
import Instagram from './instagram';
import Twitter from './twitter';
import CookieBanner from './cookieBanner';
import UserCreatedComponent from './userCreatedComponent';
import { ComponentProps } from './shared/types';

const componentLookup: {
    [K in StudioComponentTypeId]: React.ComponentType<
        ComponentProps
        & StudioComponentConfig<K>
    >;
} = {
    [CAMPAIGN_A_COMPONENT_TYPE_ID]: Campaign,
    [CAMPAIGN_B_COMPONENT_TYPE_ID]: Campaign,
    [CAMPAIGN_C_COMPONENT_TYPE_ID]: Campaign,
    [CAMPAIGN_D_COMPONENT_TYPE_ID]: Campaign,
    [WEATHER_COMPONENT_TYPE_ID]: Weather,
    [YOUTUBE_COMPONENT_TYPE_ID]: Youtube,
    [SWIPER_COMPONENT_TYPE_ID]: Swiper,
    [INTERSTITIAL_COMPONENT_TYPE_ID]: Interstitial,
    [SNIPPET_COMPONENT_TYPE_ID]: Snippet,
    [EXCLUSIVE_COMPONENT_TYPE_ID]: Exclusive,
    [COUNTDOWN_COMPONENT_TYPE_ID]: Countdown,
    [IMAGE_COMPONENT_TYPE_ID]: Image,
    [VERIFIED_COMPONENT_TYPE_ID]: Verified,
    [INPUT_COMPONENT_TYPE_ID]: Input,
    [CHECKBOX_COMPONENT_TYPE_ID]: Checkbox,
    [TEXT_COMPONENT_TYPE_ID]: Text,
    [TEXT_EDITOR_COMPONENT_TYPE_ID]: TextEditor,
    [FORM_COMPONENT_TYPE_ID]: Form,
    [SCHEDULE_COMPONENT_TYPE_ID]: Schedule,
    [GALLERY_COMPONENT_TYPE_ID]: Gallery,
    [EVENT_COMPONENT_TYPE_ID]: Event,
    [ACTION_COMPONENT_TYPE_ID]: Action,
    [SECTION_COMPONENT_TYPE_ID]: Section,
    [HIDDEN_INPUT_COMPONENT_TYPE_ID]: HiddenInput,
    [SELECT_COMPONENT_TYPE_ID]: SelectInput,
    [DELAY_COMPONENT_TYPE_ID]: Delay,
    [BUTTON_COMPONENT_TYPE_ID]: Button,
    [INSTAGRAM_COMPONENT_TYPE_ID]: Instagram,
    [TWITTER_COMPONENT_TYPE_ID]: Twitter,
    [COOKIE_BANNER_COMPONENT_TYPE_ID]: CookieBanner,
    [USER_CREATED_COMPONENT_TYPE_ID]: UserCreatedComponent,
};

type Props = {
    id: string;
};

const FALLBACK_LIST = {};

export const ComponentInner = ({
    id,
}: Props) => {
    const componentConfig = useSelector(getComponentById(id));

    const formContext = React.useContext(FormContext);
    const formId = (
        // Forms have a formId of their own ID not a parent form
        componentConfig?.typeId === FORM_COMPONENT_TYPE_ID
            ? id
            : formContext?.formId
    ) ?? null;

    const macroContext = React.useMemo(() => ({
        componentId: id,
        formId,
    }), [formId, id]);

    if (!(componentConfig && componentConfig?.typeId)) { return null; }

    const C = componentLookup[
        componentConfig.typeId
    ] as React.ComponentType<ComponentProps>;

    if (!C) { return null; }

    return (
        <C
            id={id}
            typeId={componentConfig.typeId}
            options={componentConfig.options}
            componentLists={componentConfig.componentLists ?? FALLBACK_LIST}
            macroContext={macroContext}
        />
    );
};

// Used by the inspector to identify components
// without creating a circular dependency between
// the store and this component
ComponentInner.bbInspectorTag = true;

export default React.memo(ComponentInner);
