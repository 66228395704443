import * as React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import queryString from 'query-string';
import {
    getPagePayload,
    isV3,
    PagePayload,
} from '../store/processes/pagePayload';
import * as LoadableState from '../store/processes/loadableState';
import Widget from './shared/widget';
import Loader from './shared/loader';
import { WeatherOptions } from '../store/processes/studioFile/componentConfigs/weather';
import ScaleButton from './weather/ScaleButton';
import WeatherCurrent from './weather/WeatherCurrent';
import WeatherHours from './weather/WeatherHours';
import WeatherDays from './weather/WeatherDays';
import fetchWrapper from './weather/fetchWrapper';
import * as RuntimePromise from '../macros/promise';
import { ComponentProps } from './shared/types';

const WeatherWidget = styled.div`
    h1 {
        height: 28.5px;
        font-size: 24px;
        font-weight: 300;
        letter-spacing: 0.4px;
        color: #3b3f47;
        margin: 0;
    }
`;

const WidgetHeading = styled.div`
    padding: 16px;
    padding-bottom: 0;
`;

const Title = styled.div`
    padding-bottom: 4px;
`;

const Subtitle = styled.div`
    height: 16.5px;
    line-height: normal;
    letter-spacing: 0.5px;

    span {
        cursor: pointer;
    }
`;

const Content = styled.div`
    padding: 16px;
`;

const Footer = styled.button`
    padding-bottom: 16px;
    height: 16.5px;
    color: #0087cb;
    margin: 0 auto 16px auto;
    border: none;
    display: block;
    outline: inherit;
    background-color: transparent;
`;

type WeatherStateType = {
    current: {
        temp: number;
        weather: Array<{
            description: string;
            icon: string;
        }>;
    };
    hourly: Array<{
        dt: number;
        temp: number;
        weather: Array<{
            icon: string;
        }>;
    }>;
    daily: Array<{
        dt: number;
        temp: {
            max: number;
            min: number;
        },
        weather: Array<{
            description: string;
            icon: string;
        }>;
    }>;
}

const WeatherContent = ({
    weatherState,
    scale,
    expanded,
}: {
    weatherState: WeatherStateType;
    scale: string;
    expanded: boolean;
}): React.ReactElement => (
    <Content>
        <WeatherCurrent
            current={weatherState.current}
            scale={scale}
        />
        <WeatherHours hourly={weatherState.hourly} />
        {expanded && <WeatherDays daily={weatherState.daily} />}
    </Content>
);

const setLocationData = (pagePayload: PagePayload): {
    latitude: string;
    longitude: string;
} | null => {
    if (isV3(pagePayload)) {
        const {
            latitude = null,
            longitude = null,
        } = pagePayload.sdkData?.location ?? {};
        if (!latitude || !longitude) {
            return null;
        }
        return {
            latitude: String(latitude),
            longitude: String(longitude),
        };
    }
    return pagePayload.legacy.weather;
};

export type DataConfig = {
    current: {
        temp: number;
        weather: Array<{
            description: string;
            icon: string;
        }>;
    };
    hourly: Array<{
        dt: number;
        temp: number;
        weather: Array<{
            icon: string;
        }>;
    }>;
    daily: Array<{
        dt: number;
        temp: {
            max: number;
            min: number;
        };
        weather: Array<{
            description: string;
            icon: string;
        }>;
    }>;
}

type WeatherProps = ComponentProps<WeatherOptions>;

const Weather = ({
    options,
}: WeatherProps): React.ReactElement | null => {
    const loadablePagePayload = useSelector(getPagePayload);

    const [expanded, toggleExpanded] = React.useState(false);
    const [scale, setScale] = React.useState(options.format);
    const [weatherState, setWeatherState] = React.useState<RuntimePromise.Type<
        WeatherStateType,
        string
    >>(RuntimePromise.Unresolved);

    React.useEffect(() => {
        (async (): Promise<void> => {
            if (!LoadableState.isReady(loadablePagePayload)) {
                return;
            }

            const pagePayload = LoadableState.unwrap(loadablePagePayload);

            const locationData = setLocationData(pagePayload);
            if (!locationData) {
                setWeatherState(RuntimePromise.Err('error getting location data'));
                return;
            }

            const options = {
                units: (scale === 'fahrenheit') ? 'imperial' : 'metric',
                exclude: 'minutely',
                lat: locationData?.latitude,
                lon: locationData?.longitude,
                appid: __OPENWEATHER_API_TOKEN__,
            };

            const qString = queryString.stringify(options);
            const weatherUrl = `${__OPENWEATHER_API_BASE_URL__}?${qString}`;

            try {
                const weather: DataConfig = await fetchWrapper(weatherUrl);

                setWeatherState(RuntimePromise.Success({
                    current: weather.current,
                    hourly: weather.hourly.slice(0, 5),
                    daily: weather.daily.slice(1, 8),
                }));
            } catch (e) {
                console.error(e);
                Sentry.captureException(e, {
                    tags: {
                        'error.source': 'renderer',
                        'component.type': 'weather',
                    },
                });
                setWeatherState(RuntimePromise.Err('error getting weather data'));
            }
        })();
    }, [loadablePagePayload, scale]);

    return (
        RuntimePromise.isErr(weatherState)
            ? null
            : (
                <WeatherWidget>
                    <Widget>
                        <WidgetHeading>
                            <Title>
                                <h1>Local Weather</h1>
                            </Title>
                            <Subtitle>
                                <span>
                                    <ScaleButton
                                        className="setScaleFahrenheit"
                                        active={scale === 'fahrenheit'}
                                        onClick={(): void => setScale('fahrenheit')}
                                    >
                                        Fahrenheit
                                    </ScaleButton>
                                    <span style={{ margin: '0 5px' }}>|</span>
                                    <ScaleButton
                                        className="setScaleCelsius"
                                        active={scale === 'celsius'}
                                        onClick={(): void => setScale('celsius')}
                                    >
                                        Celsius
                                    </ScaleButton>
                                </span>
                            </Subtitle>
                        </WidgetHeading>
                        <Loader height="150px" loading={RuntimePromise.isUnresolved(weatherState)}>
                            <div>
                                {/* weatherState can't be unresolved at this point due to Loader */}
                                <WeatherContent
                                    weatherState={RuntimePromise.unwrap(weatherState as
                                        RuntimePromise.Success<WeatherStateType>)}
                                    scale={scale}
                                    expanded={expanded}
                                />
                                <Footer
                                    className="footer"
                                    onClick={(): void => toggleExpanded(!expanded)}
                                >
                                    {
                                        expanded
                                            ? <i className="material-icons">expand_less</i>
                                            : <i className="material-icons">expand_more</i>
                                    }
                                </Footer>
                            </div>
                        </Loader>
                    </Widget>
                </WeatherWidget>
            )
    );
};

export default Weather;
