import * as React from 'react';
import styled from 'styled-components';
import { displayTemperature } from '../shared/utils';
import WeatherIcon from './WeatherIcon';
import { unwrapExists } from '../../functions/utils';


const Days = styled.div`
    padding-top: 32px;
`;

const DaysHeading = styled.div`
    padding-bottom: 4px;
    display: flex;
    justify-content: space-between;
`;

const HeadingTemperature = styled.div`
    width: 70px;
    display: flex;
    justify-content: space-between;
`;

const Day = styled.div`
    display: flex;
    justify-content: space-between;
`;

const FormattedDays = styled.div`
    width: 86.5px;
    font-size: 16px;
    letter-spacing: 0.4px;
    color: #3b3f47;
`;

const DayImage = styled.div`
    width: 32px;
    height: 32px;

    svg {
        width: 100%;
        height: 100%;
    }
`;

const DayTemperature = styled.div`
    width: 68.5px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    letter-spacing: 0.4px;
    color: #3b3f47;
`;

const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const formatDays = (time: number): string => {
    const date = new Date(time * 1000);
    const day = date.getDay();
    return DAYS[day];
};

type WeatherDaysProps = {
    daily: Array<{
        dt: number;
        temp: {
            max: number;
            min: number;
        },
        weather: Array<{
            description: string;
            icon: string;
        }>;
    }>;
}

const WeatherDays = ({
    daily,
}: WeatherDaysProps): React.ReactElement => (
    <Days>
        <DaysHeading>
            <div>
                7 Day Forecast
            </div>
            <HeadingTemperature>
                <div>High</div>
                <div>Low</div>
            </HeadingTemperature>
        </DaysHeading>
        {
            daily.map((day) => (
                <Day key={day.dt}>
                    <FormattedDays>
                        {formatDays(day.dt)}
                    </FormattedDays>
                    <DayImage>
                        <WeatherIcon name={unwrapExists(day.weather[0]).icon} />
                    </DayImage>
                    <DayTemperature>
                        <div>
                            {displayTemperature(day.temp.max)}
                        </div>
                        <div>
                            {displayTemperature(day.temp.min)}
                        </div>
                    </DayTemperature>
                </Day>
            ))
        }
    </Days>
);

export default WeatherDays;
