import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    getForm,
    formSetAttribute,
} from '../../store/processes/forms';
import {
    useMacro,
    useMacroScope,
    RuntimePromise,
    MacroContext,
} from '../shared/macroHelpers';
import {
    HiddenInputOptions,
} from '../../store/processes/studioFile/componentConfigs/hiddenInput';
import { State } from '../../store/processes';

const componentName = 'Hidden Input';

type HiddenInputProps = {
    options: HiddenInputOptions;
    macroContext: MacroContext;
};

const HiddenInput = ({
    options,
    macroContext,
}: HiddenInputProps): React.ReactElement | null => {
    const scope = useMacroScope(macroContext);
    const valuePromise = useMacro(
        options.value ?? '',
        scope,
        {
            componentName,
            optionName: 'value',
        },
    );

    const form = useSelector((state: State) => getForm(state, macroContext.formId ?? '-1'));
    const dispatch = useDispatch();
    React.useEffect(() => {
        const existingVal = form?.data?.[options.name];
        if (
            form
            && !(existingVal && RuntimePromise.eql(existingVal, valuePromise))
        ) { dispatch(formSetAttribute(form.id, options.name, valuePromise, [])); }
    }, [dispatch, form, options.name, valuePromise]);
    return (
        <input
            type="hidden"
            name={options.name}
            value={RuntimePromise.unwrapOr('', valuePromise)}
        />
    );
};

export default HiddenInput;
