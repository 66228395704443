import * as React from 'react';
import styled from 'styled-components';
import Widget from './shared/widget';
import { useMacroScope, makeUseMacros } from './shared/macroHelpers';
import * as RuntimePromise from '../macros/promise';
import { ComponentProps } from './shared/types';

const Body = styled.div`
    padding:
        ${({ theme }): string | null => (
            theme.showWidget
                ? theme.componentPadding
                : null
        )};

    img {
        display: inline;
    }
`;

type SnippetProps = ComponentProps<{
    code?: string;
    rawHtml?: boolean;
}>;

const useMacros = makeUseMacros('Snippet', {
    code: {},
});

/*
    This HTML is cleaned on the server otherwise we would not permit it to be
    dangerously set. Experiences are still hosted on a single domain so we do
    not allow the users of the Studio to inject abitrary HTML onto the page.
*/
const Snippet = ({
    options: { code, rawHtml = false },
    macroContext,
}: SnippetProps): React.ReactElement | null => {
    const scope = useMacroScope(macroContext);

    const macroOptionsWithFallbacks = React.useMemo(() => ({
        code: code ?? '',
    }), [code]);

    // All values dynamically inputed via macro must be HTML escaped
    const renderedCodePromise = useMacros(scope, macroOptionsWithFallbacks);

    if (!RuntimePromise.isSuccess(renderedCodePromise)) { return null; }

    const {
        code: renderedCode,
    } = RuntimePromise.unwrap(renderedCodePromise);

    return (
        <Widget>
            {
                rawHtml
                    ? (
                        <iframe
                            aria-label="iframe for code snippets"
                            srcDoc={renderedCode}
                        />
                    )
                    : (
                        <Body
                            /* eslint-disable-next-line */
                            dangerouslySetInnerHTML={{ __html: renderedCode }}
                        />
                    )
            }
        </Widget>
    );
};

export default Snippet;
