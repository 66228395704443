export const COOKIE_BANNER_THEME_LIGHT = 'light' as const;
export const COOKIE_BANNER_THEME_DARK = 'dark' as const;

export type CookieBannerTheme = typeof COOKIE_BANNER_THEME_LIGHT | typeof COOKIE_BANNER_THEME_DARK;

export type CookieBannerOptions = {
    ppUrl?: string | undefined | null;
    theme: CookieBannerTheme;
};

type CookieBannerConfig = {
    options: CookieBannerOptions;
};

export default CookieBannerConfig;
