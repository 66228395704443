import { eventChannel, END, EventChannel } from 'redux-saga';

type Options = {
    enableHighAccuracy: boolean;
};

export type BrowserLocation = {
    coords: {
        latitude: number;
        longitude: number;
    };
    timestamp: number;
};

export const getLocation = (options: Options): Promise<BrowserLocation> => (
    'geolocation' in navigator
        ? new Promise(
            (resolve, reject) => (
                navigator.geolocation.getCurrentPosition(
                    resolve,
                    reject,
                    options,
                )
            ),
        )
        : Promise.reject(new Error('geolocation not supported'))
);

export const watchLocation = (
    options: Options,
): EventChannel<BrowserLocation> => (
    eventChannel((emitter) => {
        if (!('geolocation' in navigator)) {
            emitter(END);
            /* eslint-disable-next-line @typescript-eslint/no-empty-function */
            return (): void => {};
        }

        const id = navigator.geolocation.watchPosition(
            emitter,
            () => emitter(END),
            options,
        );

        return (): void => {
            navigator.geolocation.clearWatch(id);
        };
    })
);
