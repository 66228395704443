import { SagaIterator, eventChannel } from 'redux-saga';
import {
    call,
    select,
    put,
    takeEvery,
    getContext,
    spawn,
    take,
} from 'redux-saga/effects';
import analytics, { ActionAnalyticsOptions } from '../analytics';
import * as RuntimePromise from '../../../macros/promise';
import { makeMacroScope, runMacroString, MacroScope } from '../../../macros';
import {
    DEVICE_VAR,
    OBJECT_VAR,
    loadVariables,
    getVariablesForType,
    getVariable,
    variableReducer,
    rawObjectVariableOperation,
    rawLocalVariableOperation,
    RawVariableOperationProps,
    RawVariablesOperationProps,
    rawObjectVariablesOperation,
    OP_UPDATE,
} from './reducer';
import { withDelayedDispatch } from '../../../utils/redux';
import {
    getBBStudioVars,
    setBBStudioVars,
} from '../../../functions/bbStudioVars';
import { PagePayload, V3PagePayload, getPagePayload } from '../pagePayload';
import { updateObjectUserData } from '../../../functions/objectUserData';
import { getRootStudioFileId } from '../rootStudioFile';
import { MetadataOptions } from '../../../components/shared/meta';
import {
    BlueBiteSDKManager,
    RendererSDKManagerInitializer,
    LocalVariableUpdateAction,
    ObjectVariablesUpdateAction,
} from '../rendererSDK';
import { notifyLocalVariablesUpdated, notifyObjectVariablesUpdated } from '../xpManager';

export const DEVICE_OPERATION = 'VARIABLES/DEVICE/OPERATION' as const;
const OBJECT_OPERATION = 'VARIABLE/OBJECT/OPERATION' as const;
const OBJECTS_OPERATION = 'VARIABLES/OBJECT/OPERATION' as const;

type VariableOperationProps = RawVariableOperationProps & {
    actionAnalytics: ActionAnalyticsOptions;
    meta: MetadataOptions;
};

type VariablesOperationProps = RawVariablesOperationProps & {
    actionAnalytics: ActionAnalyticsOptions;
    meta: MetadataOptions;
};

export type DeviceVariableOperationAction =
    & { type: typeof DEVICE_OPERATION }
    & VariableOperationProps;

export const deviceVariableOperation = (
    action: VariableOperationProps,
): DeviceVariableOperationAction => ({
    ...action,
    type: DEVICE_OPERATION,
});

type ObjectVariableOperationAction = { type: typeof OBJECT_OPERATION } & VariableOperationProps;

export const objectVariableOperation = (
    action: VariableOperationProps,
): ObjectVariableOperationAction => ({
    ...action,
    type: OBJECT_OPERATION,
});

type ObjectVariablesOperationAction = { type: typeof OBJECTS_OPERATION } & VariablesOperationProps;

export const objectVariablesOperation = (
    action: VariablesOperationProps,
): ObjectVariablesOperationAction => ({
    ...action,
    type: OBJECTS_OPERATION,
});

export function* initializeSDK(id: string): SagaIterator {
    const localVariables = getBBStudioVars(id);
    yield put(loadVariables(DEVICE_VAR, localVariables));
    const pagePayload = yield select(getPagePayload);
    const sdkManager = yield call(connectSDK, localVariables,
        pagePayload.value as V3PagePayload);
    yield spawn(function* handleSDKDeviceOperations() {
        yield takeEvery(DEVICE_OPERATION as any, deviceVariableOperationSaga, sdkManager);
    });
    yield spawn(function* handleSDKObjectOperations() {
        yield takeEvery(OBJECT_OPERATION, objectVariableOperationSaga, sdkManager);
    });
    yield spawn(function* handleSDKObjectsOperations() {
        yield takeEvery(OBJECTS_OPERATION, objectVariablesOperationSaga, sdkManager);
    });
}

function* connectSDK(
    initialLocalVariables: Record<string, string>,
    pagePayload: V3PagePayload,
): SagaIterator {
    const managerInitializer: RendererSDKManagerInitializer = (
        yield getContext('rendererSDKManagerInitializer')
    );
    const deviceChannel = eventChannel<LocalVariableUpdateAction>((emitter) => {
        const p = managerInitializer.localVariableSetup(
            Object.fromEntries(Object.entries(initialLocalVariables)
                .map(([key, value]) => [key, value])),
            emitter,
        );
        return async () => {
            const manager = await p;
            manager.clearDeviceEmitter();
        };
    });
    yield spawn(function* handleSDKDeviceAction(c) {
        while (true) {
            const { type, key, value }: LocalVariableUpdateAction = yield take(c);
            if (type === 'SetLocalVariable') {
                yield put(deviceVariableOperation({
                    varOperation: value == null ? 'clear' : 'set',
                    varName: key,
                    opValue: value ?? '',
                    actionAnalytics: { type: 'none' },
                    // meta is only used for analytics which is none here
                    // this is not ideal but analytics is due for a refactor
                    meta: {} as any,
                }));
            }
        }
    }, deviceChannel);
    const objectChannel = eventChannel<ObjectVariablesUpdateAction>((emitter) => {
        const p = managerInitializer.interactionDataSetup(
            pagePayload,
            emitter,
        );
        return async () => {
            const manager = await p;
            manager.clearObjectEmitter();
        };
    });
    yield spawn(function* handleSDKObjectAction(c) {
        while (true) {
            const { type, ...actionProps } = yield take(c);
            if (type === 'SetObjectVariables') {
                yield put(objectVariablesOperation({
                    varOperation: OP_UPDATE,
                    ...actionProps,
                    actionAnalytics: { type: 'none' },
                    // same meta comment applies to this as it did to local variables
                    meta: {} as any,
                }));
            }
        }
    }, objectChannel);
    return (yield call(managerInitializer.waitForInitialization.bind(managerInitializer)));
}

export function* loadObjectVariablesSaga(pagePayload: PagePayload): SagaIterator {
    const variables = pagePayload.sdkData.object?.variables ?? {};
    yield put(loadVariables(OBJECT_VAR, variables));
    if (__IS_PREVIEW__) {
        yield put(notifyObjectVariablesUpdated(variables));
    }
}

function* renderAction<A extends VariableOperationProps>({
    varName: varNameRaw,
    opValue: opValueRaw,
    meta,
    ...rest
}: A): SagaIterator<A | null> {
    return yield call(
        withDelayedDispatch,
        function* run(dispatch): SagaIterator<A | null> {
            const scope: MacroScope = yield select(makeMacroScope({
                callsiteMetadata: {
                    componentId: meta.firingComponentId,
                    formId: meta.formId,
                },
                dispatch,
            }));

            const renderedOptions = RuntimePromise.all([
                runMacroString(varNameRaw ?? '', scope),
                runMacroString(opValueRaw ?? '', scope),
            ]);

            if (!RuntimePromise.isSuccess(renderedOptions)) {
                return null;
            }

            const [varName, opValue] = RuntimePromise.unwrap(renderedOptions);

            return {
                varName,
                opValue,
                meta,
                ...rest,
            } as A;
        },
    );
}

function* deviceVariableOperationSaga(
    sdkManager: BlueBiteSDKManager,
    rawAction: DeviceVariableOperationAction,
): SagaIterator {
    const action: DeviceVariableOperationAction | null = yield call(renderAction, rawAction);
    if (!action) { return; }
    const {
        varOperation,
        varName,
        opValue,
        actionAnalytics,
        meta,
    } = action;

    const id = yield select(getRootStudioFileId);
    yield put(rawLocalVariableOperation({
        varOperation,
        varName,
        opValue,
    }));
    const newDeviceVars = yield select(getVariablesForType(DEVICE_VAR));
    setBBStudioVars(id, newDeviceVars);

    const newValue = newDeviceVars[varName];
    sdkManager.localVariableChanged(
        varName,
        newValue == null ? undefined : newValue,
    );
    // IMPORTANT: This update sagas can be triggered concurrently. If we yield
    // between the above select and the variable update below we could be preempted.
    // We would thenupdate the preview with the previous value stored in this saga.
    // As is we will always dispatch the latest value. `takeLatest` should also cut out
    // some intermediate variable states from being `postMessaged` out to the editor.
    if (__IS_PREVIEW__) {
        yield put(notifyLocalVariablesUpdated(newDeviceVars));
    }
    yield call(
        analytics,
        'Local Variable Change',
        actionAnalytics,
        {
            eventCategory: meta.component,
            eventLabel: [
                varOperation,
                ' ',
                varName,
                ' to ',
                newDeviceVars[varName],
            ].join(''),
        },
        meta,
    );
}

function* objectVariableOperationSaga(
    sdkManager: BlueBiteSDKManager,
    rawAction: ObjectVariableOperationAction,
): SagaIterator {
    const action: ObjectVariableOperationAction | null = yield call(renderAction, rawAction);
    if (!action) { return; }

    const oldValue = yield select(getVariable(OBJECT_VAR, action.varName));
    const newValue = variableReducer(oldValue, rawObjectVariableOperation(action)) ?? '';

    try {
        yield call(updateObjectUserData, {
            [action.varName]: newValue,
        });

        yield put(rawObjectVariableOperation(action));
        sdkManager.objectVariablesChanged({ [action.varName]: newValue });
        yield call(
            analytics,
            'Object Variable Update',
            action.actionAnalytics,
            {
                eventCategory: action.meta.component,
                eventLabel: [
                    action.varOperation,
                    ' ',
                    action.varName,
                    ' to ',
                    newValue,
                ].join(''),
            },
            action.meta,
        );
        if (__IS_PREVIEW__) {
            const newObjectVars = yield select(getVariablesForType(OBJECT_VAR));
            // IMPORTANT: This update sagas can be triggered concurrently. If we yield
            // between the above select and the variable update below we could be preempted.
            // We would thenupdate the preview with the previous value stored in this saga.
            // As is we will always dispatch the latest value. `takeLatest` should also cut out
            // some intermediate variable states from being `postMessaged` out to the editor.
            yield put(notifyObjectVariablesUpdated(newObjectVars));
        }
    } catch (e) {
        console.error('Failed To Update Object Variable');
    }
}

function* objectVariablesOperationSaga(
    sdkManager: BlueBiteSDKManager,
    action: ObjectVariablesOperationAction,
): SagaIterator {
    const { updates, resolve, reject } = action;

    try {
        yield call(updateObjectUserData, updates);
        yield put(rawObjectVariablesOperation(action));
        sdkManager.objectVariablesChanged(updates);
        if (__IS_PREVIEW__) {
            yield put(notifyObjectVariablesUpdated(updates));
        }
        resolve();
    } catch (e) {
        console.error('Failed To Update Object Variable');
        reject(e);
    }
}
