import CampaignSpecificComponentConfig from './componentConfigs/campaign';
import WeatherSpecificComponentConfig from './componentConfigs/weather';
import YoutubeSpecificComponentConfig from './componentConfigs/youtube';
import SwiperSpecificComponentConfig from './componentConfigs/swiper';
import InterstitialSpecificComponentConfig from './componentConfigs/interstitial';
import SnippetSpecificComponentConfig from './componentConfigs/snippet';
import ExclusiveComponentTypeId from './componentConfigs/exclusive';
import CountdownSpecificComponentConfig from './componentConfigs/countdown';
import ImageSpecificComponentConfig from './componentConfigs/image';
import VerifiedSpecificComponentConfig from './componentConfigs/verified';
import InputSpecificComponentConfig from './componentConfigs/input';
import CheckboxSpecificComponentConfig from './componentConfigs/checkbox';
import FormSpecificCompopnentConfig from './componentConfigs/form';
import TextSpecificComponentConfig from './componentConfigs/text';
import TextEditorSpecificComponentConfig from './componentConfigs/textEditor';
import ScheduleSpecificComponentConfig from './componentConfigs/schedule';
import GallerySpecificComponentConfig from './componentConfigs/gallery';
import EventSpecificComponentConfig from './componentConfigs/event';
import ActionSpecificComponentConfig from './componentConfigs/action';
import SectionSpecificCompopnentConfig from './componentConfigs/section';
import HiddenInputSpecificComponentConfig from './componentConfigs/hiddenInput';
import SelectSpecificComponentConfig from './componentConfigs/select';
import DelaySpecificComponentConfig from './componentConfigs/delay';
import ButtonSpecificComponentConfig from './componentConfigs/button';
import InstagramSpecificComponentConfig from './componentConfigs/instagram';
import TwitterSpecificComponentConfig from './componentConfigs/twitter';
import CookieBannerSpecificComponentConfig from './componentConfigs/cookieBanner';
import UserCreatedSpecificComponentConfig from './componentConfigs/userCreatedComponent';

export type { Event } from './componentConfigs/event';

export const CAMPAIGN_A_COMPONENT_TYPE_ID = '1' as const;
export const CAMPAIGN_B_COMPONENT_TYPE_ID = '2' as const;
export const CAMPAIGN_C_COMPONENT_TYPE_ID = '3' as const;
export const CAMPAIGN_D_COMPONENT_TYPE_ID = '4' as const;
export const WEATHER_COMPONENT_TYPE_ID = '6' as const;
export const YOUTUBE_COMPONENT_TYPE_ID = '7' as const;
export const SWIPER_COMPONENT_TYPE_ID = '10' as const;
export const INTERSTITIAL_COMPONENT_TYPE_ID = '13' as const;
export const SNIPPET_COMPONENT_TYPE_ID = '14' as const;
export const EXCLUSIVE_COMPONENT_TYPE_ID = '15' as const;
export const COUNTDOWN_COMPONENT_TYPE_ID = '16' as const;
export const IMAGE_COMPONENT_TYPE_ID = '17' as const;
export const VERIFIED_COMPONENT_TYPE_ID = '18' as const;
export const INPUT_COMPONENT_TYPE_ID = '19' as const;
export const CHECKBOX_COMPONENT_TYPE_ID = '20' as const;
export const FORM_COMPONENT_TYPE_ID = '21' as const;
export const TEXT_COMPONENT_TYPE_ID = '22' as const;
export const SCHEDULE_COMPONENT_TYPE_ID = '23' as const;
export const GALLERY_COMPONENT_TYPE_ID = '24' as const;
export const EVENT_COMPONENT_TYPE_ID = '25' as const;
export const ACTION_COMPONENT_TYPE_ID = '26' as const;
export const SECTION_COMPONENT_TYPE_ID = '27' as const;
export const HIDDEN_INPUT_COMPONENT_TYPE_ID = '28' as const;
export const SELECT_COMPONENT_TYPE_ID = '29' as const;
export const DELAY_COMPONENT_TYPE_ID = '30' as const;
export const BUTTON_COMPONENT_TYPE_ID = '31' as const;
export const INSTAGRAM_COMPONENT_TYPE_ID = '32' as const;
export const TWITTER_COMPONENT_TYPE_ID = '33' as const;
export const COOKIE_BANNER_COMPONENT_TYPE_ID = '34' as const;
export const TEXT_EDITOR_COMPONENT_TYPE_ID = '35' as const;
export const USER_CREATED_COMPONENT_TYPE_ID = '36' as const;

export type StudioComponentTypeId =
    | typeof CAMPAIGN_A_COMPONENT_TYPE_ID
    | typeof CAMPAIGN_B_COMPONENT_TYPE_ID
    | typeof CAMPAIGN_C_COMPONENT_TYPE_ID
    | typeof CAMPAIGN_D_COMPONENT_TYPE_ID
    | typeof WEATHER_COMPONENT_TYPE_ID
    | typeof YOUTUBE_COMPONENT_TYPE_ID
    | typeof SWIPER_COMPONENT_TYPE_ID
    | typeof INTERSTITIAL_COMPONENT_TYPE_ID
    | typeof SNIPPET_COMPONENT_TYPE_ID
    | typeof EXCLUSIVE_COMPONENT_TYPE_ID
    | typeof COUNTDOWN_COMPONENT_TYPE_ID
    | typeof IMAGE_COMPONENT_TYPE_ID
    | typeof VERIFIED_COMPONENT_TYPE_ID
    | typeof INPUT_COMPONENT_TYPE_ID
    | typeof CHECKBOX_COMPONENT_TYPE_ID
    | typeof FORM_COMPONENT_TYPE_ID
    | typeof TEXT_COMPONENT_TYPE_ID
    | typeof TEXT_EDITOR_COMPONENT_TYPE_ID
    | typeof SCHEDULE_COMPONENT_TYPE_ID
    | typeof GALLERY_COMPONENT_TYPE_ID
    | typeof EVENT_COMPONENT_TYPE_ID
    | typeof ACTION_COMPONENT_TYPE_ID
    | typeof SECTION_COMPONENT_TYPE_ID
    | typeof HIDDEN_INPUT_COMPONENT_TYPE_ID
    | typeof SELECT_COMPONENT_TYPE_ID
    | typeof DELAY_COMPONENT_TYPE_ID
    | typeof BUTTON_COMPONENT_TYPE_ID
    | typeof INSTAGRAM_COMPONENT_TYPE_ID
    | typeof TWITTER_COMPONENT_TYPE_ID
    | typeof COOKIE_BANNER_COMPONENT_TYPE_ID
    | typeof USER_CREATED_COMPONENT_TYPE_ID;

export type StudioComponentSharedConfig = {
    id: string;
    typeId: StudioComponentTypeId;
    options?: object;
    componentLists?: { [key: string]: string };
};

export type StudioComponentOptionsMapping<T extends StudioComponentTypeId> = (
    T extends (
        | typeof CAMPAIGN_A_COMPONENT_TYPE_ID
        | typeof CAMPAIGN_B_COMPONENT_TYPE_ID
        | typeof CAMPAIGN_C_COMPONENT_TYPE_ID
        | typeof CAMPAIGN_D_COMPONENT_TYPE_ID
    )
        ? CampaignSpecificComponentConfig
    : T extends typeof WEATHER_COMPONENT_TYPE_ID
        ? WeatherSpecificComponentConfig
    : T extends typeof YOUTUBE_COMPONENT_TYPE_ID
        ? YoutubeSpecificComponentConfig
    : T extends typeof SWIPER_COMPONENT_TYPE_ID
        ? SwiperSpecificComponentConfig
    : T extends typeof INTERSTITIAL_COMPONENT_TYPE_ID
        ? InterstitialSpecificComponentConfig
    : T extends typeof SNIPPET_COMPONENT_TYPE_ID
        ? SnippetSpecificComponentConfig
    : T extends typeof EXCLUSIVE_COMPONENT_TYPE_ID
        ? ExclusiveComponentTypeId
    : T extends typeof COUNTDOWN_COMPONENT_TYPE_ID
        ? CountdownSpecificComponentConfig
    : T extends typeof IMAGE_COMPONENT_TYPE_ID
        ? ImageSpecificComponentConfig
    : T extends typeof VERIFIED_COMPONENT_TYPE_ID
        ? VerifiedSpecificComponentConfig
    : T extends typeof INPUT_COMPONENT_TYPE_ID
        ? InputSpecificComponentConfig
    : T extends typeof CHECKBOX_COMPONENT_TYPE_ID
        ? CheckboxSpecificComponentConfig
    : T extends typeof FORM_COMPONENT_TYPE_ID
        ? FormSpecificCompopnentConfig
    : T extends typeof TEXT_COMPONENT_TYPE_ID
        ? TextSpecificComponentConfig
        : T extends typeof TEXT_EDITOR_COMPONENT_TYPE_ID
        ? TextEditorSpecificComponentConfig
    : T extends typeof SCHEDULE_COMPONENT_TYPE_ID
        ? ScheduleSpecificComponentConfig
    : T extends typeof GALLERY_COMPONENT_TYPE_ID
        ? GallerySpecificComponentConfig
    : T extends typeof EVENT_COMPONENT_TYPE_ID
        ? EventSpecificComponentConfig
    : T extends typeof ACTION_COMPONENT_TYPE_ID
        ? ActionSpecificComponentConfig
    : T extends typeof SECTION_COMPONENT_TYPE_ID
        ? SectionSpecificCompopnentConfig
    : T extends typeof HIDDEN_INPUT_COMPONENT_TYPE_ID
        ? HiddenInputSpecificComponentConfig
    : T extends typeof SELECT_COMPONENT_TYPE_ID
        ? SelectSpecificComponentConfig
    : T extends typeof DELAY_COMPONENT_TYPE_ID
        ? DelaySpecificComponentConfig
    : T extends typeof BUTTON_COMPONENT_TYPE_ID
        ? ButtonSpecificComponentConfig
    : T extends typeof INSTAGRAM_COMPONENT_TYPE_ID
        ? InstagramSpecificComponentConfig
    : T extends typeof TWITTER_COMPONENT_TYPE_ID
        ? TwitterSpecificComponentConfig
    : T extends typeof COOKIE_BANNER_COMPONENT_TYPE_ID
        ? CookieBannerSpecificComponentConfig
    : T extends typeof USER_CREATED_COMPONENT_TYPE_ID
        ? UserCreatedSpecificComponentConfig
    : never
);

export type StudioComponentConfig<
    T extends StudioComponentTypeId = StudioComponentTypeId
> = (
    & StudioComponentSharedConfig
    & { typeId: T }
    & StudioComponentOptionsMapping<T>
);

export type CampaignComponentConfig = StudioComponentConfig<
    | typeof CAMPAIGN_A_COMPONENT_TYPE_ID
    | typeof CAMPAIGN_B_COMPONENT_TYPE_ID
    | typeof CAMPAIGN_C_COMPONENT_TYPE_ID
    | typeof CAMPAIGN_D_COMPONENT_TYPE_ID
>;

export type WeatherComponentConfig = StudioComponentConfig<
    typeof WEATHER_COMPONENT_TYPE_ID
>;

export type YoutubeComponentConfig = StudioComponentConfig<
    typeof YOUTUBE_COMPONENT_TYPE_ID
>;

export type SwiperComponentConfig = StudioComponentConfig<
    typeof SWIPER_COMPONENT_TYPE_ID
>;

export type InterstitialComponentConfig = StudioComponentConfig<
    typeof INTERSTITIAL_COMPONENT_TYPE_ID
>;

export type SnippetComponentConfig = StudioComponentConfig<
    typeof SNIPPET_COMPONENT_TYPE_ID
>;

export type ExclusiveComponentConfig = StudioComponentConfig<
    typeof EXCLUSIVE_COMPONENT_TYPE_ID
>;

export type CountdownComponentConfig = StudioComponentConfig<
    typeof COUNTDOWN_COMPONENT_TYPE_ID
>;

export type ImageComponentConfig = StudioComponentConfig<
    typeof IMAGE_COMPONENT_TYPE_ID
>;

export type VerifiedComponentConfig = StudioComponentConfig<
    typeof VERIFIED_COMPONENT_TYPE_ID
>;

export type InputComponentConfig = StudioComponentConfig<
    typeof INPUT_COMPONENT_TYPE_ID
>;

export type CheckboxComponentConfig = StudioComponentConfig<
    typeof CHECKBOX_COMPONENT_TYPE_ID
>;

export type FormComponentConfig = StudioComponentConfig<
    typeof FORM_COMPONENT_TYPE_ID
>;

export type TextComponentConfig = StudioComponentConfig<
    typeof TEXT_COMPONENT_TYPE_ID
>;

export type TextEditorComponentConfig = StudioComponentConfig<
    typeof TEXT_EDITOR_COMPONENT_TYPE_ID
>;

export type ScheduleComponentConfig = StudioComponentConfig<
    typeof SCHEDULE_COMPONENT_TYPE_ID
>;

export type GalleryComponentConfig = StudioComponentConfig<
    typeof GALLERY_COMPONENT_TYPE_ID
>;

export type EventComponentConfig = StudioComponentConfig<
    typeof EVENT_COMPONENT_TYPE_ID
>;

export type ActionComponentConfig = StudioComponentConfig<
    typeof ACTION_COMPONENT_TYPE_ID
>;

export type SectionComponentConfig = StudioComponentConfig<
    typeof SECTION_COMPONENT_TYPE_ID
>;

export type HiddenInputComponentConfig = StudioComponentConfig<
    typeof HIDDEN_INPUT_COMPONENT_TYPE_ID
>

export type SelectComponentConfig = StudioComponentConfig<
    typeof SELECT_COMPONENT_TYPE_ID
>

export type DelayComponentConfig = StudioComponentConfig<
    typeof DELAY_COMPONENT_TYPE_ID
>;

export type ButtonComponentConfig = StudioComponentConfig<
    typeof BUTTON_COMPONENT_TYPE_ID
>;

export type InstagramComponentConfig = StudioComponentConfig<
    typeof INSTAGRAM_COMPONENT_TYPE_ID
>;

export type TwitterComponentConfig = StudioComponentConfig<
    typeof TWITTER_COMPONENT_TYPE_ID
>;

export type CookieComponentConfig = StudioComponentConfig<
    typeof COOKIE_BANNER_COMPONENT_TYPE_ID
>;

export type UserCreatedComponentConfig = StudioComponentConfig<
    typeof USER_CREATED_COMPONENT_TYPE_ID
>;
