import { BlueBiteSDKManager } from './manager';
import {
    Interaction,
    LocalVariableEmitter,
    ObjectVariableEmitter,
    convertPagePayloadToInteraction,
} from './types';
import { unwrapExists } from '../../../functions/utils';
import { V3PagePayload } from '../pagePayload';

export class RendererSDKManagerInitializer {
    #localVariables: Record<string, string> | undefined;

    #localVariablesEmitter: LocalVariableEmitter | undefined;

    #interaction: Interaction | null | undefined;

    #objectVariablesEmitter: ObjectVariableEmitter | undefined;

    #promise: Promise<BlueBiteSDKManager>;

    #done: ((sdk: BlueBiteSDKManager) => void) | undefined;

    constructor() {
        this.#promise = new Promise<BlueBiteSDKManager>((resolve) => {
            this.#done = resolve;
        });
    }

    waitForInitialization(): Promise<BlueBiteSDKManager> {
        return this.#promise;
    }

    checkDone() {
        if (
            this.#localVariables
            && this.#localVariablesEmitter
            && this.#objectVariablesEmitter
            && typeof this.#interaction !== 'undefined'
        ) {
            const manager = new BlueBiteSDKManager(
                this.#localVariables,
                this.#localVariablesEmitter,
                this.#interaction,
                this.#objectVariablesEmitter,
            );
            manager.connect();
            unwrapExists(this.#done)(manager);
            return true;
        }
        return false;
    }

    localVariableSetup(
        localVariables: Record<string, string>,
        localVariablesEmitter: LocalVariableEmitter,
    ) {
        this.#localVariables = localVariables;
        this.#localVariablesEmitter = localVariablesEmitter;
        this.checkDone();
        return this.#promise;
    }

    interactionDataSetup(
        pagePayload: V3PagePayload,
        objectVariablesEmitter: ObjectVariableEmitter,
    ) {
        this.#interaction = convertPagePayloadToInteraction(pagePayload);
        this.#objectVariablesEmitter = objectVariablesEmitter;
        this.checkDone();
        return this.#promise;
    }
}
