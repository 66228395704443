import * as React from 'react';
import styled from 'styled-components';
import { displayTemperature } from '../shared/utils';
import WeatherIcon from './WeatherIcon';
import { unwrapExists } from '../../functions/utils';


const ContentCurrent = styled.div`
    display: flex;
`;

const CurrentImage = styled.div`
    width: 96px;
    height: 96px;
    padding-right: 16px;

    svg {
        width: 100%;
        height: 100%;
    }
`;

const CurrentInfo = styled.div`
    align-self: center;
`;

const CurrentSummary = styled.div`
    height: 21px;
    font-size: 18px;
    font-weight: 300;
`;

type WeatherCurrentProps = {
    current: {
        temp: number;
        weather: Array<{
            description: string;
            icon: string;
        }>;
    };
    scale: string;
}

const WeatherCurrent = ({
    current,
    scale,
}: WeatherCurrentProps): React.ReactElement => (
    <ContentCurrent>
        <CurrentImage>
            <WeatherIcon name={unwrapExists(current.weather[0]).icon} />
        </CurrentImage>
        <CurrentInfo>
            <h1>
                {displayTemperature(current.temp)}
                {scale === 'fahrenheit' ? 'F' : 'C'}
            </h1>
            <CurrentSummary>
                {unwrapExists(current.weather[0]).description}
                .
            </CurrentSummary>
        </CurrentInfo>
    </ContentCurrent>
);

export default WeatherCurrent;
