import { BlueBiteSDKManager } from './manager';
import { Task } from './task';
import {
    Interaction,
    OnLocalVariableChangeCallback,
    OnObjectVariablesChangeCallback,
} from './types';

const assertValidValue: (
    (value: unknown) => asserts value is string | undefined
) = (value: unknown) => {
    if (typeof value !== 'string' && typeof value !== 'undefined') {
        throw new TypeError('Invalid Variable Value');
    }
};

export class BlueBiteSDK {
    #manager: BlueBiteSDKManager;

    constructor(manager: BlueBiteSDKManager) {
        this.#manager = manager;
    }

    getLocalVariables(): Record<string, string> {
        return this.#manager.getLocalVariables();
    }

    setLocalVariable(key: unknown, value: unknown) {
        if (typeof key !== 'string') {
            throw new TypeError('Invalid variable key');
        }
        assertValidValue(value);
        this.#manager.setLocalVariable(key, value);
    }

    clearLocalVariable(key: unknown) {
        if (typeof key !== 'string') {
            throw new TypeError('Invalid variable key');
        }
        this.#manager.setLocalVariable(key, undefined);
    }

    /* eslint-disable-next-line promise/prefer-await-to-callbacks */
    registerOnLocalVariableChange(callback: unknown): Task {
        if (typeof callback !== 'function') {
            throw new TypeError('Invalid callback function');
        }
        return this.#manager.registerOnLocalVariableChange(
            callback as OnLocalVariableChangeCallback,
        );
    }

    /* eslint-disable-next-line promise/prefer-await-to-callbacks */
    registerOnObjectVariablesChange(callback: unknown): Task {
        if (typeof callback !== 'function') {
            throw new TypeError('Invalid callback function');
        }
        return this.#manager.registerOnObjectVariablesChange(
            callback as OnObjectVariablesChangeCallback,
        );
    }

    getInteraction(): Promise<Interaction | null> {
        return this.#manager.getInteraction();
    }

    getObjectVariables(objectUuid: string): { [key: string]: string } {
        return this.#manager.getObjectVariables(objectUuid);
    }

    async setObjectVariables(objectUuid: string, updates: Record<string, string>): Promise<void> {
        return this.#manager.setObjectVariables(objectUuid, updates);
    }
}
