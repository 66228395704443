/* eslint-disable max-len */
import React from 'react';

const Thunderstorm = (): React.ReactElement => (
    <svg aria-label="thunderstorm icon" width="128" height="128" viewBox="0 0 128 128">
        <defs>
            <clipPath id="clip-path-thunderstorm">
                <rect id="Rectangle_9" data-name="Rectangle 9" width="85.915" height="79.598" fill="none" />
            </clipPath>
            <clipPath id="clip-TStorm">
                <rect width="128" height="128" />
            </clipPath>
        </defs>
        <g id="TStorm" clipPath="url(#clip-TStorm)">
            <rect width="128" height="128" fill="#fff" />
            <g id="Group_29" data-name="Group 29" transform="translate(21 24)">
                <g id="Group_17" data-name="Group 17" transform="translate(0 0)" clipPath="url(#clip-path-thunderstorm)">
                    <path id="Path_49" data-name="Path 49" d="M74.933,15.923a17.755,17.755,0,0,0-33.715-5.75A17.757,17.757,0,1,0,38.64,45.492L74.548,45.4a14.743,14.743,0,0,0,.385-29.48" transform="translate(-3.347 0)" fill="#3d3d3d" />
                    <path id="Path_50" data-name="Path 50" d="M54.1,25.006a17.755,17.755,0,0,0-33.715-5.75,17.757,17.757,0,1,0-2.578,35.318l35.907-.088a14.743,14.743,0,0,0,.386-29.48" transform="translate(0 -1.459)" fill="#e6e7ea" />
                    <path id="Path_51" data-name="Path 51" d="M12.572,81.905a1.679,1.679,0,0,1-1.683-1.674L10.862,69.4a1.679,1.679,0,1,1,3.357-.008l.027,10.833a1.679,1.679,0,0,1-1.674,1.683" transform="translate(-1.745 -10.881)" fill="#1a65ef" />
                    <path id="Path_52" data-name="Path 52" d="M59.762,81.788a1.679,1.679,0,0,1-1.683-1.674l-.027-10.833a1.679,1.679,0,0,1,3.357-.008l.027,10.833a1.679,1.679,0,0,1-1.674,1.683" transform="translate(-9.328 -10.862)" fill="#1a65ef" />
                    <path id="Path_53" data-name="Path 53" d="M20.645,76.486a1.679,1.679,0,0,1-1.683-1.674l-.013-5.434a1.679,1.679,0,0,1,3.357-.008L22.32,74.8a1.679,1.679,0,0,1-1.674,1.683" transform="translate(-3.045 -10.878)" fill="#1a65ef" />
                    <path id="Path_54" data-name="Path 54" d="M67.758,76.369a1.679,1.679,0,0,1-1.683-1.674l-.013-5.434a1.679,1.679,0,0,1,3.357-.008l.013,5.434a1.679,1.679,0,0,1-1.674,1.683" transform="translate(-10.615 -10.859)" fill="#1a65ef" />
                    <path id="Path_55" data-name="Path 55" d="M35.067,38.6l14.951-.037L40.505,58.213l9.154-.023-16.51,27.6,5.44-21.27L27.2,64.551Z" transform="translate(-4.37 -6.196)" fill="#f3d33f" />
                </g>
            </g>
        </g>
    </svg>
);

export default Thunderstorm;
