import * as R from 'ramda';
import * as RuntimePromise from './promise';

export type Result<A, E> = Ok<A> | Err<E>;
export type Type<A, E> = Result<A, E>;

export type Ok<A> = {
    readonly ok: true;
    readonly value: A;
};

export type Err<E> = {
    readonly ok: false;
    readonly error: E;
}

export const Ok = <A>(value: A): Ok<A> => ({
    ok: true,
    value,
});

export const Err = <E>(error: E): Err<E> => ({
    ok: false,
    error,
});

export const up = <A>(value: A): A extends Result<any, any> ? A : Ok<A> => (
    Object.prototype.hasOwnProperty.call(value, 'ok')
        ? value
        : {
            ok: true,
            value,
        }
) as any;

export const isOk = <A>(r: Result<A, any>): r is Ok<A> => r.ok;
export const map: {
    <A, B, E>(
        fn: (a: A) => B,
        result: Result<A, E>,
    ): Result<B, E>;
    <A, B, E>(fn: (a: A) => B): (
        (result: Result<A, E>) => Result<B, E>
    );
} = R.curry(<A, B, E>(
    fn: (a: A) => B,
    result: Result<A, E>,
): Result<B, E> => (
    !isOk(result) ? result : up(fn(result.value))
)) as any;
export const chain: {
    <A, B, E, F>(
        fn: (a: A) => Result<B, F>,
        result: Result<A, E>,
    ): Result<B, E | F>;
    <A, B, E, F>(fn: (a: A) => Result<B, F>): (
        (result: Result<A, E>) => Result<B, E | F>
    );
} = R.curry(<A, B, E, F>(
    fn: (a: A) => Result<B, F>,
    result: Result<A, E>,
): Result<B, E | F> => (
    !isOk(result) ? result : fn(result.value)
)) as any;
export const all = <A extends Array<Result<any, any>>>(rs: A): (
    A extends Array<Result<any, infer E>>
        ? Result<
            { [K in keyof A]: (A[K] extends Result<infer I, any> ? I : never) },
            E
        >
        : never
) => (
    rs.find((r) => !isOk(r))
        ?? Ok((rs as Array<Ok<A>>).map(({ value }) => value))
) as any;
export const mapError = R.curry(<A, E, F>(
    fn: (e: E) => F,
    result: Result<A, E>,
): Result<A, F> => (
    isOk(result) ? result : Err(fn(result.error))
));
export const okOr = R.curry(<R extends Result<any, any>, B>(
    or: B,
    result: R,
): R extends Ok<infer A> ? A : B => (
    isOk(result)
        ? result.value
        : or
) as R extends Ok<infer A> ? A : B);
export const unwrapResult = <A>(r: Result<A, any>): A => {
    if (isOk(r)) {
        return r.value;
    }
    throw new Error(r.error);
};

export const unwrap = <A>(r: Ok<A>): A => r.value;
export const unwrapErr = <E>(r: Err<E>): E => r.error;

export const firstOk = <
    A extends Array<any>,
    F extends Array<(...args: A) => Result<any, any>>,
>(fs: F) => (...args: A): (
    F extends Array<(...args: A) => Result<infer C, infer D | string>>
    ? Result<C, D>
    : never) => {
    for (const f of fs) {
        const r = f(...args);
        if (isOk(r)) {
            return r as any;
        }
    }
    return Err('No Resulting Match') as any;
};

export const resultToPromise = <A, E>(
    result: Result<A, E>,
): RuntimePromise.Type<A, E> => (
    isOk(result)
        ? RuntimePromise.Success(unwrap(result))
        : RuntimePromise.Err(unwrapErr(result))
);

// Typescripts not really powerfull enough for this one.
// A massive amount of overloads is the best we can do.
/* eslint-disable max-len */
export const chains: {
    <I, A0, E0>(f0: (a: I) => A0 | Result<A0, E0>): (arg: I) => Result<A0, E0>;
    <I, A0, A1, E0, E1>(
        f0: (a: I) => A0 | Result<A0, E0>,
        f1: (a: A0) => A1 | Result<A1, E1>,
    ): (arg: I) => Result<A1, E0 | E1>;
    <I, A0, A1, A2, E0, E1, E2>(
        f0: (a: I) => A0 | Result<A0, E0>,
        f1: (a: A0) => A1 | Result<A1, E1>,
        f2: (a: A1) => A2 | Result<A2, E2>,
    ): (arg: I) => Result<A2, E0 | E1 | E2>;
    <I, A0, A1, A2, A3, E0, E1, E2, E3>(
        f0: (a: I) => A0 | Result<A0, E0>,
        f1: (a: A0) => A1 | Result<A1, E1>,
        f2: (a: A1) => A2 | Result<A2, E2>,
        f3: (a: A2) => A3 | Result<A3, E3>,
    ): (arg: I) => Result<A3, E0 | E1 | E2 | E3>;
    <I, A0, A1, A2, A3, A4, E0, E1, E2, E3, E4>(
        f0: (a: I) => A0 | Result<A0, E0>,
        f1: (a: A0) => A1 | Result<A1, E1>,
        f2: (a: A1) => A2 | Result<A2, E2>,
        f3: (a: A2) => A3 | Result<A3, E3>,
        f4: (a: A3) => A4 | Result<A4, E4>,
    ): (arg: I) => Result<A4, E0 | E1 | E2 | E3 | E4>;
    <I, A0, A1, A2, A3, A4, A5, E0, E1, E2, E3, E4, E5>(
        f0: (a: I) => A0 | Result<A0, E0>,
        f1: (a: A0) => A1 | Result<A1, E1>,
        f2: (a: A1) => A2 | Result<A2, E2>,
        f3: (a: A2) => A3 | Result<A3, E3>,
        f4: (a: A3) => A4 | Result<A4, E4>,
        f5: (a: A4) => A5 | Result<A5, E5>,
    ): (arg: I) => Result<A5, E0 | E1 | E2 | E3 | E4 | E5>;
    <I, A0, A1, A2, A3, A4, A5, A6, E0, E1, E2, E3, E4, E5, E6>(
        f0: (a: I) => A0 | Result<A0, E0>,
        f1: (a: A0) => A1 | Result<A1, E1>,
        f2: (a: A1) => A2 | Result<A2, E2>,
        f3: (a: A2) => A3 | Result<A3, E3>,
        f4: (a: A3) => A4 | Result<A4, E4>,
        f5: (a: A4) => A5 | Result<A5, E5>,
        f6: (a: A5) => A6 | Result<A6, E6>,
    ): (arg: I) => Result<A6, E0 | E1 | E2 | E3 | E4 | E5 | E6>;
    <I, A0, A1, A2, A3, A4, A5, A6, A7, E0, E1, E2, E3, E4, E5, E6, E7>(
        f0: (a: I) => A0 | Result<A0, E0>,
        f1: (a: A0) => A1 | Result<A1, E1>,
        f2: (a: A1) => A2 | Result<A2, E2>,
        f3: (a: A2) => A3 | Result<A3, E3>,
        f4: (a: A3) => A4 | Result<A4, E4>,
        f5: (a: A4) => A5 | Result<A5, E5>,
        f6: (a: A5) => A6 | Result<A6, E6>,
        f7: (a: A6) => A7 | Result<A7, E7>,
    ): (arg: I) => Result<A7, E0 | E1 | E2 | E3 | E4 | E5 | E6 | E7>;
    <I, A0, A1, A2, A3, A4, A5, A6, A7, A8, E0, E1, E2, E3, E4, E5, E6, E7, E8>(
        f0: (a: I) => A0 | Result<A0, E0>,
        f1: (a: A0) => A1 | Result<A1, E1>,
        f2: (a: A1) => A2 | Result<A2, E2>,
        f3: (a: A2) => A3 | Result<A3, E3>,
        f4: (a: A3) => A4 | Result<A4, E4>,
        f5: (a: A4) => A5 | Result<A5, E5>,
        f6: (a: A5) => A6 | Result<A6, E6>,
        f7: (a: A6) => A7 | Result<A7, E7>,
        f8: (a: A7) => A8 | Result<A8, E8>,
    ): (arg: I) => Result<A8, E0 | E1 | E2 | E3 | E4 | E5 | E6 | E7 | E8>;
    <I, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, E0, E1, E2, E3, E4, E5, E6, E7, E8, E9>(
        f0: (a: I) => A0 | Result<A0, E0>,
        f1: (a: A0) => A1 | Result<A1, E1>,
        f2: (a: A1) => A2 | Result<A2, E2>,
        f3: (a: A2) => A3 | Result<A3, E3>,
        f4: (a: A3) => A4 | Result<A4, E4>,
        f5: (a: A4) => A5 | Result<A5, E5>,
        f6: (a: A5) => A6 | Result<A6, E6>,
        f7: (a: A6) => A7 | Result<A7, E7>,
        f8: (a: A7) => A8 | Result<A8, E8>,
        f9: (a: A8) => A9 | Result<A9, E9>,
    ): (arg: I) => Result<A9, E0 | E1 | E2 | E3 | E4 | E5 | E6 | E7 | E8 | E9>;
    <I, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, E0, E1, E2, E3, E4, E5, E6, E7, E8, E9, E10>(
        f0: (a: I) => A0 | Result<A0, E0>,
        f1: (a: A0) => A1 | Result<A1, E1>,
        f2: (a: A1) => A2 | Result<A2, E2>,
        f3: (a: A2) => A3 | Result<A3, E3>,
        f4: (a: A3) => A4 | Result<A4, E4>,
        f5: (a: A4) => A5 | Result<A5, E5>,
        f6: (a: A5) => A6 | Result<A6, E6>,
        f7: (a: A6) => A7 | Result<A7, E7>,
        f8: (a: A7) => A8 | Result<A8, E8>,
        f9: (a: A8) => A9 | Result<A9, E9>,
        f10: (a: A9) => A10 | Result<A10, E10>,
    ): (arg: I) => Result<A10, E0 | E1 | E2 | E3 | E4 | E5 | E6 | E7 | E8 | E9 | E10>;
    <I, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, E0, E1, E2, E3, E4, E5, E6, E7, E8, E9, E10, E11>(
        f0: (a: I) => A0 | Result<A0, E0>,
        f1: (a: A0) => A1 | Result<A1, E1>,
        f2: (a: A1) => A2 | Result<A2, E2>,
        f3: (a: A2) => A3 | Result<A3, E3>,
        f4: (a: A3) => A4 | Result<A4, E4>,
        f5: (a: A4) => A5 | Result<A5, E5>,
        f6: (a: A5) => A6 | Result<A6, E6>,
        f7: (a: A6) => A7 | Result<A7, E7>,
        f8: (a: A7) => A8 | Result<A8, E8>,
        f9: (a: A8) => A9 | Result<A9, E9>,
        f10: (a: A9) => A10 | Result<A10, E10>,
        f11: (a: A10) => A11 | Result<A11, E11>,
    ): (arg: I) => Result<A11, E0 | E1 | E2 | E3 | E4 | E5 | E6 | E7 | E8 | E9 | E10 | E11>;
} = (...fns: Array<(arg: unknown) => unknown>) => (arg: any): any => (
    fns.reduce((acc, f) => map(f as any, up(acc)), up(arg))
);
/* eslint-enable max-len */
