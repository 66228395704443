import { Dispatch } from 'redux';

export type MacroEvalOptions = {
    unescapeStringLiterals?: boolean,
    escapeHtml?: boolean,
    eager?: boolean,
    htmlLinebreaks?: boolean,
};

export type MacroCallsiteMetadata = {
    componentId: string | null,
    formId: string | null,
};

export type MacroScopeContext = {
    callsiteMetadata?: MacroCallsiteMetadata,
    dispatch?: Dispatch,
};

export type MacroEvalContext = {
    evalOptions: MacroEvalOptions,
};

export const defaultMacroOptions = Object.freeze({
    escapeHtml: false,
    eager: true,
});
