import * as React from 'react';
import styled from 'styled-components';

const Button = styled.button<{ active: boolean }>`
    color: ${(props): string => (props.active ? '#0087cb' : 'inherit')};
    text-decoration: ${(props): string => (props.active ? 'underline' : '')};
    letter-spacing: 0.5px;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    background-color: transparent;
`;

type ScaleButtonProps = {
    className: string;
    active: boolean;
    children: string;
    onClick: () => void;
};

const ScaleButton = ({
    className,
    active,
    children,
    onClick,
}: ScaleButtonProps): React.ReactElement => (
    <Button
        className={className}
        onClick={onClick}
        active={active}
    >
        {children}
    </Button>
);

export default ScaleButton;
