import { eventChannel, SagaIterator } from 'redux-saga';
import { put, takeLatest } from 'redux-saga/effects';
import { updateLocation } from './reducer';

function* updateLocationSaga(): SagaIterator {
    yield put(updateLocation());
}

export function* saga(): SagaIterator {
    const urlChange = eventChannel((emitter): () => void => {
        window.addEventListener('locationchange', emitter);
        window.addEventListener('hashchange', emitter);
        return (): void => {
            window.removeEventListener('locationchange', emitter);
            window.removeEventListener('hashchange', emitter);
        };
    });

    yield takeLatest(urlChange, updateLocationSaga);
}
