import * as R from 'ramda';
import React from 'react';
import {
    SectionOptions,
    SectionComponentLists,
} from '../store/processes/studioFile/componentConfigs/section';
import { assignIfTruthy } from '../utils/object';
import ComponentList from './shared/componentList';
import { ComponentProps } from './shared/types';

export const mapOption = (options: SectionOptions, key: string): Record<string, string> => {
    if (key === 'cssClass') {
        // This is a special case and not mapped to a CSS attribute.
        return {};
    }

    if (key === 'textColor') {
        return {
            color: options[key],
        } as Record<string, string>;
    }

    if (key === 'display' && options.display === 'flex') {
        return {
            display: 'flex',
            ...(R.filter(
                (v: string | undefined) => !!v,
                options.flexOptions ?? {},
            ) as Record<string, string>),
        };
    }

    if (key === 'flexChild' && options.flexChild === 'true') {
        return R.filter(
            (v: string | undefined) => !!v,
            options.flexChildOptions ?? {},
        ) as Record<string, string>;
    }

    if (key === 'borderWidth') {
        return {
            borderWidth: options.borderWidth,
            borderStyle: 'solid',
        } as Record<string, string>;
    }

    return assignIfTruthy(key, options[key as keyof SectionOptions] as string);
};

export const getSectionStyles = (options: SectionOptions) : React.CSSProperties => {
    const styles = Object.keys(options).map((key) => (mapOption(options, key)));
    return Object.assign({ overflow: 'hidden' }, ...styles);
};

type SectionProps = ComponentProps<SectionOptions, SectionComponentLists>;

const Section = ({
    options,
    componentLists: { content },
}: SectionProps): React.ReactElement | null => (
    <div style={getSectionStyles(options)} className={options.cssClass}>
        <ComponentList id={content} />
    </div>
);

export default Section;
