const toRadians = (v: number): number => (v * Math.PI) / 180;

export const coordinateDist = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
): number => {
    const R = 6371e3; // meters
    const phi1 = toRadians(lat1);
    const phi2 = toRadians(lat2);
    const deltaphi = toRadians(lat2 - lat1);
    const deltalambda = toRadians(lon2 - lon1);

    const a = Math.sin(deltaphi / 2) * Math.sin(deltaphi / 2)
            + Math.cos(phi1) * Math.cos(phi2)
            * Math.sin(deltalambda / 2) * Math.sin(deltalambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
};
