import * as R from 'ramda';
import { State } from '../index';

export const DEVICE_VAR = 'device' as const;
export const OBJECT_VAR = 'object' as const;

type VarType =
    | typeof DEVICE_VAR
    | typeof OBJECT_VAR;

const varLens = (type: VarType, key: string): R.Lens => (
    R.lensPath([type, key])
);

export const OP_SET = 'set' as const;
export const OP_UPDATE = 'update' as const;
export const OP_CLEAR = 'clear' as const;
export const OP_INCREMENT = 'increment' as const;
export const OP_DECREMENT = 'decrement' as const;

export type VariableOperation =
    | typeof OP_SET
    | typeof OP_UPDATE
    | typeof OP_CLEAR
    | typeof OP_INCREMENT
    | typeof OP_DECREMENT;

export const VAR_LOAD = 'VARIABLE/LOAD' as const;
export const VAR_SET = 'VARIABLE/SET' as const;
export const VARS_UPDATE = 'VARIABLES/UPDATE' as const;
export const VAR_CLEAR = 'VARIABLE/CLEAR' as const;
export const VAR_INCREMENT = 'VARIABLE/INCREMENT' as const;
export const VAR_DECREMENT = 'VARIABLE/DECREMENT' as const;

type VariableOperationActionType =
    | typeof VAR_SET
    | typeof VARS_UPDATE
    | typeof VAR_CLEAR
    | typeof VAR_INCREMENT
    | typeof VAR_DECREMENT;

const OP_ACTION_MAP: Record<VariableOperation, VariableOperationActionType> = {
    [OP_SET]: VAR_SET,
    [OP_UPDATE]: VARS_UPDATE,
    [OP_CLEAR]: VAR_CLEAR,
    [OP_INCREMENT]: VAR_INCREMENT,
    [OP_DECREMENT]: VAR_DECREMENT,
};

const operateAsNumber = (
    op: (n: number) => number,
): ((s: string) => string
) => (
    R.pipe(
        (v: string) => parseInt(v, 10),
        R.defaultTo(0),
        op,
        R.toString,
    )
);

export const variableReducer = (
    state: string | undefined,
    { type, opValue }: VariableAction,
): string | undefined => {
    switch (type) {
        case VAR_SET:
            return opValue;
        case VAR_INCREMENT:
            return operateAsNumber(
                (n) => n + parseInt(opValue, 10),
            )(state ?? 'NaN');
        case VAR_DECREMENT:
            return operateAsNumber(
                (n) => n - parseInt(opValue, 10),
            )(state ?? 'NaN');
        default:
            return state;
    }
};

export type RawVariableOperationProps = {
    varOperation: VariableOperation;
    varName: string;
    opValue: string;
};

export type RawVariablesOperationProps = {
    varOperation: typeof OP_UPDATE;
    updates: Record<string, string>;
    resolve: (value: void | PromiseLike<void>) => void;
    reject: (r?: any) => void;
};

type VariableAction = {
    type: VariableOperationActionType;
    varType: VarType;
    varName: string;
    opValue: string;
};

type VariablesAction = {
    type: VariableOperationActionType;
    varType: VarType;
    updates: Record<string, string | undefined | null>;
};

export const rawObjectVariableOperation = ({
    varOperation,
    varName,
    opValue,
}: RawVariableOperationProps): VariableAction => ({
    type: OP_ACTION_MAP[varOperation],
    varType: OBJECT_VAR,
    varName,
    opValue,
});

export const rawObjectVariablesOperation = ({
    varOperation,
    updates,
}: RawVariablesOperationProps): VariablesAction => ({
    type: OP_ACTION_MAP[varOperation],
    varType: OBJECT_VAR,
    updates,
});

export const rawLocalVariableOperation = ({
    varOperation,
    varName,
    opValue,
}: RawVariableOperationProps): VariableAction => ({
    type: OP_ACTION_MAP[varOperation],
    varType: DEVICE_VAR,
    varName,
    opValue,
});

type VarLoadAction = {
    type: typeof VAR_LOAD;
    varType: VarType;
    vars: Record<string, string>;
};

export const loadVariables = (
    varType: VarType,
    vars: Record<string, string>,
): VarLoadAction => ({
    type: VAR_LOAD,
    varType,
    vars,
});

type Actions = VariableAction | VariablesAction | VarLoadAction;

type LocalState = {
    device: Record<string, string>;
    object: Record<string, string>;
};

const initialState = {
    device: {},
    object: {},
};

const reducer = (
    state: LocalState = initialState,
    action: Actions,
): LocalState => {
    switch (action.type) {
        case VAR_LOAD:
            return {
                ...state,
                [action.varType]: action.vars,
            };
        case VAR_CLEAR: {
            const { [(action as VariableAction).varName]: _, ...vars } = state[action.varType];
            return {
                ...state,
                [action.varType]: vars,
            };
        }
        case VAR_SET:
        case VAR_INCREMENT:
        case VAR_DECREMENT: {
            const vLens = varLens(action.varType, (action as VariableAction).varName);
            return R.over(
                vLens,
                (s) => variableReducer(s, action as VariableAction),
                state,
            );
        }
        case VARS_UPDATE: {
            return {
                ...state,
                [action.varType]: {
                    ...state[action.varType],
                    ...(action as VariablesAction).updates,
                },
            };
        }
        default:
            return state;
    }
};

export const getVariablesRoot = (state: State): LocalState => state.variables;
export const getVariablesForType = (type: VarType) => (
    (state: State): Record<string, string> => (
        getVariablesRoot(state)[type]
    )
);
export const getVariable = (
    type: VarType,
    name: string,
) => (state: State): string | undefined => (
    getVariablesRoot(state)[type][name]
);
export const getLocalVariable = (name: string) => (state: State): string | undefined => (
    getVariable(DEVICE_VAR, name)(state)
);

export default reducer;
