import * as React from 'react';
import styled, {
    StyledComponent,
    DefaultTheme,
} from 'styled-components';
import { useStore, useSelector } from 'react-redux';
import {
    ButtonOptions,
} from '../store/processes/studioFile/componentConfigs/button';
import { isDisabled as getIsDisabled } from '../store/processes/buttons';
import {
    getForm,
    submitDisabled,
} from '../store/processes/forms';
import * as RuntimePromise from '../macros/promise';
import { useMacroScope, useMacro } from './shared/macroHelpers';
import { ComponentProps } from './shared/types';
import {
    ACTION_TYPE_LINK,
    ActionOptions,
} from '../store/processes/studioFile/componentConfigs/shared/action';
import { handleLinkAction } from './shared/utils';
import { State } from '../store/processes';

const ButtonBody = <T extends 'a' | 'button'>(el: T): (
    StyledComponent<T, DefaultTheme, object, never>
) => (
    styled[el]`
        display: inline-block;
        position: relative;
        text-decoration: none;
        border-style: solid;
        box-sizing: border-box;
        line-height: 1.3;
        letter-spacing: normal;

        &:active {
            top: 2px;
        }

        &:disabled {
            opacity: 0.6;
        }
    ` as any
);

const ButtonBodyAnchor = ButtonBody('a');
const ButtonBodyButton = ButtonBody('button');

const componentName = 'Button';

const createBodyStyles = (options: ButtonOptions): React.CSSProperties => ({
    minWidth: options.width,
    padding: options.padding,
    borderWidth: options.borderWidth,
    borderRadius: options.borderRadius,
    fontSize: options.fontSize,
    fontWeight: options.fontWeight as any,
    textAlign: options.textAlign,
    color: options.textColor,
    backgroundColor: options.backgroundColor,
    borderColor: options.borderColor,
    outline: 'none',
});

const createContainerStyles = (
    options: ButtonOptions,
): React.CSSProperties => ({
    margin: options.margin,
    textAlign: options.buttonAlign,
});

type ButtonProps = ComponentProps<ButtonOptions>;

const Button = ({
    id,
    typeId,
    options,
    macroContext,
}: ButtonProps): React.ReactElement | null => {
    const macroScope = useMacroScope(macroContext);
    const buttonText = RuntimePromise.unwrapOr(
        null,
        useMacro(
            options.buttonText ?? '',
            macroScope,
            {
                componentName,
                optionName: 'button text',
            },
        ),
    );
    const action = options as ActionOptions;
    const link = RuntimePromise.unwrapOr(
        '',
        useMacro(
            action.action === ACTION_TYPE_LINK ? action.link : '',
            macroScope,
            {
                componentName,
                optionName: 'link',
            },
        ),
    );

    const form = useSelector((state: State) => getForm(state, macroContext.formId ?? '-1'));
    const isDisabled = useSelector(getIsDisabled(id)) || (!!form && submitDisabled(form));

    const store = useStore();
    const buttonFireAction = React.useMemo(
        () => handleLinkAction({
            componentName,
            isDisabled,
            options,
            id,
            typeId,
            dispatch: store.dispatch,
            formId: macroContext.formId,
        }),
        [isDisabled, store, options, id, typeId, macroContext.formId],
    );

    const bodyStyle = React.useMemo(
        () => createBodyStyles(options),
        [options],
    );

    const containerStyle = React.useMemo(
        () => createContainerStyles(options),
        [options],
    );

    if (buttonText === null) {
        return null;
    }

    return (
        <div style={containerStyle} data-type-id={typeId}>
            {
                options.action === ACTION_TYPE_LINK
                    ? (
                        <ButtonBodyAnchor
                            href={link}
                            style={bodyStyle}
                            onClick={buttonFireAction}
                            target={
                                (options.action === ACTION_TYPE_LINK) && !!options.targetNewTab
                                    ? '_blank'
                                    : undefined
                            }
                        >
                            {buttonText}
                        </ButtonBodyAnchor>
                    )
                    : (
                        <ButtonBodyButton
                            type="button"
                            style={bodyStyle}
                            onClick={buttonFireAction}
                            disabled={isDisabled}
                        >
                            {buttonText}
                        </ButtonBodyButton>
                    )
            }
        </div>
    );
};

export default Button;
