export const VALIDATION_REQUIRED = 'required' as const;

export type ValidationType = typeof VALIDATION_REQUIRED;

export type Validation = {
    on: boolean;
    type: ValidationType;
};

// Due to a bug this can be an array or an object
export type Validations = Array<Validation> | Record<string, Validation>;

export const validationsArray = (validations: Validations): Array<Validation> => (
    Array.isArray(validations)
        ? validations
        : Object.values(validations)
);
