import * as R from 'ramda';
import { SagaIterator } from 'redux-saga';
import { call, take } from 'redux-saga/effects';
import {
    ActionAnalyticsOptions,
    ANALYTICS_NONE,
    ANALYTICS_CUSTOM,
    ANALYTICS_DEFAULT,
} from '../studioFile/componentConfigs/shared/analytics';
import { makeMacroScope, runMacroString, MacroScope } from '../../../macros';
import {
    getAnalyticsIsOn as rawGetAnalyticsIsOn,
    getStudioFileAttribute,
    getStudioFileId,
    Dimension,
    AnalyticsIds,
} from '../studioFile/reducer';
import * as RuntimePromise from '../../../macros/promise';
import { select, withDelayedDispatch } from '../../../utils/redux';
import { injectGa } from '../../../utils/scripts';
import gtag from '../../../functions/gtag';
import { MetadataOptions } from '../../../components/shared/meta';
import { getCookieConsentEnabled, getCookieConsentAccepted } from '../cookieConsent';
import { State } from '../index';

export type { ActionAnalyticsOptions };

export {
    ANALYTICS_NONE,
    ANALYTICS_CUSTOM,
    ANALYTICS_DEFAULT,
};

type GAOptions = {
    eventCategory?: string;
    eventLabel?: string;
};

const getAnalyticsIsOn = (state: State): boolean | null => {
    const enabled = getCookieConsentEnabled(state);
    const isOn = rawGetAnalyticsIsOn(state);
    const accepted = getCookieConsentAccepted(state);
    return isOn && (!enabled || accepted);
};

function* createDimensions(scope: MacroScope): SagaIterator<Record<string, string>> {
    const gaDimensions: Array<Dimension> = (
        (yield select(getStudioFileAttribute('gaDimensions'))) ?? []
    );
    return R.mergeAll(gaDimensions.map(({ key, value }) => {
        const macroPromise = runMacroString(value, scope);
        RuntimePromise.logUnresolved(macroPromise, `analytics ${key}`);
        return { [key]: RuntimePromise.unwrapOr('', macroPromise) };
    }));
}

const renderConfig = (
    scope: MacroScope,
    config: GAOptions,
): GAOptions => R.evolve({
    eventCategory: (s: string): string => (
        RuntimePromise.unwrapOr('', runMacroString(s, scope))
    ),
    eventLabel: (s: string): string => (
        RuntimePromise.unwrapOr('', runMacroString(s, scope))
    ),
})(config);

export function* setupAnalytics(): SagaIterator<void> {
    let run: ReturnType<typeof getAnalyticsIsOn> = yield select(getAnalyticsIsOn);

    const studioFileId: string = yield select(getStudioFileId);

    while (run === null) {
        yield take('*');
        // The user may have linked to a new studio file
        // instead of responding to cookie consent
        if (studioFileId !== (yield select(getStudioFileId))) {
            return;
        }
        run = yield select(getAnalyticsIsOn);
    }

    if (run !== true) { return; }

    const dimensions: Record<string, string> = yield call(
        withDelayedDispatch,
        function* render(dispatch): SagaIterator {
            const scope: MacroScope = yield select(makeMacroScope, {
                callsiteMetadata: {
                    componentId: null,
                    formId: null,
                },
                dispatch,
            });

            return yield call(createDimensions, scope);
        },
    );

    const trackingId: AnalyticsIds = yield select(getStudioFileAttribute('trackingId'));
    yield call(gtag.config, trackingId, dimensions);
    yield call(injectGa);
}

function* handleAnalytics(
    name: string,
    analytics: ActionAnalyticsOptions | null | undefined,
    defaults: GAOptions,
    metadata: MetadataOptions,
): SagaIterator<void> {
    const run: ReturnType<typeof getAnalyticsIsOn> = yield select(getAnalyticsIsOn);
    const sendTo: AnalyticsIds = yield select(getStudioFileAttribute('trackingId'));
    if (!(run && sendTo)) { return; }

    yield call(
        withDelayedDispatch,
        function* render(dispatch): SagaIterator {
            const scope: MacroScope = yield select(makeMacroScope, {
                callsiteMetadata: {
                    componentId: metadata.firingComponentId,
                    formId: metadata.formId,
                },
                dispatch,
            });

            switch (analytics?.type) {
                case 'custom': {
                    const dimensions = yield call(createDimensions, scope);
                    if (analytics?.customOptions?.eventName) {
                        const parameterArray = analytics.customOptions.eventParameters?.map(
                            ({ key, value }) => {
                                const macroValue = RuntimePromise.unwrapOr(
                                    '', runMacroString(value, scope),
                                );
                                return [key, macroValue];
                            },
                        ) ?? [];
                        const expectedEventParameters = Object.fromEntries(parameterArray);
                        yield call(
                            gtag.event,
                            RuntimePromise.unwrapOr(
                                '',
                                runMacroString(analytics.customOptions.eventName, scope),
                            ),
                            {
                                sendTo,
                                ...dimensions,
                                ...expectedEventParameters,
                            },
                        );
                    } else if (analytics?.customOptions?.eventAction) {
                        yield call(
                            gtag.event,
                            RuntimePromise.unwrapOr(
                                '',
                                runMacroString(analytics.customOptions.eventAction, scope),
                            ),
                            {
                                sendTo,
                                ...dimensions,
                                ...renderConfig(scope, {
                                    eventCategory: analytics.customOptions.eventCategory,
                                    eventLabel: analytics.customOptions.eventLabel,
                                }),
                            },
                        );
                    }
                    break;
                }
                case 'none':
                    break;
                case 'default':
                default: {
                    const dimensions = yield call(createDimensions, scope);
                    yield call(
                        gtag.event,
                        RuntimePromise.unwrapOr('', runMacroString(name, scope)),
                        { sendTo, ...defaults, ...dimensions },
                    );
                }
            }
        },
    );
}

export default handleAnalytics;
