import * as React from 'react';
import styled from 'styled-components';
import { useMacroScope, makeUseMacros } from './shared/macroHelpers';
import * as RuntimePromise from '../macros/promise';
import {
    InstagramOptions,
} from '../store/processes/studioFile/componentConfigs/instagram';
import { useEmbed } from '../functions/socialEmbed';
import { ComponentProps } from './shared/types';
import { useInstagram } from '../utils/scripts';

const InstagramContainer = styled.div`
    position: relative;
`;

const useEmbedFromFetch = (url: string | null): string | null => {
    const embed = useEmbed('instagram', url != null ? { url } : null);
    return embed?.html ?? null;
};

const INSTAGRAM_COMPONENT_NAME = 'Instagram';

type InstagramParams = ComponentProps<InstagramOptions>;

const useMacros = makeUseMacros(INSTAGRAM_COMPONENT_NAME, {
    url: {},
});

const useInstagramEmbed = (embed: string | null) => {
    const instagramLoaded = useInstagram();

    React.useEffect(
        () => {
            if (embed && instagramLoaded) {
                window.instgrm?.Embeds.process();
            }
        },
        [embed, instagramLoaded],
    );
};

const InstrgramWithCachedEmbed: React.FC<{ embed: string }> = ({ embed }) => {
    useInstagramEmbed(embed);

    return (
        <InstagramContainer
            dangerouslySetInnerHTML={{
                /* eslint-disable-next-line */
                __html: embed
            }}
        />
    );
};
const InstrgramWithoutCachedEmbed: React.FC<{ url: string | null }> = ({ url }) => {
    const embed = useEmbedFromFetch(url);
    useInstagramEmbed(embed);

    return embed
        ? (
            <InstagramContainer
                dangerouslySetInnerHTML={{
                    /* eslint-disable-next-line */
                    __html: embed,
                }}
            />
        )
        : null;
};

const Instagram = ({ options, macroContext }: InstagramParams): React.ReactElement | null => {
    const scope = useMacroScope(macroContext);
    const macros = useMacros(scope, options as Record<string, string>);
    const { cachedEmbed } = options;

    const { url } = RuntimePromise.unwrapOr(
        { url: null }, macros,
    );

    return cachedEmbed
        ? <InstrgramWithCachedEmbed embed={cachedEmbed} />
        : <InstrgramWithoutCachedEmbed url={url} />;
};

export default Instagram;
