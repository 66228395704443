import { useState, useEffect } from 'react';

const watchScriptResolution = (script: HTMLScriptElement) => (
    new Promise<void>((resolve, reject) => {
        document.body.appendChild(script);
        script.addEventListener('load', () => { resolve(); });
        script.addEventListener('error', () => { reject(); });
    })
);

const injectScript = (id: string, src: string): Promise<void> => {
    let script = document.getElementById(id) as HTMLScriptElement;

    if (script) {
        if (document.readyState === 'complete') { return Promise.resolve(); }

        return new Promise((resolve) => {
            document.addEventListener('readystatechange', () => {
                if (document.readyState === 'complete') {
                    resolve();
                }
            });
        });
    }

    script = document.createElement('script');
    script.id = id;
    script.src = src;

    return watchScriptResolution(script);
};

export const useScript = (injector: () => Promise<void>): boolean | null => {
    const [injected, setInjected] = useState<boolean | null>(null);

    useEffect(() => {
        let canceled = false;
        (async () => {
            try {
                if (canceled) { return; }
                await injector();
                setInjected(true);
            } catch (e) {
                setInjected(false);
            }
        })();
        return () => {
            canceled = true;
        };
    // We only need to run this once
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return injected;
};

export const injectGa = () => injectScript(
    'gtag-script',
    'https://www.googletagmanager.com/gtag/js',
);

export const useTwitter = () => useScript(() => injectScript(
    'twitter-wjs',
    'https://platform.twitter.com/widgets.js',
));

export const useInstagram = () => useScript(() => injectScript(
    'instagram-script',
    'https://www.instagram.com/embed.js',
));
