import { SagaIterator } from 'redux-saga';
import { Action as ActionType } from 'redux';
import { put, takeEvery } from 'redux-saga/effects';
import { urlLink, experienceLink, none } from '../links';
import { formSubmit } from '../forms';
import { deviceVariableOperation, objectVariableOperation } from '../variables';
import {
    ActionOptions,
    ACTION_TYPE_LINK,
    ACTION_TYPE_EXPERIENCE_LINK,
    ACTION_TYPE_FORM_SUBMIT,
    ACTION_TYPE_LOCAL_VARIABLE,
    ACTION_TYPE_NONE,
    ACTION_TYPE_TAG_VARIABLE,
} from '../studioFile/componentConfigs/shared/action';
import { Metadata } from '../../../components/shared/meta';
import { getNavActionEnabled } from '../../../functions/previewMode';

export {
    ACTION_TYPE_LINK,
    ACTION_TYPE_EXPERIENCE_LINK,
    ACTION_TYPE_FORM_SUBMIT,
    ACTION_TYPE_LOCAL_VARIABLE,
    ACTION_TYPE_NONE,
    ACTION_TYPE_TAG_VARIABLE,
};

const FIRE_ACTION = 'ACTIONS/FIRE' as const;

export type ComponentMetadata = Metadata['meta'];
export type ActionAnalytics = ActionOptions['actionAnalytics'];

export type ActionWithMetadata = (
    & ActionOptions
    & Metadata
);

type FireAction = {
    type: typeof FIRE_ACTION;
    fire: ActionWithMetadata;
};

export const fireAction = (fire: ActionWithMetadata): FireAction => ({
    type: FIRE_ACTION,
    fire,
});

// maps function to action
const ActionDictionary: Record<string, (fire: any) => ActionType> = {
    ...((__IS_PREVIEW__ && !getNavActionEnabled())
        ? {}
        : {
            [ACTION_TYPE_LINK]: urlLink,
            [ACTION_TYPE_EXPERIENCE_LINK]: experienceLink,
        }),
    [ACTION_TYPE_FORM_SUBMIT]: formSubmit,
    [ACTION_TYPE_LOCAL_VARIABLE]: deviceVariableOperation,
    [ACTION_TYPE_TAG_VARIABLE]: objectVariableOperation,
};

function* fireActionSaga({ fire }: FireAction): SagaIterator<void> {
    if (fire.action in ActionDictionary) {
        const actionWith = ActionDictionary[fire.action];
        yield put(actionWith(fire as any));
        return;
    }
    yield put(none(fire as any));
}

function* saga(): SagaIterator<void> {
    yield takeEvery(FIRE_ACTION, fireActionSaga);
}

export default saga;
