/* eslint-disable max-len */
import React from 'react';

const Rain = (): React.ReactElement => (
    <svg aria-label="rain icon" width="128" height="128" viewBox="0 0 128 128">
        <defs>
            <clipPath id="clip-path-rain">
                <rect id="Rectangle_7" data-name="Rectangle 7" width="69.226" height="64.111" fill="none" />
            </clipPath>
            <clipPath id="clip-Rain">
                <rect width="128" height="128" />
            </clipPath>
        </defs>
        <g id="Rain" clipPath="url(#clip-Rain)">
            <rect width="128" height="128" fill="#fff" />
            <g id="Group_14" data-name="Group 14" transform="translate(29 32)">
                <g id="Group_13" data-name="Group 13" transform="translate(0 0)" clipPath="url(#clip-path-rain)">
                    <path id="Path_43" data-name="Path 43" d="M54.731,16.108a17.961,17.961,0,0,0-34.106-5.817,17.963,17.963,0,1,0-2.607,35.728l36.323-.089a14.914,14.914,0,0,0,.391-29.822" transform="translate(0 0)" fill="#e6e7ea" />
                    <path id="Path_44" data-name="Path 44" d="M24.463,72.958a1.7,1.7,0,0,1-1.7-1.694l-.027-10.959a1.7,1.7,0,1,1,3.4-.008l.027,10.959a1.7,1.7,0,0,1-1.694,1.7" transform="translate(-3.432 -8.846)" fill="#1a65ef" />
                    <path id="Path_45" data-name="Path 45" d="M45.926,72.9a1.7,1.7,0,0,1-1.7-1.694L44.2,60.252a1.7,1.7,0,1,1,3.4-.008L47.619,71.2a1.7,1.7,0,0,1-1.694,1.7" transform="translate(-6.672 -8.838)" fill="#1a65ef" />
                    <path id="Path_46" data-name="Path 46" d="M35.181,67.47a1.7,1.7,0,0,1-1.7-1.694l-.014-5.5a1.7,1.7,0,0,1,3.4-.008l.014,5.5a1.7,1.7,0,0,1-1.694,1.7" transform="translate(-5.052 -8.842)" fill="#1a65ef" />
                    <path id="Path_47" data-name="Path 47" d="M56.644,67.417a1.7,1.7,0,0,1-1.7-1.694l-.014-5.5a1.7,1.7,0,1,1,3.4-.008l.014,5.5a1.7,1.7,0,0,1-1.694,1.7" transform="translate(-8.292 -8.834)" fill="#1a65ef" />
                </g>
            </g>
        </g>
    </svg>
);

export default Rain;
