export const EVENT_TYPE_NONE = 'none' as const;
export const EVENT_TYPE_FORM_SUBMIT = 'formSubmit' as const;
export const EVENT_TYPE_FORM_ERROR = 'formError' as const;
export const EVENT_TYPE_VARIABLE_CONDITION = 'variableCondition' as const;
export const EVENT_TYPE_TAG_VARIABLE_CONDITION = (
    'tagVariableCondition' as const
);
export const EVENT_TYPE_ADVANCED = (
    'advanced' as const
);

export type EventType =
    | typeof EVENT_TYPE_NONE
    | typeof EVENT_TYPE_FORM_SUBMIT
    | typeof EVENT_TYPE_FORM_ERROR
    | typeof EVENT_TYPE_VARIABLE_CONDITION
    | typeof EVENT_TYPE_TAG_VARIABLE_CONDITION
    | typeof EVENT_TYPE_ADVANCED;

export type BaseEvent<T extends EventType> = {
    type: T;
};

export type EventNone = BaseEvent<typeof EVENT_TYPE_NONE>;

export const FORM_SUBMIT_WHEN_BEFORE = 'before' as const;
export const FORM_SUBMIT_WHEN_AFTER = 'after' as const;

export type FormSubmitWhen =
    | typeof FORM_SUBMIT_WHEN_BEFORE
    | typeof FORM_SUBMIT_WHEN_AFTER;

export type EventFormSubmit = (
    BaseEvent<typeof EVENT_TYPE_FORM_SUBMIT>
    & {
        when: FormSubmitWhen;
    }
);

export type EventFormError = BaseEvent<typeof EVENT_TYPE_FORM_ERROR>;

export const CONDITIONAL_TYPE_EQL = 'eql' as const;
export const CONDITIONAL_TYPE_NEQL = 'neql' as const;
export const CONDITIONAL_TYPE_GT = 'gt' as const;
export const CONDITIONAL_TYPE_LS = 'ls' as const;
export const CONDITIONAL_TYPE_GTE = 'gte' as const;
export const CONDITIONAL_TYPE_LTE = 'lte' as const;

export type ConditionalType =
    | typeof CONDITIONAL_TYPE_EQL
    | typeof CONDITIONAL_TYPE_NEQL
    | typeof CONDITIONAL_TYPE_GT
    | typeof CONDITIONAL_TYPE_LS
    | typeof CONDITIONAL_TYPE_GTE
    | typeof CONDITIONAL_TYPE_LTE;

export type EventVariableCondition = (
    BaseEvent<typeof EVENT_TYPE_VARIABLE_CONDITION>
    & {
        varName: string;
        conditionalType: ConditionalType;
        conditionalValue: string;
    }
);

export type EventTagVariableCondition = (
    BaseEvent<typeof EVENT_TYPE_TAG_VARIABLE_CONDITION>
    & {
        varName: string;
        conditionalType: ConditionalType;
        conditionalValue: string;
    }
);

export type EventAdvanced = (
    BaseEvent<typeof EVENT_TYPE_ADVANCED>
    & {
        valOne: string;
        valTwo: string;
        conditionalType: (
            | typeof CONDITIONAL_TYPE_EQL
            | typeof CONDITIONAL_TYPE_NEQL
        );
    }
);

export type Event =
    | EventNone
    | EventFormSubmit
    | EventFormError
    | EventVariableCondition
    | EventTagVariableCondition
    | EventAdvanced;

export type EventOptions = {
    events: readonly [Event];
};

export type EventComponentLists = {
    content: string;
};

type EventConfig = {
    options: EventOptions;
    componentLists: EventComponentLists;
};

export default EventConfig;
