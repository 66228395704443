export const SHOW_BEFORE = 'before' as const;
export const SHOW_AFTER = 'after' as const;

export type ShowType = typeof SHOW_BEFORE | typeof SHOW_AFTER;

export type DelayOptions = {
    show?: ShowType;
    delay: string;
};

export type DelayComponentLists = {
    content: string;
};

type DelayConfig = {
    options: DelayOptions;
    componentLists: DelayComponentLists;
};

export default DelayConfig;
