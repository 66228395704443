import * as React from 'react';
import styled from 'styled-components';
import { useStore, useSelector } from 'react-redux';
import * as RuntimePromise from '../macros/promise';
import { isDisabled as getIsDisabled } from '../store/processes/buttons';
import {
    getAssetAssociations,
    urlForFile,
} from '../store/processes/studioFile';
import {
    ACTION_TYPE_LINK,
} from '../store/processes/studioFile/componentConfigs/shared/action';
import { useMacro, useMacroScope } from './shared/macroHelpers';
import {
    ImageOptions,
    IMAGE_SOURCE_NONE,
} from '../store/processes/studioFile/componentConfigs/image';
import { ComponentProps } from './shared/types';
import { handleLinkAction } from './shared/utils';


const ImageBody = styled.img`
    width: 100%;
    border-radius:
        ${(props): string | null => (
            props.theme.showWidget
                ? props.theme.componentBorderRadius
                : null
        )};
    margin:
        ${(props): string | null => (
            props.theme.showWidget
                ? props.theme.componentMargin
                : null
        )};
`;

const Container = ({
    actionType,
    link,
    targetNewTab,
    children,
    onClick,
}: React.PropsWithChildren<{
    link: string;
    targetNewTab: string,
    actionType: string;
    onClick: (e: React.MouseEvent) => void;
}>): React.ReactElement => (
    actionType === ACTION_TYPE_LINK
        ? (
            <a href={link} onClick={onClick} target={targetNewTab || undefined}>
                {children}
            </a>
        )
        : <>{children}</>
);

const componentName = 'Image';

type ImageProps = ComponentProps<ImageOptions>;

const Image = ({
    id,
    typeId,
    options,
    macroContext,
}: ImageProps): React.ReactElement | null => {
    const assetAssociations = useSelector(getAssetAssociations);
    const scope = useMacroScope(macroContext);

    const renderedRawSrcStringPromise = useMacro(
        typeof options.src === 'string' ? options.src : '',
        scope,
        {
            componentName,
            optionName: 'url',
        },
    );
    const chooseRawSrcPromise = (
        typeof options.src === 'string'
            ? renderedRawSrcStringPromise
            : RuntimePromise.Success(options.src ?? '')
    );
    const srcPromise = RuntimePromise.map(
        (src) => urlForFile(src, assetAssociations),
        chooseRawSrcPromise,
    );
    const link = RuntimePromise.unwrapOr(
        '',
        useMacro(
            options.action === ACTION_TYPE_LINK ? options.link : '',
            scope,
            {
                componentName,
                optionName: 'link',
            },
        ),
    );

    const store = useStore();

    const isDisabled = useSelector(getIsDisabled(id));

    const imageFireAction = React.useMemo(
        () => handleLinkAction({
            componentName,
            isDisabled,
            options,
            id,
            typeId,
            dispatch: store.dispatch,
            formId: macroContext.formId,
        }),
        [isDisabled, store, options, id, typeId, macroContext.formId],
    );

    if (options.source === IMAGE_SOURCE_NONE) {
        return null;
    }

    return (
        RuntimePromise.isSuccess(srcPromise)
            ? (
                <Container
                    actionType={options.action}
                    link={link}
                    targetNewTab={
                        (options.action === ACTION_TYPE_LINK) && !!options.targetNewTab
                            ? '_blank'
                            : ''
                    }
                    onClick={imageFireAction}
                >
                    <ImageBody
                        src={RuntimePromise.unwrap(srcPromise)}
                        onClick={
                            options.action === ACTION_TYPE_LINK
                                ? undefined
                                : imageFireAction
                        }
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...(options.alt ? { alt: options.alt } : {})}
                    />
                </Container>
            )
            : null
    );
};

export default Image;
