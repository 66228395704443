import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    useMacroScope,
    useMacro,
    MacroContext,
    RuntimePromise,
} from '../shared/macroHelpers';
import {
    CookieBannerThemeProvider,
    BannerContainer,
    CookieBannerButtons,
    CookieBannerButton,
    ContentContainer,
    CookieBannerSpacer,
    ReopenButton,
    PrimaryHeader,
    SwitchLabel,
    SecondaryHeaderSection,
    MoreOptions,
    UpdateSettings,
    SwitchValue,
} from './styles';
import { cleanLink } from '../../functions/clean';
import {
    CookieBannerOptions,
} from '../../store/processes/studioFile/componentConfigs/cookieBanner';
import { useTranslations } from '../../translations';
import { COOKIE_CONSENT_KEY, getCookieConsentAccepted } from '../../store/processes/cookieConsent';
import {
    deviceVariableOperation,
    DeviceVariableOperationAction,
    OP_SET,
} from '../../store/processes/variables';
import { ANALYTICS_NONE, ANALYTICS_CUSTOM } from '../../store/processes/analytics';
import Switch, {
    SwitchContainer,
} from './switch';

// This value should be slightly longer than the
// length of the css transition. After this delay
// the element can be removed from the page.
const TRANSITION_LENGTH = 500;

const componentName = 'Cookie Banner';

const setConsent = (
    id: string,
    typeId: string,
    opValue: boolean,
): DeviceVariableOperationAction => (
    deviceVariableOperation({
        varOperation: OP_SET,
        opValue: String(opValue),
        actionAnalytics: (
            opValue
                ? {
                    type: ANALYTICS_CUSTOM,
                    customOptions: {
                        eventCategory: 'Legal Compliance',
                        eventAction: 'Cookie Consent',
                        eventLabel: 'Accept',
                    },
                }
                : { type: ANALYTICS_NONE }
        ),
        varName: COOKIE_CONSENT_KEY,
        meta: {
            component: componentName,
            formId: null,
            firingComponentId: id,
            firingComponentType: {
                name: componentName,
                id: typeId,
            },
        },
    })
);

type BasicContentProps = {
    disabled: boolean;
    setConsent: (v: boolean) => void;
    triggerMoreOptions: (v: boolean) => void;
};
const BasicContent = ({
    disabled,
    setConsent,
    triggerMoreOptions,
}: BasicContentProps): React.ReactElement => {
    const t = useTranslations();

    const acceptConsentCallback = React.useCallback(
        () => { setConsent(true); },
        [setConsent],
    );

    const declineConsentCallback = React.useCallback(
        () => { setConsent(false); },
        [setConsent],
    );

    const moreOptionsCallback = React.useCallback(
        () => { triggerMoreOptions(true); },
        [triggerMoreOptions],
    );

    return (
        <>
            <CookieBannerButtons>
                <CookieBannerButton
                    disabled={disabled}
                    primary
                    onClick={declineConsentCallback}
                >
                    {t('cookieConsentDecline')}
                </CookieBannerButton>
                <CookieBannerSpacer />
                <CookieBannerButton
                    disabled={disabled}
                    primary
                    onClick={acceptConsentCallback}
                >
                    {t('cookieConsentAccept')}
                </CookieBannerButton>
            </CookieBannerButtons>
            <MoreOptions
                onClick={moreOptionsCallback}
            >
                {t('cookieConsentMoreOptions')}
            </MoreOptions>
        </>
    );
};

type DetailedContentProps = {
    currentConsent: boolean | undefined | null;
    setConsent: (v: boolean) => void;
};
const DetailedContent = ({
    currentConsent,
    setConsent,
}: DetailedContentProps): React.ReactElement => {
    const t = useTranslations();

    const [willAcceptConsent, setWillAcceptConsent] = React.useState(currentConsent ?? false);

    const updateConsentFallback = React.useCallback(
        () => setConsent(willAcceptConsent),
        [setConsent, willAcceptConsent],
    );

    React.useEffect(
        () => {
            if (currentConsent != null) {
                setWillAcceptConsent(currentConsent);
            }
        },
        [setWillAcceptConsent, currentConsent],
    );

    return (
        <>
            <SecondaryHeaderSection>
                <SwitchLabel
                    htmlFor="alwaysAcceptSwitch"
                >
                    {t('cookieConsentNecessary')}
                </SwitchLabel>
                <SwitchContainer>
                    <SwitchValue>
                        {t('cookieConsentAlwaysAccept')}
                    </SwitchValue>
                    <Switch
                        id="alwaysAcceptSwitch"
                        value
                        disable
                    />
                </SwitchContainer>
            </SecondaryHeaderSection>
            <p>{t('cookieConsentStoreAndAccessInfoBody')}</p>
            <SecondaryHeaderSection>
                <SwitchLabel
                    htmlFor="measurementSwitch"
                    onClick={(e): void => { e.preventDefault(); }}
                >
                    {t('cookieConsentMeasurementTitle')}
                </SwitchLabel>
                <SwitchContainer>
                    <SwitchValue>
                        {willAcceptConsent
                            ? t('cookieConsentAccept')
                            : t('cookieConsentDecline')}
                    </SwitchValue>
                    <Switch
                        id="measurementSwitch"
                        value={willAcceptConsent}
                        onChange={setWillAcceptConsent}
                    />
                </SwitchContainer>
            </SecondaryHeaderSection>
            <p>{t('cookieConsentMeasurementBody')}</p>
            <UpdateSettings onClick={updateConsentFallback}>
                {t('cookieConsentUpdateSettings')}
            </UpdateSettings>
        </>
    );
};

type CookieBannerProps = {
    id: string;
    typeId: string;
    options: CookieBannerOptions;
    macroContext: MacroContext;
};

const CookieBanner = ({
    id,
    typeId,
    options,
    macroContext,
}: CookieBannerProps): React.ReactElement | null => {
    const [moreOptions, setMoreOptions] = React.useState(false);

    const [animateClosed, setAnimateClosed] = React.useState(false);
    React.useEffect(
        () => {
            if (animateClosed === true) {
                setTimeout(() => {
                    setAnimateClosed(false);
                    setMoreOptions(false);
                }, TRANSITION_LENGTH);
            }
        },
        [animateClosed],
    );

    const [retriggered, setRetriggered] = React.useState(false);
    const retriggerCallback = React.useCallback(
        () => setRetriggered(true),
        [setRetriggered],
    );

    const scope = useMacroScope(macroContext);
    const ppUrl = RuntimePromise.unwrapOr(
        '',
        useMacro(options.ppUrl ?? '', scope, { componentName, optionName: 'Privacy Policy URL' }),
    );

    const dispatch = useDispatch();

    const consentCallback = React.useCallback(
        (val: boolean): void => {
            setAnimateClosed(true);
            setRetriggered(false);
            dispatch(setConsent(id, typeId, val));
        },
        [dispatch, id, typeId],
    );

    const cookieConsentAccepted = useSelector(getCookieConsentAccepted);

    const t = useTranslations();

    const renderedBodyText = React.useMemo(() => {
        const cookieConsentBody = t('cookieConsentBody');
        return cookieConsentBody
            && cookieConsentBody
                .replace('<$0>', `<a href="${cleanLink(ppUrl) ?? ''}">`)
                .replace('</$0>', '</a>');
    }, [ppUrl, t]);

    /* eslint-disable react/no-danger */
    return (
        <CookieBannerThemeProvider theme={options.theme}>
            <>
                {
                    cookieConsentAccepted != null && !animateClosed && !retriggered ? null : (
                        <BannerContainer className={animateClosed ? 'hide' : ''}>
                            <ContentContainer>
                                <PrimaryHeader>
                                    {t('cookieConsentCookieSettingsTitle')}
                                </PrimaryHeader>
                                <p
                                    dangerouslySetInnerHTML={{
                                        /* eslint-disable-next-line */
                                        __html: renderedBodyText ?? '',
                                    }}
                                />
                                {
                                    moreOptions
                                    ? (
                                        <DetailedContent
                                            currentConsent={cookieConsentAccepted}
                                            setConsent={consentCallback}
                                        />
                                    )
                                    : (
                                        <BasicContent
                                            setConsent={consentCallback}
                                            disabled={animateClosed}
                                            triggerMoreOptions={setMoreOptions}
                                        />
                                    )
                                }
                            </ContentContainer>
                        </BannerContainer>
                    )
                }
                {
                    cookieConsentAccepted == null || retriggered
                        ? null
                        : <ReopenButton onClick={retriggerCallback} />
                }
            </>
        </CookieBannerThemeProvider>
    );
    /* eslint-enable react/no-danger */
};

export default CookieBanner;
