import BlueBiteSDK from '@bluebite/sdk';
import getQueryStringParameters from './queryString';
import { assignIfTruthy } from '../../../utils/object';

export type ObjectData = Record<string, string>;
export type VariableData = Record<string, string>;

export type V2SDKObject = {
    uuid?: string;
    data: ObjectData;
    variables: VariableData;
};

export type V3SDKObject = {
    uuid: string;
    data: ObjectData;
    variables: VariableData;
};

export type VerificationState =
    | { supported: true; result: boolean }
    | { supported: false; result: null };

export type TamperState =
    | { supported: true; result: boolean }
    | { supported: false; result: null };

export type V2SDKInteractionData = {
    touchpoint: {
        verification: VerificationState;
        tamper: TamperState;
    };
};

export type V3Touchpoint = {
    slug: string;
    technology: string;
    identifiers: Record<string, string>;
    url: {
        href: string;
        searchParams?: Record<string, string>;
    };
    verification: VerificationState;
    tamper: TamperState;
};

export type V3SDKInteractionData = {
    touchpoint: V3Touchpoint;
    timestamp: string;
};

export type V3SDKDeviceData = {
    id?: string;
    ip_address?: string;
    os_name?: string;
    user_agent?: string;
    type?: string;
    browser?: string;
    manufacturer?: string;
    carrier?: string;
    // only for preview
    language?: string;
};

export type V3SDKLocationData = {
    timezone?: string;
    latitude?: number;
    longitude?: number;
    city?: {
        name?: string;
        geoname_id?: string;
    };
    subdivision_1?: {
        iso_code?: string;
        name?: string;
    };
    subdivision_2?: {
        iso_code?: string;
        name?: string;
    };
    country?: {
        name?: string;
        iso_code?: string;
    };
    postal_code?: string;
    continent?: {
        name?: string;
        code?: string;
    };
};

export type V3SDKProjectData = {
    id?: number;
    name?: string;
    data?: ObjectData;
};

export type V3SDKData = {
    location?: V3SDKLocationData;
    device?: V3SDKDeviceData;
    interaction?: V3SDKInteractionData;
    object?: V3SDKObject;
    variables?: VariableData;
    project?: V3SDKProjectData;
};

export type V2SDKData = {
    location?: V3SDKLocationData;
    device?: V3SDKDeviceData;
    interaction?: V2SDKInteractionData;
    object?: V2SDKObject;
    variables?: VariableData;
};

export type PagePayload = V2PagePayload | V3PagePayload;

export type V3PagePayload = {
    type: 'V3';
    experienceStudioId: string | null;
    sdkData: V3SDKData;
    eventToken?: string;
};

export type V2PagePayload = {
    type: 'V2';
    experienceStudioId: string | null;
    sdkData: V2SDKData;
    legacy: typeof window.Mustache;
};

export const createV2PagePayload = (
    data: typeof window.Mustache = window.Mustache,
): V2PagePayload => {
    const experienceStudioId = (
        (
            __EXPERIENCE_STUDIO_TYPE__ === 'preview'
                ? getQueryStringParameters().studioId
                : null
        )
        ?? data?.experienceStudioId
        ?? null
    );

    return {
        type: 'V2',
        experienceStudioId,
        sdkData: {
            object: {
                data: data?.macros['tag:data_json'] ?? {},
                variables: data?.macros['tag:user_data_json'] ?? {},
            },
            device: {
                id: data?.macros['device:id'],
                ip_address: data?.macros['device:ip'],
                os_name: data?.macros['device:os_name'],
                user_agent: data?.macros['device:user_agent'],
                type: data?.macros['device:type'],
                browser: data?.macros['device:browser'],
                manufacturer: data?.macros['device:manufacturer'],
                carrier: data?.macros['device:carrier'],
            },
            location: {
                ...((): { latitude?: number } => {
                    const latitude = parseFloat(data?.macros['device:ip_lat'] ?? '');
                    return Number.isNaN(latitude) ? {} : { latitude };
                })(),
                ...((): { longitude?: number } => {
                    const longitude = parseFloat(data?.macros['device:ip_lon'] ?? '');
                    return Number.isNaN(longitude) ? {} : { longitude };
                })(),
                city: {
                    ...assignIfTruthy(
                        'name',
                        data?.macros['device:address_city'],
                    ),
                },
                subdivision_1: {
                    iso_code: data?.macros['device:address_state'],
                },
                country: {
                    iso_code: data?.macros['device:address_country'],
                    name: data?.macros['device:address_country_name'],
                },
                postal_code: data?.macros['device:address_zip'],
            },
            interaction: {
                touchpoint: {
                    verification: (
                        data?.verified != null
                            ? {
                                supported: true,
                                result: data?.verified,
                            }
                            : {
                                supported: false,
                                result: null,
                            }
                    ),
                    tamper: (
                        data?.tampered != null
                            ? {
                                supported: true,
                                result: data?.tampered,
                            }
                            : {
                                supported: false,
                                result: null,
                            }
                    ),
                },
            },
        },
        legacy: data,
    };
};

export const createV3PagePayload = async (): Promise<V3PagePayload> => {
    // if on preview, ignore pulling uuid from url path
    const path = __IS_PREVIEW__ ? [] : window.location.pathname.split('/');
    const studioFileUuid = (path.length === 2 ? path[1] : null)
        || window.initialExperienceUuid
        || null;
    const experienceStudioId = studioFileUuid ? `bluebite:es:${studioFileUuid}` : null;

    let sdkData;
    let eventToken;

    try {
        const sdk = BlueBiteSDK({
            apiBase: __TRIDENT_API_BASE_URL__,
        });
        eventToken = sdk.session.eventToken;
        sdkData = (
            __IS_LOCAL__
                ? require('../../../../dev/v3_sdk_data.json')
                : await sdk.getEvent({
                    getObject: true,
                    getInteraction: true,
                    getDevice: true,
                    getLocation: true,
                    getProject: true,
                })
        );
    } catch (_) {
        sdkData = {
            object: {
                uuid: null,
                data: {},
            },
            experienceStudioId,
            device: {},
            interaction: {
                touchpoint: {
                    slug: null,
                    technology: null,
                    identifiers: {},
                    url: null,
                    verification: {
                        supported: false,
                        result: null,
                    },
                    tamper: {
                        supported: false,
                        result: null,
                    },
                },
            },
            location: {
                country: {
                    iso_code: null,
                    name: null,
                },
                subdivision_1: {
                    iso_code: null,
                    name: null,
                },
                subdivision_2: {
                    iso_code: null,
                    name: null,
                },
                city: {
                    geoname_id: null,
                    name: null,
                },
                continent: {
                    code: null,
                    name: null,
                },
                latitude: null,
                longitude: null,
                postal_code: null,
                timezone: null,
            },
            project: {},
        };
    }

    return {
        type: 'V3',
        sdkData,
        experienceStudioId,
        eventToken,
    };
};
/* eslint-enable global-require */

export const createPagePayload: () => Promise<V2PagePayload | V3PagePayload> =
    __IS_V3__
        ? createV3PagePayload
        : async (): Promise<V2PagePayload> => createV2PagePayload();

export const blueBiteSDK = BlueBiteSDK({ apiBase: __TRIDENT_API_BASE_URL__ });
