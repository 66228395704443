import { V3SDKData } from '../pagePayload';
import { StudioFile } from '../studioFile';

export const EditorMessageTypes = {
    treeUpdate: 'ExperienceTreeUpdate',
    variableUpdate: 'LocalVariableUpdate',
    objectDataUpdate: 'ObjectDataUpdate',
    pageDataUpdate: 'ApiDataUpdate',
    loadTimeUpdate: 'MacroPageLoadUpdate',
    startInspector: 'StartInspector',
    stopInspector: 'StopInspector',
} as const;
export type EditorMessageTypes = typeof EditorMessageTypes[keyof typeof EditorMessageTypes];

export type EditorMessage = (
    | ExperienceTreeUpdate
    | VariableUpdate
    | ObjectDataUpdate
    | PageDataUpdate
    | LoadTimeUpdate
    | StartInspector
    | StopInspector
);

export type StartInspector = {
    type: 'StartInspector',
}

export type StopInspector = {
    type: 'StopInspector',
}

export type ExperienceTreeUpdate = {
    type: 'ExperienceTreeUpdate',
    experience: StudioFile,
};

export type ExperienceLocalVariables = Record<string, string>;
export type VariableUpdate = {
    type: 'LocalVariableUpdate',
    values: ExperienceLocalVariables,
};
export type ObjectDataUpdate = {
    type: 'ObjectDataUpdate',
    values: ExperienceLocalVariables,
};

export type PageDataUpdate = {
    type: 'ApiDataUpdate',
    sdkData: V3SDKData,
};

export type LoadTimeUpdate = {
    type: 'MacroPageLoadUpdate',
    timestamp?: number,
};

export type MessageHandler = (msg: EditorMessage) => void;

export type MessageEmitter = ((msg: EditorMessage) => void) | null;
