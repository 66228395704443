import * as React from 'react';
import {
    ScheduleOptions,
    ScheduleComponentLists,
} from '../store/processes/studioFile/componentConfigs/schedule';
import ComponentList from './shared/componentList';
import { ComponentProps } from './shared/types';
import { getTime, SECOND } from './shared/getTime';


type ScheduleProps = ComponentProps<ScheduleOptions, ScheduleComponentLists>;

const Schedule = ({
    options,
    componentLists: { valid },
}: ScheduleProps): React.ReactElement | null => {
    const [time, setTime] = React.useState(getTime());

    React.useEffect(
        () => {
            const t = setInterval(
                () => {
                    setTime(getTime());
                },
                SECOND,
            );
            return (): void => {
                clearInterval(t);
            };
        },
        [],
    );

    const startDate = options?.startDate.epoch ?? -Infinity;
    const endDate = options?.endDate.epoch ?? Infinity;

    return (
        startDate <= time && time < endDate
            ? <ComponentList id={valid} />
            : null
    );
};

export default Schedule;
