import { EditorMessage, ExperienceLocalVariables, MessageEmitter } from './types';

export class ExperienceManager {
    #messageEmmitter: MessageEmitter;

    #listening = false;

    #leftExperience = false;

    constructor(messageEmitter: MessageEmitter) {
        this.#messageEmmitter = messageEmitter;
    }

    #reciever(event: MessageEvent<EditorMessage>) {
        if (event.origin === __SBS_EDITOR_ORIGIN__ || __DEPLOYMENT_ENV__ === 'dev') {
            const { data } = event;
            if (data.type) {
                if ((data.type as string) === 'Ping') {
                    this.#sendToEditor({ ...data, type: 'Pong' });
                }
                /* once you've navigated away don't respond to file changes */
                if (!this.#leftExperience) {
                    this.#messageEmmitter?.(data);
                }
            }
        }
    }

    #clearReciever() {
        window.removeEventListener('message', this.#reciever.bind(this));
        this.#listening = false;
    }

    #sendToEditor(data: any) {
        window.parent?.postMessage(
            data,
            __DEPLOYMENT_ENV__ === 'dev' ? '*' : __SBS_EDITOR_ORIGIN__,
        );
        return this;
    }

    connect() {
        if (!this.#listening) {
            window.addEventListener('message', this.#reciever.bind(this));
            this.#listening = true;
        }
        return this;
    }

    clearEmitter() {
        this.#clearReciever();
        this.#messageEmmitter = null;
    }

    /* methods to send different action types */
    readyForUpdates() {
        if (this.#listening) {
            this.#sendToEditor({ type: 'Initialized' });
        }
    }

    leavingPage(url: string) {
        this.#sendToEditor({ type: 'LeavingPage', data: { url } });
    }

    ready() {
        this.#sendToEditor({ type: 'Ready' });
    }

    unloaded() {
        this.#sendToEditor({ type: 'Unloaded' });
    }

    changedExperiences(uuid: string) {
        this.#leftExperience = true;
        this.#sendToEditor({ type: 'ChangedExperience', data: { uuid } });
    }

    updatedLocalVariables(data: ExperienceLocalVariables) {
        this.#sendToEditor({ type: 'Variables', data });
    }

    updatedObjectVariables(data: ExperienceLocalVariables) {
        this.#sendToEditor({ type: 'ObjectData', data });
    }

    inspectComponent(id: number) {
        this.#sendToEditor({ type: 'InspectComponent', data: { id } });
    }
}
