import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
    TwitterOptions,
    TwitterTheme,
} from '../store/processes/studioFile/componentConfigs/twitter';
import { useMacroScope, makeUseMacros } from './shared/macroHelpers';
import * as RuntimePromise from '../macros/promise';
import { useEmbed } from '../functions/socialEmbed';
import { ComponentProps } from './shared/types';
import { useTwitter } from '../utils/scripts';


type EmbedOptions = {
    url: string | null;
    theme: TwitterTheme;
};

const useTwitterEmbed = ({
    url,
    theme,
}: EmbedOptions): string | null => {
    const embed = useEmbed('twitter', url != null ? { url, theme } : null);
    return embed?.html ?? null;
};

type TwitterContainerProps = {
    maxWidth: string;
    maxHeight: string;
}
const TwitterContainer = styled.div`
    max-width: ${(props: TwitterContainerProps): string => props.maxWidth};
    max-height: ${(props: TwitterContainerProps): string => props.maxHeight};
    margin-bottom: 12px;
    overflow-y: 'auto';
    overflow-x: 'hidden';
`;

const TWITTER_COMPONENT_NAME = 'Twitter';

const useMacros = makeUseMacros(TWITTER_COMPONENT_NAME, {
    url: {},
});

type TwitterParams = ComponentProps<TwitterOptions>;

const Twitter = ({
    options,
    macroContext,
}: TwitterParams): React.ReactElement | null => {
    const twitterLoaded = useTwitter();

    const scope = useMacroScope(macroContext);
    const macros = useMacros(scope, options);

    const { url } = RuntimePromise.unwrapOr({ url: null }, macros);

    const embed = useTwitterEmbed({ url, theme: options.theme });

    useEffect(
        () => {
            if (embed && twitterLoaded) {
                window.twttr?.widgets.load();
            }
        },
        [embed, twitterLoaded],
    );

    if (!embed) { return null; }

    return (
        <TwitterContainer
            maxWidth={options.maxWidth}
            maxHeight={options.maxHeight}
            dangerouslySetInnerHTML={{
                /* eslint-disable-next-line */
                __html: embed,
            }}
        />
    );
};

export default Twitter;
