import * as R from 'ramda';
import { COOKIE_BANNER_COMPONENT_TYPE_ID } from '../studioFile/components';
import * as LoadableState from '../loadableState';
import {
    UPDATE_STUDIO_FILE,
    UpdateStudioFileAction,
    StudioFile,
} from '../studioFile';
import { State } from '../index';
import { getLocalVariable } from '../variables';

export const COOKIE_CONSENT_KEY = 'BB::COOKIE_CONSENT';

type Actions = UpdateStudioFileAction | { type: 'FALLBACK' };

const studioFileHasCookieConsent = (file: StudioFile): boolean => (
    !!file.relationships.components.items.find((i) => i.typeId === COOKIE_BANNER_COMPONENT_TYPE_ID)
);

export type LocalState = {
    enabled: boolean;
};

const initialState: LocalState = {
    enabled: false,
};

export const reducer = (state: LocalState = initialState, action: Actions): LocalState => {
    switch (action.type) {
        case UPDATE_STUDIO_FILE:
            return (
                (
                    !state.enabled
                    && LoadableState.isReady(action.update)
                    && studioFileHasCookieConsent(LoadableState.unwrap(action.update))
                )
                    ? { ...state, enabled: true }
                    : state
            );
        default:
            return state;
    }
};

const parseCookieConsent = (consent: string): true | false | null => {
    switch (consent) {
        case 'true': return true;
        case 'false': return false;
        default: return null;
    }
};

const getCookieConsent = (state: State): LocalState => state.cookieConsent;
export const getCookieConsentEnabled = R.pipe(getCookieConsent, R.prop('enabled'));
export const getCookieConsentAccepted = (state: State): true | false | null => (
    parseCookieConsent(getLocalVariable(COOKIE_CONSENT_KEY)(state) ?? '')
);
export const getCookiesDisabled = R.both(
    getCookieConsentEnabled,
    (state: State) => !getLocalVariable(COOKIE_CONSENT_KEY)(state) === true,
);
