/* eslint-disable max-len */
import React from 'react';

const Moon = (): React.ReactElement => (
    <svg aria-label="moon icon" width="128" height="128" viewBox="0 0 128 128">
        <defs>
            <clipPath id="clip-Moon">
                <rect width="128" height="128" />
            </clipPath>
        </defs>
        <g id="Moon" clipPath="url(#clip-Moon)">
            <rect width="128" height="128" fill="#fff" />
            <g id="Group_4" data-name="Group 4" transform="translate(44 44.622)">
                <g id="Group_25" data-name="Group 25" transform="translate(0 -0.623)">
                    <path id="Path_10" data-name="Path 10" d="M16.94,3.584A18.214,18.214,0,0,1,17.293,0,19.7,19.7,0,1,0,39.319,21.274a18.168,18.168,0,0,1-4.09.509A18.244,18.244,0,0,1,16.94,3.584" transform="translate(0 0)" fill="#d9d2ca" />
                </g>
            </g>
        </g>
    </svg>
);

export default Moon;
