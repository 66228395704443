import { SagaIterator, eventChannel } from 'redux-saga';
import { createAction } from '@reduxjs/toolkit';
import {
    call,
    getContext,
    take,
    put,
    spawn,
    delay,
    select,
    throttle,
} from 'redux-saga/effects';
import {
    EditorMessageTypes, EditorMessage,
    ExperienceTreeUpdate, VariableUpdate,
    ExperienceLocalVariables,
    PageDataUpdate,
    LoadTimeUpdate,
    ObjectDataUpdate,
} from './types';
import { ExperienceManagerInitializer } from './initialize';
import { ExperienceManager } from './manager';
import { updateStudioFile } from '../studioFile/reducer';
import * as LoadableState from '../loadableState';
import { loadCustomComponentScriptsSaga } from '../customComponents';
import {
    DEVICE_VAR, OBJECT_VAR, loadObjectVariablesSaga, loadVariables,
} from '../variables';
import {
    getPagePayload, PagePayload, LocalState as PagePayloadState, updatePagePayload,
} from '../pagePayload';
import { updateLoadTime } from '../page';
import { saga as inspectorSaga, startInspector, stopInspector } from './inspector';
import { updateTimeDelta } from '../../../components/shared/getTime';
import { loadCustomFontsSaga } from '../fonts';
import fetchRootFont from '../../../functions/fetchRootFont';

export const notifyLocalVariablesUpdated = createAction<ExperienceLocalVariables>(
    'NOTIFY_EDITOR_LOCAL_VARIABLES',
);
export const notifyObjectVariablesUpdated = createAction<ExperienceLocalVariables>(
    'NOTIFY_EDITOR_OBJECT_VARIABLES',
);

export function* initEditorListener(): SagaIterator {
    const managerInitializer: ExperienceManagerInitializer = (
        yield getContext('previewManagerInitializer')
    );

    yield throttle(100, notifyLocalVariablesUpdated.type, notifyLocalVariablesUpdatedSaga);
    yield throttle(100, notifyObjectVariablesUpdated.type, notifyObjectVariablesUpdatedSaga);

    const channel = eventChannel<EditorMessage>((emitter) => {
        let manager: ExperienceManager;
        // eslint-disable-next-line promise/prefer-await-to-then, promise/catch-or-return
        managerInitializer.setup(emitter).then((m) => {
            manager = m;
            return manager.readyForUpdates();
        });

        return () => {
            manager?.clearEmitter();
        };
    });

    yield spawn(inspectorSaga);

    yield spawn(function* handleMsgReciept(c) {
        while (true) {
            const action: EditorMessage = yield take(c);
            const executor = ActionExecutor[action.type];
            if (executor) {
                yield executor(action);
            }
        }
    }, channel);
    const manager: ExperienceManager = yield call(() => (
        managerInitializer.waitForInitialization()
    ));
    window.addEventListener('beforeunload', () => {
        manager.unloaded();
    });
    // Yield to react to render the page before we say we are ready
    yield delay(0);
    manager.ready();
}

function* notifyLocalVariablesUpdatedSaga({
    payload: values,
}: ReturnType<typeof notifyLocalVariablesUpdated>): SagaIterator {
    const managerInitializer: ExperienceManagerInitializer = (
        yield getContext('previewManagerInitializer')
    );
    const manager: ExperienceManager = yield call(() => (
        managerInitializer.waitForInitialization()
    ));
    manager.updatedLocalVariables(values);
}
function* notifyObjectVariablesUpdatedSaga({
    payload: values,
}: ReturnType<typeof notifyObjectVariablesUpdated>): SagaIterator {
    const managerInitializer: ExperienceManagerInitializer = (
        yield getContext('previewManagerInitializer')
    );
    const manager: ExperienceManager = yield call(() => (
        managerInitializer.waitForInitialization()
    ));
    manager.updatedObjectVariables(values);
}

const ActionExecutor: Record<
    EditorMessageTypes,
    (action: EditorMessage) => SagaIterator<void>
> = {
    * [EditorMessageTypes.treeUpdate](action: EditorMessage): SagaIterator<void> {
        try {
            // yield put(updateStudioFile(LoadableState.Loading));
            const { experience } = action as ExperienceTreeUpdate;
            // updates includedFonts and finds root font
            yield call(loadCustomFontsSaga, experience);
            const { content } = experience as any;
            const rootFont = ((yield call(fetchRootFont, content?.rootFontId ?? 1)) as Awaited<ReturnType<typeof fetchRootFont>>) ?? undefined;
            // loads custom components
            yield call(loadCustomComponentScriptsSaga, experience);
            yield call(loadCustomFontsSaga, experience);
            yield put(updateStudioFile(LoadableState.Ready({
                ...experience,
                rootFont,
                rootFontWeight: content?.rootFontWeight ?? '400',
            })));
            // yield spawn(setupAnalytics);
        } catch (e) {
            yield put(updateStudioFile(
                LoadableState.Err('Error loading studio file'),
            ));
        }
    },
    * [EditorMessageTypes.variableUpdate](action: EditorMessage): SagaIterator<void> {
        try {
            const { values } = action as VariableUpdate;

            if (Object.keys(values).length) {
                yield put(loadVariables(DEVICE_VAR, values));
            }
        } catch (_e) {
            // silently do nothing
        }
    },
    * [EditorMessageTypes.objectDataUpdate](action: EditorMessage): SagaIterator<void> {
        try {
            const { values } = action as ObjectDataUpdate;

            if (Object.keys(values).length) {
                yield put(loadVariables(OBJECT_VAR, values));
            }
        } catch (_e) {
            // silently do nothing
        }
    },
    * [EditorMessageTypes.pageDataUpdate](action: EditorMessage): SagaIterator<void> {
        try {
            const pagePayloadPromise = (yield select(getPagePayload)) as PagePayloadState;

            if (LoadableState.isReady(pagePayloadPromise)) {
                const currentPayload = LoadableState.unwrap(pagePayloadPromise);
                const { sdkData } = action as PageDataUpdate;
                yield put(updatePagePayload(LoadableState.Loading));
                const newPayload = {
                    ...currentPayload,
                    sdkData,
                } as PagePayload;
                yield put(updatePagePayload(LoadableState.Ready(newPayload)));
                yield call(loadObjectVariablesSaga, newPayload);
            }
        } catch (_e) {
            // silently do nothing
        }
    },
    * [EditorMessageTypes.loadTimeUpdate](action: EditorMessage): SagaIterator<void> {
        try {
            const { timestamp = Date.now() } = action as LoadTimeUpdate;
            updateTimeDelta(timestamp);
            yield put(updateLoadTime(timestamp));
        } catch (_e) {
            // silently do nothing
        }
    },
    * [EditorMessageTypes.startInspector](): SagaIterator<void> {
        yield put(startInspector());
    },
    * [EditorMessageTypes.stopInspector](): SagaIterator<void> {
        yield put(stopInspector());
    },
};
