import { Task } from './task';
import { BlueBiteSDK } from './sdk';
import {
    LocalVariableEmitter,
    Interaction,
    ObjectVariableEmitter,
    ObjectVariablesUpdateAction,
    OnLocalVariableChangeCallback,
    OnObjectVariablesChangeCallback,
} from './types';
import { unwrapExists } from '../../../functions/utils';

const cloneSDKVariableMap = (map: Record<string, string>): Record<string, string> => {
    const newMap: Record<string, string> = {};
    for (const key of Object.keys(map)) {
        newMap[key] = map[key];
    }
    return newMap;
};

export class BlueBiteSDKManager {
    #localVariables: Record<string, string>;

    #localVariableWatchers: Array<OnLocalVariableChangeCallback> = [];

    #localVariablesEmitter: LocalVariableEmitter | null;

    #readOnlyOriginalInteraction: Interaction | null;

    #mutableObjectVariables: Record<string, string> = {};

    #objectVariableWatchers: Array<OnObjectVariablesChangeCallback> = [];

    #objectVariablesEmitter: ObjectVariableEmitter | null;

    #deviceTaskLookup = new WeakMap<Task, OnLocalVariableChangeCallback>();

    #objectTaskLookup = new WeakMap<Task, OnObjectVariablesChangeCallback>();

    constructor(
        localVariables: Record<string, string>,
        localVariablesEmitter: LocalVariableEmitter,
        interaction: Interaction | null,
        objectVariablesEmitter: ObjectVariableEmitter,
    ) {
        this.#localVariables = localVariables;
        this.#localVariablesEmitter = localVariablesEmitter;
        this.#readOnlyOriginalInteraction = interaction;
        this.#mutableObjectVariables = cloneSDKVariableMap(interaction?.object.variables ?? {});
        this.#objectVariablesEmitter = objectVariablesEmitter;
        this.cancelDeviceTask = this.cancelDeviceTask.bind(this);
        this.cancelObjectTask = this.cancelObjectTask.bind(this);
    }

    connect(): BlueBiteSDK {
        const sdk = new BlueBiteSDK(this);
        if (window?.BlueBiteSDK) {
            window.BlueBiteSDK = () => Promise.resolve(sdk);
            while (window.waitingForBBSDK.length) {
                unwrapExists(window.waitingForBBSDK.pop())(sdk);
            }
        }
        return sdk;
    }

    cancelDeviceTask(task: Task) {
        const callback = this.#deviceTaskLookup.get(task);
        if (!callback) {
            return;
        }
        const idx = this.#localVariableWatchers.indexOf(callback);
        if (idx === -1) {
            return;
        }
        this.#localVariableWatchers.splice(idx, 1);
    }

    registerOnLocalVariableChange(
        /* eslint-disable-next-line promise/prefer-await-to-callbacks */
        callback: OnLocalVariableChangeCallback,
    ): Task {
        const task = new Task(this.cancelDeviceTask);
        this.#deviceTaskLookup.set(task, callback);
        // You could register the same callback againt
        // we should only keep one copy of it but create
        // another Task allowing for cancelation
        if (!this.#localVariableWatchers.includes(callback)) {
            this.#localVariableWatchers.push(callback);
        }
        return task;
    }

    clearDeviceEmitter() {
        this.#localVariablesEmitter = null;
    }

    setLocalVariable(key: string, value: string | undefined) {
        if (!this.#localVariablesEmitter) {
            throw new Error('SDK Error');
        }
        this.#localVariablesEmitter({
            type: 'SetLocalVariable',
            key,
            value,
        });
    }

    localVariableChanged(key: string, value: string | undefined) {
        const before = cloneSDKVariableMap(this.#localVariables);
        if (before[key] === value) {
            return;
        }
        if (value == null) {
            delete this.#localVariables[key];
        } else {
            this.#localVariables[key] = value;
        }
        for (const cb of this.#localVariableWatchers) {
            /* eslint-disable-next-line promise/prefer-await-to-callbacks */
            cb(key, value, cloneSDKVariableMap(before));
        }
    }

    getLocalVariables(): Record<string, string> {
        return cloneSDKVariableMap(this.#localVariables);
    }

    cancelObjectTask(task: Task) {
        const callback = this.#objectTaskLookup.get(task);
        if (!callback) {
            return;
        }
        const idx = this.#objectVariableWatchers.indexOf(callback);
        if (idx === -1) {
            return;
        }
        this.#objectVariableWatchers.splice(idx, 1);
    }

    registerOnObjectVariablesChange(
        /* eslint-disable-next-line promise/prefer-await-to-callbacks */
        callback: OnObjectVariablesChangeCallback,
    ): Task {
        const task = new Task(this.cancelObjectTask);
        this.#objectTaskLookup.set(task, callback);
        if (!this.#objectVariableWatchers.includes(callback)) {
            this.#objectVariableWatchers.push(callback);
        }
        return task;
    }

    clearObjectEmitter() {
        this.#objectVariablesEmitter = null;
    }

    async setObjectVariables(objectUuid: string, updates: Record<string, string>): Promise<void> {
        if (!this.#objectVariablesEmitter) {
            throw new Error('SDK Error');
        }
        if (objectUuid?.toLowerCase() === this.#readOnlyOriginalInteraction?.object.uuid) {
            const action: ObjectVariablesUpdateAction = {
                type: 'SetObjectVariables',
                updates,
                resolve: () => undefined,
                reject: () => undefined,
            };
            const promise = new Promise<void>((resolve, reject) => {
                action.resolve = resolve;
                action.reject = reject;
            });
            try {
                this.#objectVariablesEmitter(action);
            } catch (err) {
                console.error('failed to update object variables');
                action.reject(err);
            }
            return promise;
        }
        return Promise.reject(new Error(`mutation for '${objectUuid}' object variables `
            + 'does not match loaded interaction'));
    }

    objectVariablesChanged(updates: Record<string, string>) {
        if (!this.#readOnlyOriginalInteraction) {
            return;
        }
        const updateKeys = Object.keys(updates);
        const mutateKeys = [];
        for (const updateKey of updateKeys) {
            const updateValue = updates[updateKey];
            if (this.#mutableObjectVariables[updateKey] !== updateValue) {
                this.#mutableObjectVariables[updateKey] = updateValue;
                mutateKeys.push(updateKey);
            }
        }
        if (!mutateKeys.length) {
            return;
        }
        for (const cb of this.#objectVariableWatchers) {
            /* eslint-disable-next-line promise/prefer-await-to-callbacks */
            cb(updates, cloneSDKVariableMap(this.#mutableObjectVariables));
        }
    }

    getInteraction(): Promise<Interaction | null> {
        if (!this.#readOnlyOriginalInteraction) {
            return Promise.resolve(null);
        }
        const interaction = this.#readOnlyOriginalInteraction;
        return Promise.resolve(JSON.parse(JSON.stringify(interaction)));
    }

    getObjectVariables(objectUuid: string): { [key: string]: string } {
        if (objectUuid?.toLowerCase() === this.#readOnlyOriginalInteraction?.object.uuid) {
            return cloneSDKVariableMap(this.#mutableObjectVariables);
        }
        throw new Error(`request for '${objectUuid}' object variables does not `
            + 'match loaded interaction');
    }
}
