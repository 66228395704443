import { EventChannel, eventChannel, SagaIterator } from 'redux-saga';
import {
    all,
    call,
    put,
    take,
} from 'redux-saga/effects';
import { updateScriptStatus } from './reducer';
import { StudioFile } from '../studioFile';
import * as LoadableState from '../loadableState';
import {
    getModFedContainer,
    makeScriptId,
    makeScriptPath,
} from '../../../components/userCreatedComponent/utils';

const makeScriptLoadChannel = (el: HTMLScriptElement): EventChannel<any> => {
    const loadChannel = eventChannel((emitter) => {
        el.addEventListener('load', emitter);
        return () => el.removeEventListener('load', emitter);
    });
    return loadChannel;
};

function* loadCustomComponentScript(uuid: string, version: string) {
    yield put(updateScriptStatus(uuid, LoadableState.LOADABLE_STATUS_LOADING));
    const scriptId = makeScriptId(uuid, version);
    const docScript = document.getElementById(scriptId) as HTMLScriptElement | null;
    let newScript: HTMLScriptElement | undefined;
    if (!docScript) {
        newScript = document.createElement('script');
        newScript.id = scriptId;
        newScript.type = 'text/javascript';
        newScript.async = true;
        newScript.src = `${makeScriptPath(uuid, version)}/component.js`;
    }
    const scriptEl = (docScript ?? newScript) as HTMLScriptElement;
    const channel = makeScriptLoadChannel(scriptEl);
    if (newScript) {
        document.body.appendChild(newScript);
    }
    const container = getModFedContainer(uuid);
    if (!container) {
        yield take(channel);
    }
    yield put(updateScriptStatus(uuid, LoadableState.LOADABLE_STATUS_READY));
}

export function* loadCustomComponentScriptsSaga(studioFile: StudioFile): SagaIterator<void> {
    const { customComponentVersions } = studioFile.relationships;
    yield all(Object.keys(customComponentVersions ?? {}).map((uuid: string) => {
        const version = customComponentVersions[uuid];
        return call(loadCustomComponentScript, uuid, version);
    }));
}
