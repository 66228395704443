import React, { FunctionComponent } from 'react';
import {
    createGlobalStyle,
    css,
    DefaultTheme,
    ThemeProvider,
} from 'styled-components';
import {
    FormStylingOptions,
} from './store/processes/studioFile/componentConfigs/form';
import {
    CookieBannerTheme,
} from './store/processes/studioFile/componentConfigs/cookieBanner';
import { getNavActionEnabled } from './functions/previewMode';

declare module 'styled-components' {
    export interface DefaultTheme {
        fontRegular: string;
        fontWeight: string;
        backgroundColor: string;
        backgroundImage: string;
        bodyPadding: string;
        componentMargin: string;
        componentBorder: string;
        componentBorderRadius: string;
        componentPadding: string;
        formTheme?: FormStylingOptions;
        showWidget: boolean;
        maxWidth: string;
        cookieBannerTheme: CookieBannerTheme;
    }
}

const BlockInteractions = css`
    body * {
        pointer-events: none !important;
    }
`;

const GlobalStyles = createGlobalStyle(({ theme }) => css`
    body {
        margin: 0 auto;
        padding: 0;
        font-family: ${theme.fontRegular};
        font-weight: ${theme.fontWeight};
        font-size: 16px;
        background-color: ${theme.backgroundColor};
        background-image: ${theme.backgroundImage};
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        letter-spacing: 0.5px;
        line-height: 1.3;
    }

    ${(__IS_PREVIEW__ && !getNavActionEnabled())
        ? BlockInteractions
        : ''}
`);

const StyleRoot: FunctionComponent<{ theme: DefaultTheme }> = ({
    children,
    theme,
}) => (
    <ThemeProvider theme={theme}>
        <>
            <GlobalStyles />
            {children}
        </>
    </ThemeProvider>
);

export default StyleRoot;
