const reUnescapedHtml = /[&<>"']/g;
const reEscapedHtml = /&(?:amp|lt|gt|quot|#(0+)?39);/g;
const reHasUnescapedHtml = RegExp(reUnescapedHtml.source);
const reHasEscapedHtml = RegExp(reEscapedHtml.source);

const htmlEscapes: Record<string, string> = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    '\'': '&#39;',
};

const htmlUnescapes: Record<string, string> = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': '\'',
};

export const escapeHtml = (str: string): string => (
    reHasUnescapedHtml.test(str)
        ? str.replace(reUnescapedHtml, (s: string) => htmlEscapes[s])
        : str
);

export const unescapeHtml = (str: string) => (
    (str && reHasEscapedHtml.test(str))
        ? str.replace(reEscapedHtml, (entity) => (htmlUnescapes[entity] || '\''))
        : (str || '')
);

// borrowed from lodash
const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
const reHasRegExpChar = RegExp(reRegExpChar.source);
export const escapeRegExp = (string: string): string => (
    string && reHasRegExpChar.test(string)
        ? string.replace(reRegExpChar, '\\$&')
        : (string || '')
);
