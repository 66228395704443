/* eslint-disable-next-line no-script-url */
const disallowedProtocols = ['javascript:'];

export const cleanLink = (link: string): string => {
    // Adding this to handle the case of blank urls
    // however in the past we have problems with null
    // and undefined urls which this also caught.
    // Until we refactor the options configuration
    // we should be aware of that possiblity. Although
    // defensiveness beyond the typings in the call signature
    // is not generally recommended.
    if (!link) {
        return link;
    }
    let href = link;
    const indexOfProtocol = href.indexOf(':');
    const protocol = href.toLowerCase().substring(0, indexOfProtocol + 1);
    if (indexOfProtocol === -1 || disallowedProtocols.includes(protocol)) {
        href = `https://${link}`;
    }
    return href;
};
