import * as React from 'react';
import {
    Sun,
    Moon,
    PartlyCloudyDay,
    PartlyCloudyNight,
    Cloudy,
    BreakingCloud,
    ShowersDay,
    ShowersNight,
    Rain,
    Thunderstorm,
    Snow,
    Mist,
} from './svg/index';

const WeatherIcon = ({ name }: {name: string }): React.ReactElement | null => {
    switch (name) {
        case '01d':
            return <Sun />;
        case '01n':
            return <Moon />;
        case '02d':
            return <PartlyCloudyDay />;
        case '02n':
            return <PartlyCloudyNight />;
        case '03d':
        case '03n':
            return <Cloudy />;
        case '04d':
        case '04n':
            return <BreakingCloud />;
        case '09d':
            return <ShowersDay />;
        case '09n':
            return <ShowersNight />;
        case '10d':
        case '10n':
            return <Rain />;
        case '11d':
        case '11n':
            return <Thunderstorm />;
        case '13d':
        case '13n':
            return <Snow />;
        case '50d':
        case '50n':
            return <Mist />;
        default:
            return null;
    }
};

export default WeatherIcon;
