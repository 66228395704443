import * as R from 'ramda';
import * as LoadableState from '../store/processes/loadableState';

export const PROMISE = 'PROMISE';

const SUCCESS = 'SUCCESS' as const;
const UNRESOLVED = 'UNRESOLVED' as const;
const ERROR = 'ERROR' as const;

export type Success<A> = {
    readonly type: typeof PROMISE;
    readonly value: {
        readonly promiseStatus: typeof SUCCESS;
        readonly value: A;
    };
};

export type Err<E> = {
    readonly type: typeof PROMISE;
    readonly value: {
        readonly promiseStatus: typeof ERROR;
        readonly err: E;
    };
};

export type Unresolved = {
    readonly type: typeof PROMISE;
    readonly value: {
        readonly promiseStatus: typeof UNRESOLVED;
    };
};

export type RuntimePromise<A, E> = Success<A> | Err<E> | Unresolved;
export type Type<A, E> = RuntimePromise<A, E>;
export type ValueForPromise<A> =
    A extends RuntimePromise<infer B, any>
        ? B
        : never;
export type ErrorForPromise<A> =
    A extends RuntimePromise<any, infer B>
        ? B
        : never;

export const Success = <A>(value: A): Success<A> => ({
    type: PROMISE,
    value: {
        value,
        promiseStatus: SUCCESS,
    },
});

export const Unresolved: Unresolved = {
    type: PROMISE,
    value: {
        promiseStatus: UNRESOLVED,
    },
};

export const Err = <E>(err: E): Err<E> => ({
    type: PROMISE,
    value: {
        promiseStatus: ERROR,
        err,
    },
});

export const unwrap = <P>(sp: Success<P>): P => sp.value.value;
export const unwrapErr = <P>(sp: Err<P>): P => sp.value.err;

export const isSuccess = <A>(a: RuntimePromise<A, any>): a is Success<A> => (
    a.value.promiseStatus === SUCCESS
);
export const isUnresolved = (a: RuntimePromise<any, any>): a is Unresolved => (
    a.value.promiseStatus === UNRESOLVED
);
export const isErr = <E>(a: RuntimePromise<any, E>): a is Err<E> => (
    a.value.promiseStatus === ERROR
);

export type Up<A> = A extends RuntimePromise<any, any> ? A : Success<A>;

export const of = Success;

export const up = <A extends { type?: unknown }>(a: A): Up<A> => (
    (typeof a === 'object' && a.type === PROMISE ? a : of(a)) as any
);

export const map: {
    <A, B, E>(fn: (a: A) => B, p: RuntimePromise<A, E>): RuntimePromise<B, E>;
    <A, B, E>(fn: (a: A) => B): (p: RuntimePromise<A, E>) =>
        RuntimePromise<B, E>;
} = R.curry(<A, B, E>(
    fn: (a: A) => B,
    p: RuntimePromise<A, E>,
): RuntimePromise<B, E> => (
    isSuccess(p) ? Success(fn(unwrap(p))) : p
)) as any;

export const mapErr: {
    <A, E, F>(fn: (a: E) => F, p: RuntimePromise<A, E>): RuntimePromise<A, F>;
    <A, E, F>(fn: (a: E) => F): (p: RuntimePromise<A, E>) =>
        RuntimePromise<A, F>;
} = R.curry(<A, E, F>(
    fn: (a: E) => F,
    p: RuntimePromise<A, E>,
): RuntimePromise<A, F> => (
    isErr(p) ? Err(fn(unwrapErr(p))) : p
)) as any;

// combines map and mapErr to use the same function on both
// mostly useful for cases where both the value and error are
// objects with the same structure
export const mapBoth: {
    <A, B, E, F>(fn: ((a: A) => B) | ((e: E) => F), p: RuntimePromise<A, E>): RuntimePromise<B, F>;
    <A, B, E, F>(fn: ((a: A) => B) | ((e: E) => F)): (p: RuntimePromise<A, E>) =>
        RuntimePromise<B, F>;
} = R.curry(<A, B, E, F>(
    fn: ((a: A) => B) | ((e: E) => F),
    p: RuntimePromise<A, E>,
): RuntimePromise<B, F> => (
    isErr(p)
        ? Err(fn(unwrapErr(p) as any) as F)
        : isSuccess(p)
            ? Success(fn(unwrap(p) as any) as B)
            : p
)) as any;

export const chain: {
    <A, B, E, F>(
        fn: (a: A) => RuntimePromise<B, F>,
        p: RuntimePromise<A, E>,
    ): RuntimePromise<B, E | F>;
    <A, B, E, F>(
        fn: (a: A) => RuntimePromise<B, F>,
    ): (p: RuntimePromise<A, E>) => RuntimePromise<B, E | F>;
} = R.curry(<A, B, E, F>(
    fn: (a: A) => RuntimePromise<B, F>,
    p: RuntimePromise<A, E>,
): RuntimePromise<B, E | F> => (
    isSuccess(p) ? fn(p.value.value) : p
)) as any;

export const unwrapOr: {
    <A, B>(fallback: B, p: RuntimePromise<A, any>): A | B;
    <B>(fallback: B): <A>(p: RuntimePromise<A, any>) => A | B;
} = R.curry(<A, B>(
    fallback: B,
    p: RuntimePromise<A, any>,
): A | B => (isSuccess(p) ? p.value.value : fallback));

export const or: {
    <A, B, F>(fallback: RuntimePromise<B, F>, p: RuntimePromise<A, any>):
        RuntimePromise<A | B, F>;
    <A, B, F>(fallback: RuntimePromise<B, F>):
        (p: RuntimePromise<A, any>) => RuntimePromise<A | B, F>;
} = R.curry(<A, B, F>(
    fallback: RuntimePromise<B, F>,
    p: RuntimePromise<A, any>,
): RuntimePromise<A | B, F> => (
    isErr(p) ? fallback : p
)) as any;

export const orElse: {
    <A, B, E, F>(
        fallback: (err: E) => RuntimePromise<B, F>,
        p: RuntimePromise<A, E>,
    ): RuntimePromise<A | B, F>;
    <A, B, E, F>(fallback: (err: E) => RuntimePromise<B, F>):
        (p: RuntimePromise<A, E>) => RuntimePromise<A | B, F>;
} = R.curry(<A, B, E, F>(
    fallback: (err: E) => RuntimePromise<B, F>,
    p: RuntimePromise<A, E>,
): RuntimePromise<A | B, F> => (
    isErr(p) ? fallback(unwrapErr(p)) : p
)) as any;

export const immediateOr: {
    <A, B, F>(
        fallback: RuntimePromise<B, F>,
        p: RuntimePromise<A, any>,
    ): RuntimePromise<A | B, F>;
    <A, B, F>(fallback: RuntimePromise<B, F>):
        (p: RuntimePromise<A, any>) => RuntimePromise<A | B, F>;
} = R.curry(<A, B, F>(
    fallback: RuntimePromise<B, F>,
    p: RuntimePromise<A, any>,
): RuntimePromise<A | B, F> => (
    isSuccess(p) ? p : fallback
)) as any;

export const fromLoadableState = <V, E>(
    ls: LoadableState.Type<V, E>,
): RuntimePromise<V, E> => {
    if (LoadableState.isError(ls)) { return Err(LoadableState.unwrapErr(ls)); }
    if (LoadableState.isReady(ls)) { return Success(LoadableState.unwrap(ls)); }
    return Unresolved;
};

/* eslint-disable max-len */
export const all: {
    (promises: []): Success<[]>;
    <A, E>(promises: [RuntimePromise<A, E>]): RuntimePromise<[A], E>;
    <A, B, E, F>(promises: [RuntimePromise<A, E>, RuntimePromise<B, F>]): RuntimePromise<[A, B], E | F>;
    <A, B, C, E, F, G>(promises: [RuntimePromise<A, E>, RuntimePromise<B, F>, RuntimePromise<C, G>]): RuntimePromise<[A, B, C], E | F | G>;
    <A, B, C, D, E, F, G, H>(promises: [RuntimePromise<A, E>, RuntimePromise<B, F>, RuntimePromise<C, G>, RuntimePromise<D, H>]): RuntimePromise<[A, B, C, D], E | F | G | H>;
    <R, E>(promises: Array<RuntimePromise<R, E>>): RuntimePromise<Array<R>, E>;
} = (<R, E>(
    promises: ReadonlyArray<RuntimePromise<R, E>>,
): RuntimePromise<ReadonlyArray<R>, E> => (
    promises.reduce((
        acc: RuntimePromise<Array<any>, any>,
        p: RuntimePromise<any, any>,
    ) => {
        if (!isSuccess(acc)) { return acc; }
        if (!isSuccess(p)) { return p; }
        unwrap(acc).push(unwrap(p));
        return acc;
    }, Success([]))
)) as any;
/* eslint-enable max-len */

// same as all or Promise.all but on an objects values instead of an array
export const allValues = <O extends Record<string, RuntimePromise<any, any>>>(obj: O): (
    RuntimePromise<
        { [K in keyof O]: O[K] extends RuntimePromise<infer V, any> ? V : never },
        O[string] extends RuntimePromise<any, infer E> ? E : never
    >
) => {
    const [keys = [], values = []] = R.transpose(R.toPairs(obj)) as [
        Array<string>,
        Array<RuntimePromise<any, any>>,
    ];
    const allValues = all(values) as RuntimePromise<any, any>;
    return map(R.zipObj(keys), allValues) as RuntimePromise<any, any>;
};

export const allTupple: {
    <A extends ReadonlyArray<RuntimePromise<any, any>>>(promises: A): (
        A extends ReadonlyArray<RuntimePromise<any, infer E>>
            ? RuntimePromise<{ [K in keyof A]: ValueForPromise<A[K]> }, E>
            : never
    );
} = all as any;

export const ap: {
    <A, R, E1, E2>(
        f: RuntimePromise<(a: A) => R, E1>,
        arg: RuntimePromise<A, E2>,
    ): RuntimePromise<R, E1 | E2>;
    <A, R, E1>(f: RuntimePromise<(a: A) => R, E1>):
        <E2>(arg: RuntimePromise<A, E2>) => RuntimePromise<R, E1 | E2>;
} = R.curry(<A, R, E1, E2>(
    f: RuntimePromise<(a: A) => R, E1>,
    arg: RuntimePromise<A, E2>,
): RuntimePromise<R, E1 | E2> => {
    if (!isSuccess(f)) { return f; }
    if (!isSuccess(arg)) { return arg; }
    return of(unwrap(f)(unwrap(arg)));
}) as any;

export const allObject: {
    <O extends Record<string, RuntimePromise<any, any>>>(
        promises: O,
    ): RuntimePromise<
        {
            [K in keyof O]:
                O[K] extends RuntimePromise<infer A, any>
                    ? A
                    : never
        },
        Partial<{
            [K in keyof O]:
                O[K] extends RuntimePromise<any, infer E>
                    ? E
                    : never
        }>
    >;
} = ((promises: Record<string, RuntimePromise<any, any>>): (
    RuntimePromise<Record<string, any>, Record<string, any>>) => R.reduce(
    (
        acc: RuntimePromise<Record<string, any>, any>,
        [k, v]: [string, RuntimePromise<any, any>],
    ): RuntimePromise<Record<string, any>, any> => {
        if (isUnresolved(acc) || isUnresolved(v)) {
            return Unresolved;
        }
        if (isSuccess(acc)) {
            if (isSuccess(v)) {
                return Success({ ...unwrap(acc), [k]: unwrap(v) });
            }
            if (isErr(v)) {
                return Err({ [k]: unwrapErr(v) });
            }
        }
        if (isErr(acc) && isErr(v)) {
            return Err({ ...unwrapErr(acc), [k]: unwrapErr(v) });
        }
        return acc;
    },
    Success({}),
    R.toPairs(promises),
)) as any;

export const eql = (p0: Type<any, any>, p1: Type<any, any>): boolean => (
    (isSuccess(p0) && isSuccess(p1) && unwrap(p0) === unwrap(p1))
    || (isErr(p0) && isErr(p1) && unwrapErr(p0) === unwrapErr(p1))
    || (isUnresolved(p0) && isUnresolved(p1))
);

export const logUnresolved = <A>(
    p: Type<A, string>,
    where: string,
): p is Unresolved | Err<string> => {
    if (isErr(p)) {
        const err = unwrapErr(p);
        if (!__IS_TEST__) {
            console.error(`Error in ${where}: ${err}`);
        }
        return true;
    }
    if (isUnresolved(p)) {
        if (!__IS_TEST__) {
            console.error(`Could not resolve ${where}`);
        }
        return true;
    }
    return false;
};
