import * as React from 'react';
import styled from 'styled-components';
import {
    CookieBannerTheme,
    COOKIE_BANNER_THEME_DARK,
    COOKIE_BANNER_THEME_LIGHT,
} from '../../store/processes/studioFile/componentConfigs/cookieBanner';

const switchBackgroundColor = (theme: CookieBannerTheme): string => {
    switch (theme) {
        case COOKIE_BANNER_THEME_DARK:
            return '#626262';
        case COOKIE_BANNER_THEME_LIGHT:
            return '#a8a8a8';
    }
};

const Slider = styled.input<{ disable: boolean }>`
    appearance: none;
    position: absolute;
    margin: auto;
    right: 0;
    display: inline-block;
    width: 32px;
    height: 18px;
    opacity: ${(props): number => (props.disable ? 0.46 : 1)};
    border-radius: 9px;
    background-color:
        ${({ theme: { cookieBannerTheme } }): string => (
            switchBackgroundColor(cookieBannerTheme)
        )};

    &::before {
        position: absolute;
        width: 14px;
        height: 14px;
        content: '';
        background-color: #fff;
        top: 50%;
        left: 2px;
        border-radius: 50%;
        transform: translate(0, -50%);
        transition: all 0.2s;
    }

    &:checked::before {
        left: 16px;
    }

    &:checked {
        background-color: #7cb94e;
    }

    transition: all 0.2s;
`;

export const SwitchContainer = styled.span`
    position: relative;
    height: 18px;
`;

type SwitchProps = {
    id: string;
    value: boolean;
    onChange?: (v: boolean) => void;
    disable?: boolean;
};

const Switch = ({
    id,
    value,
    onChange,
    disable = false,
}: SwitchProps): React.ReactElement => {
    const onChangeCallback = React.useCallback(
        () => onChange?.(!value),
        [onChange, value],
    );

    return (
        <Slider
            id={id}
            type="checkbox"
            checked={value}
            onChange={onChangeCallback}
            disable={disable}
        />
    );
};

export default Switch;
