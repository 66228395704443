/* eslint-disable max-len */
import React from 'react';

const Cloudy = (): React.ReactElement => (
    <svg aria-label="cloudy icon" width="128" height="128" viewBox="0 0 128 128">
        <defs>
            <clipPath id="clip-path-cloudy">
                <rect id="Rectangle_8" data-name="Rectangle 8" width="70" height="46.534" fill="none" />
            </clipPath>
            <clipPath id="clip-Cloudy">
                <rect width="128" height="128" />
            </clipPath>
        </defs>
        <g id="Cloudy" clipPath="url(#clip-Cloudy)">
            <rect width="128" height="128" fill="#fff" />
            <g id="Group_16" data-name="Group 16" transform="translate(29 32)">
                <g id="Group_15" data-name="Group 15" transform="translate(0 0)" clipPath="url(#clip-path-cloudy)">
                    <path id="Path_48" data-name="Path 48" d="M55.343,16.288a18.162,18.162,0,0,0-34.487-5.882,18.164,18.164,0,1,0-2.637,36.127l36.729-.09a15.081,15.081,0,0,0,.395-30.155" transform="translate(0 0)" fill="#e6e7ea" />
                </g>
            </g>
        </g>
    </svg>
);

export default Cloudy;
