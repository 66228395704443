/* eslint-disable max-len */
import React from 'react';

const Mist = (): React.ReactElement => (
    <svg aria-label="mist icon" width="128" height="128" viewBox="0 0 128 128">
        <defs>
            <clipPath id="clip-path-mist">
                <rect id="Rectangle_12" data-name="Rectangle 12" width="85" height="49.197" fill="none" />
            </clipPath>
            <clipPath id="clip-Mist">
                <rect width="128" height="128" />
            </clipPath>
        </defs>
        <g id="Mist" clipPath="url(#clip-Mist)">
            <rect width="128" height="128" fill="#fff" />
            <g id="Group_24" data-name="Group 24" transform="translate(22 39)">
                <g id="Group_23" data-name="Group 23" clipPath="url(#clip-path-mist)">
                    <path id="Path_64" data-name="Path 64" d="M47.841,2.96a2.968,2.968,0,0,1-2.96,2.975l-19.15.047A2.967,2.967,0,1,1,25.718.047L44.867,0a2.967,2.967,0,0,1,2.975,2.96" transform="translate(-1.622 0)" fill="#d9d2ca" />
                    <path id="Path_65" data-name="Path 65" d="M55.184,49.454a2.968,2.968,0,0,1-2.96,2.975l-33.986.084a2.967,2.967,0,1,1-.015-5.934l33.986-.084a2.967,2.967,0,0,1,2.975,2.96" transform="translate(-1.088 -3.315)" fill="#d9d2ca" />
                    <path id="Path_66" data-name="Path 66" d="M64.746,14.541a2.968,2.968,0,0,1-2.96,2.975l-48.822.121a2.967,2.967,0,0,1-.015-5.934l48.822-.121a2.967,2.967,0,0,1,2.975,2.96" transform="translate(-0.712 -0.826)" fill="#d9d2ca" />
                    <path id="Path_67" data-name="Path 67" d="M68.11,37.788a2.968,2.968,0,0,1-2.96,2.975l-62.175.153a2.967,2.967,0,0,1-.015-5.934l62.175-.153a2.967,2.967,0,0,1,2.975,2.96" transform="translate(0 -2.483)" fill="#d9d2ca" />
                    <path id="Path_68" data-name="Path 68" d="M86.41,26.114a2.968,2.968,0,0,1-2.96,2.975l-60.692.15a2.967,2.967,0,1,1-.015-5.934l60.692-.15a2.967,2.967,0,0,1,2.975,2.96" transform="translate(-1.41 -1.651)" fill="#d9d2ca" />
                </g>
            </g>
        </g>
    </svg>
);

export default Mist;
