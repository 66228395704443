import * as React from 'react';
import { useMacroScope, makeUseMacros } from './shared/macroHelpers';
import * as RuntimePromise from '../macros/promise';
import { useWindowSize } from './shared/hooks';
import {
    YoutubeOptions,
    VideoSourceType,
    VIDEO_SOURCE_TYPE_PLAYLIST,
    VIDEO_SOURCE_TYPE_USER,
    VIDEO_SOURCE_TYPE_VIDEO,
} from '../store/processes/studioFile/componentConfigs/youtube';
import { ComponentProps } from './shared/types';


const urlFromTypeTerm = async (type: VideoSourceType, term: string): (
    Promise<string | null>) => {
    switch (type) {
        case VIDEO_SOURCE_TYPE_VIDEO:
            return `https://www.youtube.com/embed/${term}?enablejsapi=1`;
        case VIDEO_SOURCE_TYPE_PLAYLIST:
            return [
                'https://www.youtube.com/embed',
                `?listType=playlist&list=${term}&enablejsapi=1`,
            ].join('');
        case VIDEO_SOURCE_TYPE_USER:
            return [
                'https://www.youtube.com/embed',
                `?listType=user_uploads&list=${term}&enablejsapi=1`,
            ].join('');
    }
};

const RATIO = 0.5625;

const YOUTUBE_COMPONENT_NAME = 'YouTube';

const useMacros = makeUseMacros(YOUTUBE_COMPONENT_NAME, {
    term: {},
});

type YoutubeParams = ComponentProps<YoutubeOptions>;

const Youtube = ({
    options,
    macroContext,
}: YoutubeParams): React.ReactElement | null => {
    const { type } = options;

    const [el, setEl] = React.useState<HTMLElement | null>(null);
    const [width, setWidth] = React.useState(0);
    const [url, setUrl] = React.useState<string | null>(null);

    const scope = useMacroScope(macroContext);
    const macros = useMacros(scope, options);
    const { term } = RuntimePromise.unwrapOr(
        { term: null },
        macros,
    );

    React.useEffect((): void => {
        if (!term) { return; }
        const run = async (): Promise<void> => {
            setUrl(await urlFromTypeTerm(type, term));
        };
        run();
    }, [type, term]);

    const windowSize = useWindowSize();

    React.useEffect(
        () => {
            if (el) {
                setWidth(el.offsetWidth);
            }
        },
        [el, windowSize],
    );

    if (!term) {
        return null;
    }

    const updateWidth = (element: HTMLDivElement | null): void => {
        if (element && element.offsetWidth !== width) {
            setEl(element);
        }
    };
    return (
        <div ref={updateWidth}>
            {
                width && url
                    ? (
                        <iframe
                            width={width}
                            height={width * RATIO}
                            src={url}
                            frameBorder="0"
                            aria-label="iframe for youtube content"
                            allowFullScreen
                        />
                    )
                    : null
            }
        </div>
    );
};

export default Youtube;
