import * as React from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import ObjectIdentifier from './objectIdentifier';
import background from '../assets/images/graphic-background@3x.png';
import top from '../assets/images/graphic-topscreen@3x.png';
import middle from '../assets/images/graphic-midscreen@3x.png';
import bottom from '../assets/images/graphic-basescreen@3x.png';

const FallbackDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const HeroDiv = styled.div`
    margin-top: 56px;
    position: relative;
`;

const HeroBackground = styled.img`
    width: 100%;
    max-width: 480px;
    object-fit: contain;
`;

const HeroLayer = styled.img`
    width: 50%;
    position: absolute;
    object-fit: contain;
    margin: 0 auto;
    left: 0;
    right: 0;
`;

const keyframeStart = '40%';
const keyFrameTop = keyframes`
    from {
        opacity: 0;
        top: ${keyframeStart};
    }

    to {
        opacity: 1;
        top: 0;
    }
`;

const keyFrameMiddle = keyframes`
    from {
        opacity: 0;
        top: ${keyframeStart};
    }

    to {
        opacity: 1;
        top: 12%;
    }
`;

const keyFrameBottom = keyframes`
    from {
        opacity: 0;
        top: ${keyframeStart};
    }

    to {
        opacity: 1;
        top: 24%;
    }
`;

const HeroTop = styled(HeroLayer)`
    top: 24%;
    z-index: 3;
    animation: ${keyFrameTop} 0.75s linear 0s 1 normal;
    animation-fill-mode: forwards;
`;

const HeroMiddle = styled(HeroLayer)`
    top: 24%;
    z-index: 2;
    animation: ${keyFrameMiddle} 0.75s linear 0s 1 normal;
    animation-fill-mode: forwards;
`;

const HeroBottom = styled(HeroLayer)`
    top: 24%;
    z-index: 1;
    animation: ${keyFrameBottom} 0.75s linear 0s 1 normal;
    animation-fill-mode: forwards;
`;

const Message = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 31.5px;
`;

const colorBlack = '#000000';
const MessageHeading = styled.h2`
    height: 20px;
    font-size: 22px;
    margin: 0;
    font-weight: 900;
    line-height: 0.5;
    letter-spacing: 0.2px;
    text-align: center;
    color: ${colorBlack};
`;

const colorGrey = '#666666';

const MessageBody = styled.p`
    width: 100%;
    max-width: 250px;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.3px;
    text-align: center;
    color: ${colorGrey};
`;


const MessageText = (): React.ReactElement => (
    __IS_V3__
        ? (
            <span>
                Looks like this experience doesn&#39;t exist.
            </span>
        ) : (
            <span>
                Looks like this mTAG needs an experience.
            </span>
        )
);

const FallbackGlobalStyles = createGlobalStyle`
    body {
        font-family: 'Roboto', sans-serif;
        margin: 0 auto;
        padding: 0;
        letter-spacing: 0.5px;
        line-height: 1.3;
    }
`;

const Missing = (): React.ReactElement => (
    <div>
        <FallbackGlobalStyles />
        <FallbackDiv>
            <HeroDiv>
                <HeroBackground src={background} />
                <HeroTop src={top} />
                <HeroMiddle src={middle} />
                <HeroBottom src={bottom} />
            </HeroDiv>
        </FallbackDiv>
        <Message>
            <MessageHeading>Whoops!</MessageHeading>
            <MessageBody>
                <MessageText />
            </MessageBody>
            {!__IS_V3__ && <ObjectIdentifier />}
        </Message>
    </div>
);

export default Missing;
