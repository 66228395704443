import { LOADABLE_STATUS_LOADING, LOADABLE_STATUS_READY } from '../loadableState';
import { State } from '../index';

export type ScriptStatus = typeof LOADABLE_STATUS_LOADING | typeof LOADABLE_STATUS_READY;

export type LocalState = {
    scriptStatus: Record<string, ScriptStatus>
};

export const UPDATE_SCRIPT_STATUS = 'CUSTOM_COMPONENT/UPDATE_SCRIPT_STATUS' as const;

export type UpdateScriptStatusAction = {
    type: typeof UPDATE_SCRIPT_STATUS;
    status: ScriptStatus;
    uuid: string;
};

export type Action = UpdateScriptStatusAction;

export const updateScriptStatus = (
    uuid: string,
    status: ScriptStatus,
): UpdateScriptStatusAction => ({
    type: UPDATE_SCRIPT_STATUS,
    uuid,
    status,
});

export const selectScriptStatus = (uuid: string) => (state: State)
    : ScriptStatus => (state.customComponents.scriptStatus[uuid]);

const initialState: LocalState = {
    scriptStatus: {},
};

const reducer = (
    state: LocalState = initialState,
    action: Action,
): LocalState => {
    switch (action.type) {
        case UPDATE_SCRIPT_STATUS:
            return {
                ...state,
                scriptStatus: {
                    ...state.scriptStatus,
                    [action.uuid]: action.status,
                },
            };
        default:
            return state;
    }
};

export default reducer;
