import { EventChannel, eventChannel, SagaIterator } from 'redux-saga';
import {
    all,
    call,
    put,
    take,
} from 'redux-saga/effects';
import { StudioFile } from '../studioFile';
import * as LoadableState from '../loadableState';
import { updateScriptStatus } from '../customComponents';

const FONT_PREFIX = 'font_' as const;

const makeFontLoadChannel = (el: HTMLLinkElement): EventChannel<any> => {
    const loadChannel = eventChannel((emitter) => {
        el.addEventListener('load', emitter);
        return () => el.removeEventListener('load', emitter);
    });
    return loadChannel;
};

function* loadCustomFont(fontId: number) {
    const fontSelector = `${FONT_PREFIX}${fontId}`;
    yield put(updateScriptStatus(fontSelector, LoadableState.LOADABLE_STATUS_LOADING));
    let fontEl = document.getElementById(fontSelector) as HTMLLinkElement | null;
    if (!fontEl) {
        fontEl = document.createElement('link');
        fontEl.id = fontSelector;
        fontEl.rel = 'stylesheet';
        fontEl.href = `/api/fonts/${fontId}`;
    }
    const channel = makeFontLoadChannel(fontEl);
    if (!document.head.contains(fontEl)) {
        document.head.appendChild(fontEl);
        yield take(channel);
    }
    yield put(updateScriptStatus(fontSelector, LoadableState.LOADABLE_STATUS_READY));
}

export function* loadCustomFontsSaga(studioFile: StudioFile): SagaIterator<void> {
    const { included_fonts } = studioFile;
    yield all((included_fonts ?? [])
        .map((fontId: number) => call(loadCustomFont, fontId)));
}
