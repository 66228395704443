import * as R from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/browser';
import { getComponentListItems } from '../../store/processes/studioFile';
import Component from '..';

type Props = {
    id: string;
};

const ComponentList: React.ComponentType<Props> = ({ id }) => {
    const componentListItems = useSelector(getComponentListItems(id));

    const makeErrorHandler = (id: string, typeId: string) => (
        (error: any) => {
            Sentry.captureException(error, {
                level: Sentry.Severity.Fatal,
                tags: {
                    'error.source': 'renderer',
                    'component.type.id': typeId,
                },
                extra: {
                    'component.id': id,
                },
            });
        }
    );

    return React.useMemo(() => (
        <ThemeProvider theme={R.assoc('showWidget', id === '0')}>
            <>
                {
                    componentListItems.map(({ id, typeId }) => (
                        <ErrorBoundary
                            key={id}
                            fallback={<></>}
                            onError={makeErrorHandler(id, typeId)}
                        >
                            <Component id={id} />
                        </ErrorBoundary>
                    ))
                }
            </>
        </ThemeProvider>
    ), [componentListItems, id]);
};

export default React.memo(ComponentList);
