import { State } from '../index';
import {
    PagePayload,
    V2PagePayload,
    V3PagePayload,
} from './createPagePayload';
import * as LoadableState from '../loadableState';

const UPDATE_PAGE_PAYLOAD = 'PAGE_PAYLOAD/UPDATE' as const;

type UpdatePagePayloadAction = {
    type: typeof UPDATE_PAGE_PAYLOAD;
    update: LoadableState.Type<PagePayload, string>;
};

export const updatePagePayload = (
    update: LoadableState.Type<PagePayload, string>,
): UpdatePagePayloadAction => ({
    type: UPDATE_PAGE_PAYLOAD,
    update,
});

type Action =
    | UpdatePagePayloadAction
    | { type: 'FALLBACK' };

export type LocalState = LoadableState.Type<PagePayload, string>;

const initialState = LoadableState.Loading;

const reducer = (
    state: LocalState = initialState,
    action: Action,
): LocalState => {
    switch (action.type) {
        case UPDATE_PAGE_PAYLOAD:
            return action.update;
        default:
            return state;
    }
};

export const getPagePayload = (state: State): LocalState => state.pagePayload;

export const isV2 = (payload: PagePayload): payload is V2PagePayload => (
    payload.type === 'V2'
);

export const isV3 = (payload: PagePayload): payload is V3PagePayload => (
    payload.type === 'V3'
);

export type AdPayload = {
    data: string;
    url: string;
};

export const swiperLetters = [
    'e' as const,
    'f' as const,
    'g' as const,
    'h' as const,
    'i' as const,
];

export type SwiperAdPayloadLetter = typeof swiperLetters[number];

export type AdPayloadLetter =
    | 'a'
    | 'b'
    | 'c'
    | 'd'
    | SwiperAdPayloadLetter
    | 'interstitial';

export const getAdPayload = (
    payload: V2PagePayload,
    letter: AdPayloadLetter,
    // payload.legacy?.adPayloads[letter] is either serialized JSON
    // or an empty string so use || instead of ??
): AdPayload => JSON.parse(payload.legacy?.adPayloads[letter] || '{}');

export default reducer;
