import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { State } from '../store/store';
import {
    getAnyFormDone,
    getAnyFormFailed,
} from '../store/processes/forms';
import ComponentList from './shared/componentList';
import * as RuntimePromise from '../macros/promise';
import { useMacroScope, makeUseMacros } from './shared/macroHelpers';
import {
    EventOptions,
    EventComponentLists,
    ConditionalType,
    CONDITIONAL_TYPE_EQL,
    CONDITIONAL_TYPE_NEQL,
    CONDITIONAL_TYPE_GT,
    CONDITIONAL_TYPE_LS,
    CONDITIONAL_TYPE_GTE,
    CONDITIONAL_TYPE_LTE,
    Event,
    FORM_SUBMIT_WHEN_BEFORE,
    EVENT_TYPE_NONE,
    EVENT_TYPE_FORM_SUBMIT,
    EVENT_TYPE_FORM_ERROR,
    EVENT_TYPE_ADVANCED,
    EVENT_TYPE_TAG_VARIABLE_CONDITION,
    EVENT_TYPE_VARIABLE_CONDITION,
} from '../store/processes/studioFile/componentConfigs/event';
import {
    DEVICE_VAR,
    OBJECT_VAR,
    getVariable,
} from '../store/processes/variables';
import { ComponentProps } from './shared/types';

const numberComparisons = (
    type: ConditionalType,
    v1: number,
    v2: number,
): boolean => {
    switch (type) {
        case CONDITIONAL_TYPE_GT:
            return v1 > v2;
        case CONDITIONAL_TYPE_LS:
            return v1 < v2;
        case CONDITIONAL_TYPE_GTE:
            return v1 >= v2;
        case CONDITIONAL_TYPE_LTE:
            return v1 <= v2;
        default:
            return false;
    }
};

const variableCondition = (
    type: ConditionalType,
    v1: string | null | undefined,
    v2: string | null | undefined,
): boolean => {
    switch (type) {
        case CONDITIONAL_TYPE_EQL:
            return v1 === v2;
        case CONDITIONAL_TYPE_NEQL:
            return v1 !== v2;
        default: {
            return numberComparisons(
                type,
                parseInt(v1 ?? 'NaN', 10),
                parseInt(v2 ?? 'NaN', 10),
            );
        }
    }
};

const createPredicate = (event: Event): ((s: State) => boolean) => {
    switch (event.type) {
        case EVENT_TYPE_NONE:
            return (): boolean => false;
        case EVENT_TYPE_FORM_SUBMIT:
            return R.pipe(
                getAnyFormDone,
                (
                    event.when === FORM_SUBMIT_WHEN_BEFORE
                        ? R.not
                        : R.identity
                ),
            );
        case EVENT_TYPE_FORM_ERROR:
            return getAnyFormFailed;
        case EVENT_TYPE_VARIABLE_CONDITION:
            return (state: State): boolean => {
                const v1 = getVariable(DEVICE_VAR, event.varName)(state);
                // user cannot enter null or undefined as a conditional value
                const v2 = event.conditionalValue ?? '';
                return variableCondition(event.conditionalType, v1, v2);
            };
        case EVENT_TYPE_TAG_VARIABLE_CONDITION: {
            return (state: State): boolean => {
                const v1 = getVariable(OBJECT_VAR, event.varName)(state);
                // user cannot enter null or undefined as a conditional value
                const v2 = event.conditionalValue ?? '';
                return variableCondition(event.conditionalType, v1, v2);
            };
        }
        case EVENT_TYPE_ADVANCED: {
            const { valOne, valTwo } = event;
            // user cannot enter null or undefined as a conditional value
            return (): boolean => (
                variableCondition(event.conditionalType, valOne ?? '', valTwo ?? '')
            );
        }
        default:
            return (): boolean => false;
    }
};

type EventParameters = ComponentProps<EventOptions, EventComponentLists>;

const EVENT_COMPONENT_NAME = 'Event';

const defaultEvent = {
    varName: '',
    conditionalValue: '',
    valOne: '',
    valTwo: '',
};

const useMacros = makeUseMacros(EVENT_COMPONENT_NAME, {
    varName: {},
    conditionalValue: {},
    valOne: {},
    valTwo: {},
});

const EventComponent = ({
    options,
    componentLists,
    macroContext,
}: EventParameters): React.ReactElement | null => {
    const macroScope = useMacroScope(macroContext);

    const rawEvent = options.events[0];

    const optionsWithDefault = React.useMemo(() => ({
        ...defaultEvent,
        ...rawEvent,
    }), [rawEvent]);
    const macros = useMacros(
        macroScope,
        optionsWithDefault,
    );

    const predicate = React.useMemo(
        () => (
            RuntimePromise.isSuccess(macros)
                ? createPredicate({
                    ...rawEvent,
                    ...RuntimePromise.unwrap(macros),
                })
                : (): boolean => false
        ),
        [rawEvent, macros],
    );

    const passed = useSelector(predicate);

    return (
        passed
            ? <ComponentList id={componentLists.content} />
            : null
    );
};

export default EventComponent;
