import storage from './storage';

const bbStudioVars = (id: string): string => `BB_STUDIO_VARS-${id}`;

export const getBBStudioVars = (id: string): any => (
    (
        !__IS_PREVIEW__
        && JSON.parse(storage.get(bbStudioVars(id)) || '{}')
    ) || {}
);
export const setBBStudioVars = (id: string, v: any): void => {
    if (!__IS_PREVIEW__) {
        storage.set(bbStudioVars(id), JSON.stringify(v));
    }
};
