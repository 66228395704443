import * as React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getPagePayload } from '../../store/processes/pagePayload';
import * as LoadableState from '../../store/processes/loadableState';
import Widget from '../shared/widget';
import verifiedImage from './verified.gif';
import unverifiedImage from './unverified.gif';

const VerifiedBody = styled.div`
    height: inherit;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const VerifiedImage = styled.img`
    width: 200px;
    height: 200px;
    object-fit: contain;
`;

const Verified = (): React.ReactElement => {
    const loadablePagePayload = useSelector(getPagePayload);

    const pagePayload = LoadableState.unwrapOr(null, loadablePagePayload);

    const {
        supported = false,
        result = false,
    } = pagePayload?.sdkData?.interaction?.touchpoint?.verification ?? {};

    const verified = supported && result;

    return (
        <Widget>
            <VerifiedBody>
                {
                    verified
                        ? (
                            <VerifiedImage
                                src={verifiedImage}
                                alt="Animated verification success checkmark"
                            />
                        )
                        : (
                            <VerifiedImage
                                src={unverifiedImage}
                                alt='Animated verification failure "X"'
                            />
                        )
                }
            </VerifiedBody>
        </Widget>
    );
};

export default Verified;
