import React from 'react';
import styled from 'styled-components';

const LoaderDiv = styled.div<{height: string }>`
    height: ${(props): string => props.height};
    display: flex;
    justify-content: center;
`;

const LoaderWrap = styled.div`
    display: flex;
    align-self: center;
`;

type LoaderProps = {
    height: string;
    loading: boolean;
    children: React.ReactElement;
}

const Loader = ({
  height,
  loading,
  children,
}: LoaderProps): React.ReactElement => (
    !loading
      ? <>{children}</>
      : (
          <LoaderDiv height={height}>
              <LoaderWrap>
                  <svg
                      width={30}
                      height={30}
                      viewBox="0 0 105 105"
                      fill="#0087CB"
                  >
                      <circle cx={12.5} cy={12.5} r={12.5}>
                          <animate
                              attributeName="fill-opacity"
                              begin="0s"
                              dur="1s"
                              values="1;.2;1"
                              calcMode="linear"
                              repeatCount="indefinite"
                          />
                      </circle>
                      <circle cx={12.5} cy={52.5} r={12.5} fillOpacity={0.5}>
                          <animate
                              attributeName="fill-opacity"
                              begin="100ms"
                              dur="1s"
                              values="1;.2;1"
                              calcMode="linear"
                              repeatCount="indefinite"
                          />
                      </circle>
                      <circle cx={52.5} cy={12.5} r={12.5}>
                          <animate
                              attributeName="fill-opacity"
                              begin="300ms"
                              dur="1s"
                              values="1;.2;1"
                              calcMode="linear"
                              repeatCount="indefinite"
                          />
                      </circle>
                      <circle cx={52.5} cy={52.5} r={12.5}>
                          <animate
                              attributeName="fill-opacity"
                              begin="600ms"
                              dur="1s"
                              values="1;.2;1"
                              calcMode="linear"
                              repeatCount="indefinite"
                          />
                      </circle>
                      <circle cx={92.5} cy={12.5} r={12.5}>
                          <animate
                              attributeName="fill-opacity"
                              begin="800ms"
                              dur="1s"
                              values="1;.2;1"
                              calcMode="linear"
                              repeatCount="indefinite"
                          />
                      </circle>
                      <circle cx={92.5} cy={52.5} r={12.5}>
                          <animate
                              attributeName="fill-opacity"
                              begin="400ms"
                              dur="1s"
                              values="1;.2;1"
                              calcMode="linear"
                              repeatCount="indefinite"
                          />
                      </circle>
                      <circle cx={12.5} cy={92.5} r={12.5}>
                          <animate
                              attributeName="fill-opacity"
                              begin="700ms"
                              dur="1s"
                              values="1;.2;1"
                              calcMode="linear"
                              repeatCount="indefinite"
                          />
                      </circle>
                      <circle cx={52.5} cy={92.5} r={12.5}>
                          <animate
                              attributeName="fill-opacity"
                              begin="500ms"
                              dur="1s"
                              values="1;.2;1"
                              calcMode="linear"
                              repeatCount="indefinite"
                          />
                      </circle>
                      <circle cx={92.5} cy={92.5} r={12.5}>
                          <animate
                              attributeName="fill-opacity"
                              begin="200ms"
                              dur="1s"
                              values="1;.2;1"
                              calcMode="linear"
                              repeatCount="indefinite"
                          />
                      </circle>
                  </svg>
              </LoaderWrap>
          </LoaderDiv>
      )
);

export default Loader;
