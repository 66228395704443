import * as R from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import 'swiper/css/swiper.css';
import { getComponentListItems, StudioComponentSharedConfig } from '../store/processes/studioFile';
import Component from '.';
import {
    GalleryOptions,
    GalleryComponentLists,
} from '../store/processes/studioFile/componentConfigs/gallery';
import { ComponentProps } from './shared/types';
import Widget from './shared/widget';

const GalleryWidget = styled(Widget)`
    padding: ${({ theme }): string | null => (theme.showWidget ? '12px' : null)};

    img {
        width: 100%;
    }
`;

const GalleryList = styled.div`
    display: flex;
    box-sizing: border-box;

    &:not(:last-child) {
        padding-bottom: 8px;
    }
`;

const GalleryListDiv = styled.div<{ rowSize: number; lastItem: boolean }>`
    width:
        ${(props): string => (
            `calc(${100 / props.rowSize}% - ${((props.rowSize - 1) * 8) / props.rowSize}px)`
        )};
    padding-right: ${(props): string => (props.lastItem ? '0px' : '8px')};
`;

const SwiperContainer = styled.div`
    padding-bottom: 24px;
`;

type SwiperProps = {
    id: string;
    componentListItems: Array<StudioComponentSharedConfig>;
};

const Swiper = ({
    id,
    componentListItems,
}: SwiperProps): React.ReactElement => (
    <SwiperContainer className={`swiper-container component-${id}`}>
        <div className="swiper-wrapper">
            {
                componentListItems.map(({ id }) => (
                    <div
                        key={id}
                        className="swiper-slide"
                    >
                        <Component id={id} />
                    </div>
                ))
            }
        </div>
        <div
            className="swiper-pagination swiper-pagination-black"
        />
    </SwiperContainer>
);

type GridProps = {
    componentListItems: Array<StudioComponentSharedConfig>;
    rowSize: string;
}

const Grid = ({
    componentListItems,
    rowSize,
}: GridProps): React.ReactElement => {
    const parsedRowSize = parseInt(rowSize, 10) || 3;
    const rows = R.splitEvery(parsedRowSize, componentListItems);

    return (
        <div>
            { rows.map((row, rowIdx) => (
                /* eslint-disable-next-line react/no-array-index-key */
                <GalleryList key={rowIdx}>
                    {
                        row.map(({ id }, idx) => {
                            const lastItem = (idx + 1) % parsedRowSize === 0;
                            return (
                                <GalleryListDiv
                                    rowSize={parsedRowSize}
                                    lastItem={lastItem}
                                    key={id}
                                >
                                    <Component id={id} />
                                </GalleryListDiv>
                            );
                        })
                    }
                </GalleryList>
            ))}
        </div>
    );
};

type GalleryProps = ComponentProps<GalleryOptions, GalleryComponentLists>;

const Gallery = ({
    id,
    options,
    componentLists: { content },
}: GalleryProps): React.ReactElement | null => {
    React.useEffect(() => {
        (async (): Promise<void> => {
            const { default: SwiperPackage } = await
                import(/* webpackChunkName: "swiper" */ 'swiper');

            /* eslint-disable-next-line no-new */
            new SwiperPackage(`.swiper-container.component-${id}`, {
                direction: 'horizontal',
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                },
            });
        })();
    }, [id]);

    const componentListItems = useSelector(getComponentListItems(content));
    return (
        <GalleryWidget>
            <ThemeProvider theme={R.assoc('showWidget', false)}>
                { React.useMemo(
                    () => {
                        if (options.type === 'grid') {
                            return (
                                <Grid
                                    componentListItems={componentListItems}
                                    rowSize={options.rowSize}
                                />
                            );
                        }
                        if (options.type === 'swiper') {
                            return (
                                <Swiper
                                    id={id}
                                    componentListItems={componentListItems}
                                />
                            );
                        }
                    },
                    [componentListItems, id, options.rowSize, options.type],
                )}
            </ThemeProvider>
        </GalleryWidget>
    );
};

export default Gallery;
