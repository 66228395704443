import 'whatwg-fetch';
import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import 'core-js/stable';
import { ErrorBoundary } from 'react-error-boundary';
import createStore from './store/store';
import { globalInit } from './store/processes/init';
import Experience from './experience';
import Missing from './missing';
import { makeAllowedErrorUrlRegEx } from './utils/errors';

if (!__IS_LOCAL__) {
    Sentry.init({
        dsn: __SENTRY_DSN_URL__,
        environment: __DEPLOYMENT_ENV__,
        allowUrls: [makeAllowedErrorUrlRegEx(window.location)],
    });
}

const store = createStore();
store.dispatch(globalInit());

const onError = (error: any) => {
    if (!__IS_LOCAL__) {
        Sentry.captureException(error, {
            tags: {
                'error.source': 'renderer',
                'component.type': 'approot',
            },
        });
    }
};

ReactDOM.render(
    <ErrorBoundary onError={onError} fallback={<Missing />}>
        <Provider store={store}>
            <Experience />
        </Provider>
    </ErrorBoundary>,
    document.getElementById('experience-app'),
);
