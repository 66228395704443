/* eslint-disable max-len */
import React from 'react';

const PartlyCloudyDay = (): React.ReactElement => (
    <svg aria-label="partly cloudy day icon" width="128" height="128" viewBox="0 0 128 128">
        <defs>
            <clipPath id="clip-path-partly-cloudy">
                <rect id="Rectangle_3" data-name="Rectangle 3" width="85" height="62.817" fill="none" />
            </clipPath>
            <clipPath id="clip-PartlyCloudyDay">
                <rect width="128" height="128" />
            </clipPath>
        </defs>
        <g id="PartlyCloudyDay" clipPath="url(#clip-PartlyCloudyDay)">
            <rect width="128" height="128" fill="#fff" />
            <g id="Group_6" data-name="Group 6" transform="translate(22 33)">
                <g id="Group_5" data-name="Group 5" transform="translate(0 0)" clipPath="url(#clip-path-partly-cloudy)">
                    <path id="Path_11" data-name="Path 11" d="M78.432,32.183A14.508,14.508,0,1,1,63.888,17.711,14.508,14.508,0,0,1,78.432,32.183" transform="translate(-8.366 -2.999)" fill="#f3d33f" />
                    <path id="Path_12" data-name="Path 12" d="M66.58,8.667a1.662,1.662,0,0,1-1.666-1.657L64.9,1.666a1.661,1.661,0,0,1,3.323-.008L68.237,7A1.661,1.661,0,0,1,66.58,8.667" transform="translate(-10.988 0)" fill="#f3d33f" />
                    <path id="Path_13" data-name="Path 13" d="M98.9,36.828l-5.345.013a1.661,1.661,0,1,1-.007-3.323l5.344-.013a1.661,1.661,0,0,1,.008,3.323" transform="translate(-15.557 -5.673)" fill="#f3d33f" />
                    <path id="Path_14" data-name="Path 14" d="M38.653,36.977l-5.344.013a1.661,1.661,0,0,1-.008-3.323l5.344-.013a1.661,1.661,0,1,1,.008,3.323" transform="translate(-5.357 -5.698)" fill="#f3d33f" />
                    <path id="Path_15" data-name="Path 15" d="M85.793,17.087a1.661,1.661,0,0,1-1.182-2.834l3.77-3.788a1.661,1.661,0,1,1,2.355,2.344L86.966,16.6a1.658,1.658,0,0,1-1.174.489" transform="translate(-14.243 -1.689)" fill="#f3d33f" />
                    <path id="Path_16" data-name="Path 16" d="M43.093,59.5a1.661,1.661,0,0,1-1.181-2.834L45.68,52.88a1.661,1.661,0,1,1,2.355,2.344l-3.769,3.788a1.658,1.658,0,0,1-1.174.489" transform="translate(-7.014 -8.87)" fill="#f3d33f" />
                    <path id="Path_17" data-name="Path 17" d="M46.763,16.814a1.657,1.657,0,0,1-1.175-.483L41.8,12.56a1.661,1.661,0,1,1,2.344-2.355l3.788,3.769a1.661,1.661,0,0,1-1.168,2.839" transform="translate(-6.994 -1.646)" fill="#f3d33f" />
                    <path id="Path_18" data-name="Path 18" d="M89.691,59.494a1.661,1.661,0,0,1-1.163-.47L84.7,55.3a1.662,1.662,0,0,1,2.318-2.382l3.831,3.726a1.662,1.662,0,0,1-1.155,2.853" transform="translate(-14.255 -8.88)" fill="#f3d33f" />
                    <path id="Path_19" data-name="Path 19" d="M66.729,69.038a1.662,1.662,0,0,1-1.666-1.657l-.013-5.344a1.661,1.661,0,1,1,3.323-.008l.013,5.344a1.661,1.661,0,0,1-1.657,1.666" transform="translate(-11.013 -10.221)" fill="#f3d33f" />
                    <path id="Path_20" data-name="Path 20" d="M53.548,37.179a17.573,17.573,0,0,0-33.369-5.691,17.575,17.575,0,1,0-2.551,34.956l35.538-.087a14.592,14.592,0,0,0,.382-29.177" transform="translate(0 -3.627)" fill="#e6e7ea" />
                </g>
            </g>
        </g>
    </svg>
);

export default PartlyCloudyDay;
