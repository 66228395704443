import * as React from 'react';
import {
    DelayOptions,
    DelayComponentLists,
    SHOW_AFTER,
} from '../store/processes/studioFile/componentConfigs/delay';
import { ComponentProps } from './shared/types';
import ComponentList from './shared/componentList';


type DelayProps = ComponentProps<DelayOptions, DelayComponentLists>;

const Delay = ({
    options,
    componentLists: { content },
}: DelayProps): React.ReactElement | null => {
    const [triggered, setTriggered] = React.useState(false);

    const show = options.show ?? SHOW_AFTER;
    const delay = Math.round(parseFloat(options.delay) * 1000);

    React.useEffect(
        () => {
            if (Number.isNaN(delay)) {
                return undefined;
            }
            const t = setTimeout(
                () => {
                    setTriggered(true);
                },
                delay,
            );
            return (): void => {
                clearTimeout(t);
            };
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [],
    );

    // show decides if we are matching when triggered is true or false
    return (
        triggered === (show === SHOW_AFTER)
            ? <ComponentList id={content} />
            : null
    );
};

export default Delay;
