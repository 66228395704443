import type { SagaIterator } from 'redux-saga';
import { takeLatest, getContext, call } from 'redux-saga/effects';
import type { ExperienceManagerInitializer } from './initialize';
import type { ExperienceManager } from './manager';
import { EXPERIENCE_LINK } from '../links';
import cursor from './inspectCursor.svg';

const START_INSPECTOR = 'START_INSPECTOR';
const STOP_INSPECTOR = 'STOP_INSPECTOR';

export const startInspector = () => ({ type: START_INSPECTOR });
export const stopInspector = () => ({ type: STOP_INSPECTOR });

export function* saga(): SagaIterator {
    const managerInitializer: ExperienceManagerInitializer = (
        yield getContext('previewManagerInitializer')
    );
    const manager: ExperienceManager = yield call(() => managerInitializer.waitForInitialization());

    const inspector = new Inspector();
    yield takeLatest(START_INSPECTOR, () => {
        inspector.start();
    });
    yield takeLatest(STOP_INSPECTOR, () => {
        inspector.stop();
    });
    yield takeLatest(EXPERIENCE_LINK, () => {
        inspector.stop();
    });
    inspector.watchForSelection((id) => {
        manager.inspectComponent(id);
    });
}

const BLOCKED_EVENTS = [
    'click',
    'dblclick',
    'mousedown',
    'mouseup',
    'pointerdown',
    'pointerup',
] as const;

const STYLES_ID = 'bb-inspector-styles';
const BORDER_COLOR = '#1a65ef';

export class Inspector {
    el?: HTMLElement;

    border: HTMLElement;

    hoverCallback: (e: MouseEvent) => void;

    clickCallback?: (e: MouseEvent) => void;

    resizeCallback: () => void;

    stopPropagationCallback: (e: MouseEvent) => void;

    resizeObserver: ResizeObserver;

    running = false;

    constructor() {
        this.resizeObserver = new ResizeObserver(() => {
            if (!this.el) {
                return;
            }
            this.highlight(this.el);
        });
        this.stopPropagationCallback = (event) => {
            event.stopImmediatePropagation();
            event.preventDefault();
        };
        this.hoverCallback = (event) => {
            event.stopImmediatePropagation();
            event.preventDefault();
            const lookup = findReactFiber(event.target as HTMLElement);
            if (!lookup) { return; }
            this.highlight(lookup.el);
        };
        this.resizeCallback = () => {
            this.highlight(this.el);
        };
        if (!document.getElementById(STYLES_ID)) {
            const styles = document.createElement('style');
            styles.id = STYLES_ID;
            styles.type = 'text/css';
            styles.appendChild(document.createTextNode(`
                body[data-bb-inspector=true] * {
                    cursor: url("${cursor}"), pointer !important;
                }

                .bb-inspector-border {
                    pointer-events: none;
                    position: absolute;
                    border: 2px dashed ${BORDER_COLOR};
                }
            `));
            document.head.appendChild(styles);
        }
        this.border = document.createElement('div');
        this.border.className = 'bb-inspector-border';
    }


    // This can return more then one time so we need a callback
    /* eslint-disable-next-line promise/prefer-await-to-callbacks */
    watchForSelection(callback: (id: number) => void) {
        if (this.clickCallback) {
            removeEventListener('click', this.clickCallback, true);
        }
        this.clickCallback = (event) => {
            event.preventDefault();
            event.stopImmediatePropagation();
            const lookup = findReactFiber(event.target as HTMLElement);
            if (!lookup) { return; }
            const { id, el } = lookup;
            if (el !== this.el) {
                return;
            }
            /* eslint-disable-next-line promise/prefer-await-to-callbacks */
            callback(parseInt(id, 10));
        };
        if (this.running) {
            addEventListener('click', this.clickCallback, true);
        }
    }

    highlight(el: HTMLElement | undefined) {
        if (!el || this.el !== el) {
            this.resizeObserver.disconnect();
            if (el) {
                this.resizeObserver.observe(el);
            }
        }
        this.el = el;
        if (!el) {
            this.border.remove();
            return;
        }
        document.body.appendChild(this.border);
        this.border.style.width = `${el.offsetWidth - 4}px`;
        this.border.style.height = `${el.offsetHeight - 4}px`;
        const { x, y } = el.getBoundingClientRect();
        this.border.style.left = `${x + window.scrollX}px`;
        this.border.style.top = `${y + window.scrollY}px`;
    }

    start() {
        this.running = true;
        document.body.dataset.bbInspector = 'true';
        addEventListener('mouseover', this.hoverCallback);
        addEventListener('resize', this.resizeCallback);
        if (this.clickCallback) {
            addEventListener('click', this.clickCallback, true);
        }
        for (const e of BLOCKED_EVENTS) {
            addEventListener(e, this.stopPropagationCallback, true);
        }
    }

    stop() {
        this.running = false;
        delete document.body.dataset.bbInspector;
        removeEventListener('mouseover', this.hoverCallback);
        removeEventListener('resize', this.resizeCallback);
        if (this.clickCallback) {
            removeEventListener('click', this.clickCallback, true);
        }
        for (const e of BLOCKED_EVENTS) {
            removeEventListener(e, this.stopPropagationCallback, true);
        }
        this.border?.remove();
        this.resizeObserver.disconnect();
    }
}


/* eslint-disable-next-line max-len */
// Taken from https://stackoverflow.com/questions/29321742/react-getting-a-component-from-a-dom-element-for-debugging
// https://github.com/Venryx/mobx-devtools-advanced/blob/master/Docs/TreeTraversal.md
// may break in future react versions but implementation should change with style work.
const findReactFiber = (dom: any): null | { id: string, el: HTMLElement } => {
    let el = dom;
    let fiber = null;
    while (!fiber && el) {
        const key = Object.keys(el).find((key) => key.startsWith('__reactFiber$')) as string;
        fiber = el?.[key];
        el = el?.parentElement;
    }

    let lastStateNode = fiber?.stateNode ?? null;
    while (fiber && !(typeof fiber.type === 'function' && fiber.type.bbInspectorTag)) {
        fiber = fiber.return;
        if (fiber && fiber.stateNode) {
            lastStateNode = fiber.stateNode;
        }
    }

    if (!(fiber && lastStateNode)) {
        return null;
    }
    return { id: fiber.memoizedProps.id, el: lastStateNode };
};
