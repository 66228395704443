import * as React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import {
    getForm,
    formSetAttribute,
    FORM_STATUS_SETUP,
} from '../../store/processes/forms';
import {
    useMacroScope,
    useMacro,
    RuntimePromise,
    MacroContext,
} from '../shared/macroHelpers';
import {
    SelectOptions,
} from '../../store/processes/studioFile/componentConfigs/select';
import {
    Validation,
    VALIDATION_REQUIRED,
    validationsArray,
} from '../../store/processes/studioFile/componentConfigs/shared/form';
import { FormLabel } from './shared';
import { State } from '../../store/processes';

const FormSelect = styled.select`
    display: block;
    width: ${({ theme }) => theme?.formTheme?.inputWidth};
    margin: ${({ theme }) => theme?.formTheme?.inputMargins};
`;

const componentName = 'Select';

type SelectProps = {
    id: string;
    options: SelectOptions;
    macroContext: MacroContext;
};

const getArray = (s: string): Array<string> => s.split(',').map(R.trim);

const Select = ({
    id,
    options,
    macroContext,
}: SelectProps): React.ReactElement | null => {
    const scope = useMacroScope(macroContext);
    const label = RuntimePromise.unwrapOr('', useMacro(
        options.label ?? '',
        scope,
        {
            componentName,
            optionName: 'label',
        },
    ));
    const optionLabels = RuntimePromise.unwrapOr('', useMacro(
        options.optionLabels ?? '',
        scope,
        {
            componentName,
            optionName: 'option labels',
        },
    ));
    const defaultValuePromise = useMacro(
        options.defaultValue ?? '',
        scope,
        {
            componentName,
            optionName: 'default value',
        },
    );
    const required = React.useMemo(
        () => (
            validationsArray(options.formValidations ?? [])
                .some((v: Validation): boolean => v.type === VALIDATION_REQUIRED && v.on)
        ),
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [],
    );
    const optionValues = options.optionValues ?? '';
    const name = options.name ?? '';

    const form = useSelector((state: State) => getForm(state, macroContext.formId ?? '-1'));
    const value = RuntimePromise.unwrapOr(
        '',
        form?.data[options.name] ?? defaultValuePromise,
    );

    const selectOptions: Array<[string, string]> = React.useMemo(
        () => R.zip(
            getArray(optionLabels),
            getArray(optionValues),
        ),
        [optionLabels, optionValues],
    );

    const dispatch = useDispatch();
    const setValue = React.useCallback(
        (val: string): void => {
            if (form) {
                dispatch(formSetAttribute(
                    form.id,
                    options.name,
                    RuntimePromise.Success(val),
                    options.formValidations,
                ));
            }
        },
        [dispatch, form, options.formValidations, options.name],
    );

    React.useEffect(() => {
        if (form?.status === FORM_STATUS_SETUP) {
            setValue(RuntimePromise.unwrapOr('', defaultValuePromise));
        }
    }, [defaultValuePromise, form, setValue]);

    const valueString = String(value);

    React.useEffect(() => {
        if (
            valueString !== ''
            && !selectOptions.some(([, value]) => value === valueString)
        ) { setValue(''); }
    }, [selectOptions, setValue, value, valueString]);

    const htmlId = React.useMemo(() => `input-${id}`, [id]);
    return (
        <>
            <FormLabel htmlFor={htmlId}>{label}</FormLabel>
            <FormSelect
                id={htmlId}
                name={name}
                value={String(value)}
                onChange={(e): void => setValue(e.target.value)}
            >
                <option
                    key="ES_SELECT_BLANK_VALUE"
                    value=""
                    disabled={required}
                    aria-label="Blank Value"
                />
                {selectOptions.map(
                    ([label, value]) => <option key={value} value={value}>{label}</option>,
                )}
            </FormSelect>
        </>
    );
};

export default Select;
