import { V3PagePayload, V3SDKInteractionData } from '../pagePayload';

export type OnLocalVariableChangeCallback = (
    key: string,
    value: string | undefined,
    oldMap: { [key: string]: string },
) => void;

export type OnObjectVariablesChangeCallback = (
    updates: Record<string, string | undefined | null>,
    oldMap: { [key: string]: string },
) => void;

export type LocalVariableUpdateAction = {
    type: 'SetLocalVariable',
    key: string,
    value: string | undefined,
};

export type ObjectVariablesUpdateAction = {
    type: 'SetObjectVariables',
    updates: Record<string, string>,
    resolve: (value: void | PromiseLike<void>) => void,
    reject: (r?: any) => void,
};

export type LocalVariableEmitter = (e: LocalVariableUpdateAction) => void;

export type ObjectVariableEmitter = (e: ObjectVariablesUpdateAction) => void;

export interface BBObject {
    uuid: string;
    data: { [key: string]: string };
    variables: { [key: string]: string },
}

export interface Project {
    id: number;
    name: string;
    data: { [jey: string]: string },
}

export interface Location { // todo finish this
    timezone: string;
    latitude: number;
    longitude: number;
    postalCode: string;
    continent: {
        code: string;
        name: string;
    };
    country: {
        isoCode: string;
        name: string;
    };
    subdivision1: {
        isoCode: string;
        name: string;
    } | null;
    subdivision2: {
        isoCode: string;
        name: string;
    } | null;
    city: {
        geonameId: string;
        name: string;
    };
}

type PotentiallySupportedResult<T> = {
    supported: false, result: null;
} | {
    supported: true, result: T;
};

interface InteractionData {
    token: string;
    timestamp: string;
    touchpoint: {
        identifiers: Record<string, string>;
        slug: string;
        tamper: PotentiallySupportedResult<boolean>;
        verification: PotentiallySupportedResult<boolean>;
        technology: string;
        url: {
            href: string;
            searchParams?: Record<string, string>;
        };
    };
}

export interface Interaction extends InteractionData {
    object: BBObject;
    project: Project | null;
    location: Location;
}

export type InteractionLoading<T> =
    { isLoading: true }
    | { isLoading: false, isError: true }
    | (T & { isLoading: false, isError: false });

export const convertPagePayloadToInteraction = (pagePayload: V3PagePayload): Interaction | null => {
    if (!pagePayload || !pagePayload.sdkData.interaction) { return null; }

    const { sdkData } = pagePayload;
    const { object, project, location } = sdkData;
    const sdkInteraction = sdkData.interaction as V3SDKInteractionData;
    const { touchpoint } = sdkInteraction;
    const interaction: Interaction = {
        token: pagePayload.eventToken ?? '',
        timestamp: sdkData.interaction?.timestamp
            ?? new Date(window.macroPageLoadEpoch as number).toISOString(),
        object: {
            uuid: '00000000-00000000-00000000-00000000',
            data: {}, // or name this attributes instead?
            variables: {},
            ...object,
        },
        project: !project
            || typeof project.id === 'undefined'
            || typeof project.name === 'undefined' ? null : {
            id: project.id,
            name: project.name,
            data: project.data ?? {},
        },
        touchpoint,
        location: {
            timezone: location?.timezone ?? 'Etc/Universal',
            latitude: location?.latitude ?? 0.0,
            longitude: location?.longitude ?? 0.0,
            postalCode: location?.postal_code ?? '',
            continent: {
                code: location?.continent?.code ?? '',
                name: location?.continent?.name ?? '',
            },
            country: {
                isoCode: location?.country?.iso_code ?? '',
                name: location?.country?.name ?? '',
            },
            subdivision1: !location?.subdivision_1?.iso_code
                && !location?.subdivision_1?.name ? null : {
                isoCode: location.subdivision_1.iso_code as string,
                name: location.subdivision_1.name as string,
            },
            subdivision2: !location?.subdivision_2?.iso_code
                && !location?.subdivision_2?.name ? null : {
                isoCode: location.subdivision_2.iso_code as string,
                name: location.subdivision_2.name as string,
            },
            city: {
                geonameId: location?.city?.geoname_id ?? '',
                name: location?.city?.name ?? '',
            },
        },
    };
    return JSON.parse(JSON.stringify(interaction));
};

export type BBStudioUseLocalVariablesHook = () => { [key: string]: string };

export type BBStudioUseLocalVariableHook = (key: string, defaultValue?: string)
    => [string | undefined, (value?: string) => void];

export type BBStudioUseInteractionHook = ()
    => InteractionLoading<{
        interaction: Interaction
    }>;

export type BBStudioSDKHooks = {
    useLocalVariables: BBStudioUseLocalVariablesHook;
    useLocalVariable: BBStudioUseLocalVariableHook;
    useInteraction: BBStudioUseInteractionHook;
}

export type BBStudioSDK = {
    hooks: BBStudioSDKHooks;
};
