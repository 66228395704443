import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { all } from 'redux-saga/effects';
import { saga as initSaga } from './init';
import {
    reducer as variablesReducer,
} from './variables';
import {
    reducer as studioFileReducer,
    saga as studioFileSaga,
    LocalState as StudioFileState,
} from './studioFile';
import {
    reducer as pagePayloadReducer,
    LocalState as PagePayloadState,
} from './pagePayload';
import {
    reducer as formsReducer,
    saga as formsSaga,
} from './forms';
import {
    reducer as locationReducer,
    saga as locationSaga,
    LocalState as LocationState,
} from './location';
import { saga as linksSaga } from './links';
import { saga as actionsSaga } from './actions';
import {
    reducer as RootStudioFileReducer,
    LocalState as RootStudioFileLocalState,
} from './rootStudioFile';
import {
    reducer as cookieConsentReducer,
    LocalState as CookieConsentState,
} from './cookieConsent';
import { reducer as buttonReducer } from './buttons';
import {
    reducer as pageReducer,
    LocalState as PageState,
    saga as pageSaga,
} from './page';
import {
    reducer as customComponentsReducer,
    LocalState as CustomComponentsState,
} from './customComponents';
import { RendererSDKManagerInitializer } from './rendererSDK';
import { ExperienceManagerInitializer } from './xpManager';

export type State = {
    variables: any;
    studioFile: StudioFileState;
    forms: any;
    rootStudioFile: RootStudioFileLocalState;
    location: LocationState;
    buttons: any;
    pagePayload: PagePayloadState;
    cookieConsent: CookieConsentState;
    page: PageState;
    customComponents: CustomComponentsState;
};

export type Selector<A> = (state: State) => A;

export const reducer = combineReducers<State>({
    variables: variablesReducer,
    studioFile: studioFileReducer,
    forms: formsReducer,
    rootStudioFile: RootStudioFileReducer,
    location: locationReducer,
    buttons: buttonReducer,
    pagePayload: pagePayloadReducer,
    cookieConsent: cookieConsentReducer,
    page: pageReducer,
    customComponents: customComponentsReducer,
});

export function* saga(): SagaIterator<void> {
    yield all([
        linksSaga(),
        studioFileSaga(),
        actionsSaga(),
        formsSaga(),
        locationSaga(),
        pageSaga(),
        initSaga(),
    ]) as any;
}

export type ReduxContext = {
    rendererSDKManagerInitializer: RendererSDKManagerInitializer,
    previewManagerInitializer : ExperienceManagerInitializer,
};
export const createContext = (): ReduxContext => ({
    rendererSDKManagerInitializer: new RendererSDKManagerInitializer(),
    previewManagerInitializer: new ExperienceManagerInitializer(),
});
