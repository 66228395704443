import * as R from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as LoadableState from '../store/processes/loadableState';
import {
    getPagePayload,
    isV2,
    V3PagePayload,
    V2PagePayload,
} from '../store/processes/pagePayload';

const RELEVANT_IDENTIFIER_TAG_DATA = ['serial_number', 'alias'];

const IdentifierList = styled.ul`
    list-style-type: none;
    padding-left: 0;
`;

const UnknownIdentifiers = (): React.ReactElement => (
    <IdentifierList>
        <li key="unknown">
            Details Unknown
        </li>
    </IdentifierList>
);

type IdentifierMapping = { [key: string]: string };

const objectIdentifiersV2 = (
    pagePayload: V2PagePayload,
): IdentifierMapping | null => {
    const mtagId = pagePayload.legacy?.macros['tag:mtag_id'];

    const identifierData: IdentifierMapping = (
        mtagId
            ? { 'mTAG ID': mtagId }
            : {}
    );
    const usesUid = pagePayload.legacy?.macros['tag:uses_uid'];
    const uid = pagePayload.legacy?.macros['tag:uid'];

    if (usesUid && uid) {
        identifierData.UID = uid;
    }

    const tagData = pagePayload.legacy?.macros['tag:data_json'] ?? {} as IdentifierMapping;

    return {
        ...identifierData,
        ...R.pick(
            RELEVANT_IDENTIFIER_TAG_DATA,
            tagData,
        ),
    };
};

const objectIdentifierV3 = (
    pagePayload: V3PagePayload,
): IdentifierMapping | null => {
    const obj = pagePayload.sdkData.object;

    if (!obj) { return null; }

    const UUID = obj.uuid;

    return { UUID };
};

const ObjectIdentifier = (): React.ReactElement => {
    const selectedPayload = useSelector(getPagePayload);

    if (LoadableState.isLoading(selectedPayload)) {
        return <div />;
    }

    if (!LoadableState.isReady(selectedPayload)) {
        return (<UnknownIdentifiers />);
    }

    const pagePayload = LoadableState.unwrap(selectedPayload);

    const identifiers = (
        isV2(pagePayload)
            ? objectIdentifiersV2(pagePayload)
            : objectIdentifierV3(pagePayload)
    );

    if (!identifiers) { return <UnknownIdentifiers />; }

    return (
        <IdentifierList>
            {
                R.map(([prop, value]) => (
                    <li key={prop}>
                        <b>
                            {`${prop}: `}
                        </b>
                        {value}
                    </li>
                ), R.toPairs(identifiers))
            }
        </IdentifierList>
    );
};

export default ObjectIdentifier;
