import * as R from 'ramda';
import queryString from 'query-string';

const mapKey = {
    studioId: 'studio_id',
};

type QueryStringParams = {
    studioId?: string;
};

const getQueryStringParameters = (): QueryStringParams => {
    const qs = queryString.parse(
        window.location.search,
    ) as { [key: string]: string };
    return R.filter(
        (i) => !!i,
        R.map((v) => qs[v], mapKey),
    ) as QueryStringParams;
};

export default getQueryStringParameters;
