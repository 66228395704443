import * as R from 'ramda';

const DISABLE_BUTTON = 'BUTTON/DISABLE';
const ENABLE_BUTTON = 'BUTTON/ENABLE';

export const disableButton = (buttonId) => ({
    buttonId,
    type: DISABLE_BUTTON,
});


export const enableButton = (buttonId) => ({
    buttonId,
    type: ENABLE_BUTTON,
});

const defaultState = {
    disabledButtons: {},
};

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case DISABLE_BUTTON:
            return R.assocPath(
                ['disabledButtons', action.buttonId],
                true,
                state,
            );
        case ENABLE_BUTTON:
            return R.assocPath(
                ['disabledButtons', action.buttonId],
                false,
                state,
            );
        default:
            return state;
    }
};

export default reducer;

export const isDisabled = (buttonId) => (
    (state) => !!state.buttons.disabledButtons[buttonId]
);
