import { MetadataOptions } from '../components/shared/meta';

const recreateMouseEvent = (options: MetadataOptions['event']) => new MouseEvent('click', options);

export const navigate = (link: string, { event }: MetadataOptions): void => {
    if (event) {
        const element = document.createElement('a');
        element.setAttribute('href', link);
        if (event?.target) {
            element.setAttribute('target', event.target);
        }
        const evt = recreateMouseEvent(event);
        element.dispatchEvent(evt);
    } else {
        // otherwise do default assumed action
        window.location.assign(link);
    }
};
