import { ExperienceManager } from './manager';
import { unwrapExists } from '../../../functions/utils';
import { MessageEmitter } from './types';

export class ExperienceManagerInitializer {
    #promise: Promise<ExperienceManager>;

    #done: ((manager: ExperienceManager) => void) | undefined;

    #messageEmitter: MessageEmitter | undefined;

    constructor() {
        this.#promise = new Promise<ExperienceManager>((resolve) => {
            this.#done = resolve;
        });
    }

    waitForInitialization(): Promise<ExperienceManager> {
        return this.#promise;
    }

    checkDone(): boolean {
        if (this.#messageEmitter) {
            const manager = new ExperienceManager(this.#messageEmitter);
            manager.connect();
            unwrapExists(this.#done)(manager);
            return true;
        }
        return false;
    }

    setup(messageEmitter: MessageEmitter): Promise<ExperienceManager> {
        this.#messageEmitter = messageEmitter;
        this.checkDone();
        return this.waitForInitialization();
    }
}
