import React, { ReactNode, useEffect, useState } from 'react';
import { BlueBiteSDK } from './store/store';

export const SDKContext = React.createContext<BlueBiteSDK | undefined>(undefined);

type SDKContextProviderProps = {
    children: ReactNode;
};

export const SDKContextProvider = ({ children }: SDKContextProviderProps) => {
    const [sdk, setSDK] = useState<BlueBiteSDK | undefined>();

    useEffect(() => {
        (async () => {
            setSDK(typeof window.BlueBiteSDK === 'function'
                ? await window.BlueBiteSDK()
                : undefined);
        })();
    }, []);

    return (
        <SDKContext.Provider value={sdk}>
            {children}
        </SDKContext.Provider>
    );
};
