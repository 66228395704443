import * as R from 'ramda';
import {
    useRef,
    useEffect,
    useState,
    useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    runMacroString,
    makeMacroScope,
    MacroScope,
    MacroEvalOptions,
    MacroCallsiteMetadata,
    MacroEvalContext,
    variableScope,
    pagePayloadScope,
} from '../../macros';
import * as RuntimePromise from '../../macros/promise';
import * as Logging from '../../utils/logging';
import {
    Err,
    Ok,
    Result,
    resultToPromise,
} from '../../macros/result';

// Limiting the scope of this change eventually we should
// rename context to callsite metadata to avoid confusion
// with the actual contexts for scope creation and evaluation
export type MacroContext = MacroCallsiteMetadata;

export { RuntimePromise };

export const useMacroScope = (callsiteMetadata: MacroCallsiteMetadata): MacroScope => {
    const [beforeMount, setBeforeMount] = useState(true);
    const dispatch = useDispatch();
    const variables = useSelector(variableScope);
    const sdkData = useSelector(pagePayloadScope);

    const selector = useMemo(
        () => makeMacroScope({
            callsiteMetadata,
            dispatch: (
                beforeMount
                    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
                    ? ((): void => {}) as any
                    : dispatch
            ),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, callsiteMetadata, beforeMount, variables, sdkData],
    );

    useEffect(() => { setBeforeMount(false); }, []);

    return useSelector(selector);
};

type MacroOptions = {
    optionName: string;
    componentName: string;
    timeoutAfter?: number;
    timeoutValue?: RuntimePromise.Type<string, string>;
};

export type UseMacrosState<A extends string> = RuntimePromise.Type<
    Record<A, string>,
    Partial<Record<A, string>>
>;

export const mapPairs = <A extends string, B, C extends string, D>(
    func: (a: [A, B]) => [C, D],
    r: Record<A, B>,
): Record<C, D> => R.pipe(
    R.toPairs as any as (r: Record<A, B>) => Array<[A, B]>,
    R.map(func) as (r: Array<[A, B]>) => Array<[C, D]>,
    R.fromPairs as any as (r: Array<[C, D]>) => Record<C, D>,
)(r);

const timeoutErrorMessage = (time: number): string => `rendering macro timedout after ${time}ms`;

export const useMacro = (
    macro: string,
    scope: MacroScope,
    optionsRaw: MacroOptions,
): RuntimePromise.Type<string, string> => {
    // This first value passed into options is the only one ever used
    const [{
        componentName,
        optionName,
        timeoutAfter,
        timeoutValue,
    }] = useState(optionsRaw);

    const errors = useRef<Array<string>>([]);


    const [shouldFallback, setShouldFallback] = useState(false);
    const getNewValue = useMemo(() => (): RuntimePromise.Type<string, string> => {
        const newVal = runMacroString(macro, scope);
        if (RuntimePromise.isErr(newVal)) {
            const error = RuntimePromise.unwrapErr(newVal);
            if (!errors.current.includes(error)) {
                errors.current.push(error);
                Logging.logUserError(
                    Logging.GENERIC_MACRO_ERROR,
                    Logging.WHILE_RENDERING_COMPONENT,
                    {
                        component: componentName,
                        macro,
                        key: optionName,
                        error,
                    },
                );
            }
        }
        return newVal;
    }, [macro, componentName, optionName, scope]);
    const [val, setVal] = useState<RuntimePromise.Type<string, string>>(getNewValue);

    useEffect(() => {
        if (timeoutAfter) {
            const handle = setTimeout(() => {
                if (RuntimePromise.isUnresolved(val)) {
                    Logging.logUserError(
                        Logging.GENERIC_MACRO_ERROR,
                        Logging.WHILE_RENDERING_COMPONENT,
                        {
                            component: componentName,
                            macro,
                            key: optionName,
                            error: timeoutErrorMessage(timeoutAfter),
                        },
                    );
                    setShouldFallback(true);
                }
            }, timeoutAfter);
            return (): void => { clearTimeout(handle); };
        }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    // change to only on update
    useEffect(() => {
        if (!shouldFallback) {
            setVal(getNewValue());
        }
    }, [getNewValue, shouldFallback]);

    return (
        shouldFallback
            ? (
                // timeoutAfter should always be available here
                timeoutValue
                ?? RuntimePromise.Err(timeoutErrorMessage(timeoutAfter as number))
            )
            : val
    );
};

type MultiMacroOptions = Record<string, MacroEvalOptions>;
/* eslint-disable react-hooks/exhaustive-deps */
export const makeUseMacros = <
    A extends MultiMacroOptions,
>(
    component: string,
    macroOptions: A,
) => (
    (scope: MacroScope, macros: Record<keyof A, string>): UseMacrosState<keyof A & string> => {
        const [val, setVal] = useState<UseMacrosState<string>>(
            RuntimePromise.Unresolved,
        );
        const [errors, setErrors] = useState<Array<string>>([]);

        useEffect(() => {
            const newVal = RuntimePromise.allObject(mapPairs(
                ([
                    k,
                    evalOptions,
                ]): [string, RuntimePromise.Type<string, string>] => [
                    k,
                    runMacroString(macros[k as keyof A], scope, { evalOptions }),
                ],
                macroOptions,
            ));

            if (RuntimePromise.isErr(newVal)) {
                const newErrorPairs = R.filter(
                    ([, error]: [string, string]) => !errors.includes(error),
                    R.toPairs(
                        RuntimePromise.unwrapErr(newVal),
                    ) as Array<[string, string]>,
                );
                R.forEach(
                    ([k, error]: [string, string]) => Logging.logUserError(
                        Logging.GENERIC_MACRO_ERROR,
                        Logging.WHILE_RENDERING_COMPONENT,
                        {
                            component,
                            macro: macros[k as keyof A],
                            key: k,
                            error,
                        },
                    ),
                    newErrorPairs,
                );
                const newErrors = R.map(([, error]) => error, newErrorPairs);
                if (newErrors.length) {
                    setErrors([...newErrors, ...errors]);
                }
            }

            setVal(newVal);
        }, [scope, macros]);

        return val;
    }
);

export const makeUseMultiMacros = <
    A extends MultiMacroOptions,
>(
    component: string,
    macroOptions: A,
) => (
    (
        scope: MacroScope,
        macros: Record<keyof A, string>,
    ): Record<keyof A & string, RuntimePromise.RuntimePromise<string, string>> => {
        const macroKeys = useMemo(() => Object.keys(macros), [macros]);
        const [val, setVal] = useState<Record<keyof A, Result<string, string>>>();
        const errors = useRef<string[]>([]);

        useEffect(() => {
            if (macroKeys.length) {
                const nextVal: Record<string, Result<string, string>> = {};
                for (let i = 0; i < macroKeys.length; i += 1) {
                    const macroKey = macroKeys[i];
                    const macroContext: MacroEvalContext = {
                        evalOptions: macroOptions[macroKey],
                    };
                    const newVal = runMacroString(macros[macroKey as keyof A], scope, macroContext);

                    if (RuntimePromise.isErr(newVal)) {
                        const error = RuntimePromise.unwrapErr(newVal);
                        nextVal[macroKey] = Err(error);
                        if (!errors.current.includes(error)) {
                            Logging.logUserError(
                                Logging.GENERIC_MACRO_ERROR,
                                Logging.WHILE_RENDERING_COMPONENT,
                                {
                                    component,
                                    macro: macros[macroKey as keyof A],
                                    key: macroKey,
                                    error,
                                },
                            );
                            errors.current.push(error);
                        }
                    } else if (RuntimePromise.isSuccess(newVal)) {
                        nextVal[macroKey] = Ok(RuntimePromise.unwrap(newVal));
                    }
                }
                setVal(nextVal as Record<keyof A, Result<string, string>>);
            }
        }, [scope, macros]);

        return mapPairs(([k]: [keyof A & string, string]) => [
            k, val && val[k]
                ? resultToPromise(val[k])
                : RuntimePromise.Unresolved,
        ], macros);
    }
);
