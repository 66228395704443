import * as React from 'react';
import styled, { ThemeConsumer, DefaultTheme } from 'styled-components';

export const WidgetBody = styled.div`
    position: relative;
    background: #fff;
    font-size: 14px;
    color: #6b6b6b;
    margin: ${({ theme }): string => theme.componentMargin};
    border: ${({ theme }): string => theme.componentBorder};
    border-radius: ${({ theme }): string => theme.componentBorderRadius};
`;

const Widget = ({
    className,
    children,
}: React.PropsWithChildren<{ className?: string }>): React.ReactElement => (
    <ThemeConsumer>
        {(theme: DefaultTheme): React.ReactElement => {
            const Wrapper = theme?.showWidget ? WidgetBody : 'div';
            return <Wrapper className={className}>{ children }</Wrapper>;
        }}
    </ThemeConsumer>
);

export default Widget;
