/* eslint-disable max-len */
import React from 'react';

const ShowersDay = (): React.ReactElement => (
    <svg aria-label="showers at day icon" width="128" height="128" viewBox="0 0 128 128">
        <defs>
            <clipPath id="clip-path-day-showers">
                <rect id="Rectangle_5" data-name="Rectangle 5" width="86.558" height="82.134" fill="none" />
            </clipPath>
            <clipPath id="clip-ShowersDay">
                <rect width="128" height="128" />
            </clipPath>
        </defs>
        <g id="ShowersDay" clipPath="url(#clip-ShowersDay)">
            <rect width="128" height="128" fill="#fff" />
            <g id="Group_10" data-name="Group 10" transform="translate(21 13.977)">
                <g id="Group_9" data-name="Group 9" transform="translate(0 0)" clipPath="url(#clip-path-day-showers)">
                    <path id="Path_23" data-name="Path 23" d="M78.964,32.449a14.774,14.774,0,1,1-14.81-14.738,14.774,14.774,0,0,1,14.81,14.738" transform="translate(-7.614 -2.729)" fill="#f3d33f" />
                    <path id="Path_24" data-name="Path 24" d="M66.611,8.826a1.692,1.692,0,0,1-1.7-1.688L64.9,1.7a1.692,1.692,0,1,1,3.384-.008L68.3,7.129a1.692,1.692,0,0,1-1.688,1.7" transform="translate(-10 0)" fill="#f3d33f" />
                    <path id="Path_25" data-name="Path 25" d="M99.029,36.889l-5.443.014a1.692,1.692,0,1,1-.008-3.384L99.02,33.5a1.692,1.692,0,0,1,.008,3.384" transform="translate(-14.158 -5.162)" fill="#f3d33f" />
                    <path id="Path_26" data-name="Path 26" d="M38.782,37.038l-5.442.014a1.692,1.692,0,0,1-.008-3.384l5.442-.014a1.692,1.692,0,1,1,.008,3.384" transform="translate(-4.876 -5.185)" fill="#f3d33f" />
                    <path id="Path_27" data-name="Path 27" d="M85.823,17.217a1.692,1.692,0,0,1-1.2-2.885l3.839-3.857a1.692,1.692,0,1,1,2.4,2.387l-3.838,3.857a1.688,1.688,0,0,1-1.2.5" transform="translate(-12.962 -1.537)" fill="#f3d33f" />
                    <path id="Path_28" data-name="Path 28" d="M43.123,59.632a1.692,1.692,0,0,1-1.2-2.885l3.838-3.857a1.692,1.692,0,1,1,2.4,2.387l-3.838,3.857a1.688,1.688,0,0,1-1.2.5" transform="translate(-6.383 -8.072)" fill="#f3d33f" />
                    <path id="Path_29" data-name="Path 29" d="M46.863,16.945a1.687,1.687,0,0,1-1.2-.492l-3.858-3.839a1.692,1.692,0,1,1,2.387-2.4l3.857,3.838a1.692,1.692,0,0,1-1.189,2.891" transform="translate(-6.365 -1.498)" fill="#f3d33f" />
                    <path id="Path_30" data-name="Path 30" d="M89.792,59.624a1.691,1.691,0,0,1-1.184-.479l-3.9-3.795a1.692,1.692,0,1,1,2.36-2.425l3.9,3.794a1.692,1.692,0,0,1-1.176,2.9" transform="translate(-12.972 -8.081)" fill="#f3d33f" />
                    <path id="Path_31" data-name="Path 31" d="M66.76,69.2a1.692,1.692,0,0,1-1.7-1.688l-.014-5.442a1.692,1.692,0,1,1,3.384-.008l.014,5.442a1.692,1.692,0,0,1-1.688,1.7" transform="translate(-10.023 -9.302)" fill="#f3d33f" />
                    <path id="Path_32" data-name="Path 32" d="M54.53,37.468a17.895,17.895,0,0,0-33.981-5.8,17.9,17.9,0,1,0-2.6,35.6l36.189-.089a14.859,14.859,0,0,0,.389-29.712" transform="translate(0 -3.3)" fill="#e6e7ea" />
                    <path id="Path_33" data-name="Path 33" d="M24.4,94.489a1.692,1.692,0,0,1-1.7-1.688l-.027-10.918a1.692,1.692,0,1,1,3.384-.008l.027,10.918a1.692,1.692,0,0,1-1.688,1.7" transform="translate(-3.494 -12.355)" fill="#1a65ef" />
                    <path id="Path_34" data-name="Path 34" d="M45.865,94.436a1.692,1.692,0,0,1-1.7-1.688L44.141,81.83a1.692,1.692,0,0,1,3.384-.008l.028,10.918a1.693,1.693,0,0,1-1.688,1.7" transform="translate(-6.801 -12.347)" fill="#1a65ef" />
                    <path id="Path_35" data-name="Path 35" d="M35.12,89.02a1.692,1.692,0,0,1-1.7-1.688l-.014-5.477a1.692,1.692,0,0,1,3.384-.008l.014,5.477a1.692,1.692,0,0,1-1.688,1.7" transform="translate(-5.148 -12.351)" fill="#1a65ef" />
                    <path id="Path_36" data-name="Path 36" d="M56.584,88.967a1.693,1.693,0,0,1-1.7-1.688L54.874,81.8a1.691,1.691,0,1,1,3.383-.008l.014,5.477a1.692,1.692,0,0,1-1.687,1.7" transform="translate(-8.455 -12.343)" fill="#1a65ef" />
                </g>
            </g>
        </g>
    </svg>
);

export default ShowersDay;
