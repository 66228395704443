import * as React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
    CampaignOptions,
} from '../store/processes/studioFile/componentConfigs/campaign';
import {
    AdPayloadLetter,
    getAdPayload,
    getPagePayload,
    isV3,
} from '../store/processes/pagePayload';
import * as LoadableState from '../store/processes/loadableState';
import {
    CAMPAIGN_A_COMPONENT_TYPE_ID,
    CAMPAIGN_B_COMPONENT_TYPE_ID,
    CAMPAIGN_C_COMPONENT_TYPE_ID,
    CAMPAIGN_D_COMPONENT_TYPE_ID,
} from '../store/processes/studioFile';
import { ComponentProps } from './shared/types';

const TYPEID_MAP: Record<string, 'a' | 'b' | 'c' | 'd'> = {
    [CAMPAIGN_A_COMPONENT_TYPE_ID]: 'a',
    [CAMPAIGN_B_COMPONENT_TYPE_ID]: 'b',
    [CAMPAIGN_C_COMPONENT_TYPE_ID]: 'c',
    [CAMPAIGN_D_COMPONENT_TYPE_ID]: 'd',
};

const Content = styled.div`
    width: 100%;
`;

const ContentWrapper = styled.div`
    position: relative;
`;

const Image = styled.img`
    width: calc(100% - 2px);
    display: block;
    border-radius: 2.5px;
    border: solid 1px rgba(0, 0, 0, 0.08);
`;

type CampaignRootProps = {
    options: CampaignOptions & { letter: AdPayloadLetter };
};

export const CampaignRoot = ({
    options,
}: CampaignRootProps): React.ReactElement | null => {
    const loadablePagePayload = useSelector(getPagePayload);

    if (!LoadableState.isReady(loadablePagePayload)) {
        return null;
    }

    const pagePayload = LoadableState.unwrap(loadablePagePayload);

    if (isV3(pagePayload)) {
        return null;
    }

    const { letter } = options;

    const adPayload = options?.configuration === 'static'
        ? { url: options.link, data: options.source }
        : getAdPayload(pagePayload, letter);

    return (
        !adPayload.data ? null
            : (
                <Content>
                    <ContentWrapper>
                        {adPayload.url
                            ? (
                                <a
                                    href={adPayload.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Image src={adPayload.data} />
                                </a>
                            )
                            : <Image src={adPayload.data} />}
                    </ContentWrapper>
                </Content>
            )
    );
};

type CampaignProps = ComponentProps<CampaignOptions>;

const Campaign = ({
    typeId,
    options,
}: CampaignProps): React.ReactElement | null => {
    const letter = TYPEID_MAP[typeId];

    return (
        <CampaignRoot
            options={{ ...options, letter }}
        />
    );
};

export default Campaign;
