import { pipe, replace, filter } from 'ramda';

export const compact = filter((i) => !!i);

export const chomp = pipe(
    replace(/^\s+/, ''),
    replace(/\s+$/, ''),
);

export const isExisting = <T>(val: T): val is Exclude<T, null | undefined> => (
    val != null
);

export const unwrapExists = <T>(val: T): Exclude<T, null | undefined> => {
    if (!isExisting(val)) {
        throw new Error('asserted value is nullish');
    }
    return val;
};
