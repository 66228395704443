import { SagaIterator } from 'redux-saga';
import {
    call, takeEvery, select, fork,
} from 'redux-saga/effects';
import { loadPagePayloadSaga, getPagePayload, LocalState as PagePayloadState } from './pagePayload';
import { setRootStudioFileSaga } from './rootStudioFile';
import { loadStudioFile, loadStudioFileSaga } from './studioFile';
import * as LoadableState from './loadableState';
import { initEditorListener } from './xpManager/saga';
import { getNavActionEnabled } from '../../functions/previewMode';

const GLOBAL_INIT = 'GLOBAL_INIT';

type GlobalInitAction = {
    type: typeof GLOBAL_INIT;
};

export const globalInit = (): GlobalInitAction => ({
    type: GLOBAL_INIT,
});

function* globalInitSaga(): SagaIterator<void> {
    yield call(loadPagePayloadSaga);
    const pagePayloadPromise = (yield select(getPagePayload)) as PagePayloadState;
    // Only set root studio file during initialization
    if (LoadableState.isReady(pagePayloadPromise)) {
        const { experienceStudioId } = LoadableState.unwrap(pagePayloadPromise);
        yield call(setRootStudioFileSaga, experienceStudioId);
        if (__IS_PREVIEW__) {
            yield fork(initEditorListener);
        }
        // load studio file last so everything is available only non preview or when navaction is set
        if (!__IS_PREVIEW__ || !getNavActionEnabled()) {
            yield call(loadStudioFileSaga, loadStudioFile(experienceStudioId));
        }
    }
}

export function* saga(): SagaIterator<void> {
    yield takeEvery(GLOBAL_INIT, globalInitSaga);
}
