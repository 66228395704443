import { WebpackModFedContainer } from './type';

const underscoreUuid = (uuid: string): string => uuid.replace(/-/g, '_');

export const initSharing = async () => {
    if (!__webpack_share_scopes__?.default) {
        await __webpack_init_sharing__('default');
    }
};

export const initContainer = async (containerScope: any) => {
    try {
        if (!containerScope.__initialized
            && !containerScope.__initializing) {
            /* eslint-disable no-param-reassign */
            containerScope.__initializing = true;
            await containerScope.init(__webpack_share_scopes__.default);
            containerScope.__initialized = true;
            /* eslint-restore no-param-reassign */
            delete containerScope.__initializing;
        }
    } catch (error) {
        console.error(error);
    }
};

export const makeScope = (uuid: string) => `component${underscoreUuid(uuid)}`;

export const makeScriptId = (
    uuid: string,
    version: string,
) => `custom_component_${underscoreUuid(uuid)}v${version}`;

export const makeScriptPath = (
    uuid: string,
    version: string,
) => `/assets/components/${uuid}/${version}`;

export const getModFedContainer = (uuid: string)
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    : WebpackModFedContainer | undefined => (window[makeScope(uuid)]);
