export const SECOND = 1000;

// set initially
let timeDelta = window.macroPageLoadEpoch && __IS_PREVIEW__
    ? Date.now() - window.macroPageLoadEpoch
    : 0;

// allow delta to be updated
export const updateTimeDelta = (macroPageLoadEpoch: number) => {
    if (macroPageLoadEpoch && __IS_PREVIEW__) {
        timeDelta = Date.now() - macroPageLoadEpoch;
    }
};

export const getTime = (): number => Math.floor(
    (Date.now() - timeDelta) / SECOND,
);
