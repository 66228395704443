import { ActionOptions } from './shared/action';
import { FileReference } from '..';

export const IMAGE_SOURCE_UPLOAD = 'upload' as const;
export const IMAGE_SOURCE_URL = 'url' as const;
export const IMAGE_SOURCE_NONE = 'none' as const;

export type ImageSource =
    | typeof IMAGE_SOURCE_UPLOAD
    | typeof IMAGE_SOURCE_URL
    | typeof IMAGE_SOURCE_NONE;

export type ImageOptions = (
    ActionOptions
    & {
        source: ImageSource;
        src?: FileReference;
        alt?: string;
    }
);

type ImageConfig = {
    options: ImageOptions;
};

export default ImageConfig;
