import Cookies from 'js-cookie';

// This is standardized code suggested by mozilla for detecting storage
/* eslint-disable */
function storageAvailable(type) {
    try {
        var storage = window[type],
            x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0;
    }
}
/* eslint-enable */

const wrapFailure = (func) => (...args) => {
    try { return func(...args); } catch (e) {
        console.error(e);
        return null;
    }
};

export default (
    storageAvailable('localStorage')
        ? {
            get: wrapFailure((key) => window.localStorage.getItem(key)),
            set: wrapFailure((key, val) => {
                window.localStorage.setItem(key, val);
            }),
        }
        : {
            get: wrapFailure((key) => Cookies.get(key)),
            set: wrapFailure((key, val) => { Cookies.set(key, val); }),
        }
);
