import { MouseEvent } from 'react';
import { fireAction } from '../../store/processes/actions';
import {
    ACTION_TYPE_LINK,
} from '../../store/processes/studioFile/componentConfigs/shared/action';

import { MetadataOptions } from './meta';
import { ImageOptions } from '../../store/processes/studioFile/componentConfigs/image';
import { ButtonOptions } from '../../store/processes/studioFile/componentConfigs/button';

export const displayTemperature = (num: number): string => {
    // remove decimal and rounds positives down and negatives up
    const n = Math.round(num);
    // remove negative zero because that's very confusing
    return n === 0 ? `${Math.abs(n)}°` : `${n}°`;
};

type ActionHandler = {
    componentName: string;
    isDisabled: boolean;
    dispatch: (f: any) => void;
    options: ImageOptions | ButtonOptions;
    id: string;
    typeId: string;
    formId: string | null;
};

const handleDisabled = (e?: MouseEvent): void => { if (e) { e.preventDefault(); } };
const extractFromEvent = (e: MouseEvent, targetBlank: boolean): MetadataOptions['event'] => {
    e.preventDefault();
    const {
        ctrlKey, shiftKey, altKey, metaKey, button,
    } = e;
    return {
        ctrlKey, shiftKey, altKey, metaKey, button, target: targetBlank ? '_blank' : null,
    };
};

export const handleLinkAction = ({
 isDisabled, dispatch, options, id, typeId, formId, componentName,
}: ActionHandler) => (isDisabled
    ? handleDisabled
    : (e?: MouseEvent): void => {
        const event = e
            ? extractFromEvent(e, (options.action === ACTION_TYPE_LINK && !!options.targetNewTab))
            : undefined;

        dispatch(fireAction({
            ...options,
            meta: {
                component: componentName,
                firingComponentId: id,
                firingComponentType: {
                    name: componentName,
                    id: typeId,
                },
                formId,
                event,
            },
        }));
    });
